import {
	Box,
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider as MuiDivider,
	Fade,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@material-ui/core";
import {
	Add as AddIcon,
	PictureAsPdf,
	PieChart,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useEffect, useMemo } from "react";
import { ChevronRight, Edit, Trash2 } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
	NavLink,
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import { Opener } from "../../components/CheckPro/Opener";
import { DropdownMenu } from "../../components/Generic/DropdownMenu";
import { NewConfirmDialog } from "../../components/NewConfirmDialog";
import { TableNoContent } from "../../components/TableNoContent";
import { TableSkeleton } from "../../components/TableSkeleton";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../../constants";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
import { useTable } from "../../hooks/useTable";
import { getBusiness } from "../../redux/actions/businessActions";
import {
	addList,
	deleteList,
	getLists,
	setSelectedList,
	updateList,
} from "../../redux/actions/listsActions";
import { getOneProject } from "../../redux/actions/projectsActions";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { IListsState } from "../../redux/reducers/listsReducer";
import { IProjectsState } from "../../redux/reducers/projectsReducer";
import { IRevisionsState } from "../../redux/reducers/revisionsReducer";
import { RootState } from "../../redux/store/reducer";
import { useStyles } from "../../theme/useStyles";
import { timesStampToDateFormatted } from "../../utils/utils";
import { DialogNewEdit } from "./components/DialogNewEdit";
import { useState } from "react";
import Axios from "axios";
import { Empresa } from "src/models/Empresa";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface Props {
	array: any[];
	isLoading: boolean;
	totalDocs: number;
	openEditDialog: (selected: any) => void;
	openConfirmDialog: (selected: any) => any;
	projectId: string;
	revisionId: string;
	selectedBusiness?: Empresa | null;
	listado?: any;
}

const ContentCard: React.FC<Props> = ({
	array,
	isLoading,
	totalDocs,
	projectId,
	revisionId,
	openEditDialog,
	openConfirmDialog,
	selectedBusiness,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	// const { orderBy } = useSelector<RootState, IListsState>(
	// 	(state) => state.listsReducer
	// );

	const {
		limit,
		handleLimitChange,
		handlePageChange,
		page,
	} = useTable({
		limit: TABLE_LIMIT_DEFAULT || 5,
	});

	const [modalResumen, setModalResumen] = useState(false);
	const [listado, setListado] = useState<any>(null);
	const [generando, setGenerando] = useState([
		false,
		false,
	]);

	const handlePdfDownload = async (
		ordenarPorSubcontrato: boolean,
		i = 0
	) => {
		if (!listado) return;

		setGenerando((g) => {
			const _g = [...g];
			_g[i] = true;
			return _g;
		});

		try {
			const response = await Axios.post(
				"https://us-central1-checkpro-3a90a.cloudfunctions.net/generateLegacyProjectSummary",
				{
					textoFooter:
						selectedBusiness?.PiePagina || "CheckPro",
					listadoId: listado.id,
					userId: listado.Responsable.FirebaseId,
					orderBy: ordenarPorSubcontrato
						? "subcontrato"
						: "recinto",
					// ordenarPorSubcontrato,
				}
			);

			if (!response.data) throw Error("No data");
			window.open(response.data.url, "_blank");
		} catch (error: any) {
			console.error(error);
			alert("Ocurrió un error: " + error.message);
		}

		setGenerando((g) => {
			const _g = [...g];
			_g[i] = false;
			return _g;
		});
	};

	const [orderBy, setOrderBy] = useState<
		"Nombre" | "FechaCreacion" | null
	>("Nombre");

	const filteredArray = useMemo(() => {
		if (!orderBy) return array;

		if (orderBy === "Nombre") {
			return array.sort((a, b) =>
				a.Nombre.localeCompare(b.Nombre)
			);
		} else {
			return array.sort(
				(a, b) =>
					b.FechaCreacion.toDate() -
					a.FechaCreacion.toDate()
			);
		}
	}, [array, orderBy]);

	const theme = useTheme();

	return (
		<>
			<Card mb={6}>
				<CardHeader
					action={
						<FormControl
							style={{ width: 300 }}
							variant="outlined"
							size="small"
						>
							<InputLabel>Ordenar por</InputLabel>
							<Select
								label="Ordenar por"
								value={orderBy}
								onChange={(ev) =>
									setOrderBy(ev.target.value as any)
								}
							>
								<MenuItem value="Nombre">Nombre</MenuItem>
								<MenuItem value="FechaCreacion">
									Fecha de creación
								</MenuItem>
							</Select>
						</FormControl>
					}
				/>
				<CardContent>
					<TableContainer
						className={classes.tableContainer}
					>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Nombre</TableCell>
									<TableCell>Observaciones</TableCell>
									<TableCell>Encargado</TableCell>
									<TableCell>Cargo</TableCell>
									<TableCell>Fecha de Creación</TableCell>
									<TableCell align="center">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!isLoading ? (
									filteredArray
										.slice(
											page * limit,
											page * limit + limit
										)
										.map((data: any, i: any) => (
											<Fade key={i} in={true}>
												<TableRow
													hover
													className={classes.styledRow}
												>
													<TableCell>
														{data.Nombre}
													</TableCell>
													<TableCell>
														{data?.ObservacionesResueltas ||
															0}
														{" / "}
														{data?.ObservacionesTotales ||
															0}
													</TableCell>
													<TableCell>
														{
															data.Responsable
																.NombreCompleto
														}
													</TableCell>
													<TableCell>
														{data.Responsable?.Cargo}
													</TableCell>
													<TableCell>
														{timesStampToDateFormatted(
															data.FechaCreacion
														)}
													</TableCell>
													<TableCell
														style={{ whiteSpace: "nowrap" }}
														align="center"
													>
														<DropdownMenu
															Opener={Opener}
															options={[
																{
																	label: "Editar",
																	icon: (
																		<Edit
																			size={22}
																			color={
																				theme.palette
																					.primary.main
																			}
																		/>
																	),
																	onClick: () =>
																		openEditDialog(data),
																},
																{
																	label: "Eliminar",
																	icon: (
																		<Trash2
																			color="red"
																			size={22}
																		/>
																	),
																	onClick: () =>
																		openConfirmDialog(data),
																},
																{
																	label: "Estadísticas",
																	icon: (
																		<PieChart color="primary" />
																	),
																	onClick: () =>
																		history.push(
																			`/administrador/estadisticas/${projectId}/${revisionId}/${data.id}
`
																		),
																},
																{
																	label:
																		"Solicitar informe",
																	icon: (
																		<PictureAsPdf htmlColor="red" />
																	),
																	onClick: () => {
																		setListado(data);
																		setModalResumen(true);
																	},
																},
															]}
														/>

														<Tooltip title="Observaciones">
															<IconButton
																color="primary"
																size="small"
																onClick={() => {
																	dispatch(
																		setSelectedList(data)
																	);
																	history.push(
																		`listados/${data.id}/observaciones`
																	);
																}}
															>
																<ChevronRight />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											</Fade>
										))
								) : !isLoading && array.length === 0 ? (
									<TableNoContent colSpan={5} />
								) : (
									<TableSkeleton
										colSpan={5}
										limit={limit}
									/>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={filteredArray.length}
						onChangePage={handlePageChange as any}
						onChangeRowsPerPage={handleLimitChange}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={TABLE_LIMITS}
					/>
				</CardContent>
			</Card>
			<Dialog
				open={modalResumen}
				onClose={() => setModalResumen(false)}
			>
				<DialogTitle style={{ textAlign: "center" }}>
					Ordenar por:
				</DialogTitle>
				<DialogContent
					style={{
						display: "flex",
						flexDirection: "row",
						gap: 5,
					}}
				>
					<Button
						disabled={generando.some((i) => i)}
						style={{ minWidth: 150 }}
						variant="contained"
						color="primary"
						onClick={() => {
							handlePdfDownload(true, 1);
						}}
					>
						{generando[1] ? (
							<CircularProgress size={20} />
						) : (
							"Por subcontrato"
						)}
					</Button>

					<Button
						disabled={generando.some((i) => i)}
						style={{ minWidth: 150 }}
						variant="contained"
						fullWidth
						color="primary"
						onClick={() => {
							handlePdfDownload(false);
						}}
					>
						{generando[0] ? (
							<CircularProgress size={20} />
						) : (
							"Por recinto"
						)}
					</Button>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setModalResumen(false)}>
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export const ListsPage = () => {
	const { businessId, projectId, revisionId } =
		useParams<any>();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const isFromBusiness = pathname.startsWith(
		"/administrador/empresas/"
	);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const {
		revisions: {
			selectedProject,
			isLoading: projectIsLoading,
		},
	} = useSelector<RootState, IProjectsState>(
		(state) => state.projectsReducer
	);

	const { selectedRevision } = useSelector<
		RootState,
		IRevisionsState
	>((state) => state.revisionsReducer);

	const { lists, isLoading, totalDocs, updateLoading } =
		useSelector<RootState, IListsState>(
			(state) => state.listsReducer
		);

	useEffect(() => {
		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		}
		if (isFromBusiness && !selectedBusiness) {
			dispatch(getBusiness(businessId));
		}
	}, [
		dispatch,
		businessId,
		projectId,
		revisionId,
		selectedProject,
		selectedRevision,
		selectedBusiness,
		isFromBusiness,
		lists,
	]);

	useEffect(() => {
		if (!getLists) return;
		dispatch(getLists(projectId, revisionId));
		// dispatch(getTotalDocs(projectId, revisionId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId, revisionId]);

	const {
		open,
		openEditDialog,
		openNewDialog,
		closeDialog,
		onAccept,
		action,
		selected,
		setIsLoading: setIsLoadingNewEditDialog,
	} = useNewEditDialog<any>({
		onAccept: (values, action) => {
			if (action === "New") {
				dispatch(addList(values));
			} else if (action === "Edit") {
				dispatch(
					updateList(values, selected.id, revisionId)
				);
			}
		},
	});

	const {
		open: openConfirmDialog,
		setIsLoading: setIsLoadingConfirmDialog,
		dialogConfirmHandleClose,
		dialogConfirmHandleConfirm,
		openDialog,
		selected: confirmDialogSelected,
	} = useConfirmDialog({
		onConfirm: (selected) => {
			dispatch(deleteList(selected.id));
		},
	});

	useEffect(() => {
		setIsLoadingNewEditDialog(updateLoading);
		setIsLoadingConfirmDialog(updateLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLoading]);

	// noinspection ConstantConditionalExpressionJS
	return (
		<React.Fragment>
			<Helmet title="Listados" />
			<Grid justify="space-between" container>
				<Grid item>
					<Box>
						<Typography
							variant="h3"
							gutterBottom
							display="inline"
						>
							{/** @ts-ignore */}
							{`${selectedBusiness?.Nombre} / ${selectedProject?.NombreProyecto} / ${selectedRevision?.Nombre}`}
						</Typography>
					</Box>

					{isFromBusiness ? (
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							<Link
								component={NavLink}
								exact
								to="/administrador/empresas"
							>
								Empresas
							</Link>
							<Link
								component={NavLink}
								exact
								to={`/administrador/empresas/${businessId}/proyectos`}
							>
								Proyectos
							</Link>
							<Link
								component={NavLink}
								exact
								to={`/administrador/empresas/${businessId}/proyectos/${projectId}/revisiones`}
							>
								Revisiones
							</Link>
							<Typography>Listados</Typography>
						</Breadcrumbs>
					) : (
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							{/*TODO: Modificar en vistas de cliente*/}
							<Link
								component={NavLink}
								exact
								to="/revisiones/proyectos"
							>
								Proyectos
							</Link>
							<Link
								component={NavLink}
								exact
								to="/revisiones/proyectos"
							>
								Revisiones
							</Link>
							<Typography>Listados</Typography>
						</Breadcrumbs>
					)}
				</Grid>
				<Grid item>
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={() => openNewDialog()}
						>
							<AddIcon />
							Nuevo Listado
						</Button>
					</div>
				</Grid>
			</Grid>
			<Divider my={6} />
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard
						selectedBusiness={selectedBusiness}
						projectId={projectId}
						revisionId={revisionId}
						array={lists}
						isLoading={isLoading}
						totalDocs={totalDocs}
						openEditDialog={openEditDialog}
						openConfirmDialog={openDialog}
					/>
				</Grid>
			</Grid>

			{!projectIsLoading && (
				<React.Fragment>
					<DialogNewEdit
						open={open}
						onClose={closeDialog}
						onAccept={onAccept}
						isLoading={updateLoading}
						action={action}
						title="Lista"
						selected={selected}
						project={selectedProject}
					/>
					<NewConfirmDialog
						open={openConfirmDialog}
						title={`¿Desea borrar la lista ${confirmDialogSelected?.Nombre}?`}
						content={
							<Alert severity="warning">
								Se borraran los Listado, y Observaciones que
								contenía esta Revisión
							</Alert>
						}
						onClose={dialogConfirmHandleClose}
						isLoading={updateLoading}
						onConfirm={dialogConfirmHandleConfirm}
						onConfirmText="Borrar"
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
