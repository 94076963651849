import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import {
	firestore,
	firebaseConfig,
} from "../../firebase/firebase";
import firebase from "firebase/app";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { cleanString, sortByName } from "../../utils/utils";
import Axios from "axios";
import Firebase from "firebase";
import {
	encontrarEntrada,
	encontrarIndexEntrada,
	entradaExiste,
	quitarEntradasRepetidas,
} from "../../services/empresas";
import {
	Empresa,
	Subcontrato,
	ToggableProyecto,
} from "../../models/Empresa";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";

const API_KEY = firebaseConfig.apiKey;

const listener = FirebaseListener.addListener("business");

const deleteReference = (obj: any) => {
	if (obj.EmpresaReference) {
		delete obj.EmpresaReference;
	}
	return obj;
};

export const getBusinesses = (): AppThunk => {
	return async (dispatch, getState) => {
		const { businessFilter } = getState().businessReducer;

		dispatch(isLoading(true));
		listener.close();
		listener.set(
			firestore
				.collection("Empresas")
				.orderBy("Nombre_lower")
				.where("Activado", "==", businessFilter)
				.onSnapshot(
					(snap) => {
						const data = snap.docs.map((d) => {
							const data = mapOneBusiness(d);

							if (
								d.id ===
								getState().businessReducer.edit
									.selectedBusiness?.id
							) {
								dispatch(setSelectedBusiness(data));
							}

							return data;
						});

						dispatch(
							setBusiness(
								data,
								data.length,
								data[data.length - 1]
							)
						);

						dispatch(isLoading(false));
					},

					(error) => {
						console.error(error);
						dispatch(isLoading(false));
					}
				)
		);
	};
};

export const getBusiness = (byId?: string): AppThunk => {
	return async (dispatch, getState) => {
		const businessId =
			byId ??
			getState().authReducer.user?.empresaReference.id;

		if (!businessId) throw Error("No business id.");

		dispatch(setSelectedBusiness(null));
		listener.close();
		listener.set(
			firestore
				.collection("Empresas")
				.doc(businessId)
				.onSnapshot(
					(snap) => {
						const data = mapOneBusiness(snap);

						dispatch(setSelectedBusiness(data));
					},

					(error) => {
						console.error(error);
					}
				)
		);
	};
};

export const getOneBusinessChecklist = (): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const { user } = getState().authReducer;
			if (!user) throw Error("User is not logged.");

			dispatch(isEditLoading(true));
			const response = await firestore
				.collection("Empresas")
				.doc(user.empresaReference?.id)
				.get();

			dispatch(
				setSelectedBusiness(mapOneBusiness(response))
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isEditLoading(false));
		}
	};
};

export const reloadDefaultTables = (
	business: any
): AppThunk => {
	return async (dispatch) => {
		dispatch(isUpdateLoading(true));
		try {
			const [
				chargesResponse,
				subContractResponse,
				placesResponse,
				sectorsResponse,
				prioritiesResponse,
			] = await Promise.all([
				firestore.collection("Cargos").get(),
				firestore.collection("Subcontratos").get(),
				firestore.collection("Recintos").get(),
				firestore.collection("Sectores").get(),
				firestore.collection("Prioridades").get(),
			]);

			const updateData = {
				Cargos: chargesResponse.docs.map((d) => ({
					...d.data(),
					Activado: true,
				})),
				Subcontratos: subContractResponse.docs.map((d) =>
					deleteReference(d.data())
				),
				Recintos: placesResponse.docs.map((d) =>
					deleteReference(d.data())
				),
				Sectores: sectorsResponse.docs.map((d) =>
					deleteReference(d.data())
				),
				Prioridades: prioritiesResponse.docs.map((d) =>
					deleteReference(d.data())
				),
			};

			await firestore
				.collection("Empresas")
				.doc(business.id)
				.update(updateData);

			dispatch(actualizarEmpresaActual(updateData as any));
			dispatch(
				openSnack("Empresa Recargada", SnackState.SUCCESS)
			);
			dispatch(
				actualizarEmpresaActual({
					...(updateData as any),
					id: business.id,
				})
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isUpdateLoading(false));
		}
	};
};

export const addBusiness = (business: any): AppThunk => {
	return async (dispatch) => {
		dispatch(isEditLoading(true));
		try {
			//
			const businessCheck = firestore
				.collection("Empresas")
				.where("Rut", "==", business.Rut);
			const querySnapshot = await businessCheck.get();

			if (querySnapshot.docs.length === 0) {
				const [
					chargesResponse,
					subContractResponse,
					placesResponse,
					sectorsResponse,
					prioritiesResponse,
				] = await Promise.all([
					firestore.collection("Cargos").get(),
					firestore.collection("Subcontratos").get(),
					firestore.collection("Recintos").get(),
					firestore.collection("Sectores").get(),
					firestore.collection("Prioridades").get(),
				]);

				await firestore.collection("Empresas").add({
					...business,
					Nombre_lower: business.Nombre.toLowerCase(),
					Cargos: chargesResponse.docs.map((d) => ({
						...d.data(),
						Activado: true,
					})),
					Subcontratos: subContractResponse.docs.map((d) =>
						deleteReference(d.data())
					),
					Recintos: placesResponse.docs.map((d) =>
						deleteReference(d.data())
					),
					Sectores: sectorsResponse.docs.map((d) =>
						deleteReference(d.data())
					),
					Prioridades: prioritiesResponse.docs.map((d) =>
						deleteReference(d.data())
					),
					Activado: true,
				});
				dispatch(getBusinesses());
				dispatch(
					openSnack("Empresa Agregada", SnackState.SUCCESS)
				);
			} else {
				dispatch(
					openSnack(
						"Rut de empresa ya existe",
						SnackState.ERROR
					)
				);
			}
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isEditLoading(false));
		}
	};
};

export const updateBusiness = (
	business: Partial<Empresa>
): AppThunk => {
	return async (dispatch) => {
		dispatch(isUpdateLoading(true));

		try {
			const { id, ..._business } = business;
			//
			if (!id)
				throw new Error(
					"Falta el id de la Empresa al actualizar."
				);

			const updateData = { ..._business };
			if (business.Nombre)
				updateData.Nombre_lower = cleanString(
					business.Nombre
				);

			await firestore
				.collection("Empresas")
				.doc(business.id)
				.update(updateData);
		} catch (error: any) {
			console.error(error);
			dispatch(setError(error));
		}

		dispatch(isUpdateLoading(false));
	};
};

export const mapBusiness = async (
	response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
	let business: Empresa[] = [];
	response.docs.forEach((doc) => {
		business.push(mapOneBusiness(doc));
	});
	return business;
};

const mapOneBusiness = (
	doc: Firebase.firestore.DocumentSnapshot
) => {
	let data = doc.data()! as Empresa;
	data.id = doc.id;

	if (data.Recintos) data.Recintos.sort(sortByName);
	if (data.Cargos) data.Cargos.sort(sortByName);
	if (data.Sectores) data.Sectores.sort(sortByName);
	if (data.Subcontratos) data.Subcontratos.sort(sortByName);
	data.Subcontratos.forEach((subcontrato, i) => {
		if (subcontrato.ErroresTipo)
			data.Subcontratos[i].ErroresTipo.sort((a, b) =>
				a.Nombre.localeCompare(b.Nombre)
			);
	});

	return { ...data, _ref: doc.ref, id: doc.id } as Empresa;
};

export const desactivarEmpresa = (
	businessId: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isUpdateLoading(true));
		try {
			//
			await firestore
				.collection("Empresas")
				.doc(businessId)
				.update({
					Activado: false,
					FechaEliminacion:
						firebase.firestore.FieldValue.serverTimestamp(),
				});

			dispatch(
				openSnack("Empresa Eliminada", SnackState.SUCCESS)
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isUpdateLoading(false));
		}
	};
};

export const addMoreBusiness = (
	limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));

		const lastDoc =
			getState().businessReducer.lastDoc || "";
		const { totalDocs } = getState().businessReducer;
		const { businessFilter } = getState().businessReducer;
		try {
			const response = await firestore
				.collection("Empresas")
				.orderBy("Nombre_lower")
				.where("Activado", "==", businessFilter)
				.startAfter(lastDoc)
				.limit(limit)
				.get();
			dispatch(setTotalDocs(totalDocs));
			dispatch(
				addMoreBusinessesToState(
					await mapBusiness(response)
				)
			);
			dispatch(
				setLastDoc(response.docs[response.docs.length - 1])
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const getTotalDocs = (): AppThunk => {
	return async (dispatch) => {
		try {
			//
			const response = await firestore
				.collection("Empresas")
				.get();
			dispatch(setTotalDocs(response.size));
		} catch (error: any) {
			dispatch(setError(error));
		}
	};
};

export const restoreBusiness = (
	business: any
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.BUSINESS_RESTORE_SUBMITTING,
		});
		try {
			//
			await firestore
				.collection("Empresas")
				.doc(business.id)
				.update({
					Activado: true,
				});
			dispatch({
				type: types.BUSINESS_RESTORE_SUCCESS,
			});
			dispatch(
				openSnack("Empresa restaurada", SnackState.SUCCESS)
			);
			dispatch(getBusinesses());
		} catch (error: any) {
			dispatch({
				type: types.BUSINESS_RESTORE_FAILURE,
				payload: error,
			});
		}
	};
};

export const deleteSelectedBusiness = (
	business: any
): AppThunk => {
	return async (dispatch) => {
		dispatch({ type: types.BUSINESS_DELETE_ON_REQUEST });
		try {
			const businessRef = firestore
				.collection("Empresas")
				.doc(business.id);
			await firestore
				.collection("Usuarios")
				.where("EmpresaReference", "==", businessRef)
				.get()
				.then(async (resultUsers) => {
					resultUsers.docs.forEach(async (usr) => {
						try {
							await Axios.post(
								`https://identitytoolkit.googleapis.com/v1/accounts:delete?key=${API_KEY}`,
								{
									idToken: usr.id,
								},
								{
									headers: {
										"Content-Type": "application/json",
									},
								}
							);
						} catch (errorDeleteUsr) {}
						await firestore
							.collection("Usuarios")
							.doc(usr.id)
							.delete();
					});
				});
			await firestore
				.collection("Proyectos")
				.where("EmpresaReference", "==", businessRef)
				.get()
				.then(async (resultProject) => {
					resultProject.docs.forEach(async (project) => {
						await firestore
							.collection("Proyectos")
							.doc(project.id)
							.delete();
					});
				});

			await firestore
				.collection("ProyectosChecklist")
				.where("EmpresaReference", "==", businessRef)
				.get()
				.then(async (resultProject) => {
					resultProject.docs.forEach(async (pjct) => {
						await firestore
							.collection("ProyectosChecklist")
							.doc(pjct.id)
							.delete();
					});
				});
			await firestore
				.collection("Empresas")
				.doc(business.id)
				.delete();

			dispatch({ type: types.BUSINESS_DELETE_SUCCESS });
			dispatch(
				openSnack("Empresa Eliminada", SnackState.SUCCESS)
			);
			dispatch(getBusinesses());
		} catch (error: any) {
			dispatch({
				type: types.BUSINESS_DELETE_FAILURE,
				payload: error,
			});
		}
	};
};

export const setSelectedBusiness = (
	business: Empresa | null
): Action => ({
	type: types.BUSINESS_SET_SELECTED,
	payload: business,
});

export const isUpdateLoading = (
	isLoading: boolean
): Action => ({
	type: types.BUSINESS_UPDATE_LOADING,
	payload: isLoading,
});

const setBusiness = (
	business: Empresa[],
	totalDocs: any,
	lastDoc: any
): Action => ({
	type: types.BUSINESS_GET_DOCS,
	payload: {
		business: business,
		totalDocs: totalDocs,
		lastDoc: lastDoc,
	},
});

const setLastDoc = (doc: any): Action => ({
	type: types.BUSINESS_SET_LAST_DOC,
	payload: doc,
});

const addMoreBusinessesToState = (
	business: any[]
): Action => ({
	type: types.BUSINESS_ADD_DOCS,
	payload: business,
});

const isLoading = (isLoading: boolean): Action => ({
	type: types.BUSINESS_LOADING,
	payload: isLoading,
});

const setError = (error: string): Action => ({
	type: types.BUSINESS_FAILURE,
	payload: error,
});

const setTotalDocs = (total: number): Action => ({
	type: types.BUSINESS_SET_TOTAL_DOCS,
	payload: total,
});

const actualizarEmpresaActual = (
	empresa: Empresa
): Action => ({
	type: types.BUSINESS_UPDATE_SELECTED,
	payload: empresa,
});

const isEditLoading = (isLoading: boolean): Action => ({
	type: types.BUSINESS_EDIT_LOADING,
	payload: isLoading,
});

// ====================================================
// ==================== Cargos ========================
// ====================================================
export const agregarCargoEmpresa = (
	cargo: ToggableProyecto
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isCargosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const cargoExiste = entradaExiste(
				cargo.Nombre,
				selectedBusiness.Cargos ?? []
			);

			if (cargoExiste)
				throw Error("¡Este cargo ya existe!");

			selectedBusiness.Cargos = quitarEntradasRepetidas([
				cargo,
				...(selectedBusiness.Cargos ?? []),
			]).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Cargos: selectedBusiness.Cargos,
				});

			handleSuccess(
				selectedBusiness,
				"Cargo agregado.",
				isCargosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isCargosLoading, dispatch);
		}
	};
};

export const actualizarCargoEmpresa = (
	nombreCargo: string,
	nuevoCargo: { Activado?: boolean; Nombre?: string }
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isCargosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const indexCargo = selectedBusiness.Cargos?.findIndex(
				(c) =>
					cleanString(c.Nombre) === cleanString(nombreCargo)
			);

			if (indexCargo === -1)
				throw Error(
					"¡Se intentó modificar un cargo que no existe!"
				);

			selectedBusiness.Cargos[indexCargo] = {
				...selectedBusiness.Cargos[indexCargo],
				...nuevoCargo,
			};

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Cargos: quitarEntradasRepetidas(
						selectedBusiness.Cargos
					).sort((a, b) =>
						a.Nombre.localeCompare(b.Nombre)
					),
				});

			handleSuccess(
				selectedBusiness,
				"Cargo actualizado.",
				isCargosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isCargosLoading, dispatch);
		}
	};
};

export const quitarCargoEmpresa = (
	nombreCargo: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isCargosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			selectedBusiness.Cargos = quitarEntradasRepetidas(
				selectedBusiness.Cargos.filter(
					(c) => c.Nombre !== nombreCargo
				)
			).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Cargos: selectedBusiness.Cargos,
				});

			handleSuccess(
				selectedBusiness,
				"Cargo eliminado.",
				isCargosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isCargosLoading, dispatch);
		}
	};
};

export const isCargosLoading = (
	isLoading: boolean
): Action => ({
	type: types.BUSINESS_LOADING_CHARGES,
	payload: isLoading,
});

// ====================================================
// =================== Sectores =======================
// ====================================================

export const agregarSectorEmpresa = (
	sector: ToggableProyecto
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isSectoresLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const sectorExiste = entradaExiste(
				sector.Nombre,
				selectedBusiness.Sectores ?? []
			);

			if (sectorExiste)
				throw Error("¡El sector ya existe!");

			selectedBusiness.Sectores = quitarEntradasRepetidas([
				sector,
				...(selectedBusiness.Sectores ?? []),
			]).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Sectores: selectedBusiness.Sectores,
				});

			handleSuccess(
				selectedBusiness,
				"Sector agregado.",
				isSectoresLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isSectoresLoading, dispatch);
		}
	};
};

export const actualizarSectorEmpresa = (
	nombreSector: string,
	nuevoSector: ToggableProyecto
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isSectoresLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const indexSector =
				selectedBusiness.Sectores.findIndex(
					(s) => s.Nombre === nombreSector
				);

			if (indexSector === -1)
				throw Error(
					"¡Se intentó modificar un que sector no existe!"
				);

			selectedBusiness.Sectores[indexSector] = nuevoSector;
			selectedBusiness.Sectores = quitarEntradasRepetidas(
				selectedBusiness.Sectores
			).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Sectores: selectedBusiness.Sectores,
				});

			handleSuccess(
				selectedBusiness,
				"Sector actualizado.",
				isSectoresLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isSectoresLoading, dispatch);
		}
	};
};

export const quitarSectorEmpresa = (
	nombreSector: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isSectoresLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			selectedBusiness.Sectores = quitarEntradasRepetidas(
				selectedBusiness.Sectores.filter(
					(s) => s.Nombre !== nombreSector
				)
			).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Sectores: selectedBusiness.Sectores,
				});

			handleSuccess(
				selectedBusiness,
				"Sector eliminado.",
				isSectoresLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isSectoresLoading, dispatch);
		}
	};
};

export const isSectoresLoading = (
	isLoading: boolean
): Action => ({
	type: types.BUSINESS_LOADING_PLACES,
	payload: isLoading,
});

// ====================================================
// =================== Recintos =======================
// ====================================================

export const agregarRecintoEmpresa = (
	recinto: ToggableProyecto
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isRecintosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const recintoExiste = entradaExiste(
				recinto.Nombre,
				selectedBusiness.Recintos
			);

			if (recintoExiste)
				throw Error("¡El recinto ya existe!");

			selectedBusiness.Recintos = quitarEntradasRepetidas([
				recinto,
				...(selectedBusiness.Recintos ?? []),
			]).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Recintos: selectedBusiness.Recintos,
				});

			handleSuccess(
				selectedBusiness,
				"Recinto agregado.",
				isRecintosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isRecintosLoading, dispatch);
		}
	};
};

export const actualizarRecintoEmpresa = (
	nombreRecinto: string,
	nuevoRecinto: ToggableProyecto
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isRecintosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const indexRecinto =
				selectedBusiness.Recintos?.findIndex(
					(r) => r.Nombre === nombreRecinto
				);

			if (indexRecinto === -1)
				throw Error(
					"¡Se intentó modificar un recinto que no existe!"
				);

			selectedBusiness.Recintos[indexRecinto] =
				nuevoRecinto;
			selectedBusiness.Recintos = quitarEntradasRepetidas(
				selectedBusiness.Recintos
			).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Recintos: selectedBusiness.Recintos,
				});

			handleSuccess(
				selectedBusiness,
				"Recinto actualizado.",
				isRecintosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isRecintosLoading, dispatch);
		}
	};
};

export const quitarRecintoEmpresa = (
	nombreRecinto: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isRecintosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			selectedBusiness.Recintos = quitarEntradasRepetidas(
				selectedBusiness.Recintos.filter(
					(r) => r.Nombre !== nombreRecinto
				)
			).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Recintos: selectedBusiness.Recintos,
				});

			handleSuccess(
				selectedBusiness,
				"Recinto eliminado.",
				isRecintosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isRecintosLoading, dispatch);
		}
	};
};

export const isRecintosLoading = (
	isLoading: boolean
): Action => ({
	type: types.BUSINESS_LOADING_SECTORS,
	payload: isLoading,
});

// ====================================================
// ================== Subcontratos ====================
// ====================================================

export const agregarSubcontratoEmpresa = (
	subcontrato: Subcontrato
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isSubcontratosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const subcontratoExiste = entradaExiste(
				subcontrato.Nombre,
				selectedBusiness.Subcontratos ?? []
			);

			if (subcontratoExiste)
				throw Error("¡El subcontrato ya existe!");

			selectedBusiness.Subcontratos =
				quitarEntradasRepetidas([
					subcontrato,
					...(selectedBusiness.Subcontratos ?? []),
				]).sort((a, b) =>
					a.Nombre.localeCompare(b.Nombre)
				) as Subcontrato[];

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Subcontratos: selectedBusiness.Subcontratos,
				});

			handleSuccess(
				selectedBusiness,
				"Subcontrato agregado.",
				isSubcontratosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isSubcontratosLoading, dispatch);
		}
	};
};

export const actualizarSubcontratoEmpresa = (
	nombreSubcontrato: string,
	nuevoSubcontrato: Subcontrato
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isSubcontratosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const indexSubcontrato =
				selectedBusiness.Subcontratos?.findIndex(
					(r) => r.Nombre === nombreSubcontrato
				);

			if (indexSubcontrato === -1)
				throw Error(
					"¡Se intentó modificar un subcontrato que no existe!"
				);

			selectedBusiness.Subcontratos[indexSubcontrato] =
				nuevoSubcontrato;
			selectedBusiness.Subcontratos =
				quitarEntradasRepetidas(
					selectedBusiness.Subcontratos
				).sort((a, b) =>
					a.Nombre.localeCompare(b.Nombre)
				) as Subcontrato[];

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Subcontratos: selectedBusiness.Subcontratos,
				});

			handleSuccess(
				selectedBusiness,
				"Subcontrato actualizado.",
				isSubcontratosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isSubcontratosLoading, dispatch);
		}
	};
};

export const quitarSubcontratoEmpresa = (
	nombreSubcontrato: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isSubcontratosLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);
			selectedBusiness.Subcontratos =
				quitarEntradasRepetidas(
					selectedBusiness.Subcontratos.filter(
						(r) => r.Nombre !== nombreSubcontrato
					)
				).sort((a, b) =>
					a.Nombre.localeCompare(b.Nombre)
				) as Subcontrato[];

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Subcontratos: selectedBusiness.Subcontratos,
				});

			handleSuccess(
				selectedBusiness,
				"Subcontrato eliminado.",
				isSubcontratosLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isSubcontratosLoading, dispatch);
		}
	};
};

export const isSubcontratosLoading = (
	isLoading: boolean
): Action => ({
	type: types.BUSINESS_LOADING_SUBCONTRACTS,
	payload: isLoading,
});

// ====================================================
// ================== ErroresTipo =====================
// ====================================================

export const agregarErrorTipoSubcontrato = (
	nombreSubcontrato: string,
	errorTipo: ToggableProyecto,
	setSubContract: (subContract: any) => void
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isErroresTipoLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const subcontrato = encontrarEntrada(
				nombreSubcontrato,
				selectedBusiness.Subcontratos
			) as Subcontrato;

			if (!subcontrato)
				throw Error(
					"¡Se intentó modificar un subcontrato inexistente!"
				);

			if (!subcontrato.ErroresTipo)
				subcontrato.ErroresTipo = [];

			const errorTipoExiste = entradaExiste(
				errorTipo.Nombre,
				subcontrato.ErroresTipo
			);

			if (errorTipoExiste)
				throw Error("¡El error tipo ya existe!");

			subcontrato.ErroresTipo.push(errorTipo);
			subcontrato.ErroresTipo = quitarEntradasRepetidas(
				subcontrato.ErroresTipo
			).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			const indexSubcontrato = encontrarIndexEntrada(
				nombreSubcontrato,
				selectedBusiness.Subcontratos
			);

			if (indexSubcontrato === -1)
				throw Error(
					"Se intentó modificar un subcontrato inexistente."
				);

			selectedBusiness.Subcontratos[indexSubcontrato] =
				subcontrato;

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Subcontratos: selectedBusiness.Subcontratos,
				});

			setSubContract(subcontrato);
			handleSuccess(
				selectedBusiness,
				"Error tipo creado.",
				isErroresTipoLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isErroresTipoLoading, dispatch);
		}
	};
};

export const actualizarErrorTipoSubcontrato = (
	nombreSubcontrato: string,
	nombreErrorTipo: string,
	nuevoErrorTipo: ToggableProyecto,
	setSubContract: (subContract: any) => void
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isErroresTipoLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const subcontrato = encontrarEntrada(
				nombreSubcontrato,
				selectedBusiness.Subcontratos
			) as Subcontrato;

			if (!subcontrato)
				throw Error(
					"¡Se intentó modificar un subcontrato inexistente!"
				);

			const indexErrorTipo = encontrarIndexEntrada(
				nombreErrorTipo,
				subcontrato.ErroresTipo
			);

			if (indexErrorTipo === -1)
				throw Error(
					"¡Se intentó modificar un error tipo que no existe!"
				);

			subcontrato.ErroresTipo[indexErrorTipo] =
				nuevoErrorTipo;

			const indexSubcontrato = encontrarIndexEntrada(
				nombreSubcontrato,
				selectedBusiness.Subcontratos
			);

			if (indexSubcontrato === -1)
				throw Error(
					"Se intentó modificar un subcontrato inexistente."
				);

			selectedBusiness.Subcontratos[indexSubcontrato] =
				subcontrato;

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Subcontratos: selectedBusiness.Subcontratos,
				});

			setSubContract(subcontrato);
			handleSuccess(
				selectedBusiness,
				"Error tipo creado.",
				isErroresTipoLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isErroresTipoLoading, dispatch);
		}
	};
};

export const eliminarErrorTipoSubcontrato = (
	nombreSubcontrato: string,
	nombreErrorTipo: string,
	setSubContract: (subContract: any) => void
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isErroresTipoLoading(true));

		try {
			const selectedBusiness =
				getSelectedBusiness(getState);

			const subcontrato = encontrarEntrada(
				nombreSubcontrato,
				selectedBusiness.Subcontratos
			) as Subcontrato;

			if (!subcontrato)
				throw Error(
					"¡Se intentó modificar un subcontrato inexistente!"
				);

			subcontrato.ErroresTipo = quitarEntradasRepetidas(
				subcontrato.ErroresTipo.filter(
					(et) => et.Nombre !== nombreErrorTipo
				)
			).sort((a, b) => a.Nombre.localeCompare(b.Nombre));

			const indexSubcontrato = encontrarIndexEntrada(
				nombreSubcontrato,
				selectedBusiness.Subcontratos
			);

			if (indexSubcontrato === -1)
				throw Error(
					"Se intentó modificar un subcontrato inexistente."
				);

			selectedBusiness.Subcontratos[indexSubcontrato] =
				subcontrato;

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness.id)
				.update({
					Subcontratos: selectedBusiness.Subcontratos,
				});

			setSubContract(subcontrato);
			handleSuccess(
				selectedBusiness,
				"Error tipo creado.",
				isErroresTipoLoading,
				dispatch
			);
		} catch (error: any) {
			handleError(error, isErroresTipoLoading, dispatch);
		}
	};
};

export const isErroresTipoLoading = (
	isLoading: boolean
): Action => ({
	type: types.BUSINESS_LOADING_TYPE_ERRORS,
	payload: isLoading,
});

// ====================================================
// =================== Prioridades ====================
// ====================================================

export const addBusinessPriority = (
	priority: any
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isPrioritiesLoading(true));
		const { selectedBusiness } =
			getState().businessReducer.edit;
		try {
			//
			const __FOUND = selectedBusiness!.Prioridades.find(
				(post) =>
					cleanString(post.Nombre) ===
					cleanString(priority.Nombre)
			);

			if (__FOUND) {
				dispatch(
					openSnack("Prioridad ya existe", SnackState.ERROR)
				);
			} else {
				await firestore
					.collection("Empresas")
					.doc(selectedBusiness!.id)
					.update({
						Prioridades:
							firebase.firestore.FieldValue.arrayUnion(
								priority
							),
					});
				const Prioridades = [
					...selectedBusiness!.Prioridades,
					priority,
				].sort(sortByName);
				dispatch(
					actualizarEmpresaActual({ Prioridades } as any)
				);
				dispatch(
					openSnack(
						"Prioridad Agregada",
						SnackState.SUCCESS
					)
				);
			}
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isPrioritiesLoading(false));
		}
	};
};

export const updateBusinessPriority = (
	updatePriority: any,
	oldPriority: any
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isPrioritiesLoading(true));
		const { selectedBusiness } =
			getState().businessReducer.edit;
		try {
			//
			const __FOUND = selectedBusiness!.Prioridades.find(
				(post) =>
					cleanString(post.Nombre) ===
					cleanString(updatePriority.Nombre)
			);

			if (
				__FOUND &&
				oldPriority.Activado === updatePriority.Activado
			) {
				dispatch(
					openSnack("Prioridad ya existe", SnackState.ERROR)
				);
			} else {
				await firestore
					.collection("Empresas")
					.doc(selectedBusiness!.id)
					.update({
						Prioridades:
							firebase.firestore.FieldValue.arrayRemove(
								oldPriority
							),
					});
				await firestore
					.collection("Empresas")
					.doc(selectedBusiness!.id)
					.update({
						Prioridades:
							firebase.firestore.FieldValue.arrayUnion(
								updatePriority
							),
					});

				const Prioridades =
					selectedBusiness!.Prioridades.map((x: any) => {
						if (x.Nombre === oldPriority.Nombre) {
							return updatePriority;
						}
						return x;
					});
				dispatch(
					actualizarEmpresaActual({ Prioridades } as any)
				);
				dispatch(
					openSnack(
						"Prioridad actualizada",
						SnackState.SUCCESS
					)
				);
			}
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isPrioritiesLoading(false));
		}
	};
};

export const removeBusinessPriority = (
	priority: any
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isPrioritiesLoading(true));
		const { selectedBusiness } =
			getState().businessReducer.edit;
		try {
			//
			await firestore
				.collection("Empresas")
				.doc(selectedBusiness!.id)
				.update({
					Prioridades:
						firebase.firestore.FieldValue.arrayRemove(
							priority
						),
				});
			const Prioridades =
				selectedBusiness!.Prioridades.filter(
					(x: any) => x.Nombre !== priority.Nombre
				);
			dispatch(
				actualizarEmpresaActual({ Prioridades } as any)
			);
			dispatch(
				openSnack("Prioridad Eliminada", SnackState.SUCCESS)
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isPrioritiesLoading(false));
		}
	};
};

export const isPrioritiesLoading = (
	isLoading: boolean
): Action => ({
	type: types.BUSINESS_LOADING_PRIORITIES,
	payload: isLoading,
});

export const setBusinessFilter = (filter: any): Action => ({
	type: types.BUSINESS_FILTER_ACTIVE,
	payload: filter,
});

const handleError = (
	error: any,
	weaPaPonerEnFalso: Function,
	dispatch: Function
) => {
	dispatch(openSnack(error.message, SnackState.ERROR));
	dispatch(setError(error));
	dispatch(weaPaPonerEnFalso(false));
	console.error(error);
};

const handleSuccess = (
	empresaActualizada: Empresa,
	mensaje: string,
	weaPaPonerEnFalso: Function,
	dispatch: Function
) => {
	dispatch(actualizarEmpresaActual(empresaActualizada));
	dispatch(openSnack(mensaje, SnackState.SUCCESS));
	dispatch(weaPaPonerEnFalso(false));
};

/**
 * Retorna una copia de la empresa seleccionada actualmente.
 */
const getSelectedBusiness = (getState: any): Empresa => ({
	...getState().businessReducer.edit.selectedBusiness!,
});
