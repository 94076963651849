import {
	Button,
	IconButtonProps,
	TextField,
	TextFieldProps,
} from "@material-ui/core";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import * as Yup from "yup";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { Autocomplete } from "@material-ui/lab";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { ChecklistOT } from "src/models/OT/Checklist";
import { NeoChecklistsAssignDialog } from "./Dialog/Assign";

type Props = {
	onSubmit?: (text: string) => void;
	buttonProps?: IconButtonProps;
	value?: string;
	type: ChecklistOT["Tipo"];
	onChange?: (text: string) => void;
} & Omit<TextFieldProps, "onSubmit" | "value" | "onChange">;

export const NeoChecklistSearchBar: FC<Props> = ({
	onSubmit,
	buttonProps,
	type,
	value,
	onChange,
}) => {
	const { selectedBusiness } = useBusiness();
	const { project } = useProjectsOT();

	const {
		handleSubmit,
		errors,
		values,
		setFieldValue: setValue,
		setFieldError: setError,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			dialog: 0,
			selectedChecklist: null as ChecklistOT | null,
		},
		validationSchema: Yup.object().shape({}),
		onSubmit: async () => {},
	});

	useEffect(() => {
		setValue("selectedChecklist", null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const handleAssignClick = () => {
		const selectedChecklist = values.selectedChecklist;

		if (!selectedChecklist)
			return setError(
				"selectedChecklist",
				"Primero selecciona un checklist."
			);

		if (
			project.checklists?.some(
				(c) =>
					c.Nombre_lower === selectedChecklist.Nombre_lower
			)
		)
			return setError(
				"selectedChecklist",
				"Este checklist ya está asignado."
			);

		setValue("dialog", 1);
	};

	return (
		<>
			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					gap: 5,
					padding: 5,
					flexWrap: "wrap",
					alignItems: "center",
				}}
			>
				<Autocomplete
					inputValue={value}
					onInputChange={(_, v) => onChange?.(v)}
					value={values.selectedChecklist}
					onChange={(_, v) =>
						setValue("selectedChecklist", v)
					}
					style={{ flexGrow: 1 }}
					getOptionLabel={(option) => option.Nombre}
					options={
						selectedBusiness?.ListasDeChequeo?.filter(
							(c) => c.Tipo === type
						) ?? []
					}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Asigne una lista de chequeo"
							variant="outlined"
							size="small"
							style={{ flexGrow: 1 }}
							error={!!errors.selectedChecklist}
							helperText={errors.selectedChecklist}
						/>
					)}
				/>

				<Button
					color="primary"
					variant="contained"
					disabled={!values.selectedChecklist}
					onClick={handleAssignClick}
				>
					Asignar
				</Button>
			</form>

			<NeoChecklistsAssignDialog
				baseChecklist={values.selectedChecklist}
				open={values.dialog === 1}
				onClose={() => setValue("dialog", 0)}
			/>
		</>
	);
};
