import Firebase from "firebase";
import { auth, firestore } from "src/firebase/firebase";
import { Usuario } from "src/models/Usuario";

type Filters = {
	tipoUsuario: Usuario["TipoUsuario"];
	idEmpresa?: string;
};

export const conseguirChecklists = ({
	tipoUsuario,
	idEmpresa,
}: Filters) => {
	const id = auth.currentUser?.uid;
	const ref = firestore.doc(`Empresas/${idEmpresa}`);
	let query: Firebase.firestore.Query;

	if (!id) throw Error("User is not logged.");

	if (tipoUsuario === "UsuarioEmpresa")
		query = firestore
			.collection("ProyectosChecklist")
			.where("Responsables", "array-contains", id);
	else
		query = firestore
			.collection("ProyectosChecklist")
			.where("EmpresaReference", "==", ref);

	return query;
};
 