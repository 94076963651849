import {
	ButtonProps,
	TableBodyProps,
	TableCellProps,
	TableContainerProps,
	TableFooterProps,
	TableHeadProps,
	TablePaginationProps,
	TableProps,
	TableRowProps,
} from "@material-ui/core";
import { ReactNode, useState, FC } from "react";
import { State } from "src/utils/classes/State";

type ElementsProps = {
	TableHeadProps?: TableHeadProps;
	TableContainerProps?: TableContainerProps;
	TableProps?: TableProps;
	TableRowProps?: TableRowProps;
	TablePaginationProps?: TablePaginationProps;
	TableFooterProps?: TableFooterProps;
	ActionRowProps?: TableCellProps;
	ExportButtonProps?: ButtonProps;
	TableBodyProps?: TableBodyProps;
	TableCellProps?: TableCellProps;
};

type TableColumn<T> = {
	title?: string;
	header?: ReactNode;
	Cell: FC<{ row: number; column: number; value: T }>;
} & TableCellProps;

const context = new State(() => {
	/* Lógica de paginación */
	const [page, setPage] = useState(0);
	/**
	 * Event Handler
	 */
	const onPageChange: TablePaginationProps["onChangePage"] =
		(ev, page) => setPage(page);
	const rowsPerPageOptions = [10, 20, 50, 100];
	const [rowsPerPage, setRowsPerPage] = useState(
		rowsPerPageOptions[0]
	);
	/**
	 * Event Handler
	 */
	const onRowsPerPageChange = (e: any) =>
		setRowsPerPage(+e.target.value);
	/**
	 * Recorta un arreglo basándose en los parámetros de paginación
	 * de la tabla.
	 */
	const getPaginatedList = (list: any[]) =>
		list?.slice(
			page * rowsPerPage,
			page * rowsPerPage + rowsPerPage
		) || [];
	/* Fin lógica de paginación */

	return {
		elementsProps: {} as ElementsProps,
		list: [] as any[],
		columns: [] as TableColumn<any>[],
		loading: false,
		stripped: false,
		stripeColor: undefined as string | undefined,

		// Paginación
		page,
		onPageChange,
		rowsPerPage,
		rowsPerPageOptions,
		onRowsPerPageChange,
		count: undefined as number | undefined,
		getPaginatedList,

		// Exportación
		filename: undefined as string | undefined,
		fieldsToExclude: undefined as string[] | undefined,
		fields: undefined as string[] | undefined,
		fieldTitles: undefined as string[] | undefined,

		// Flags
		disableExporting: false,
		disablePagination: false,

		// messages
		emptyMessage: "No hay elementos para mostrar.",

		// Inner components
		actions: null as ReactNode,
	};
});

export const TableProvider = context.Provider;
export const useTableContext = context.useContext;
export type CoolTableProps<T = any> = Partial<
	Omit<
		ReturnType<typeof useTableContext>,
		"list" | "columns"
	> & {
		list: T[];
		columns: TableColumn<T>[];
	}
>;
