import { Box, Typography } from "@material-ui/core";
import { FC, useMemo } from "react";
import { roundWithDecimals } from "src/utils/numbers";

type Props = {
	value?: number;
	aim?: number;
	decimals?: number;
};

export const PercentageCell: FC<Props> = ({
	decimals = 1,
	value = 0,
	aim,
}) => {
	const roundedValue = useMemo(
		() => roundWithDecimals(value, decimals),
		[value, decimals]
	);

	const statusColor = useMemo(() => {
		const x = roundedValue;
		switch (true) {
			case x <= (aim ? aim * 0.25 : 25):
				return "red";
			case x <= (aim ? aim * 0.5 : 50):
				return "darkorange";
			case x <= (aim ? aim * 0.75 : 75):
				return "yellow";
			case x <= (aim ? aim * 0.99 : 99):
				return "steelblue";
			default:
				return "forestgreen";
		}
	}, [roundedValue, aim]);

	return (
		<>
			<Box
				// minWidth={compact ? 50 : 100}
				height={10}
				style={{
					border: `1px solid ${statusColor}`,
					borderRadius: 7,
					color: "gold",
				}}
			>
				<Box
					border={`1px solid white`}
					width={`${roundedValue}%`}
					minWidth={8}
					height="100%"
					bgcolor={statusColor}
					borderRadius={20}
				/>

				{aim && (
					<Box
						style={{
							position: "relative",
							left: `${aim}%`,
							top: -10,
							height: 12,
							width: 3,
							backgroundColor: "white",
							border: `1px solid black`,
						}}
					/>
				)}
			</Box>
			<Box
				display="flex"
				flexDirection="row"
				width="100%"
				justifyContent="space-between"
			>
				<Typography variant="caption">
					{roundedValue}%
				</Typography>

				{aim && (
					<Typography variant="caption">
						Meta: {aim}%
					</Typography>
				)}
			</Box>
		</>
	);
};
