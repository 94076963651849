import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	CardHeader,
	Chip as MuiChip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider as MuiDivider,
	Fade,
	FormControlLabel as MuiFormControlLabel,
	Grid,
	IconButton,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";

import { Add as AddIcon } from "@material-ui/icons";
import {
	ChevronRight,
	Edit as EditIcon,
	Plus,
	Trash2 as RemoveIcon,
} from "react-feather";

import * as yup from "yup";
import { es } from "yup-locales";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/reducer";
import { ISubContractsState } from "../redux/reducers/subContractsReducer";
import {
	addMoreSubContracts,
	addSubContract,
	addTypeError,
	getSubContracts,
	getTotalDocs,
	openEditSubContractDialog,
	openNewSubContractDialog,
	removeSubContract,
	removeTypeError,
	setSelectedSubContract,
	setSubContractDialogOpen,
	updateSubContract,
	updateTypeError,
} from "../redux/actions/subContractActions";
import { Skeleton } from "@material-ui/lab";
import { green, red } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { DialogAction } from "../models/dialog-actions";
import { useStyles } from "../theme/useStyles";
import { useHistory } from "react-router-dom";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { SimpleCrud } from "../components/SimpleCrud";

yup.setLocale(es);

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(
	spacing
);

const Chip: any = styled(MuiChip)`
	${spacing};

	background: ${(props: any) =>
		props.activated && green[500]};
	background: ${(props: any) =>
		props.desactivated && red[500]};
	color: ${(props) => props.theme.palette.common.white};
	min-width: 90px;
`;

function ContentCard() {
	const {
		subContracts,
		isLoading,
		totalDocs,
		typeErrors: { updateLoading },
		selectedSubContract,
	} = useSelector<RootState, ISubContractsState>(
		(state) => state.subContractsReducer
	);

	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const [selectedTypeErrors, setSelectedTypeErrors] =
		useState<any>(null);

	const [limit, setLimit] = useState(TABLE_LIMIT_DEFAULT);
	const [page, setPage] = useState(0);

	const handleLimitChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(parseInt(event.target.value, 10));

		if (
			parseInt(event.target.value, 10) > subContracts.length
		) {
			dispatch(
				getSubContracts(parseInt(event.target.value, 10))
			);
		}
		setPage(0);
	};

	const handlePageChange = (_: any, newPage: number) => {
		if (subContracts.length < totalDocs && newPage > page) {
			dispatch(addMoreSubContracts(limit));
		}
		setPage(newPage);
	};

	// Confirm Dialog
	const [dialogState, setDialogState] = useState({
		open: false,
		title: "",
		subContract: {},
	});

	const handleRemoveButton = (subContract: any) => {
		setDialogState((dialogState) => ({
			...dialogState,
			title: `¿Desea eliminar el Subcontrato ${subContract.Nombre}?`,
			subContract: subContract,
			open: true,
		}));
	};

	const handleEditButton = (subContract: any) => {
		dispatch(openEditSubContractDialog(subContract));
	};

	const handleTypeErrorButton = (subContract: any) => {
		dispatch(setSelectedSubContract(subContract));
	};

	useEffect(() => {
		setSelectedTypeErrors(selectedSubContract);
	}, [selectedSubContract]);

	return (
		<Grid container spacing={4}>
			<Grid xs={6}>
				<Card mb={6} mt={2}>
					<CardHeader
						title="Subcontratos"
						action={
							<Button
								color="primary"
								variant="contained"
								onClick={() =>
									dispatch(openNewSubContractDialog())
								}
							>
								<Plus size={20} />
								&nbsp; Agregar
							</Button>
						}
					/>
					<Divider />
					<CardContent>
						<TableContainer
							className={classes.tableContainer}
						>
							<Table size="small" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>Nombre</TableCell>
										<TableCell>Estado</TableCell>
										<TableCell align="center">
											Acciones
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{!isLoading || updateLoading
										? subContracts
												.slice(
													page * limit,
													page * limit + limit
												)
												.map((subContract) => (
													<Fade
														key={subContract.id}
														in={true}
													>
														<TableRow
															hover
															className={classes.styledRow}
														>
															<TableCell>
																{subContract.Nombre}
															</TableCell>
															<TableCell>
																{subContract.Activado ? (
																	<Chip
																		size="small"
																		mr={1}
																		mb={1}
																		label="Activado"
																		activated="true"
																	/>
																) : (
																	<Chip
																		size="small"
																		mr={1}
																		mb={1}
																		label="Desactivado"
																		desactivated="true"
																	/>
																)}
															</TableCell>
															<TableCell
																style={{
																	whiteSpace: "nowrap",
																}}
																align="center"
															>
																<Tooltip title="Editar">
																	<span>
																		<IconButton
																			onClick={() =>
																				handleEditButton(
																					subContract
																				)
																			}
																			color="primary"
																		>
																			<EditIcon />
																		</IconButton>
																	</span>
																</Tooltip>
																<Tooltip title="Eliminar">
																	<span>
																		<IconButton
																			onClick={() =>
																				handleRemoveButton(
																					subContract
																				)
																			}
																		>
																			<RemoveIcon color="red" />
																		</IconButton>
																	</span>
																</Tooltip>
																<Tooltip title="Errores Tipo">
																	<span>
																		<IconButton
																			onClick={() => {
																				handleTypeErrorButton(
																					subContract
																				);
																			}}
																			color="primary"
																		>
																			<ChevronRight />
																		</IconButton>
																	</span>
																</Tooltip>
															</TableCell>
														</TableRow>
													</Fade>
												))
										: new Array(limit)
												.fill(0)
												.map((x, i) => (
													<TableRow key={i}>
														<TableCell colSpan={5}>
															<Skeleton />
														</TableCell>
													</TableRow>
												))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							component="div"
							count={totalDocs}
							onChangePage={handlePageChange}
							onChangeRowsPerPage={handleLimitChange}
							page={page}
							rowsPerPage={limit}
							rowsPerPageOptions={TABLE_LIMITS}
						/>
					</CardContent>
					<ConfirmDialog
						open={dialogState.open}
						onClose={() =>
							setDialogState((dialogState) => ({
								...dialogState,
								open: false,
							}))
						}
						onConfirm={() => {
							dispatch(
								removeSubContract(dialogState.subContract)
							);
						}}
						title={dialogState.title}
					/>
				</Card>
			</Grid>
			<Grid item xs={6}>
				{selectedTypeErrors ? (
					<SimpleCrud
						key={"ErroresTipo"}
						array={selectedTypeErrors?.ErroresTipo || []}
						isLoading={false}
						name="ErroresTipo"
						onCreateOrUpdate={(
							values,
							action,
							selected
						) => {
							if (action === "New") {
								dispatch(addTypeError(values));
							} else if (action === "Edit") {
								dispatch(updateTypeError(values, selected));
							}
						}}
						onDelete={(selected) => {
							dispatch(removeTypeError(selected));
						}}
						cardTitle={
							"Errores Tipo - " + selectedTypeErrors?.Nombre
						}
					/>
				) : (
					<Card style={{ minHeight: 250 }}>
						<Grid
							container
							direction="column"
							alignItems="center"
							justify="center"
						>
							<Grid item>
								<img
									style={{
										marginTop: 20,
										marginBottom: 20,
									}}
									alt="imagen vacio"
									src="/static/void.svg"
									height="150"
								/>
							</Grid>
							<Grid item>
								<Typography variant="h5">
									Ningun subcontrato seleccionado
								</Typography>
							</Grid>
						</Grid>
					</Card>
				)}
			</Grid>
		</Grid>
	);
}

const DialogAddSubContract = () => {
	const {
		dialog: { isDialogLoading, isDialogOpen, dialogAction },
		selectedSubContract,
	} = useSelector<RootState, ISubContractsState>(
		(state) => state.subContractsReducer
	);

	const dispatch = useDispatch();

	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		setValues,
		resetForm,
	} = useFormik({
		initialValues: {
			Nombre: "",
			Activado: true,
		},
		onSubmit: (values) => {
			if (dialogAction === DialogAction.Edit) {
				dispatch(
					updateSubContract({
						...values,
						id: selectedSubContract.id,
					})
				);
			}
			if (dialogAction === DialogAction.New) {
				dispatch(addSubContract(values));
			}
		},
		validationSchema: yup.object({
			Nombre: yup.string().required("Nombre requerido"),
		}),
	});

	useEffect(() => {
		if (dialogAction === DialogAction.Edit) {
			setValues({
				Nombre:
					dialogAction === DialogAction.Edit
						? selectedSubContract?.Nombre
						: "",
				Activado:
					dialogAction === DialogAction.Edit
						? selectedSubContract?.Activado
						: false,
			});
		} else if (dialogAction === DialogAction.New) {
			resetForm();
		}
	}, [
		dialogAction,
		selectedSubContract,
		resetForm,
		setValues,
	]);

	return (
		<React.Fragment>
			<Dialog
				open={isDialogOpen}
				onClose={() =>
					dispatch(setSubContractDialogOpen(false))
				}
				aria-labelledby="add-and-update-dialog-title"
			>
				<DialogTitle id="add-and-update-dialog-title">
					{dialogAction === DialogAction.Edit
						? "Editar Subcontrato"
						: "Agregar Subcontrato"}
				</DialogTitle>

				<form noValidate onSubmit={handleSubmit}>
					<DialogContent>
						<TextField
							autoFocus
							id="Nombre"
							label="Nombre Subcontrato"
							type="text"
							value={values.Nombre}
							name="Nombre"
							onChange={handleChange}
							error={
								touched.Nombre && Boolean(errors.Nombre)
							}
							helperText={touched.Nombre && errors.Nombre}
							fullWidth
						/>
						<FormControlLabel
							control={
								<Switch
									checked={values.Activado}
									onChange={handleChange}
									name="Activado"
									color="primary"
								/>
							}
							my={4}
							label="Activado"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() =>
								dispatch(setSubContractDialogOpen(false))
							}
							color="primary"
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							color="primary"
							disabled={isDialogLoading}
						>
							{dialogAction === DialogAction.Edit
								? "Editar"
								: "Agregar"}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
};

export const SubContract = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSubContracts());
		dispatch(getTotalDocs());
	}, [dispatch]);

	return (
		<React.Fragment>
			<Helmet title="Subcontratos" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						Subcontratos
					</Typography>

					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<Typography>Subcontratos</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					{/* <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(openNewSubContractDialog());
              }}
            >
              <AddIcon />
              Nuevo Subcontrato
            </Button>
          </div> */}
				</Grid>
			</Grid>
			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard />
				</Grid>
			</Grid>
			<DialogAddSubContract />
		</React.Fragment>
	);
};
