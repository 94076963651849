import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	LinearProgress,
	Paper,
} from "@material-ui/core";
import { FC, useEffect, useMemo, useState } from "react";
import { NeoPlaceItem } from "../PlacesOT/Item";
import { PlacesToolbar } from "../Misc/TableToolbar";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { hasRef } from "src/utils/firebase";
import { NeoProjectPlacesDeleteDialog } from "./DeletePlace";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import naturalSort from "natural-sort";
import { PlaceOT } from "src/models/OT/Place";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";

export const NeoProjectPlacesDialog: FC<DialogProps> = ({
	fullScreen,
	...props
}) => {
	const [removeMode, setRemoveMode] = useState(false);

	const {
		create,
		update,
		get,
		// loading,
		selected,
		project,
		set,
	} = useProjectsOT();

	const [loading, setLoading] = useState(-1);

	/**
	 * If there is a selected project, get its spaces.
	 */
	useEffect(() => {
		if (!selected.project) return;
		get.project.places();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected.project]);

	const handleAdd = async (name: string) => {
		if (!selected.project) return;
		await create.project.place(name);
	};

	const handleRemove = async (place: PlaceOT) => {
		set.place(place);
		setRemoveMode(true);
	};

	const handleRename = async (
		oldName: string,
		newName: string,
		index: number
	) => {
		if (!selected.project) return;
		if (oldName === newName) return;

		setLoading(index);
		const place = project.places?.find(
			(s) => s.Nombre === oldName
		);
		if (!hasRef(place)) return;

		await update.project.place({
			_ref: place._ref,
			Nombre: newName,
		});
		setLoading(-1);
	};

	const sortedPlaces = useMemo(() => {
		if (!project.places) return [];
		return [...project.places].sort((a, b) =>
			naturalSort()(a.Nombre, b.Nombre)
		);
	}, [project.places]);

	return (
		<>
			<Dialog {...props} fullScreen={fullScreen}>
				<DialogTitle
					bigText="Crear espacios"
					smallText={selected.project?.Nombre}
					onClose={() =>
						props.onClose?.({}, "escapeKeyDown")
					}
				/>

				<DialogContent
					style={{
						display: "flex",
						gap: 10,
						flexDirection: "column",
						height: fullScreen ? "100%" : "auto",
					}}
				>
					<Paper elevation={3} style={{ padding: 10 }}>
						<PlacesToolbar
							variant="outlined"
							onSubmit={handleAdd}
							fullWidth
							buttonProps={{
								style: {
									height: 36,
									width: 36,
								},
							}}
							containerProps={{
								style: {
									display: "flex",
									gap: 10,
								},
							}}
						/>
					</Paper>

					<Paper
						elevation={3}
						style={{
							width: "100%",
							minWidth: 500,
							height: fullScreen ? "100%" : 300,
							overflow: "auto",
						}}
					>
						{(!project.places || loading > -1) && (
							<LinearProgress
								style={{ width: "100%" }}
								color="primary"
							/>
						)}

						{sortedPlaces.map((place, i) => (
							<NeoPlaceItem
								key={place._ref!.id}
								placeName={place.Nombre}
								numberEditable={false}
								loading={loading === i}
								nameEditable
								onNameChange={(name) =>
									handleRename(place.Nombre, name, i)
								}
								deletable={true}
								onDelete={() => handleRemove(place)}
							/>
						))}
					</Paper>
				</DialogContent>

				<DialogActions>
					<Button
						color="primary"
						variant="contained"
						disabled={loading > -1}
						onClick={() => {
							FirebaseListener.getListener(
								"project-places-ot"
							)?.close();

							props.onClose?.({}, "escapeKeyDown");
						}}
					>
						Listo
					</Button>
				</DialogActions>
			</Dialog>

			<NeoProjectPlacesDeleteDialog
				open={removeMode}
				onClose={() => setRemoveMode(false)}
			/>
		</>
	);
};
