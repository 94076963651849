import {
	IconButton,
	Tooltip,
	Typography,
} from "@material-ui/core";
import {
	SpeakerNotes,
	SpeakerNotesOff,
} from "@material-ui/icons";
import { ReactNode } from "react";

export const HelperIcon = ({
	title = "",
	iconTrue = (<SpeakerNotes />) as ReactNode,
	iconFalse = (<SpeakerNotesOff />) as ReactNode,
}) => (
	<Tooltip
		arrow
		title={title && <Typography>{title}</Typography>}
	>
		<span>
			<IconButton
				color="primary"
				disabled={!title}
				size="small"
			>
				{title ? iconTrue : iconFalse}
			</IconButton>
		</span>
	</Tooltip>
);
