import { useEffect, useRef, useState } from "react";

type AsyncHook<T> = (
	callback: () => Promise<T>,
	deps: any[]
) => void;

/**
 * Custom hook that runs an asynchronous callback function when the specified dependencies change.
 * This hook prevents multiple instances of the callback from running concurrently.
 */
export const useAsyncEffect: AsyncHook<void> = (
	callback,
	deps
) => {
	const isRunning = useRef(false);

	useEffect(() => {
		if (isRunning.current) return;

		(async () => {
			isRunning.current = true;
			await callback();
			isRunning.current = false;
		})();
	}, deps);
};

// export const useAsyncMemo: AsyncHook<T> = <T>() => {};
export const useAsyncMemo = <T>(
	callback: () => Promise<T>,
	deps: any[]
) => {
	const isRunning = useRef(false);
	const [value, setValue] = useState<T | null>(null);

	useEffect(() => {
		if (isRunning.current) return;

		(async () => {
			isRunning.current = true;
			setValue(await callback());
			isRunning.current = false;
		})();
	}, deps);

	return value;
};
