/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Breadcrumbs,
	Divider,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { LinkProjectsOT } from "src/components/CheckPro/Links/NeoProjects";
import { RejectedByGraphOT } from "src/components/ProjectsOT/Graph/RejectedBy";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { DeviationByParameterApi } from "src/redux/neo-checklists/actions/reports";
import { otActions } from "src/redux/neo-checklists/slice";
import { useDispatch } from "src/redux/store";

export const DeviationByParameterPageOT = () => {
	const dispatch = useDispatch();
	const { selectedBusiness } = useBusiness();
	const { selected, get, set, checklist, project } =
		useProjectsOT?.() ?? {};

	const [place, setPlace] =
		useState<DeviationByParameterApi["place"]>(null);

	const [sector, setSector] = useState("" as string | null);

	useEffect(() => {
		set.graphData({});
		dispatch(
			otActions.assignChecklist({ availableSectors: [] })
		);
	}, []);

	useEffect(() => {
		if (!selected.project) return;
		get.project.places();
		get.project.checklists();
	}, [selected.project]);

	useEffect(() => {
		if (!selected.project || !selectedBusiness) return;
		get.report.deviationByParameter({
			place: place,
			sector: sector,
			checklistType: "Terminaciones",
		});
	}, [place, sector]);

	return (
		<>
			<Helmet title="Desviación por espacio" />

			<Breadcrumbs style={{ fontSize: 20 }}>
				<Typography variant="h3" style={{ color: "black" }}>
					{selectedBusiness?.Nombre ?? "Empresa"}
				</Typography>
				<Typography variant="h3" style={{ color: "black" }}>
					{selected.project?.Nombre ?? "Proyecto"}
				</Typography>
			</Breadcrumbs>

			<Breadcrumbs>
				<LinkEmpresas />
				<LinkProjectsOT />
				<Typography>Desviación por espacio</Typography>
			</Breadcrumbs>

			<Divider
				style={{ marginTop: 10, marginBottom: 10 }}
			/>

			<Box display="flex" gridGap={10} paddingTop={5}>
				<Autocomplete
					value={place}
					options={project.places ?? []}
					getOptionLabel={(option) => option.Nombre}
					onChange={(_, value) => setPlace(value)}
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label="Espacio"
							variant="outlined"
							size="small"
						/>
					)}
				/>

				<Autocomplete
					value={sector}
					options={
						checklist.availableSectors ??
						selectedBusiness?.Sectores.map(
							(s) => s.Nombre
						) ??
						[]
					}
					onChange={(_, value) => setSector(value)}
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label="Filtrar por sector"
							variant="outlined"
							size="small"
						/>
					)}
				/>
			</Box>

			<RejectedByGraphOT />
		</>
	);
};
