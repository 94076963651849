import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	TextField,
	Tooltip,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ChevronRight, Edit, Trash2 } from "react-feather";
import { useHistory } from "react-router-dom";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useBusiness } from "src/hooks/redux/useBusiness";
import {
	addChecklist,
	removeChecklist,
	setSelectedChecklist,
	updateChecklist,
} from "src/redux/actions/checklistActions";
import { useDispatch, useSelector } from "src/redux/store";
import { colorsCheckPro } from "src/theme/variants";
import { Table } from "src/utils/components/Table";
import * as Yup from "yup";

export const BusinessChecklistsTab = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedBusiness } = useBusiness();

	const [dialog, setDialog] = useState(0);

	const checklists = useSelector(
		(s) => s.checklistReducer.checklist
	);

	const [_selectedChecklist, _setSelectedChecklist] =
		useState<any>();

	const {
		values,
		handleChange,
		setFieldValue,
		isSubmitting,
		handleSubmit,
		errors,
		touched,
		resetForm,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			Nombre: _selectedChecklist?.Nombre ?? "",
			ItemsRecintos:
				_selectedChecklist?.ItemsRecintos ?? [],
		},
		onSubmit: async (values, { resetForm }) => {
			if (_selectedChecklist)
				await dispatch(
					updateChecklist(
						_selectedChecklist.id,
						values.Nombre,
						values.ItemsRecintos
					)
				);
			else await dispatch(addChecklist(values));

			handleClose();
			resetForm();
		},
		validationSchema: Yup.object().shape({
			Nombre: Yup.string().required("Requerido"),
		}),
	});

	const handleClose = () => {
		setDialog(0);
		_setSelectedChecklist(null);
	};

	const [loading, setLoading] = useState(false);

	const handleDelete = async () => {
		setLoading(true);
		await dispatch(removeChecklist(_selectedChecklist));
		setLoading(false);
		handleClose();
	};

	useEffect(() => {
		return resetForm;
	}, []);

	return (
		<Box>
			<Button
				endIcon={<Add />}
				variant="contained"
				color="primary"
				onClick={() => {
					_setSelectedChecklist(null);
					setDialog(2);
				}}
			>
				Agregar Checklist
			</Button>
			<Table<any>
				stripped
				list={checklists ?? []}
				loading={!checklists}
				columns={[
					{
						header: "Nombre",
						Cell: ({ value }) => <>{value.Nombre}</>,
					},
					{
						header: "Recintos",
						Cell: ({ value }) => (
							<>{value.ItemsRecintos.length}</>
						),
					},
					{
						header: "Acciones",
						Cell: ({ value }) => (
							<>
								<Tooltip title="Eliminar">
									<IconButton
										onClick={() => {
											_setSelectedChecklist(value);
											setDialog(1);
										}}
									>
										<Trash2 color="red" />
									</IconButton>
								</Tooltip>

								<Tooltip title="Editar">
									<IconButton
										onClick={() => {
											_setSelectedChecklist(value);
											setDialog(2);
										}}
									>
										<Edit color={colorsCheckPro.primary} />
									</IconButton>
								</Tooltip>

								<Tooltip title="Recintos">
									<IconButton
										onClick={() => {
											if (!selectedBusiness) return;
											dispatch(setSelectedChecklist(value));
											history.push(
												`/administrador/empresas/${selectedBusiness.id}/Checklists`
											);
										}}
									>
										<ChevronRight
											color={colorsCheckPro.primary}
										/>
									</IconButton>
								</Tooltip>
							</>
						),
					},
				]}
			/>

			<Dialog open={dialog === 1} onClose={handleClose}>
				<DialogTitle
					bigText="Eliminar Checklist"
					smallText="¿Estás seguro de que quieres eliminar este Checklist?"
					onClose={() => setDialog(0)}
				/>
				<DialogActions>
					<Button
						color="primary"
						variant="contained"
						onClick={handleDelete}
						disabled={loading}
					>
						{loading ? (
							<CircularProgress size={24} />
						) : (
							"Eliminar"
						)}
					</Button>
					<Button onClick={handleClose}>Cancelar</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={dialog === 2} onClose={handleClose}>
				<DialogTitle
					bigText={
						_selectedChecklist
							? "Editar Checklist"
							: "Crear Checklist"
					}
					onClose={() => setDialog(0)}
				/>

				<DialogContent
					style={{
						minWidth: 300,
						display: "flex",
						flexDirection: "column",
						gap: 20,
					}}
				>
					<TextField
						label="Nombre"
						fullWidth
						variant="outlined"
						value={values.Nombre}
						id="Nombre"
						onChange={handleChange}
						helperText={touched.Nombre && errors.Nombre}
						error={touched.Nombre && Boolean(errors.Nombre)}
					/>

					<Autocomplete
						multiple
						disableCloseOnSelect
						options={selectedBusiness?.Recintos ?? []}
						value={values.ItemsRecintos ?? []}
						getOptionSelected={(option, value) =>
							values.ItemsRecintos.some(
								(item: any) => item.Nombre === option.Nombre
							)
						}
						onChange={(_, value) =>
							setFieldValue("ItemsRecintos", value)
						}
						getOptionLabel={(option) => option.Nombre}
						renderOption={(option, { selected }) => (
							<>
								<Checkbox checked={selected} />
								{option.Nombre}
							</>
						)}
						renderInput={(props) => (
							<TextField
								{...props}
								variant="outlined"
								label="Recintos"
							/>
						)}
					/>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => handleSubmit()}
						color="primary"
						variant="contained"
						disabled={isSubmitting}
					>
						{isSubmitting ? (
							<CircularProgress size={24} />
						) : (
							"Guardar"
						)}
					</Button>
					<Button onClick={() => setDialog(0)}>
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
