import {
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider as MuiDivider,
	Fade,
	FormControlLabel as MuiFormControlLabel,
	Grid,
	IconButton,
	Link,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ChevronRight, Trash2 } from "react-feather";
import { Helmet } from "react-helmet-async";
import {
	NavLink,
	useHistory,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { es } from "yup-locales";
import { ConfirmDialog } from "../components/ConfirmDialog";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import {
	addRecinto,
	getOneChecklist,
	removeRecinto,
	setSelectedRecinto,
} from "../redux/actions/checklistActions";
import { IChecklistState } from "../redux/reducers/checklistReducer";
import { RootState } from "../redux/store/reducer";
import { useStyles } from "../theme/useStyles";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useDispatch, useSelector } from "src/redux/store";

yup.setLocale(es);

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(
	spacing
);

function ContentCard() {
	const {
		dialog: { selectedChecklist },
	} = useSelector((state) => state.checklistReducer);
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { checklisId } = useParams<any>();
	const [limit, setLimit] = useState(TABLE_LIMIT_DEFAULT);
	const [page, setPage] = useState(0);
	const [selectedDelete, setSelectedDelete] = useState<any>(
		{}
	);

	const docTotals =
		selectedChecklist?.ItemsRecintos?.map((e: any) => ({
			...e,
		})) || 0;

	const handleGoToViewSubContratos = (subContract: any) => {
		dispatch(setSelectedRecinto(subContract));
		history.push(
			`/administrador/checklist/${
				checklisId ?? selectedChecklist.id
			}/Recintos/${subContract.id}/Subcontratos`
		);
	};
	const handleLimitChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLimit(parseInt(event.target.value, 10));

		if (
			parseInt(event.target.value, 10) >
			selectedChecklist?.Recintos?.length
		) {
			// dispatch(getChecklist(parseInt(event.target.value, 10)));
		}
		setPage(0);
	};

	const handlePageChange = (_: any, newPage: number) => {
		if (
			selectedChecklist?.Recintos?.length < docTotals &&
			newPage > page
		) {
			// dispatch(getChecklist(limit));
		}
		setPage(newPage);
	};
	// Confirm Dialog
	const [dialogState, setDialogState] = useState({
		open: false,
		title: "",
		subContract: {},
	});
	// eliminar subcontrato
	const handleRemoveButton = (subContrato: any) => {
		setDialogState((dialogState) => ({
			...dialogState,
			title: `¿Desea eliminar el Recinto ${subContrato.Nombre}?`,
			subContrato: subContrato,
			open: true,
		}));
	};
	useEffect(() => {
		if (!selectedChecklist) {
			dispatch(getOneChecklist(checklisId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedChecklist, checklisId]);
	return (
		<Card mb={6} mt={2}>
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Nombre</TableCell>
								<TableCell>Subcontratos</TableCell>
								<TableCell align="center">
									Acciones
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{selectedChecklist
								? selectedChecklist?.ItemsRecintos?.slice(
										page * limit,
										page * limit + limit
								  )
										.sort((a: any, b: any) => {
											if (a.Nombre === b.Nombre) {
												return 0;
											}
											if (a.Nombre < b.Nombre) {
												return -1;
											}
											return 1;
										})
										.map((subContract: any, index: any) => (
											<Fade key={index} in={true}>
												<TableRow
													hover
													className={classes.styledRow}
												>
													<TableCell>
														{subContract?.Nombre}
													</TableCell>
													<TableCell align="left">
														{subContract?.Subcontratos
															?.length === undefined
															? 0
															: subContract?.Subcontratos
																	?.length}
													</TableCell>
													<TableCell
														style={{ whiteSpace: "nowrap" }}
														align="center"
													>
														<Tooltip title="Eliminar">
															<span>
																<IconButton
																	onClick={() => {
																		handleRemoveButton(
																			subContract
																		);
																		setSelectedDelete(
																			subContract
																		);
																	}}
																>
																	<Trash2 color="red" />
																</IconButton>
															</span>
														</Tooltip>
														<Tooltip title="Sub-contratos">
															<span>
																<IconButton
																	onClick={() => {
																		handleGoToViewSubContratos(
																			subContract
																		);
																	}}
																	color="primary"
																>
																	<ChevronRight />
																</IconButton>
															</span>
														</Tooltip>
													</TableCell>
												</TableRow>
											</Fade>
										))
								: new Array(limit).fill(0).map((x, i) => (
										<TableRow key={i}>
											<TableCell colSpan={5}>
												<Skeleton />
											</TableCell>
										</TableRow>
								  ))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={docTotals ? docTotals?.length : 0}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
				<ConfirmDialog
					open={dialogState.open}
					onClose={() =>
						setDialogState((dialogState) => ({
							...dialogState,
							open: false,
						}))
					}
					onConfirm={() => {
						dispatch(
							removeRecinto(
								selectedChecklist,
								selectedDelete
							)
						);
					}}
					title={dialogState.title}
				/>
			</CardContent>
		</Card>
	);
}

interface SimpleDialogProps {
	open: boolean;
	onClose: () => void;
	onSave: (data: any) => void;
	selected?: any;
}

const DialogAddSubContractSimple: React.FC<SimpleDialogProps> =
	({ open, onClose, onSave, selected = null }) => {
		const {
			handleSubmit,
			values,
			handleChange,
			touched,
			errors,
			setFieldValue,
			resetForm,
		} = useFormik({
			initialValues: {
				Nombre: "",
				Activado: true,
			},
			onSubmit: (values) => {
				onSave(values);
				resetForm();
			},
			validationSchema: yup.object({
				Nombre: yup.string().required("Nombre requerido"),
			}),
		});

		useEffect(() => {
			if (selected) {
				setFieldValue("Nombre", selected?.Nombre || "");
				setFieldValue(
					"Activado",
					selected?.Activado || false
				);
			} else {
				resetForm();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selected]);

		return (
			<React.Fragment>
				<Dialog
					open={open}
					onClose={() => onClose()}
					aria-labelledby="add-and-update-dialog-title"
				>
					<DialogTitle id="add-and-update-dialog-title">
						{selected
							? "Editar Subcontrato"
							: "Agregar Recinto"}
					</DialogTitle>

					<form noValidate onSubmit={handleSubmit}>
						<DialogContent>
							<TextField
								autoFocus
								id="Nombre"
								label="Nombre Recinto"
								type="text"
								value={values.Nombre}
								name="Nombre"
								onChange={handleChange}
								error={
									touched.Nombre && Boolean(errors.Nombre)
								}
								helperText={touched.Nombre && errors.Nombre}
								fullWidth
							/>
							<FormControlLabel
								control={
									<Switch
										checked={values.Activado}
										onChange={handleChange}
										name="Activado"
										color="primary"
									/>
								}
								my={4}
								label="Activado"
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => onClose()}
								color="primary"
							>
								Cancelar
							</Button>
							<Button type="submit" color="primary">
								"Agregar"
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</React.Fragment>
		);
	};

export const CreateChecklist = () => {
	const { checklistId } = useParams<any>();
	const dispatch = useDispatch();
	const [openDialog, setOpenDialog] = useState(false);
	const { selectedBusiness } = useBusiness();
	const handleShowDialog = () => {
		setOpenDialog(!openDialog);
	};
	const {
		dialog: { selectedChecklist },
	} = useSelector((state) => state.checklistReducer);

	const user = useSelector((s) => s.authReducer.user);

	const handleSaveCreate = (values: any) => {
		dispatch(addRecinto(values, checklistId));
		handleShowDialog();
	};

	return (
		<React.Fragment>
			<Helmet title="Recintos" />
			<Grid justify="space-between" container>
				<Grid item>
					<>
						<Typography
							variant="h3"
							gutterBottom
							display="inline"
						>
							{selectedBusiness?.Nombre}
							{" / "}
							{selectedChecklist.Nombre}
						</Typography>
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							<LinkEmpresas />
							{user?.tipoUsuario === "Administrador" ? (
								<Link
									component={NavLink}
									to={`/administrador/empresas/${selectedBusiness?.id}/edit`}
								>
									Checklist
								</Link>
							) : (
								<Typography>Checklist</Typography>
							)}
							<Typography>Recintos</Typography>
						</Breadcrumbs>
					</>
				</Grid>
				{/* <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleShowDialog();
              }}
            >
              <AddIcon />
              Nuevo Recinto
            </Button>
          </div>
        </Grid> */}
			</Grid>
			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard />
				</Grid>
			</Grid>
			<DialogAddSubContractSimple
				open={openDialog}
				onClose={handleShowDialog}
				onSave={handleSaveCreate}
			/>
		</React.Fragment>
	);
};
