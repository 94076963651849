import { useEffect } from "react";
import { useStyles } from "../theme/useStyles";
import {
	Button,
	Card as MuiCard,
	CardContent,
	CardHeader,
	Divider as MuiDivider,
	Fade,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
	Chip,
} from "@material-ui/core";
import {
	Edit as EditIcon,
	Plus as PlusIcon,
	Trash2 as DeleteIcon,
} from "react-feather";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { DialogEditAndNew } from "./Business/Dialogs/DialogEditAndNew";
import { NewConfirmDialog } from "./NewConfirmDialog";
import { useTable } from "../hooks/useTable";
import { useNewEditDialog } from "../hooks/useNewEditDialog";
import { useConfirmDialog } from "../hooks/useConfirmDialog";
import { DialogAction } from "../models/dialog_state";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { useLocation } from "react-router-dom";
import { green, red } from "@material-ui/core/colors";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

export type CrudValue = {
	Nombre: string;
	Activado: boolean;
	index: number;
};

export interface SimpleCrudProps {
	canDelete?: boolean;
	isLoading?: boolean;
	name: string;
	/**
	 * @param value Item seleccionado por las acciones
	 * @param action Nuevos datos a crear o actualizar
	 * @param selected Action que realiza el Dialogo
	 */
	onCreateOrUpdate: (
		value: CrudValue,
		action: DialogAction,
		selected?: CrudValue
	) => void;
	onDelete: (value: CrudValue) => void;
	cardTitle?: string;
	array: CrudValue[];
	extraActions?: {
		tooltipText: string;
		handleAction: (selected: any) => void;
		icon: React.ReactNode;
	}[];
	openNewDialog?: boolean;
	onClose?: () => void;
	tableLimit?: number;
	canToggle?: boolean;
	canEdit?: boolean;
}

export const SimpleCrud: React.FC<SimpleCrudProps> = ({
	isLoading,
	name,
	onCreateOrUpdate,
	onDelete,
	cardTitle = "",
	extraActions,
	array,
	openNewDialog: openNewEditDialog = null,
	onClose = () => {},
	tableLimit,
	canDelete = true,
	canToggle = true,
	canEdit = true,
}) => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const {
		limit,
		page,
		handleLimitChange,
		handlePageChange,
	} = useTable({
		limit: tableLimit || TABLE_LIMIT_DEFAULT,
	});
	const {
		open: newEditDialogOpen,
		setIsLoading: setIsLoadingNewEditDialog,
		selected: newEditDialogSelected,
		action,
		closeDialog,
		openNewDialog,
		openEditDialog,
		onAccept,
	} = useNewEditDialog<CrudValue>({
		onAccept: (values, action) => {
			onCreateOrUpdate(
				{
					...values,
					index: newEditDialogSelected?.index ?? 0,
				},
				action,
				newEditDialogSelected
			);
		},
		onClose: onClose,
	});

	const {
		open: confirmDialogOpen,
		dialogConfirmHandleClose,
		dialogConfirmHandleConfirm,
		openDialog,
		setIsLoading,
		selected: confirmDialogSelected,
	} = useConfirmDialog({
		onConfirm: onDelete,
		onClose: onClose,
	});

	useEffect(() => {
		setIsLoading(!!isLoading);
		setIsLoadingNewEditDialog(!!isLoading);
	}, [isLoading, setIsLoading, setIsLoadingNewEditDialog]);

	useEffect(() => {
		if (openNewEditDialog) {
			openNewDialog();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openNewEditDialog]);

	const handleRemoval = (selected: CrudValue) => {
		openDialog(selected);
	};
	return (
		<Card mb={6}>
			{openNewEditDialog === null && (
				<CardHeader
					title={cardTitle}
					action={
						canDelete && (
							<Button
								color="primary"
								variant="contained"
								onClick={() => openNewDialog()}
								disabled={
									pathname.includes(
										"/administrador/checklist"
									)
										? true
										: false
								}
							>
								<PlusIcon size={20} />
								&nbsp; Agregar
							</Button>
						)
					}
				/>
			)}

			<Divider />

			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Nombre</TableCell>
								{canToggle && (
									<TableCell align="center">
										Estado
									</TableCell>
								)}
								<TableCell align="center">
									Acciones
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{array
								.slice(page * limit, page * limit + limit)
								.map((item, i) => (
									<Fade key={i} in={true}>
										<TableRow
											hover
											className={classes.styledRow}
										>
											<TableCell>{item.Nombre}</TableCell>
											{canToggle && (
												<TableCell align="center">
													<Chip
														style={{
															width: 90,
															color: "white",
															marginRight: 10,
															marginBottom: 10,
															backgroundColor: item.Activado
																? green[500]
																: red[500],
														}}
														size="small"
														label={
															item.Activado
																? "Activo"
																: "Inactivo"
														}
													/>
												</TableCell>
											)}

											<TableCell
												style={{ whiteSpace: "nowrap" }}
												align="center"
											>
												{canEdit && (
													<Tooltip title="Editar">
														<IconButton
															color="primary"
															size="small"
															onClick={() =>
																openEditDialog(item)
															}
														>
															<EditIcon />
														</IconButton>
													</Tooltip>
												)}

												{canDelete && (
													<Tooltip title="Eliminar">
														<IconButton
															size="small"
															onClick={() =>
																handleRemoval(item)
															}
														>
															<DeleteIcon color="red" />
														</IconButton>
													</Tooltip>
												)}

												{extraActions?.map((action, i) => (
													<Tooltip
														key={i}
														title={action.tooltipText}
													>
														<IconButton
															size="small"
															onClick={() =>
																action.handleAction(item)
															}
														>
															{action.icon}
														</IconButton>
													</Tooltip>
												))}
											</TableCell>
										</TableRow>
									</Fade>
								))}

							{!array ||
								(array.length === 0 && (
									<Fade in={true}>
										<TableRow>
											<TableCell
												align={"center"}
												colSpan={3}
											>
												<img
													alt="Nada imagen"
													src="/static/no_data.svg"
													height="100"
												/>
												<Typography variant="h5">
													Nada Aun
												</Typography>
											</TableCell>
										</TableRow>
									</Fade>
								))}
						</TableBody>
					</Table>
				</TableContainer>

				<TablePagination
					component="div"
					count={array?.length || 0}
					// @ts-ignore
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
			</CardContent>

			<DialogEditAndNew
				canToggle={canToggle}
				open={newEditDialogOpen}
				onClose={closeDialog}
				onAccept={onAccept}
				isLoading={!!isLoading}
				action={action}
				object={newEditDialogSelected}
				title={name}
			/>

			<NewConfirmDialog
				open={confirmDialogOpen}
				title={`¿Desea eliminar el/la ${name} ${confirmDialogSelected?.Nombre}?`}
				onClose={dialogConfirmHandleClose}
				isLoading={isLoading}
				onConfirm={dialogConfirmHandleConfirm}
				onConfirmText={"Eliminar"}
			/>
		</Card>
	);
};
