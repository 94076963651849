import * as React from "react";
import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Box,
} from "@material-ui/core";
import { DialogAction } from "../../../models/dialog_state";
import { useFormik } from "formik";
import * as yup from "yup";
import { ResponsibleAutocomplete } from "../../../components/ResponsibleAutocomplete";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useDispatch } from "src/redux/store";
import { getUsers } from "src/redux/actions/usersActions";

interface Props {
	open: boolean;
	onClose: () => void;
	onAccept: (
		values: any,
		action: DialogAction
	) => Promise<void> | void;
	isLoading?: boolean;
	action: DialogAction;
	selected?: any;
	business: any;
	isRevision?: boolean;
}

export const DialogNewEdit: React.FC<Props> = ({
	open,
	onClose,
	onAccept,
	isLoading,
	action,
	selected,
	business,
	isRevision = false,
}) => {
	const dispatch = useDispatch();
	const [selectedResponsible, setSelectedResponsible] =
		useState<any | null>(null);
	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		resetForm,
	} = useFormik({
		enableReinitialize: true,
		initialValues: {
			NombreProyecto: selected?.NombreProyecto || "",
			DiasPorVencer: selected?.DiasPorVencer || 10,
			DiasVencidos: selected?.DiasVencidos || 20,
		},
		onSubmit: (values) => {
			onAccept(
				{
					...values,
					Responsable: selectedResponsible,
					Empresa: business,
				},
				action
			);
		},
		validationSchema: yup.object().shape({
			NombreProyecto: yup
				.string()
				.required("Este dato es importante."),
			DiasPorVencer: yup
				.number()
				.required("Este dato es importante.")
				.min(0),
			DiasVencidos: yup
				.number()
				.required("Este dato es importante.")
				.min(0),
		}),
	});

	useEffect(() => {
		if (action === "New") {
			resetForm();
			setSelectedResponsible(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, selected]);

	useEffect(() => {
		if (!open) return;
		dispatch(getUsers());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="dialog-new-edit-projects"
		>
			<DialogTitle
				bigText={
					action === "Edit"
						? "Editar proyecto"
						: "Crear proyecto"
				}
				smallText={
					isRevision ? "Revisión" : "Checklist Revisión"
				}
				onClose={onClose}
			/>

			<form onSubmit={handleSubmit}>
				<DialogContent
					style={{
						display: "flex",
						gap: 20,
						flexDirection: "column",
					}}
				>
					<TextField
						autoFocus
						id="NombreProyecto"
						label="Nombre"
						autoComplete="off"
						value={values.NombreProyecto}
						name="NombreProyecto"
						onChange={handleChange}
						error={
							touched.NombreProyecto &&
							!!errors.NombreProyecto
						}
						helperText={
							touched.NombreProyecto &&
							errors.NombreProyecto
						}
						fullWidth
					/>
					{isRevision && (
						<>
							{/* selector de días */}
							<Box display="flex" gridGap={20}>
								<TextField
									inputProps={{ min: 0 }}
									id="DiasPorVencer"
									label="Dias Por Vencer"
									type="number"
									value={values.DiasPorVencer}
									name="DiasPorVencer"
									onChange={handleChange}
									error={
										touched.DiasPorVencer &&
										Boolean(errors.DiasPorVencer)
									}
									helperText={
										touched.DiasPorVencer &&
										errors.DiasPorVencer
									}
									fullWidth
								/>

								<TextField
									inputProps={{ min: 0 }}
									id="DiasVencidos"
									label="Dias Vencidos"
									type="number"
									value={values.DiasVencidos}
									name="DiasVencidos"
									onChange={handleChange}
									error={
										touched.DiasVencidos &&
										Boolean(errors.DiasVencidos)
									}
									helperText={
										touched.DiasVencidos &&
										errors.DiasVencidos
									}
									fullWidth
								/>
							</Box>
						</>
					)}

					<ResponsibleAutocomplete
						label="Responsable"
						onChange={setSelectedResponsible}
						value={selectedResponsible}
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose} color="primary">
						Cancelar
					</Button>
					<Button
						type="submit"
						color="primary"
						disabled={isLoading}
					>
						Guardar
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
