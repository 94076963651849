import {
	Breadcrumbs,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FC } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

export const NeoProjectPlacesDeleteDialog: FC<DialogProps> =
	(props) => {
		const { selected, remove, loading, set } =
			useProjectsOT();

		const handleRemove = async () => {
			if (!selected.place) return;
			await remove.project.place(selected.place);
			props.onClose?.({}, "escapeKeyDown");
			set.place(null);
		};

		return (
			<Dialog {...props}>
				<DialogTitle
					bigText="Eliminar espacio"
					smallText={
						<Breadcrumbs>
							<Typography>
								{selected.project?.Nombre}
							</Typography>
							<Typography>
								{selected.place?.Nombre}
							</Typography>
						</Breadcrumbs>
					}
				/>

				<DialogContent>
					{selected.place?.AsignadosRef?.length ? (
						<Alert severity="warning">
							Atención: este espacio ha sido asignado a una
							o más listas de chequeo. Si lo eliminas,
							también desaparecerán de ahí. Esta acción no
							se puede deshacer.
						</Alert>
					) : (
						<Typography>
							¿Quieres eliminar este espacio? Esta acción no
							se puede deshacer.
						</Typography>
					)}
				</DialogContent>

				<DialogActions>
					<Button
						disabled={loading}
						onClick={() =>
							props.onClose?.({}, "escapeKeyDown")
						}
					>
						Cancelar
					</Button>

					<Button
						color="primary"
						variant="contained"
						disabled={loading}
						onClick={handleRemove}
					>
						{loading ? (
							<CircularProgress size={24} />
						) : (
							"Eliminar"
						)}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
