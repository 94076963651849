import Axios from "axios";
import { CF_URL } from "../constants/api";

const TYPE = {
	revision: "revisiones-pendientesVsResueltas",
	checklist: "generateProjectSummary",
};

export const generarPendientesVsResueltas = async (
	projectId: string,
	userId: string,
	tipo: keyof typeof TYPE
) => {
	const res = await Axios.post(
		`${CF_URL}/${TYPE[tipo]}`,
		{
			projectId,
			userId,
		},
		{ responseType: "blob" }

		// {
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	},
		// 	responseType: "blob",
		// }
	);
	return res.data as Blob;
};
