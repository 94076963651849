import { createAsyncThunk } from "@reduxjs/toolkit";
import { Empresa } from "src/models/Empresa";

import {
	StateGetter,
	getSelectedBusinessRef,
} from "src/redux/neo-checklists/utils";
import { openSnack } from "src/redux/actions/uiActions";
import { SnackState } from "src/models/snack-state";
import { otActions } from "../slice";
import Firebase from "firebase";
import {
	FireUpdate,
	WithRef,
	getRef,
} from "src/utils/firebase";
import { cleanString } from "src/utils/utils";
import { RootState } from "src/redux/store/reducer";
import { firestore } from "src/firebase/firebase";
import { ChecklistProject } from "src/models/OT/Projects";
import { PlaceOT } from "src/models/OT/Place";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";
import { Usuario } from "src/models/Usuario";
import { setUsers } from "src/redux/actions/usersActions";
import { WithFieldValue } from "src/utils/firebase/field-value";

export type GetProjectsApi = {
	business?: Empresa;
	filters?: Partial<{
		Eliminado: boolean;
	}>;
};

const listener =
	FirebaseListener.addListener("projects-ot");

export const getUserProjectsOT = createAsyncThunk(
	"checklists-ot/user/projects/get",
	async (_, { getState, dispatch }) => {
		const user = (getState() as RootState).authReducer
			.user!;

		const { id, empresaReference, localId } = user;
		const userRef = firestore.doc(`Usuarios/${localId}`);

		console.log("getting user projects", user);

		listener.close();
		listener.set(
			empresaReference
				.collection("ProyectosOT")
				.where("Responsables", "array-contains", userRef.id)
				.onSnapshot(
					(snap) => {
						const data = snap.docs.map((snap) => {
							const data = {
								...snap.data(),
								id: snap.id,
								_ref: snap.ref,
							} as ChecklistProject;

							if (
								(getState() as RootState)
									.neoChecklistReducer.selected.project
									?._ref?.id === data._ref?.id
							)
								dispatch(
									otActions.setSelectedProject(data)
								);

							return data;
						});

						dispatch(otActions.setProjects(data));
					},

					(error) => {
						dispatch(
							openSnack(
								error.message,
								SnackState.ERROR
							) as any
						);
					}
				)
		);
	}
);

export const getProjectsOT = createAsyncThunk(
	"checklists-ot/projects/get",
	async (
		{ business, filters }: GetProjectsApi,
		{ getState, dispatch }
	) => {
		const businessRef = getSelectedBusinessRef(
			business ?? (getState as StateGetter)
		);

		let query: Firebase.firestore.Query = businessRef
			.collection("ProyectosOT")
			.orderBy("Nombre_lower");

		query = query.where(
			"Eliminado",
			"==",
			filters?.Eliminado ?? false
		);

		dispatch(otActions.setProjects(null));

		listener.close();
		listener.set(
			query.onSnapshot(
				(snap) => {
					const data = snap.docs.map((snap) => {
						const data = {
							...snap.data(),
							id: snap.id,
							_ref: snap.ref,
						} as ChecklistProject;

						if (
							(getState() as RootState).neoChecklistReducer
								.selected.project?._ref?.id ===
							data._ref?.id
						)
							dispatch(otActions.setSelectedProject(data));

						return data;
					});

					dispatch(otActions.setProjects(data));
				},

				(error) => {
					console.error(error);
					dispatch(
						openSnack(
							error.message,
							SnackState.ERROR
						) as any
					);
				}
			)
		);
	}
);

type Create = {
	business?: Empresa;
	project: Pick<
		ChecklistProject,
		| "Nombre"
		| "Espacios"
		| "Responsable"
		| "Responsables"
		| "Tipo"
	>;
};

export const createProjectOT = createAsyncThunk(
	"checklists-ot/projects/create",
	async (params: Create, { getState, dispatch }) => {
		dispatch(otActions.setLoading(true));
		try {
			const {
				business = (getState() as RootState).businessReducer
					.edit.selectedBusiness,
				project,
			} = params;

			const projects = (getState() as RootState)
				.neoChecklistReducer.projects;

			if (!projects)
				throw Error("Los proyectos no están cargados.");

			const businessRef = firestore.doc(
				`Empresas/${business?.id}`
			);

			const projectLimit =
				business?.ChecklistsOT?.LimiteProyectos ?? 10;

			if (projects.length >= projectLimit)
				throw Error(
					"Has alcanzado el límite de proyectos."
				);

			// check if name exists
			const snap = await businessRef
				.collection("ProyectosOT")
				.where(
					"Nombre_lower",
					"==",
					cleanString(project.Nombre)
				)
				.where("Eliminado", "==", false)
				.limit(1)
				.get();

			if (!snap.empty)
				throw Error(
					`Ya existe un proyecto con el nombre ${project.Nombre}.`
				);

			const {
				Nombre,
				Espacios,
				Responsable,
				Tipo,
				Responsables,
			} = project;

			// create data...
			const data: FireUpdate<ChecklistProject> = {
				Nombre,
				Espacios,
				Responsable,
				Tipo,
				Responsables: [...Responsables, Responsable],
				TotalChecklists: 0,
				Nombre_lower: cleanString(Nombre),
				Eliminado: false,
				EmpresaReference: businessRef,
				ProyectoIniciado: false,
				FechaCreacion:
					Firebase.firestore.FieldValue.serverTimestamp(),
			};

			// ...and upload it
			const projectRef = await businessRef
				.collection("ProyectosOT")
				.add(data);

			// then, create all spaces
			const spaces: PlaceOT[] = Object.entries(
				project.Espacios
			).flatMap(([place, quantity]) =>
				Array.from({ length: quantity }, (_, i) => ({
					Nombre: `${place} ${i + 1}`,
					Nombre_lower: cleanString(`${place} ${i + 1}`),
					Revisiones: 0,
					RevisionesCompletadas: 0,
				}))
			);

			// ...and upload them with batch
			const spaceRefs = spaces.map(() =>
				projectRef.collection("EspaciosOT").doc()
			);
			const batch = firestore.batch();
			while (spaces.length) {
				const splicedSpaces = spaces.splice(0, 500);
				const splicedRefs = spaceRefs.splice(0, 500);
				splicedSpaces.forEach((space, i) =>
					batch.set(splicedRefs[i], space)
				);
				await batch.commit();
			}

			//finally update business counter
			await businessRef.update({
				"ChecklistsOT.ProyectosUsados":
					Firebase.firestore.FieldValue.increment(1),
			});

			dispatch(
				openSnack(
					"Creamos el proyecto.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
		dispatch(otActions.setLoading(false));
	}
);

type Update = WithRef<Partial<ChecklistProject>>;

export const updateProjectOT = createAsyncThunk(
	"checklists-ot/projects/update",
	async (project: Update, { dispatch, getState }) => {
		dispatch(otActions.setLoading(true));
		try {
			const {
				_ref,
				EmpresaReference,
				Espacios,
				FechaCreacion,
				Tipo,
				id,
				...rest
			} = project;

			const data: WithFieldValue<
				Partial<ChecklistProject>
			> = { ...rest };

			// check if name exists
			if (rest.Nombre) {
				const empresaRef =
					EmpresaReference ??
					getSelectedBusinessRef(getState as StateGetter);
				const snap = await empresaRef
					.collection("ProyectosOT")
					.where(
						"Nombre_lower",
						"==",
						cleanString(rest.Nombre)
					)
					.where("Eliminado", "==", false)
					.limit(1)
					.get();

				if (snap.docs?.[0] && snap.docs[0].id !== _ref.id)
					throw Error(
						`Ya existe un proyecto con el nombre ${data.Nombre}.`
					);
			}

			if (rest.Responsable) {
				if (rest.Responsables) {
					const unique = new Map<
						string,
						Firebase.firestore.DocumentReference
					>();
					rest.Responsables.forEach((ref) =>
						unique.set(ref.id, ref)
					);
					unique.set(rest.Responsable.id, rest.Responsable);
					data.Responsables = Array.from(unique.values());
				} else {
					data.Responsables =
						Firebase.firestore.FieldValue.arrayUnion(
							data.Responsable
						);
				}
			}

			await _ref.update(data);

			dispatch(
				openSnack(
					"Actualizamos el proyecto.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
		dispatch(otActions.setLoading(false));
	}
);

export const removeProjectOT = createAsyncThunk(
	"checklists-ot/projects/delete",
	async (
		project: ChecklistProject | undefined,
		{ dispatch, getState }
	) => {
		dispatch(otActions.setLoading(true));
		try {
			let gotFromState = false;

			const _project = (() => {
				if (project) return project;
				else {
					gotFromState = true;
					return (getState() as RootState)
						.neoChecklistReducer.selected.project;
				}
			})();

			const projectRef = getRef(_project);
			const businessRef = projectRef.parent.parent!;

			await Promise.all([
				projectRef.update({ Eliminado: true }),

				businessRef.update({
					"ChecklistsOT.ProyectosUsados":
						Firebase.firestore.FieldValue.increment(-1),
				}),
			]);

			await projectRef.update({ Eliminado: true });

			if (gotFromState)
				dispatch(otActions.setSelectedProject(null));

			dispatch(
				openSnack(
					"Eliminamos el proyecto.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
		dispatch(otActions.setLoading(false));
	}
);

export const getProjectUsers = createAsyncThunk(
	"checklists-ot/projects/users/get",
	async (_, { dispatch, getState }) => {
		const userRefs = (
			getState() as RootState
		).neoChecklistReducer.projects?.map(
			(p) => p.Responsable
		);

		if (!userRefs) return;

		const unique: Record<
			string,
			Firebase.firestore.DocumentReference
		> = {};

		// filter repeated
		userRefs.forEach((ref) => {
			unique[ref.id] = ref;
		});

		console.log(Object.values(unique).length);

		const users = await Promise.all(
			Object.values(unique).map(async (ref) => {
				const snap = await ref.get();
				return {
					...snap.data(),
					_ref: snap.ref,
					id: snap.id,
				} as Usuario;
			})
		);

		dispatch(setUsers(users));
	}
);
