import { createAsyncThunk } from "@reduxjs/toolkit";
import { otActions } from "../slice";
import { openSnack } from "src/redux/actions/uiActions";
import { SnackState } from "src/models/snack-state";
import { FireUpdate, getRef } from "src/utils/firebase";
import Firebase from "firebase";
import { cleanString } from "src/utils/utils";
import { RootState } from "src/redux/store/reducer";
import { CHECKLISTS_COLLECTION_NAME } from "../constants";
import { firestore } from "src/firebase/firebase";
import { ChecklistProject } from "src/models/OT/Projects";
import { ChecklistOT } from "src/models/OT/Checklist";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";

const listener =
	FirebaseListener.addListener("checklists-ot");

type Get = {
	project?: ChecklistProject;
	type?: ChecklistOT["Tipo"];
};

export const getChecklistsFromProject = createAsyncThunk(
	"checklists-ot/projects/checklists/get",
	async (params: Get, { getState, dispatch }) => {
		const {
			project = (getState() as RootState)
				.neoChecklistReducer.selected.project,
			type,
		} = params;

		const projectRef = getRef(project);

		let query;
		query = projectRef.collection(
			CHECKLISTS_COLLECTION_NAME
		);
		if (type) query = query.where("Tipo", "==", type);
		query = query.orderBy("Nombre_lower", "asc");

		listener.close();
		listener.set(
			query.onSnapshot(
				(snap) => {
					const data = snap.docs.map((snap) => {
						const data = {
							...snap.data(),
							Id: snap.id,
							_ref: snap.ref,
						} as ChecklistOT;

						if (
							(getState() as RootState).neoChecklistReducer
								.selected.checklist?._ref?.id === snap.id
						)
							dispatch(
								otActions.setSelectedChecklist(data)
							);

						return data;
					});

					dispatch(otActions.setProjectChecklists(data));
				},

				(error) => {
					dispatch(
						openSnack(
							error.message,
							SnackState.ERROR
						) as any
					);
				}
			)
		);
	}
);

type Assign = {
	project?: ChecklistProject;
	baseChecklist: ChecklistOT;
	checklist: Pick<
		ChecklistOT,
		"MetaRevision" | "Unidad" | "SubEspacio"
	>;
};

/**
 * Asigna un checklist a un proyecto.
 */
export const addChecklistToProject = createAsyncThunk(
	"checklists-ot/projects/checklists/create",
	async (params: Assign, { getState, dispatch }) => {
		try {
			const {
				project = (getState() as RootState)
					.neoChecklistReducer.selected.project,
				checklist,
				baseChecklist,
			} = params;

			const projectRef = getRef(project);

			// check if name already exists
			const snap = await projectRef
				.collection(CHECKLISTS_COLLECTION_NAME)
				.where(
					"Nombre_lower",
					"==",
					baseChecklist.Nombre_lower
				)
				.limit(1)
				.get();

			if (!snap.empty)
				throw Error(
					`La lista de chequeo ${baseChecklist.Nombre} ya fue asignada.`
				);

			const { MetaRevision, Unidad } = checklist;

			const { Tipo, Nombre, Nombre_lower, Preguntas } =
				baseChecklist;

			const data: FireUpdate<ChecklistOT> = {
				// base data
				Tipo,
				Nombre,
				Nombre_lower,
				Preguntas,
				// new data
				MetaRevision: MetaRevision!,
				Unidad: Unidad!,
				FechaCreacion:
					Firebase.firestore.FieldValue.serverTimestamp(),
				ProyectoReference: projectRef,
				Cantidad: 0,
				TotalEspacios: 0,
				EspaciosCompletados: 0,
				TotalRespuestas: 0,
			};

			const batch = firestore.batch();
			batch.update(projectRef, { Contado_: false });
			batch.set(
				projectRef.collection("ChecklistsOT").doc(),
				data
			);

			await batch.commit();

			dispatch(
				openSnack(
					"Hemos asignado la lista de chequeo.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
	}
);

export const updateChecklistFromProject = createAsyncThunk(
	"checklists-ot/projects/checklists/update",
	async (
		checklist: Partial<ChecklistOT>,
		{ dispatch, getState }
	) => {
		dispatch(otActions.setLoading(true));
		try {
			const checklistRef = getRef(checklist);
			const original = (getState() as RootState)
				.neoChecklistReducer.selected.checklist;

			if (!original)
				throw Error("No se encontró la revisión.");

			const { Nombre, MetaRevision, Unidad, SubEspacio } =
				checklist;

			const data: Partial<ChecklistOT> = {};

			if (Nombre) {
				// check if name already exists
				const snap = await checklistRef.parent
					.where("Nombre_lower", "==", cleanString(Nombre))
					.limit(1)
					.get();
				if (
					snap.docs?.[0] &&
					snap.docs[0].id !== checklistRef.id
				)
					throw Error(
						`Ya existe una revisión con el nombre ${Nombre}.`
					);
				data.Nombre = Nombre;
			}

			if (MetaRevision) data.MetaRevision = MetaRevision;
			if (Unidad) data.Unidad = Unidad;
			if (original.Tipo === "Terminaciones" && SubEspacio)
				data.SubEspacio = SubEspacio;

			await checklistRef.update(data);

			dispatch(
				openSnack(
					"Actualizamos la revisión.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
		dispatch(otActions.setLoading(false));
	}
);

export const removeChecklistFromProjectOT =
	createAsyncThunk(
		"checklists-ot/projects/checklists/delete",
		async (
			checklist: ChecklistOT,
			{ dispatch, getState }
		) => {
			dispatch(otActions.setLoading(true));
			try {
				const checklistRef = getRef(checklist);
				const projectRef = checklistRef.parent.parent!;
				const refsToRemove: Firebase.firestore.DocumentReference[] =
					[];
				const original = (getState() as RootState)
					.neoChecklistReducer.selected.checklist;

				if (!original)
					throw Error(
						"No se encontró la lista de chequeo."
					);

				// Get checklist's assigned places.
				const assignedPlaces = await checklistRef
					.collection("EspaciosAsignadosOT")
					.get();
				// Add them to removal queue.
				assignedPlaces.docs.forEach((doc) =>
					refsToRemove.push(doc.ref)
				);
				// Get responses from assigned places.
				const responses = await Promise.all(
					assignedPlaces.docs.map((doc) =>
						doc.ref.collection("RespuestasOT").get()
					)
				);
				// Add them to removal queue-
				responses
					.flatMap((colSnap) => colSnap.docs)
					.forEach((docSnap) =>
						refsToRemove.push(docSnap.ref)
					);

				while (refsToRemove.length) {
					const batch = firestore.batch();
					const spliced = refsToRemove.splice(0, 500);
					spliced.forEach((ref) => batch.delete(ref));
					await batch.commit();
				}

				const batch = firestore.batch();
				batch.delete(checklistRef);
				batch.update(projectRef, { Contado_: false });

				await batch.commit();

				dispatch(
					openSnack(
						"Eliminamos la lista de chequeo.",
						SnackState.SUCCESS
					) as any
				);
			} catch (error: any) {
				console.error(error);
				dispatch(
					openSnack(error.message, SnackState.ERROR) as any
				);
			}
			dispatch(otActions.setLoading(false));
		}
	);
