import {
	Box,
	BoxProps,
	IconButton,
	IconButtonProps,
	TextField,
	TextFieldProps,
	TooltipProps,
} from "@material-ui/core";
import { useFormik } from "formik";
import { FC, ReactNode } from "react";
import * as Yup from "yup";
import { Add } from "@material-ui/icons";

type Props = {
	onSubmit?: (name: string) => void;
	buttonProps?: IconButtonProps;
	containerProps?: BoxProps;
	tooltip?: ReactNode;
	tooltipProps?: TooltipProps;
	value?: string;
	onChange?: (value: string) => void;
} & Omit<TextFieldProps, "onSubmit" | "value" | "onChange">;

export const PlacesToolbar: FC<Props> = ({
	onSubmit,
	containerProps,
	buttonProps,
	tooltip,
	onChange,
	...props
}) => {
	const {
		handleSubmit,
		errors,
		values,
		handleChange,
		touched,
		dirty,
	} = useFormik({
		initialValues: {
			nombre: (props.value as string) || "",
		},
		onSubmit: async (values, { resetForm }) => {
			await onSubmit?.(values.nombre);
			resetForm();
		},
		validationSchema: Yup.object({
			nombre: Yup.string().required("Campo requerido"),
		}),
	});

	return (
		<Box
			component="form"
			onSubmit={handleSubmit as any}
			style={{
				justifyContent: "center",
				alignItems: "center",
				display: "flex",
				flexWrap: "nowrap",
				...containerProps?.style,
			}}
			{...containerProps}
		>
			<TextField
				error={!!errors.nombre && !!touched.nombre}
				value={values.nombre}
				name="nombre"
				helperText={touched.nombre && errors.nombre}
				label="Nombre"
				size="small"
				autoComplete="off"
				id="nombre"
				onChange={(ev) => {
					handleChange(ev);
					onChange?.(ev.target.value);
				}}
				{...props}
			/>

			<IconButton
				color="primary"
				size="small"
				type="submit"
				disabled={!dirty}
				{...buttonProps}
			>
				{buttonProps?.children ?? <Add />}
			</IconButton>
		</Box>
	);
};
