import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";

export const getCharges = (): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Cargos")
        .orderBy("Nombre")
        .get();

      dispatch(setCharges(mapCharges(response)));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const addCharge = (charge: any): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore.collection("Cargos").get();

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (cleanString(post.data().Nombre) === cleanString(charge.Nombre)) {
          return true;
        }
      });

      if (__FOUND) {
        dispatch(openSnack("Cargo ya existe", SnackState.ERROR));
      } else {
        await firestore.collection("Cargos").add(charge);

        dispatch(getCharges());
      }
    } catch (error: any) {
      dispatch(setError(error));
      
      dispatch(isLoading(false));
    }
  };
};

export const updateCharge = (charge: any, id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore.collection("Cargos").get();

      let chargeId = "0";

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (cleanString(post.data().Nombre) === cleanString(charge.Nombre)) {
          chargeId = post.id;
          return true;
        }
      });

      if (__FOUND && chargeId !== id) {
        dispatch(openSnack("Cargo ya existe", SnackState.ERROR));
      } else {
        await firestore.collection("Cargos").doc(id).update(charge);

        dispatch(updateChargeStore({ id, ...charge }));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const deleteCharge = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      await firestore.collection("Cargos").doc(id).delete();

      dispatch(removeChargeStore({ id }));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const mapCharges = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let charges: any[] = [];
    response.docs.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      charges.push(data);
    });
    return charges;
  } catch (error) {
    throw error;
  }
};

const setCharges = (charges: any[]): Action => ({
  type: types.CHARGES_GET_DOCS,
  payload: charges,
});

const isLoading = (isLoading: boolean): Action => ({
  type: types.CHARGES_LOADING,
  payload: isLoading,
});

const setError = (error: string): Action => ({
  type: types.CHARGES_FAILURE,
  payload: error,
});

const updateChargeStore = (charge: any): Action => ({
  type: types.CHARGES_UPDATE_DOC,
  payload: charge,
});

const removeChargeStore = (charge: any): Action => ({
  type: types.CHARGES_REMOVE_DOC,
  payload: charge,
});
