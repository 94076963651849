import {
	blue,
	green,
	red,
	yellow,
} from "@material-ui/core/colors";
import { addDays, compareAsc } from "date-fns";
import { Observacion } from "src/models/Observacion";
import store from "src/redux/store";

export const getStatusRevision = (data: Observacion) => {
	const { DiasPorVencer = 0, DiasVencidos = 0 } =
		store.getState().projectsReducer.revisions
			.selectedProject ?? {};
	const { CreatedAt } = data;
	const PorVencer = addDays(
		CreatedAt.toDate(),
		DiasPorVencer
	);
	const Vencida = addDays(CreatedAt.toDate(), DiasVencidos);

	console.log(data);
	// Fecha1 - Fecha2
	// 1 : Fecha1 > Fecha2
	// -1 : Fecha1 < Fecha2
	// 0 : Fecha1 === Fecha2
	// logica para render
	const isPorVencer = compareAsc(new Date(), PorVencer);
	const isVencida = compareAsc(new Date(), Vencida);
	const isResuelta = !!data.Resuelta;

	let status = "";
	let backgroundColor: string = "";
	let color: string = "white";

	if (isResuelta) {
		status = "Resuelta";
		backgroundColor = green[500];
	} else if (isVencida === 0 || isVencida > 0) {
		status = "Por Resolver";
		backgroundColor = red[500];
	} else if (
		(isPorVencer === 0 || isPorVencer > 0) &&
		isVencida < 0
	) {
		status = "Por Vencer";
		backgroundColor = yellow[500];
		color = "";
	} else {
		status = "Observada";
		backgroundColor = blue[500];
	}

	return {
		status,
		backgroundColor,
		color,
	};
};
