import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import { Add as AddIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/reducer";
import { Skeleton } from "@material-ui/lab";
import { NavLink, useParams } from "react-router-dom";
import { ISubContractsState } from "../redux/reducers/subContractsReducer";
import {
  addTypeError,
  getSubContract,
  removeTypeError,
  updateTypeError,
} from "../redux/actions/subContractActions";
import { SimpleCrud } from "../components/SimpleCrud";
import { TABLE_LIMIT_DEFAULT } from "../constants";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const TypeErrors = () => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const {
    typeErrors: { isLoading, updateLoading },
    selectedSubContract,
  } = useSelector<RootState, ISubContractsState>(
    (state) => state.subContractsReducer
  );

  useEffect(() => {
    if (!selectedSubContract) {
      dispatch(getSubContract(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Errores Tipos" />
      <Grid justify="space-between" container>
        <Grid item>
          {isLoading ? (
            <Skeleton variant="text" height={40} />
          ) : (
            <Typography variant="h3" gutterBottom display="inline">
              {"Errores Tipo de " + selectedSubContract?.Nombre}
            </Typography>
          )}

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/mantenedores/subcontratos">
              Subcontratos
            </Link>
            <Typography>Errores Tipo</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              disabled={isLoading}
            >
              <AddIcon />
              Nuevo Error Tipo
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SimpleCrud
            key={"ErroresTipo"}
            array={selectedSubContract?.ErroresTipo}
            isLoading={updateLoading}
            name="Error Tipo"
            onCreateOrUpdate={(values, action, selected) => {
              if (action === "New") {
                dispatch(addTypeError(values));
              } else if (action === "Edit") {
                dispatch(updateTypeError(values, selected));
              }
            }}
            onDelete={(selected) => {
              dispatch(removeTypeError(selected));
            }}
            openNewDialog={open}
            onClose={() => setOpen(false)}
            tableLimit={TABLE_LIMIT_DEFAULT}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
