import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { useProjectsOT } from "../redux/useProjectsOT";
import { useDispatch } from "src/redux/store";
import { addSheetToProjectSpaceOT } from "src/redux/neo-checklists/actions/sheets";
import {
	SheetOuterJoinOT,
	WithSheetTypeOT,
} from "src/models/OT/Sheet";
import { useMemo } from "react";

export const useSheetValidator = (
	mode: "Sector y recinto" | "Pasillos" | "Otro",
	afterSubmit?: (
		values: SheetOuterJoinOT & WithSheetTypeOT,
		helpers: FormikHelpers<any>
	) => void
) => {
	const dispatch = useDispatch();
	const { selected, checklist } = useProjectsOT();
	const { Tipo = "Obra Gruesa", Unidad = "unidad" } =
		selected.checklist ?? {};

	const formik = useFormik({
		enableReinitialize: true,

		initialValues: {
			Tipo,
			Unidades: 0,
			PlanoRevisor: "",
			Elemento: "",
			Ubicacion: "",
			SubEspacioRespuesta: "",
			Sector: "",
			Recinto: "",
			Pasillo: "",
			Otro: "",
		},

		validationSchema: Yup.object().shape({
			Tipo: Yup.string().oneOf([
				"Obra Gruesa",
				"Terminaciones",
			]),

			Unidades: Yup.number()
				.min(1)
				.required(`Debes revisar al menos 1 ${Unidad}`),

			PlanoRevisor:
				Tipo === "Obra Gruesa"
					? Yup.string().required(
							"Este dato es importante."
					  )
					: Yup.string().notRequired(),

			Elemento:
				Tipo === "Obra Gruesa"
					? Yup.string().required(
							"Este dato es importante."
					  )
					: Yup.string().notRequired(),

			Ubicacion:
				Tipo === "Obra Gruesa"
					? Yup.string().required(
							"Este dato es importante."
					  )
					: Yup.string().notRequired(),

			Recinto:
				Tipo === "Terminaciones" &&
				mode === "Sector y recinto"
					? Yup.string().required(
							"Este dato es importante."
					  )
					: Yup.string().notRequired(),
			Sector:
				Tipo === "Terminaciones" &&
				mode === "Sector y recinto"
					? Yup.string().required(
							"Este dato es importante."
					  )
					: Yup.string().notRequired(),

			Pasillo:
				Tipo === "Terminaciones" && mode === "Pasillos"
					? Yup.string().required(
							"Este dato es importante."
					  )
					: Yup.string().notRequired(),

			Otro:
				Tipo === "Terminaciones" && mode === "Otro"
					? Yup.string().required(
							"Este dato es importante."
					  )
					: Yup.string().notRequired(),
		}),

		onSubmit: async ({ Tipo, ...values }, helpers) => {
			await dispatch(
				addSheetToProjectSpaceOT({
					type: Tipo,
					initialValues: values,
				})
			);

			afterSubmit?.({ Tipo, ...values }, helpers);
		},
	});

	const maxUnits = useMemo(() => {
		const max = selected.assignedPlace?.Cantidad ?? 0;
		const weight =
			checklist.responses?.reduce(
				(acc, response) => acc + response.Unidades,
				0
			) ?? 0;

		return max - weight > 0 ? max - weight : 0;
	}, [
		checklist.responses,
		selected.assignedPlace?.Cantidad,
	]);

	return { ...formik, maxUnits };
};
