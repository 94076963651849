import {
	AllInbox,
	Assignment,
	Business as BusinessIcon,
	Error,
	MeetingRoom,
	Search,
} from "@material-ui/icons";
import { Clipboard, Grid } from "react-feather";
import {
	AdminGuard,
	BusinessAdminGuard,
	UserGuard,
} from "../guards";
import { RevisionesIcon } from "../icons/RevisionesIcon";
import { AnswersChecklist } from "../pages/AnswersChecklist";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import ResetPassword from "../pages/auth/ResetPassword";
// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
// Pages components
import { BusinessPage } from "../pages/Business";
import { Charges } from "../pages/Charges";
import { CheckListPage } from "../pages/CheckList";
import { CreateChecklist } from "../pages/ChecklistEdit";
import { ChecklistSubcontratos } from "../pages/ChecklistSubcontratos";
import { EditBusinessPage } from "../pages/EditBusiness/EditBusinessPage";
import { ChecklistChart } from "../pages/Estadisticas/ChecklistChart";
import { ProjectChart } from "../pages/Estadisticas/ProjectChart";
import { ListProjectChecklistPage } from "../pages/Lists/ListProjectChecklist";
import { ListsPage } from "../pages/Lists/Lists";
import { ListsBusiness } from "../pages/Lists/ListsBusiness";
import { ObservacionesSummary } from "../components/ObservacionesSummary/ObservacionesSummary";
import { ListadosScreen } from "../pages/ListadosScreen";
import { ObservationsBusiness } from "../pages/ObservationsBusiness";
import { Places } from "../pages/Places";
import { PlacesBusiness } from "../pages/PlacesBusiness";
import { Priorities } from "../pages/Priorities";
import { PrioritiesBusiness } from "../pages/PrioritiesBusiness";
import { ProjectRecintos } from "../pages/ProjectRecintos";
import { ProjectsPage } from "../pages/Projects/Projects";
import { ProjectsBusinessScreen } from "../pages/Projects/ProjectsBusiness";
import { ChecklistPage } from "../pages/Projects/ChecklistPage";
import { RevisionsPage } from "../pages/Revisions/Revisions";
import { RevisionsBusiness } from "../pages/Revisions/RevisionsBusiness";
import { Sectors } from "../pages/Sectors";
import { SectorsBusiness } from "../pages/SectorsBusiness";
import { SubContract } from "../pages/SubContract";
import { SubContractBusiness } from "../pages/SubContractBusiness";
import { SubcontratosProject } from "../pages/SubcontratosProject";
import { TypeErrors } from "../pages/TypeErrors";
import { RevisionObservationsExcelPage } from "src/components/ObservacionesSummary/LegacyObservacionesSummary";
import { PERMISOS } from "src/constants/permisos";
import {
	businessAdminSidebarOT,
	businessUserSidebarOT,
} from "./routesOT";

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administrador_empresas = {
	id: PERMISOS.ADMINISTRADOR,
	name: PERMISOS.ADMINISTRADOR,
	path: "/administrador",
	icon: <BusinessIcon />,
	component: null,
	tipoUser: PERMISOS.ADMINISTRADOR,
	children: [
		{
			path: "/administrador/empresas",
			name: "Empresas",
			header: PERMISOS.ADMINISTRADOR,
			guard: AdminGuard,
			component: BusinessPage,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administrador_mantenedores = {
	id: "Mantenedores Check Pro",
	name: "Mantenedores Check Pro",
	path: "/mantenedores",
	icon: <AllInbox />,
	component: null,
	tipoUser: PERMISOS.ADMINISTRADOR,
	children: [
		{
			path: "/mantenedores",
			name: "Mantenedores",
			id: "Revisiones",
			component: null,
			icon: (
				<RevisionesIcon style={{ height: 32, width: 32 }} />
			),
			children: [
				{
					path: "/mantenedores/sectores",
					name: "Sectores",
					guard: AdminGuard,
					component: Sectors,
				},
				{
					path: "/mantenedores/recintos",
					name: "Recintos",
					guard: AdminGuard,
					component: Places,
				},
				{
					path: "/mantenedores/subcontratos",
					name: "Subcontratos",
					guard: AdminGuard,
					component: SubContract,
				},
				{
					path: "/mantenedores/prioridades",
					name: "Prioridades",
					guard: AdminGuard,
					component: Priorities,
				},
				{
					path: "/mantenedores/cargos",
					name: "Cargos",
					header: "Mantenedores CheckPro",
					guard: AdminGuard,
					component: Charges,
				},
			],
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administradorEmpresa_checklist = {
	name: "Checklist Revisiones",
	id: "CheckList Revisiones",
	path: "/administrador/checklist",
	component: null,
	tipoUser: PERMISOS.ADMINISTRADOR_EMPRESA,
	icon: <Assignment />,
	guard: BusinessAdminGuard,
	children: [
		{
			id: "Checklist Revisiones",
			name: "Proyectos",
			path: "/administrador/checklist-proyectos",
			component: ChecklistPage,
			guard: BusinessAdminGuard,
		},
		{
			id: "Checklist Revisiones",
			name: "Checklist",
			path: "/administrador/checklist",
			component: CheckListPage,
			guard: BusinessAdminGuard,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_usuarioEmpresa_checklist = {
	name: "Checklist Revisiones",
	id: "CheckList Revisiones",
	path: "/administrador/checklist",
	component: null,
	tipoUser: PERMISOS.USUARIO_EMPRESA,
	icon: <Assignment />,
	guard: UserGuard,
	children: [
		{
			name: "Proyectos",
			path: "/administrador/checklist-proyectos",
			component: ChecklistPage,
		},
		{
			name: "Checklist",
			path: "/administrador/checklist",
			component: CheckListPage,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administradorEmpresa_editarEmpresa = {
	name: "Editar Empresa",
	id: "Editar Empresa",
	path: "/administrador/empresa/detalles",
	tipoUser: PERMISOS.ADMINISTRADOR_EMPRESA,
	icon: <Grid />,
	component: EditBusinessPage,
	guard: BusinessAdminGuard,
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administradorEmpresa_revisiones = {
	name: "Revisiones",
	id: "Revisiones",
	path: "/administrador",
	component: null,
	tipoUser: PERMISOS.ADMINISTRADOR_EMPRESA,
	icon: <Search />,
	guard: BusinessAdminGuard,
	children: [
		{
			id: "Revisiones",
			name: "Proyectos",
			path: "/administrador/empresa/proyectos",
			component: ProjectsBusinessScreen,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_usuarioEmpresa_revisiones = {
	name: "Revisiones",
	id: "Revisiones",
	path: "/administrador",
	component: null,
	tipoUser: PERMISOS.USUARIO_EMPRESA,
	icon: <Search />,
	guard: UserGuard,
	children: [
		{
			path: "/administrador/user/proyectos",
			name: "Proyectos Asignados",
			header: "Usuario",
			guard: UserGuard,
			component: ProjectsBusinessScreen,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const administradorEmpresa_observaciones = {
	path: "/administrador",
	name: "Observaciones",
	guard: BusinessAdminGuard,
	children: [
		{
			id: "Estadisticas1",
			name: "Estadísticas del proyecto",
			path: "/administrador/estadisticas/:idProyecto",
			component: ProjectChart,
		},
		{
			id: "Estadisticas2",
			name: "Estadísticas de la revisión",
			path: "/administrador/estadisticas/:idProyecto/:idRevision",
			component: ProjectChart,
		},
		{
			id: "Estadisticas3",
			name: "Estadísticas del listado",
			path: "/administrador/estadisticas/:idProyecto/:idRevision/:idListado",
			component: ProjectChart,
		},
		{
			path: "/administrador/empresa/proyectos/:projectId/revisiones",
			name: "Revisiones",
			component: RevisionsBusiness,
		},
		{
			path: "/administrador/empresa/proyectos/:projectId/revisiones/:revisionId/listados",
			name: "Revisiones",
			component: ListsBusiness,
		},
		{
			path: "/administrador/empresa/proyectos/:projectId/revisiones/:revisionId/listados/:listId/observaciones",
			name: "Observaciones",
			component: ObservationsBusiness,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const usuarioEmpresa_observaciones = {
	path: "/administrador",
	name: "Observaciones Usuario Empresa",
	guard: UserGuard,
	children: [
		{
			id: "Estadisticas1",
			name: "Estadísticas del proyecto",
			path: "/administrador/estadisticas/:idProyecto",
			component: ProjectChart,
		},
		{
			id: "Estadisticas2",
			name: "Estadísticas de la revisión",
			path: "/administrador/estadisticas/:idProyecto/:idRevision",
			component: ProjectChart,
		},
		{
			id: "Estadisticas3",
			name: "Estadísticas del listado",
			path: "/administrador/estadisticas/:idProyecto/:idRevision/:idListado",
			component: ProjectChart,
		},
		{
			path: "/administrador/empresa/proyectos/:projectId/revisiones",
			name: "Revisiones",
			component: RevisionsBusiness,
		},
		{
			path: "/administrador/empresa/proyectos/:projectId/revisiones/:revisionId/listados",
			name: "Revisiones",
			component: ListsBusiness,
		},
		{
			path: "/administrador/empresa/proyectos/:projectId/revisiones/:revisionId/listados/:listId/observaciones",
			name: "Observaciones",
			component: ObservationsBusiness,
		},
	],
};

// sub rutas de los proyectos con checklist
/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const administradorEmpresa_checklist = {
	name: "Checklist",
	path: "/administrador",
	component: null,
	guard: BusinessAdminGuard,
	children: [
		{
			name: "Proyectos CheckList",
			path: "/administrador/empresas/:idEmpresa/checklist-proyectos",
			component: ChecklistPage,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones",
			name: "Revisiones",
			component: RevisionsBusiness,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones/:revisionId/listados",
			name: "Revisiones",
			component: ListProjectChecklistPage,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones/:revisionId/listados/:listadoId/recintos",
			name: "Recintos",
			component: ProjectRecintos,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones/:revisionId/listados/:listadoId/recintos/:recintoId/subcontratos",
			name: "Subcontratos",
			component: SubcontratosProject,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones/:revisionId/listados/:listadoId/recintos/:recintoId/subcontratos/:subcontratoId/respuestas/:errtipoId",
			name: "Respuestas",
			component: AnswersChecklist,
		},
		{
			name: "Estadísticas del proyecto",
			path: "/administrador/checklist-proyectos/estadisticas/:projectId",
			component: ChecklistChart,
		},
		{
			name: "Estadísticas de la revisión",
			path: "/administrador/checklist-proyectos/estadisticas/:projectId/:revisionId",
			component: ChecklistChart,
		},
		{
			name: "Estadísticas del listado",
			path: "/administrador/checklist-proyectos/estadisticas/:projectId/:revisionId/:listadoId",
			component: ChecklistChart,
		},
		{
			name: "Observaciones",
			path: "/administrador/checklist-proyectos/:projectId/observaciones",
			component: ObservacionesSummary,
		},
		{
			name: "Observaciones",
			path: "/administrador/proyectos/:projectId/observaciones",
			component: RevisionObservationsExcelPage,
		},
		{
			path: "/administrador/checklist/:checklisId/Recintos",
			name: "Revisiones",
			component: CreateChecklist,
		},
		{
			path: "/administrador/checklist/:checklisId/Recintos/:subcontratoId/Subcontratos",
			name: "Revisiones",
			component: ChecklistSubcontratos,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const usuarioEmpresa_checklist = {
	name: "Checklist Usuario Empresa",
	path: "/administrador",
	component: null,
	guard: UserGuard,
	children: [
		{
			name: "Proyectos CheckList",
			path: "/administrador/empresas/:idEmpresa/checklist-proyectos",
			component: ChecklistPage,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones",
			name: "Revisiones",
			component: RevisionsBusiness,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones/:revisionId/listados",
			name: "Revisiones",
			component: ListProjectChecklistPage,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones/:revisionId/listados/:listadoId/recintos",
			name: "Recintos",
			component: ProjectRecintos,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones/:revisionId/listados/:listadoId/recintos/:recintoId/subcontratos",
			name: "Subcontratos",
			component: SubcontratosProject,
		},
		{
			path: "/administrador/checklist-proyectos/:projectId/revisiones/:revisionId/listados/:listadoId/recintos/:recintoId/subcontratos/:subcontratoId/respuestas/:errtipoId",
			name: "Respuestas",
			component: AnswersChecklist,
		},
		{
			name: "Estadísticas del proyecto",
			path: "/administrador/checklist-proyectos/estadisticas/:projectId",
			component: ChecklistChart,
		},
		{
			name: "Estadísticas de la revisión",
			path: "/administrador/checklist-proyectos/estadisticas/:projectId/:revisionId",
			component: ChecklistChart,
		},
		{
			name: "Estadísticas del listado",
			path: "/administrador/checklist-proyectos/estadisticas/:projectId/:revisionId/:listadoId",
			component: ChecklistChart,
		},
		{
			name: "Observaciones",
			path: "/administrador/checklist-proyectos/:projectId/observaciones",
			component: ObservacionesSummary,
		},
		{
			name: "Observaciones",
			path: "/administrador/proyectos/:projectId/observaciones",
			component: RevisionObservationsExcelPage,
		},
		{
			path: "/administrador/checklist/:checklisId/Recintos",
			name: "Revisiones",
			component: CreateChecklist,
		},
		{
			path: "/administrador/checklist/:checklisId/Recintos/:subcontratoId/Subcontratos",
			name: "Revisiones",
			component: ChecklistSubcontratos,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const proyectoBusinessUserSubRoutes = {
	name: "Proyectos",
	path: "/administrador",
	component: null,
	children: [
		{
			path: "/administrador/user/proyectos/:projectId/revisiones",
			name: "Revisiones",
			guard: UserGuard,
			component: RevisionsBusiness,
		},
		{
			path: "/administrador/user/proyectos/:projectId/revisiones/:revisionId/listados",
			name: "Listados",
			guard: UserGuard,
			component: ListsBusiness,
		},
		{
			path: "/administrador/user/proyectos/:projectId/revisiones/:revisionId/listados/:listId/observaciones",
			name: "Observaciones",
			guard: UserGuard,
			component: ObservationsBusiness,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administradorEmpresa_sectores = {
	name: "Sectores",
	id: "Sectores",
	path: "/administrador/sectores",
	tipoUser: PERMISOS.ADMINISTRADOR_EMPRESA,
	icon: <Grid />,
	component: SectorsBusiness,
	guard: BusinessAdminGuard,
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administradorEmpresa_recintos = {
	name: "Recintos",
	id: "Recintos",
	path: "/administrador/empresa/recintos",
	tipoUser: PERMISOS.ADMINISTRADOR_EMPRESA,
	icon: <MeetingRoom />,
	component: PlacesBusiness,
	guard: BusinessAdminGuard,
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administradorEmpresa_subcontratos = {
	name: "Subcontratos",
	id: "Subcontratos",
	path: "/administrador/empresa/subcontratos",
	tipoUser: PERMISOS.ADMINISTRADOR_EMPRESA,
	icon: <Clipboard />,
	component: SubContractBusiness,
	guard: BusinessAdminGuard,
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const menu_administradorEmpresas_prioridades = {
	name: "Prioridades",
	id: "Prioridades",
	path: "/administrador/empresa/prioridades",
	tipoUser: PERMISOS.ADMINISTRADOR_EMPRESA,
	icon: <Error />,
	component: PrioritiesBusiness,
	guard: BusinessAdminGuard,
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const administratorSubRoutes = {
	name: "Administrador",
	path: "/administrador",
	component: null,
	guard: AdminGuard,
	children: [
		{
			path: "/administrador/empresas/:businessId/proyectos",
			name: "Revisiones",
			component: ProjectsPage,
		},
		{
			path: "/administrador/empresas/:businessId/proyectos/:projectId/revisiones",
			name: "Revisiones",
			component: RevisionsPage,
		},
		{
			path: "/administrador/empresas/:businessId/proyectos/:projectId/revisiones/:revisionId/listados",
			name: "Listados",
			component: ListsPage,
		},
		{
			path: "/administrador/empresas/:businessId/proyectos/:projectId/revisiones/:revisionId/listados/:listId/observaciones",
			name: "Listados",
			component: ListadosScreen,
		},
		{
			path: "/administrador/empresas/:idEmpresa/edit",
			name: "Editar Empresa",
			component: EditBusinessPage,
		},
		{
			path: "/administrador/empresas/:idEmpresa/Checklists",
			name: "Revisiones",
			component: CreateChecklist,
		},
		{
			path: "/administrador/empresas/:idEmpresa/Checklists/:checklistId/Subcontratos",
			name: "Revisiones",
			component: ChecklistSubcontratos,
		},
		// MODULO V2 CHECKLISTS!!
		// ...neoChecklistRoutes,
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const usuarioEmpresa_empresas = {
	name: "Empresas",
	path: "/administrador",
	component: null,
	guard: UserGuard,
	children: [
		{
			path: "/administrador/empresas/:businessId/proyectos/:projectId/revisiones",
			name: "Revisiones",
			component: RevisionsPage,
		},
		{
			path: "/administrador/empresas/:businessId/proyectos",
			name: "Revisiones",
			component: ProjectsPage,
		},
		{
			path: "/administrador/empresas/:businessId/proyectos/:projectId/revisiones/:revisionId/listados",
			name: "Listados",
			component: ListsPage,
		},
		{
			path: "/administrador/empresas/:businessId/proyectos/:projectId/revisiones/:revisionId/listados/:listId/observaciones",
			name: "Listados",
			component: ListadosScreen,
		},
		{
			path: "/administrador/empresas/:id/edit",
			name: "Editar Empresa",
			component: EditBusinessPage,
		},
	],
};

const revisionsSubRoutes = {
	path: "/mantenedores/revisiones",
	component: null,
	children: [
		{
			path: "/mantenedores/subcontratos/:id/errorestipo",
			name: "Errores Tipo",
			guard: AdminGuard,
			component: TypeErrors,
		},
	],
};

/**
 * @type {import("./CheckproRoute").CheckproRoute}
 */
const authRoutes = {
	name: "Auth",
	id: "Auth",
	path: "/auth",
	icon: null,
	children: [
		{
			path: "/auth/sign-in",
			name: "Sign In",
			component: SignIn,
		},
		{
			path: "/auth/sign-up",
			name: "Sign Up",
			component: SignUp,
		},
		{
			path: "/auth/reset-password",
			name: "Reset Password",
			component: ResetPassword,
		},
		{
			path: "/auth/404",
			name: "404 Page",
			component: Page404,
		},
		{
			path: "/auth/500",
			name: "500 Page",
			component: Page500,
		},
	],
	component: null,
};

// volver a escribir rutas

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
/**
 * @type {import("./CheckproRoute").CheckproRoute[]}
 */
export const protectedRoutes = [
	menu_administrador_empresas,
	administratorSubRoutes,
	menu_administrador_mantenedores,
	revisionsSubRoutes,
	administradorEmpresa_observaciones,
	proyectoBusinessUserSubRoutes,
	menu_administradorEmpresa_editarEmpresa,
];

/**
 * @type {import("./CheckproRoute").CheckproRoute[]}
 */
export const adminBussinesRoutes = [
	usuarioEmpresa_checklist,
	usuarioEmpresa_observaciones,
	usuarioEmpresa_empresas,
	administradorEmpresa_checklist,
	menu_usuarioEmpresa_revisiones,
	menu_usuarioEmpresa_checklist,
	menu_administradorEmpresa_checklist,
	menu_administradorEmpresas_prioridades,
	menu_administradorEmpresa_recintos,
	menu_administradorEmpresa_revisiones,
	menu_administradorEmpresa_sectores,
	menu_administradorEmpresa_subcontratos,
];

// Routes visible in the sidebar

export const sidebarRoutes = [
	menu_administradorEmpresa_editarEmpresa,
	menu_administrador_empresas,
	menu_administrador_mantenedores,
	menu_administradorEmpresa_revisiones,
	menu_usuarioEmpresa_revisiones,
	businessAdminSidebarOT,
	businessUserSidebarOT,
	menu_usuarioEmpresa_checklist,
	menu_administradorEmpresa_checklist,
];
