import { useState, FC } from "react";
import {
	Box,
	CircularProgress,
	Switch,
	Tooltip,
	Typography,
} from "@material-ui/core";

import { Usuario } from "src/models/Usuario";
import {
	bloquearUsuario,
	activarUsuario,
} from "../../services/auth";
import { Alert } from "@material-ui/lab";

type Props = {
	user: Usuario;
};

export const AccountEnabler: FC<Props> = ({ user }) => {
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(
		user?.Activado ?? true
	);

	const handleSwitch = async () => {
		if (!user || !user.Email) return;

		setLoading(true);
		try {
			if (checked) await bloquearUsuario(user.Email);
			else await activarUsuario(user.Email);
			setChecked(!checked);

			alert(
				(checked
					? "Usuario bloqueado"
					: "Usuario activado") + " correctamente."
			);
		} catch (error: any) {
			console.error(error);
			alert(error.message);
		}
		setLoading(false);
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			marginTop={2}
			style={{ gap: 5 }}
		>
			<Alert severity="info" style={{ width: "100%" }}>
				{checked
					? "Este usuario puede iniciar sesión."
					: "Este usuario no puede iniciar sesión."}
			</Alert>

			<Typography style={{ textAlign: "center" }}>
				<b>Estado</b>
			</Typography>

			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				style={{ gap: 5 }}
			>
				<Typography>Inactivo</Typography>

				<Tooltip
					title="Activar o desactivar usuario."
					arrow
				>
					{loading ? (
						<Box
							height={38}
							width={58}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<CircularProgress size={20} />
						</Box>
					) : (
						<Switch
							checked={checked}
							onClick={handleSwitch}
						/>
					)}
				</Tooltip>

				<Typography>Activo</Typography>
			</Box>
		</Box>
	);
};
