import React, { useEffect, useMemo, useState } from "react";
import {
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { AnyProject } from "src/models/Proyecto";
import { Usuario } from "src/models/Usuario";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { actualizarRevision } from "src/services/empresa/revisiones";
import { getUsers } from "../../redux/actions/usersActions";
import { useSelector } from "src/redux/store";

interface Props {
	open: boolean;
	// title: string;
	onClose: () => void;
	/**
	 * Este debe ser el loading de cuando se envia el formulario
	 */
	isLoading?: boolean;
	// onSubmit: (r: Usuario[]) => void;
	project: AnyProject;
	error?: string;
}

export const AssigneesDialog: React.FC<Props> = React.memo(
	({
		// title,
		onClose,
		// onSubmit,
		open,
		isLoading,
		// project,
		error,
	}) => {
		const dispatch = useDispatch();

		const project = useSelector(
			(s) => s.projectsReducer.revisions.selectedProject
		);
		const [openAutocomplete, setOpenAutocomplete] =
			useState(false);
		const [loading, setLoading] = useState(false);
		const [asignados, setAsignados] = useState<
			Usuario[] | null
		>(null);

		const { users } = useSelector((s) => s.usersReducer);

		const { idEmpresa } =
			useParams<{ idEmpresa?: string }>();

		const {
			edit: { selectedBusiness },
		} = useSelector((s) => s.businessReducer);

		// cargar usuarios empresa
		useEffect(() => {
			if (!open) return;
			const id = idEmpresa ?? selectedBusiness?.id;
			if (!id) return;
			dispatch(getUsers({ id }));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [idEmpresa, selectedBusiness?.id, open]);

		useEffect(() => {
			if (!project || !users) return;
			const asignados = users.filter((u) =>
				project.Responsables?.includes(u.id ?? "")
			);
			setAsignados(asignados);
		}, [project, users]);

		const handleSubmit = async () => {
			if (!project || !asignados) return;
			try {
				setLoading(true);
				await actualizarRevision({
					id: project.id,
					Tipo: project.Tipo,
					Responsables: asignados.map((a) => a.id!),
				});
				onClose();
			} catch (error) {
				console.error(error);
			}
			setLoading(false);
		};

		return (
			<Dialog open={open} onClose={onClose}>
				<DialogTitle id="dialog-confirm-title">
					<Typography variant="h4">
						{project?.NombreProyecto}
					</Typography>
					<Typography variant="body1">
						Asignar responsables
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Autocomplete
						id="Encargados"
						options={users ?? []}
						fullWidth
						multiple
						value={asignados ?? []}
						open={openAutocomplete}
						onOpen={() => {
							setOpenAutocomplete(true);
						}}
						onClose={() => {
							setOpenAutocomplete(false);
						}}
						getOptionSelected={(option, value) => {
							if (value) {
								return option.id === value.id;
							}
							return false;
						}}
						getOptionLabel={(option) => {
							return option.Nombre && option.Apellido
								? option.Nombre + " " + option.Apellido
								: "";
						}}
						filterOptions={(option) =>
							option.filter(
								(opt: any) => opt?.Revisiones === true
							)
						}
						loadingText="Cargando..."
						onChange={(_, value) => setAsignados(value)}
						renderOption={(
							{ Cargo, Nombre, Apellido },
							{ selected }
						) => (
							<>
								<Checkbox
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{`${Nombre} ${Apellido} [${Cargo}] `}
							</>
						)}
						renderTags={(value, getTagProps) => {
							return value.map((option, index: number) => {
								const selectedUser = users?.find(
									(u) => u.id === option.id
								);
								if (!selectedUser) return null;
								const { Cargo, Nombre, Apellido } =
									selectedUser;
								return (
									selectedUser && (
										<Chip
											id={`chip-${option.id}`}
											color="primary"
											label={` ${Nombre} ${Apellido} [${Cargo}]`}
											{...getTagProps({ index })}
										/>
									)
								);
							});
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								style={{ minWidth: 300 }}
								label="Usuarios empresa"
								fullWidth
							/>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						Cancelar
					</Button>
					<Button
						color="primary"
						disabled={isLoading}
						onClick={() => handleSubmit()}
					>
						{loading ? (
							<CircularProgress size={24} />
						) : (
							"Asignar"
						)}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
);
