/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Breadcrumbs,
	Button,
	CircularProgress,
	Divider,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { LinkProjectsOT } from "src/components/CheckPro/Links/NeoProjects";
import { RejectedByGraphOT } from "src/components/ProjectsOT/Graph/RejectedBy";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

export const DeviationByQuestionGraphPageOT = () => {
	const { selectedBusiness } = useBusiness();
	const { selected, get, project, set, checklist } =
		useProjectsOT();

	const [type, setType] = useState<
		"Obra Gruesa" | "Terminaciones"
	>("Obra Gruesa");

	useEffect(() => {
		set.checklist(null);
		set.graphData({});
		get.project.checklists(type);
	}, [type]);

	const handleGenerate = () => {
		if (!selected.checklist) return;
		get.report.deviationByQuestion();
	};

	return (
		<>
			<Helmet title="Gráfico de barras" />

			<Breadcrumbs style={{ fontSize: 20 }}>
				<Typography variant="h3" style={{ color: "black" }}>
					{selectedBusiness?.Nombre ?? "Empresa"}
				</Typography>

				<Typography variant="h3" style={{ color: "black" }}>
					{selected.project?.Nombre ?? "Proyecto"}
				</Typography>
			</Breadcrumbs>

			<Box display="flex" flexDirection="row">
				<Breadcrumbs style={{ flexGrow: 1 }}>
					<LinkEmpresas />
					<LinkProjectsOT />
					<Typography>Desviación por Preguntas</Typography>
				</Breadcrumbs>
			</Box>

			<Divider
				style={{ marginTop: 10, marginBottom: 10 }}
			/>

			<Tabs
				value={type}
				onChange={(e, v) => setType(v)}
				variant="fullWidth"
			>
				<Tab label="Obra Gruesa" value="Obra Gruesa" />
				<Tab label="Terminaciones" value="Terminaciones" />
			</Tabs>

			<Box
				display={"flex"}
				flexDirection={"row"}
				gridGap={10}
				marginTop={5}
			>
				<Autocomplete
					fullWidth
					value={selected.checklist}
					options={project.checklists ?? []}
					onChange={(_, v) => set.checklist(v)}
					getOptionLabel={(option) => option.Nombre ?? ""}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Lista de chequeo"
							variant="outlined"
							size="small"
						/>
					)}
				/>

				<Button
					size="small"
					onClick={handleGenerate}
					disabled={!checklist.graphData}
					color="primary"
					variant="contained"
				>
					{checklist.graphData ? (
						"Generar"
					) : (
						<CircularProgress size={24} />
					)}
				</Button>
			</Box>
			{selected.checklist && <RejectedByGraphOT />}
		</>
	);
};
