import { useState } from "react";
import Dialog, {
	DialogProps,
} from "@material-ui/core/Dialog";
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";

type PdfListadoProps = {
	onAcceptRecinto: () => Promise<void>;
	onAcceptSubcontrato: () => Promise<void>;
} & DialogProps;

/**
 * Interfaz para generar el PDF de los listados,
 * el que tiene imágenes.
 */
export const PdfListadoDialog: React.FC<PdfListadoProps> =
	({
		onAcceptRecinto,
		onAcceptSubcontrato,
		...dialogProps
	}) => {
		const [recintoLoading, setRecintoLoading] =
			useState(false);
		const [subcontratoLoading, setSubcontratoLoading] =
			useState(false);

		const handleAcceptRecinto = async () => {
			setRecintoLoading(true);
			await onAcceptRecinto();
			setRecintoLoading(false);
		};

		const handleAcceptSubcontrato = async () => {
			setSubcontratoLoading(true);
			await onAcceptSubcontrato();
			setSubcontratoLoading(false);
		};

		return (
			<Dialog maxWidth="lg" {...dialogProps}>
				<DialogTitle style={{ textAlign: "center" }}>
					Ordenar por
				</DialogTitle>
				<DialogContent
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
						gap: 5,
					}}
				>
					<Button
						variant="contained"
						color="primary"
						style={{ minWidth: 150 }}
						onClick={handleAcceptSubcontrato}
						disabled={recintoLoading || subcontratoLoading}
					>
						{subcontratoLoading ? (
							<CircularProgress size={24} />
						) : (
							"Subcontrato"
						)}
					</Button>

					<Button
						variant="contained"
						color="primary"
						onClick={handleAcceptRecinto}
						style={{ minWidth: 150 }}
						disabled={recintoLoading || subcontratoLoading}
					>
						{recintoLoading ? (
							<CircularProgress size={24} />
						) : (
							"Recinto"
						)}
					</Button>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={(ev) =>
							dialogProps.onClose?.(ev, "escapeKeyDown")
						}
					>
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
