import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { IAuthState } from "../redux/reducers/authReducer";
import { RootState } from "../redux/store/reducer";

interface Props {
	children: any;
}

// For routes that can only be accessed by authenticated users
export const AdminGuard: React.FC<Props> = ({
	children,
}) => {
	const { user } = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const location = useLocation();

	localStorage.setItem("lastPath", location.pathname);

	if (user?.tipoUsuario !== "Administrador") {
		if (
			user?.tipoUsuario === "AdministradorEmpresa" &&
			user?.activado
		) {
			return (
				<Redirect to="/administrador/empresa/proyectos" />
			);
		} else if (
			user?.tipoUsuario === "UsuarioEmpresa" &&
			user?.activado
		) {
			return (
				<Redirect to="/administrador/user/proyectos" />
			);
		} else {
			return <Redirect to="/auth/sign-in" />;
		}
	}

	return children;
};
