/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider as MuiDivider,
	Fade,
	Grid,
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@material-ui/core";
import {
	Add as AddIcon,
	PieChart,
} from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { ChevronRight, Edit, Trash2 } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
	NavLink,
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../../components/NewConfirmDialog";
import { TableNoContent } from "../../components/TableNoContent";
import { TableSkeleton } from "../../components/TableSkeleton";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../../constants";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
import { useTable } from "../../hooks/useTable";
import {
	addList,
	addMoreLists,
	getLists,
	getTotalDocs,
	setSelectedList,
	updateList,
} from "../../redux/actions/listsActions";
import { getOneProject } from "../../redux/actions/projectsActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { IListsState } from "../../redux/reducers/listsReducer";
import { IProjectsState } from "../../redux/reducers/projectsReducer";
import { IRevisionsState } from "../../redux/reducers/revisionsReducer";
import { RootState } from "../../redux/store/reducer";
import { useStyles } from "../../theme/useStyles";
import { timesStampToDateFormatted } from "../../utils/utils";
import { DialogNewEdit } from "./components/DialogNewEdit";
import { DropdownMenu } from "../../components/Generic/DropdownMenu";
import { Opener } from "../../components/CheckPro/Opener";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Axios from "axios";
import { Empresa } from "../../models/Empresa";
import { LinkEmpresas } from "../../components/CheckPro/Links/Empresas";
import { LinkProyectos } from "../../components/CheckPro/Links/Proyectos";
import { LinkRevisiones } from "../../components/CheckPro/Links/Revisiones";
import { deleteList } from "src/redux/actions/observationsActions";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface Props {
	array: any[];
	isLoading: boolean;
	totalDocs: number;
	openEditDialog: (selected: any) => void;
	openConfirmDialog: (selected: any) => any;
	projectId: string;
	revisionId: string;
	selectedBusiness?: Empresa | null;
}

const ContentCard: React.FC<Props> = ({
	array,
	isLoading,
	totalDocs,
	projectId,
	revisionId,
	openEditDialog,
	openConfirmDialog,
	selectedBusiness,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const theme = useTheme();

	const {
		limit,
		handleLimitChange,
		handlePageChange,
		page,
	} = useTable({
		limit: TABLE_LIMIT_DEFAULT || 5,
		onLimitChange: (e, newLimit) => {
			if (newLimit > array.length) {
				// dispatch(getObservations(projectId, revisionId, listId, newLimit));
				dispatch(getLists(projectId, revisionId, newLimit));
			}
		},
		onPageChange: (newPage, oldPage, limit) => {
			if (array.length < totalDocs && newPage > oldPage) {
				dispatch(
					addMoreLists(limit, projectId, revisionId)
				);
			}
		},
	});

	const redirectToCharts = (data: any) =>
		history.push(
			`/administrador/estadisticas/${projectId}/${revisionId}/${data.id}`
		);

	const [generando, setGenerando] = useState([
		false,
		false,
	]);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [listado, setListado] = useState<any>(null);

	const handlePdfDownload = async (
		ordenarPorSubcontrato: boolean,
		i = 0
	) => {
		if (!listado) return;

		setGenerando((g) => {
			const _g = [...g];
			_g[i] = true;
			return _g;
		});

		try {
			const response = await Axios.post(
				"https://us-central1-checkpro-3a90a.cloudfunctions.net/generateLegacyProjectSummary",
				{
					textoFooter:
						selectedBusiness?.PiePagina || "CheckPro",
					listadoId: listado.id,
					userId: listado.Responsable.FirebaseId,
					orderBy: ordenarPorSubcontrato
						? "subcontrato"
						: "recinto",
					// ordenarPorSubcontrato,
				}
			);

			if (!response.data) throw Error("No data");
			window.open(response.data.url, "_blank");
		} catch (error: any) {
			console.error(error);
			alert("Ocurrió un error: " + error.message);
		}

		setGenerando((g) => {
			const _g = [...g];
			_g[i] = false;
			return _g;
		});
	};

	return (
		<>
			<Card mb={6}>
				<CardContent>
					<TableContainer
						className={classes.tableContainer}
					>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Nombre</TableCell>
									<TableCell>Observaciones</TableCell>
									<TableCell>Encargado</TableCell>
									<TableCell>Cargo</TableCell>
									<TableCell>Fecha de Creación</TableCell>
									<TableCell align="center">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!isLoading ? (
									array
										.sort((a, b) =>
											a.Nombre.localeCompare(b.Nombre)
										)
										.slice(
											page * limit,
											page * limit + limit
										)
										.map((data: any, i: any) => (
											<Fade key={i} in={true}>
												<TableRow
													hover
													className={classes.styledRow}
												>
													<TableCell>
														{data.Nombre}
													</TableCell>
													<TableCell>
														{data?.ObservacionesResueltas ||
															0}
														{" / "}
														{data?.ObservacionesTotales ||
															0}
													</TableCell>
													<TableCell>
														{
															data.Responsable
																.NombreCompleto
														}
													</TableCell>
													<TableCell>
														{data.Responsable?.Cargo}
													</TableCell>
													<TableCell>
														{timesStampToDateFormatted(
															data.FechaCreacion
														)}
													</TableCell>
													{/* Celda de acciones */}
													<TableCell
														style={{ whiteSpace: "nowrap" }}
														align="center"
													>
														<DropdownMenu
															Opener={Opener}
															options={[
																{
																	label: "Editar",
																	icon: (
																		<Edit
																			size={22}
																			color={
																				theme.palette
																					.primary.main
																			}
																		/>
																	),
																	onClick: () =>
																		openEditDialog(data),
																},
																{
																	label: "Eliminar",
																	icon: (
																		<Trash2
																			color="red"
																			size={22}
																		/>
																	),
																	onClick: () =>
																		openConfirmDialog(data),
																},
																{
																	label: "Estadísticas",
																	icon: (
																		<PieChart color="primary" />
																	),
																	onClick: () =>
																		redirectToCharts(data),
																},
																{
																	disabled: generando.some(
																		(i) => i
																	),
																	label: generando.some(
																		(i) => i
																	)
																		? "Generando informe..."
																		: "Solicitar informe",
																	icon: generando.some(
																		(i) => i
																	) ? (
																		<CircularProgress
																			size={20}
																		/>
																	) : (
																		<PictureAsPdfIcon htmlColor="red" />
																	),
																	onClick: () => {
																		setDialogOpen(true);
																		setListado(data);
																	},
																},
															]}
														/>

														<Tooltip title="Observaciones">
															<IconButton
																color="primary"
																size="small"
																onClick={() => {
																	dispatch(
																		setSelectedList(data)
																	);
																	history.push(
																		`listados/${data.id}/observaciones`
																	);
																}}
															>
																<ChevronRight />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											</Fade>
										))
								) : !isLoading && array.length === 0 ? (
									<TableNoContent colSpan={5} />
								) : (
									<TableSkeleton
										colSpan={5}
										limit={limit}
									/>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={totalDocs}
						onChangePage={handlePageChange as any}
						onChangeRowsPerPage={handleLimitChange}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={TABLE_LIMITS}
					/>
				</CardContent>
			</Card>
			<Dialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
			>
				<DialogTitle style={{ textAlign: "center" }}>
					Ordenar por:
				</DialogTitle>
				<DialogContent>
					<Box
						display="flex"
						flexDirection="row"
						gridGap={5}
					>
						<Button
							disabled={generando.some((i) => i)}
							style={{ minWidth: 150 }}
							variant="contained"
							color="primary"
							onClick={() => {
								handlePdfDownload(true, 1);
							}}
						>
							{generando[1] ? (
								<CircularProgress size={20} />
							) : (
								"Por subcontrato"
							)}
						</Button>

						<Button
							disabled={generando.some((i) => i)}
							style={{ minWidth: 150 }}
							variant="contained"
							color="primary"
							onClick={() => {
								handlePdfDownload(false);
							}}
						>
							{generando[0] ? (
								<CircularProgress size={20} />
							) : (
								"Por recinto"
							)}
						</Button>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)}>
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export const ListsBusiness = () => {
	const { projectId, revisionId } = useParams<any>();
	const { pathname } = useLocation();
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);

	const dispatch = useDispatch();
	const isFromBusiness = pathname.startsWith(
		"/administrador/empresas/"
	);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const {
		revisions: {
			selectedProject,
			isLoading: projectIsLoading,
		},
	} = useSelector<RootState, IProjectsState>(
		(state) => state.projectsReducer
	);

	const { selectedRevision } = useSelector<
		RootState,
		IRevisionsState
	>((state) => state.revisionsReducer);

	const { lists, isLoading, totalDocs, updateLoading } =
		useSelector<RootState, IListsState>(
			(state) => state.listsReducer
		);

	useEffect(() => {
		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		}
		if (isFromBusiness && !selectedBusiness) {
			// dispatch(getOneBusiness(businessId));
		}
	}, [
		dispatch,
		selectedBusiness?.id,
		projectId,
		revisionId,
		selectedProject,
		selectedRevision,
		selectedBusiness,
		isFromBusiness,
		lists,
	]);

	useEffect(() => {
		dispatch(getLists(projectId, revisionId));
		dispatch(getTotalDocs(projectId, revisionId));
	}, [dispatch]);

	const {
		open,
		openEditDialog,
		openNewDialog,
		closeDialog,
		onAccept,
		action,
		selected,
		setIsLoading: setIsLoadingNewEditDialog,
	} = useNewEditDialog<any>({
		onAccept: (values, action) => {
			if (action === "New") {
				dispatch(addList(values));
			} else if (action === "Edit") {
				dispatch(
					updateList(values, selected.id, revisionId)
				);
			}
		},
	});

	const {
		open: openConfirmDialog,
		setIsLoading: setIsLoadingConfirmDialog,
		dialogConfirmHandleClose,
		dialogConfirmHandleConfirm,
		openDialog,
		selected: confirmDialogSelected,
	} = useConfirmDialog({
		onConfirm: (selected) => {
			console.log("delete confirm", selectedProject);
			if (!selectedProject) return;
			dispatch(deleteList(selected.id));
		},
	});

	useEffect(() => {
		setIsLoadingNewEditDialog(updateLoading);
		setIsLoadingConfirmDialog(updateLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLoading]);

	// noinspection ConstantConditionalExpressionJS
	return (
		<React.Fragment>
			<Helmet title="Listados" />
			<Grid justify="space-between" container>
				<Grid item>
					{!selectedProject ? (
						<Skeleton variant="text" />
					) : (
						<Box>
							<Typography
								variant="h3"
								gutterBottom
								display="inline"
							>
								{`${selectedBusiness?.Nombre} / ${selectedProject?.NombreProyecto} / ${selectedRevision?.Nombre}`}
							</Typography>
						</Box>
						// <Typography>{selectedRevision?.Nombre}</Typography>
					)}

					{pathname.indexOf(
						"/administrador/empresa/proyectos"
					) === 0 ? (
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							<LinkEmpresas />
							<LinkProyectos />
							<LinkRevisiones />
							<Typography>Listados</Typography>
						</Breadcrumbs>
					) : (
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							{/*TODO: Modificar en vistas de cliente*/}
							<Link
								component={NavLink}
								exact
								to={
									auth.user?.tipoUsuario ===
									"AdministradorEmpresa"
										? `/administrador/checklist/proyectos/${projectId}/revisiones`
										: "/administrador/user/proyectos"
								}
							>
								Proyectos
							</Link>
							<Link
								component={NavLink}
								exact
								to={
									auth.user?.tipoUsuario ===
									"AdministradorEmpresa"
										? `/administrador/checklist/proyectos/${projectId}/revisiones`
										: `/administrador/user/proyectos/${projectId}/revisiones`
								}
							>
								Revisiones
							</Link>
							<Typography>Listados</Typography>
						</Breadcrumbs>
					)}
				</Grid>
				<Grid item>
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={() => openNewDialog()}
						>
							<AddIcon />
							Nuevo Listado
						</Button>
					</div>
				</Grid>
			</Grid>
			<Divider my={6} />
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard
						projectId={projectId}
						revisionId={revisionId}
						array={lists}
						isLoading={isLoading}
						totalDocs={totalDocs}
						openEditDialog={openEditDialog}
						openConfirmDialog={openDialog}
						selectedBusiness={selectedBusiness}
					/>
				</Grid>
			</Grid>

			{!projectIsLoading && (
				<React.Fragment>
					<DialogNewEdit
						open={open}
						onClose={closeDialog}
						onAccept={onAccept}
						isLoading={updateLoading}
						action={action}
						title="Lista"
						selected={selected}
						project={selectedProject}
					/>
					<NewConfirmDialog
						open={openConfirmDialog}
						title={`¿Desea borrar la lista ${confirmDialogSelected?.Nombre}?`}
						content={
							<Alert severity="warning">
								Se borraran los Listado, y Observaciones que
								contenía esta Revisión
							</Alert>
						}
						onClose={dialogConfirmHandleClose}
						isLoading={updateLoading}
						onConfirm={dialogConfirmHandleConfirm}
						onConfirmText="Borrar"
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
