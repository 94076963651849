import firebase from "firebase/app";
import * as types from "../../constants";
import { firestore, auth } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";
import { getBusiness } from "./businessActions";
import { openSnack } from "./uiActions";
import {
	indexarObservaciones,
	sePuedeAgregarRevision,
} from "../../services/empresa/revisiones";

import { format } from "date-fns";
import { Empresa } from "../../models/Empresa";
import {
	ProjectStatus,
	RevisionProject,
	RevisionChecklistProject,
	AnyProject,
	isChecklistRevision,
	LegacyProject,
} from "src/models/Proyecto";
import { conseguirChecklists } from "src/services/usuarios/checklists";
import Firebase from "firebase";
import { Usuario } from "src/models/Usuario";
import { getRef } from "src/utils/firebase";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";

type OrderByDirection = firebase.firestore.OrderByDirection;
type OrderBy = "NombreProyecto" | "FechaCreacion";

const projectListener =
	FirebaseListener.addListener("projects");

export const getProjectsLegacy = (
	limit: number = types.TABLE_LIMIT_DEFAULT,
	orderBy: OrderBy = "NombreProyecto"
): AppThunk => {
	return async (dispatch, useState) => {
		try {
			const { user } = useState().authReducer;
			if (!user) throw Error("User not logged.");
			dispatch(isLoading(true));

			let response: any;
			if (user.tipoUsuario !== "UsuarioEmpresa") {
				response = await firestore
					.collection("Proyectos")
					.orderBy("Estado")
					.orderBy(orderBy, "desc")
					.where(
						"Estado",
						"!=",
						"Eliminado" as ProjectStatus
					)
					.where("Tipo", "!=", "CheckList")
					.orderBy("Tipo")
					.limit(limit)
					.get();
			} else {
				response = await firestore
					.collection("Proyectos")
					.orderBy("Estado")
					.orderBy(orderBy, "desc")
					.where(
						"Estado",
						"!=",
						"Eliminado" as ProjectStatus
					)
					.where(
						"Responsables",
						"array-contains",
						user.localId
					)
					.where("Tipo", "!=", "CheckList")
					.orderBy("Tipo")
					.limit(limit)
					.get();
			}
			dispatch(setProjects(await mapProjects(response)));
			dispatch(
				setLastDoc(response.docs[response.docs.length - 1])
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

// get project by type checklist  getProjectsTypeChecklist
export const getProjectsTypeChecklist = (
	limit: number = types.TABLE_LIMIT_DEFAULT,
	orderBy: OrderBy = "NombreProyecto"
): AppThunk => {
	return async (dispatch, useState) => {
		dispatch({
			type: types.CHECKLISTS_GET_SUBMITTING,
		});
		const { user } = useState().authReducer;
		try {
			const [response1, response2] = await Promise.all([
				firestore
					.collection("ProyectosChecklist")
					.where(
						"EmpresaReference",
						"==",
						user?.empresaReference
					)
					.where(
						"Estado",
						"!=",
						"Eliminado" as ProjectStatus
					)
					.orderBy("Estado")
					.orderBy("NombreProyecto_lower")
					.limit(limit)
					.get(),

				firestore
					.collection("ProyectosChecklist")
					.where(
						"EmpresaReference",
						"==",
						user?.empresaReference
					)
					.where(
						"Estado",
						"!=",
						"Eliminado" as ProjectStatus
					)
					.orderBy("Estado")
					.orderBy("NombreProyecto_lower")
					.get(),
			]);

			const projectList = response1.docs.map((x: any) => ({
				...x.data(),
				id: x.id,
			}));

			dispatch({
				type: types.CHECKLISTS_GET_SUCCESS,
				payload: {
					projectsChecklist: projectList,
					totalDocsProjectChecklist: response2.size,
					lastDocProjectChecklist:
						response1.docs[response1.docs.length - 1],
				},
			});
		} catch (error: any) {
			console.error(error);
			dispatch({
				type: types.CHECKLISTS_GET_FAILURE,
				payload: "Ha ocurrido un error...",
			});
		}
	};
};

// actions para traer todos los listados de la revision para pdf
export const getAllListOfRevisions = (
	proyectoId: any,
	revisionsId: any,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({ type: types.GET_ALL_LIST_SUBMITTING });
		try {
			const response = await firestore
				.collection("ProyectosChecklist")
				.doc(proyectoId)
				.collection("Revisiones")
				.doc(revisionsId)
				.collection("Listado")
				.get();

			const allList = response.docs.map((listado) => ({
				...listado.data(),
				id: listado.id,
			}));

			dispatch({
				type: types.GET_ALL_LIST_SUCCESS,
				payload: allList,
			});
		} catch (error: any) {
			dispatch({ type: types.GET_ALL_LIST_FAILURE });
			console.error(error);
		}
	};
};

export const getOneProject = (
	id: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		dispatch({ type: types.PROJECT_GET_ONE_SUBMITTING });
		try {
			await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(id)
				.get()
				.then((response: any) => {
					const project = {
						...response.data(),
						id: response.id,
					};
					dispatch({
						type: types.PROJECT_GET_ONE_SUCCESS,
						payload: project,
					});
				});
		} catch (error: any) {
			dispatch({ type: types.PROJECT_GET_ONE_FAILURE });
			console.error(error);
		}
	};
};

export const updateProjectsResponsible = (
	project: any,
	user: any,
	businessId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		dispatch(updateLoading(true));
		try {
			if (project) {
				project.forEach((element: any) => {
					firestore
						.collection(
							isChecklist
								? "ProyectosChecklist"
								: "Proyectos"
						)
						.doc(element.id)
						.update({
							Responsable: {
								NombreCompleto:
									user.Nombre + " " + user.Apellido,
								NombreCompleto_lower: (
									user.Nombre +
									" " +
									user.Apellido
								)
									.trim()
									.toLowerCase(),
								FirebaseId: user.id,
								FechaAsignado:
									firebase.firestore.FieldValue.serverTimestamp(),
								Cargo: user.Cargo,
							},
							Responsables:
								firebase.firestore.FieldValue.arrayUnion(
									user.id
								),
						});
				});
			}
			dispatch(
				openSnack(
					"Responsable Actualizado correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch(
				openSnack("Error al actualizar", SnackState.ERROR)
			);
			dispatch(setError(error));
			console.error(error);
		} finally {
			dispatch(updateLoading(false));
		}
	};
};

export const addProject = (
	project: any,
	business?: any
): AppThunk => {
	return async (dispatch) => {
		dispatch(isProjectsAddDocLoading(true));

		try {
			const businessRef = firestore.doc(
				`Empresas/${business.id}`
			);

			const {
				Revisiones: { RevisionesUsados, LimiteRevisiones },
			} = (await businessRef.get()).data() as Empresa;

			const projectWithSameName = await firestore
				.collection("Proyectos")
				.where(
					"NombreProyecto_lower",
					"==",
					cleanString(project.NombreProyecto)
				)
				.where("EmpresaReference", "==", businessRef)
				.limit(1)
				.get();

			if (!projectWithSameName.empty)
				throw Error(
					"Ya tienes un proyecto con ese nombre."
				);

			if (RevisionesUsados + 1 > LimiteRevisiones)
				throw Error(
					"Has alcanzado el límite de proyectos."
				);

			const data = mapNewProject(project, businessRef);

			await Promise.all([
				firestore.collection("Proyectos").add(data),

				firestore
					.collection("Empresas")
					.doc(business.id)
					.update({
						"Revisiones.RevisionesUsados":
							Firebase.firestore.FieldValue.increment(1),
					}),
			]);

			dispatch(
				openSnack("Proyecto Agregado", SnackState.SUCCESS)
			);
		} catch (error: any) {
			dispatch(openSnack(error.message, SnackState.ERROR));
			console.error(error);
		}

		dispatch(isProjectsAddDocLoading(false));
	};
};

export const addProjectChecklist = (
	project: RevisionChecklistProject,
	business: Empresa
): AppThunk => {
	return async (dispatch) => {
		dispatch(isProjectsAddDocLoading(true));
		try {
			const businessSnap = await firestore
				.doc(`Empresas/${business.id}`)
				.get();

			if (!businessSnap.exists)
				throw Error("No se encuentra empresa");

			const {
				CheckList: { CheckListUsados, LimiteCheckList },
			} = businessSnap.data() as Empresa;

			const projectsWithTheSameName = await firestore
				.collection("ProyectosChecklist")
				.where(
					"NombreProyecto_lower",
					"==",
					cleanString(project.NombreProyecto)
				)
				.where("EmpresaReference", "==", businessSnap.ref)
				.limit(1)
				.get();

			if (!projectsWithTheSameName.empty) {
				throw Error(
					"Ya tienes un proyecto con este nombre."
				);
			}

			if (CheckListUsados + 1 > LimiteCheckList)
				throw Error(
					"Has alcanzado el límite de proyectos."
				);

			const data = mapNewProject(
				project,
				businessSnap.ref,
				true
			);

			await Promise.all([
				firestore
					.collection("ProyectosChecklist")
					.add(data),

				businessSnap.ref.update({
					"CheckList.CheckListUsados":
						firebase.firestore.FieldValue.increment(1),
				}),
			]);

			dispatch(
				openSnack("Proyecto Agregado", SnackState.SUCCESS)
			);
		} catch (error: any) {
			dispatch(openSnack(error.message, SnackState.ERROR));
			console.error(error);
		}

		dispatch(isProjectsAddDocLoading(false));
	};
};

export const updateProject = (
	project: LegacyProject,
	responsible?: Usuario
): AppThunk => {
	return async (dispatch) => {
		dispatch(updateLoading(true));
		try {
			const collection = firestore.collection(
				isChecklistRevision(project)
					? "ProyectosChecklist"
					: "Proyectos"
			);

			const projectSnap = await collection
				.doc(project.id)
				.get();

			const previous = projectSnap.data() as LegacyProject;

			if (!projectSnap.exists)
				throw Error(
					`${project.Tipo || "El proyecto"} con id ${
						project.id
					} no existe.`
				);

			const businessRef = firestore
				.collection("Empresas")
				.doc(project.EmpresaReference.id);

			const projects = await collection
				.where(
					"NombreProyecto_lower",
					"==",
					cleanString(project.NombreProyecto)
				)
				.where("EmpresaReference", "==", businessRef)
				.get();

			if (
				projects.docs.some((doc) => doc.id !== project.id)
			)
				throw Error(
					"Ya existe un proyecto con este nombre."
				);

			const dataToUpdate: {
				[key: string]: any;
			} = {};

			if (project.NombreProyecto) {
				dataToUpdate.NombreProyecto =
					project.NombreProyecto;
				dataToUpdate.NombreProyecto_lower = cleanString(
					project.NombreProyecto
				);
			}

			if (responsible) {
				dataToUpdate.Responsable = {
					...project.Responsable,
					FirebaseId: responsible.id,
					FechaAsignado:
						firebase.firestore.FieldValue.serverTimestamp(),
					NombreCompleto: `${responsible.Nombre} ${responsible.Apellido}`,
					NombreCompleto_lower: cleanString(
						project.Responsable.NombreCompleto
					),
					Cargo: responsible.Cargo,
				};
				//primero se remueve si cambia el responsable
				dataToUpdate.Responsables = [
					...previous.Responsables.filter(
						(r) => r !== previous.Responsable.FirebaseId
					),
					responsible.id,
				];
			}

			if (typeof project.DiasPorVencer === "number") {
				dataToUpdate.DiasPorVencer = project.DiasPorVencer;
			}

			if (typeof project.DiasVencidos === "number") {
				dataToUpdate.DiasVencidos = project.DiasVencidos;
			}

			await Promise.all([
				projectSnap.ref.update(dataToUpdate),

				!isChecklistRevision(project) &&
					project.NombreProyecto !==
						previous.NombreProyecto &&
					indexarObservaciones(
						"NombreProyecto",
						project.id,
						project.NombreProyecto
					),
			]);

			dispatch(
				openSnack(
					"Proyecto actualizado.",
					SnackState.SUCCESS
				)
			);

			dispatch(
				updateProjectStore({
					...previous,
					...dataToUpdate,
				})
			);
		} catch (error: any) {
			dispatch(openSnack(error.message, SnackState.ERROR));
			dispatch(setError(error));
			console.error(error);
		}

		dispatch(updateLoading(false));
	};
};

export const deleteProject = (
	project: RevisionProject | RevisionChecklistProject,
	business?: Empresa | null
): AppThunk => {
	return async (dispatch, getState) => {
		const { user } = getState().authReducer;
		dispatch(isEditLoading(true));
		const {
			edit: { selectedBusiness },
		} = getState().businessReducer;
		try {
			if (!user) {
				return;
			}
			const newProjectName =
				project.NombreProyecto +
				"_" +
				format(new Date(), "dd_MM_yyyy_HH_mm_ss");

			await firestore
				.collection("Proyectos")
				.doc(project.id)
				.update({
					Estado: "Eliminado",
					FechaEliminacion:
						firebase.firestore.FieldValue.serverTimestamp(),
					EliminadoPor: user.id,
					AlertaEliminacion: true,
					DiasEliminacion: 5,
					NombreProyecto: newProjectName,
					NombreProyecto_lower: cleanString(newProjectName),
				});
			await indexarObservaciones(
				"NombreProyecto",
				project.id,
				newProjectName
			);

			await firestore
				.collection("Empresas")
				.doc(selectedBusiness!.id)
				.update({
					"Revisiones.RevisionesUsados":
						firebase.firestore.FieldValue.increment(-1),
				})
				.then(() => {
					dispatch(getRevisions(business?.id));
				})
				.then(() => {
					dispatch(
						getBusiness(project.EmpresaReference.id)
					);
				})
				.then(() => {
					dispatch(
						getTotalDocsBusinessProjects(selectedBusiness)
					);
				});

			//
			dispatch(
				openSnack("Proyecto eliminado", SnackState.SUCCESS)
			);
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		} finally {
			dispatch(isEditLoading(false));
		}
	};
};
// delete tipo checklist
export const deleteChecklistProject = (
	project: AnyProject,
	businessId?: string
): AppThunk => {
	return async (dispatch) => {
		dispatch(isEditLoading(true));
		try {
			const userId = auth.currentUser?.uid;
			if (!userId) throw Error("No estás autenticado.");

			await Promise.all([
				firestore
					.doc(`ProyectosChecklist/${project.id}`)
					.update({
						Estado: "Eliminado",
						FechaEliminacion:
							firebase.firestore.FieldValue.serverTimestamp(),
						EliminadoPor: userId,
						AlertaEliminacion: true,
						DiasEliminacion: 5,
					}),

				firestore.doc(`Empresas/${businessId}`).update({
					"CheckList.CheckListUsados":
						firebase.firestore.FieldValue.increment(-1),
				}),
			]);

			dispatch(
				openSnack("Proyecto eliminado", SnackState.SUCCESS)
			);
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		}
		dispatch(isEditLoading(false));
	};
};
export const getProjectUsers = (project: any): AppThunk => {
	return async (dispatch) => {
		dispatch(isProjectsUsersLoading(true));
		try {
			if (
				!project?.hasOwnProperty("EmpresaReference") &&
				!project?.hasOwnProperty("Empresa")
			) {
				throw new Error("Proyecto no tiene Empresa");
			}
			const response = await firestore
				.collection("Usuarios")
				.where(
					"EmpresaReference",
					"==",
					project.EmpresaReference
						? project.EmpresaReference
						: firestore
								.collection("Empresas")
								.doc(project.Empresa.id)
				)
				.where("Deleted", "==", false)
				.where("Activado", "==", true)
				.orderBy("Nombre")
				.get();

			dispatch(
				setProjectsUsers(
					response.docs.map((doc: any) => ({
						...doc.data(),
						id: doc.id,
					}))
				)
			);
		} catch (error: any) {
			console.error(error);
		} finally {
			dispatch(isProjectsUsersLoading(false));
		}
	};
};

export const getUsersInProject = (
	project: any
): AppThunk => {
	return async (dispatch) => {
		dispatch(isProjectsUsersLoading(true));
		try {
			const userPromises: Promise<
				firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
			>[] = [];

			project?.Responsables?.forEach((r: string) => {
				userPromises.push(
					firestore.collection("Usuarios").doc(r).get()
				);
			});

			const responseUsers = await Promise.all(userPromises);
			const users = responseUsers.map((doc) => ({
				...doc.data(),
				id: doc.id,
			}));

			dispatch(setProjectsUsers(users));
		} catch (error: any) {
			console.error(error);
		} finally {
			dispatch(isProjectsUsersLoading(false));
		}
	};
};

type Snap =
	firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;

export const mapProjects = async (response: Snap) => {
	let projects: any[] = [];
	let businessesSet = new Set<string>();
	response.docs.forEach((doc: any) => {
		let data = doc.data();
		data.id = doc.id;
		projects.push(data);
		businessesSet.add(data.EmpresaReference.path);
	});

	const businessesResponse = await Promise.all(
		Array.from(businessesSet).map((x) =>
			firestore.doc(x).get()
		)
	);
	projects.forEach((p) => {
		const businessDoc: any = businessesResponse.find(
			(x) => x.ref.path === p.EmpresaReference.path
		);
		p.Empresa = {
			...businessDoc.data(),
			id: businessDoc.id,
		};
	});
	return projects;
};

const mapNewProject = (
	project: any,
	businessRef: any,
	checklist: boolean = false
) => {
	return {
		NombreProyecto: project.NombreProyecto.trim(),
		NombreProyecto_lower:
			project.NombreProyecto.trim().toLowerCase(),
		FechaCreacion:
			firebase.firestore.FieldValue.serverTimestamp(),
		DiasVencidos: project.DiasVencidos,
		DiasPorVencer: project.DiasPorVencer,
		Responsable: {
			FechaAsignado:
				firebase.firestore.FieldValue.serverTimestamp(),
			FirebaseId: project.Responsable
				? project.Responsable.id
				: "",
			NombreCompleto: project.Responsable
				? project.Responsable.Nombre +
				  " " +
				  project.Responsable.Apellido
				: "",
			NombreCompleto_lower: project.Responsable
				? (
						project.Responsable.Nombre +
						" " +
						project.Responsable.Apellido
				  )
						.trim()
						.toLowerCase()
				: "",
			Cargo: project.Responsable
				? project.Responsable.Cargo
				: "",
		},
		Responsables: project?.Responsable
			? [project.Responsable.id]
			: [],
		EmpresaReference: businessRef,
		Estado: "Activo",
		Tipo: checklist ? "CheckList" : "Revision",
	};
};

export const addMoreProjects = (
	limit: number = types.TABLE_LIMIT_DEFAULT,
	orderBy: OrderBy = "NombreProyecto"
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const lastDoc =
			getState().projectsReducer.lastDoc || "";
		try {
			const response = await firestore
				.collection("Proyectos")
				.orderBy("Estado")
				.orderBy(orderBy, "desc")
				.where("Estado", "!=", "Eliminado" as ProjectStatus)
				.startAfter(lastDoc)
				.limit(limit)
				.get();

			dispatch(setProjects(await mapProjects(response)));
			dispatch(
				setLastDoc(response.docs[response.docs.length - 1])
			);
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const addMoreProjectsByBusiness = (
	business: any,
	limit: number = types.TABLE_LIMIT_DEFAULT,
	orderBy: OrderBy = "NombreProyecto",
	directionSrt: OrderByDirection = "asc"
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const lastDoc =
			getState().projectsReducer.lastDoc || "";
		const { user } = getState().authReducer;
		try {
			let query;

			if (user?.tipoUsuario !== "UsuarioEmpresa") {
				query = firestore
					.collection("Proyectos")
					.where("Estado", "==", "Activo" as ProjectStatus)
					.where("Tipo", "!=", "CheckList")
					.where(
						"EmpresaReference",
						"==",
						firestore
							.collection("Empresas")
							.doc(business.id)
					)
					.orderBy(orderBy, directionSrt)
					.startAfter(lastDoc)
					.limit(limit);
			} else {
				query = firestore
					.collection("Proyectos")
					.where("Estado", "==", "Activo" as ProjectStatus)
					.where(
						"EmpresaReference",
						"==",
						firestore
							.collection("Empresas")
							.doc(business.id)
					)
					.where(
						"Responsables",
						"array-contains",
						user?.localId
					)
					.orderBy(orderBy, directionSrt)
					.startAfter(lastDoc)
					.limit(limit);
			}
			const result = await query.get();
			dispatch(setProjects(await mapProjects(result)));
			dispatch(
				setLastDoc(result.docs[result.docs.length - 1])
			);
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const getTotalDocs = (): AppThunk => {
	return async (dispatch) => {
		try {
			const response = await firestore
				.collection("Proyectos")
				.orderBy("Estado")
				.where("Estado", "!=", "Eliminado" as ProjectStatus)
				.where("Tipo", "!=", "CheckList")
				.get();
			dispatch(setTotalDocs(response.size));
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		}
	};
};

export const setSelectedProject = (
	project?: any
): Action => ({
	type: types.PROJECTS_SELECTED_DOC,
	payload: project,
});

const setProjects = (
	projects: RevisionProject[]
): Action => ({
	type: types.PROJECTS_GET_DOCS,
	payload: projects,
});

export const setProjectsUser = (
	projects: any[]
): Action => ({
	type: types.PROJECTS_USER_GET_DOCS,
	payload: projects,
});

const setLastDoc = (doc: any): Action => ({
	type: types.PROJECTS_SET_LAST_DOC,
	payload: doc,
});

const isLoading = (isLoading: boolean): Action => ({
	type: types.PROJECTS_LOADING,
	payload: isLoading,
});

const isEditLoading = (isLoading: boolean): Action => ({
	type: types.PROJECTS_EDIT_LOADING,
	payload: isLoading,
});

const setError = (error: string): Action => ({
	type: types.PROJECTS_FAILURE,
	payload: error,
});

const setTotalDocs = (total: number): Action => ({
	type: types.PROJECTS_SET_TOTAL_DOCS,
	payload: total,
});

const updateLoading = (isLoading: boolean): Action => ({
	type: types.PROJECTS_UPDATE_LOADING,
	payload: isLoading,
});

const setProjectsUsers = (users: any[]): Action => ({
	type: types.PROJECTS_GET_USERS,
	payload: users,
});

const isProjectsUsersLoading = (
	isLoading: boolean
): Action => ({
	type: types.PROJECTS_USERS_LOADING,
	payload: isLoading,
});

const updateProjectStore = (
	updatedProject: any
): Action => ({
	type: types.PROJECTS_UPDATE_DOC,
	payload: updatedProject,
});

//================================================================================
//============================ Business ==========================================
//================================================================================

export const getTotalDocsBusinessProjects = (
	business: any,
	isChecklist = false
): AppThunk => {
	return async (dispatch, useState) => {
		const { hideDeleted } = useState().projectsReducer;
		const { user } = useState().authReducer;
		try {
			let response: any;

			if (user?.tipoUsuario !== "UsuarioEmpresa") {
				if (hideDeleted) {
					response = firestore
						.collection(
							isChecklist
								? "ProyectosChecklist"
								: "Proyectos"
						)
						.where(
							"Estado",
							"==",
							"Activo" as ProjectStatus
						)
						.where("Tipo", "!=", "CheckList")
						.where(
							"EmpresaReference",
							"==",
							firestore
								.collection("Empresas")
								.doc(business.id)
						)
						.orderBy("Tipo");
				} else {
					response = firestore
						.collection(
							isChecklist
								? "ProyectosChecklist"
								: "Proyectos"
						)
						.where(
							"Estado",
							"==",
							"Eliminado" as ProjectStatus
						)
						.where("Tipo", "!=", "CheckList")
						.where(
							"EmpresaReference",
							"==",
							firestore
								.collection("Empresas")
								.doc(business.id)
						)
						.orderBy("Tipo");
				}
			} else {
				response = firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.where("Estado", "==", "Activo" as ProjectStatus)
					.where("Tipo", "!=", "CheckList")
					.where(
						"EmpresaReference",
						"==",
						firestore
							.collection("Empresas")
							.doc(business.id)
					)
					.where(
						"Responsables",
						"array-contains",
						user?.localId
					)
					.orderBy("Tipo");
			}

			const res = await response.get();
			dispatch(setTotalDocs(res.size));
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		}
	};
};

/**
 * Conseguir los proyectos de una empresa.
 */
export const getRevisions = (
	businessId?: string
): AppThunk => {
	return async (dispatch, useState) => {
		try {
			const { user } = useState().authReducer;
			if (!user) throw Error("User not logged.");
			dispatch(isLoading(true));

			const empresaRef =
				businessId &&
				firestore.doc(`Empresas/${businessId}`);

			/**
			 * Si se provee la ID de la empresa, trae sus revisiones.
			 * En caso contrario, trae las revisiones en las que el usuario es responsable.
			 */
			let query;
			if (empresaRef)
				query = firestore
					.collection("Proyectos")
					.where("EmpresaReference", "==", empresaRef);
			else
				query = firestore
					.collection("Proyectos")
					.where(
						"Responsables",
						"array-contains",
						user.localId
					);

			projectListener.close();
			projectListener.set(
				query.onSnapshot((snap) => {
					dispatch(
						setProjects(
							snap.docs.map(
								(doc) =>
									({
										...doc.data(),
										id: doc.ref.id,
									} as RevisionProject)
							)
						)
					);
				})
			);
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		} finally {
			dispatch(isLoading(false));
		}
	};
};

////////////

///////////

////////////

///////////

///////////

export const getAllBusinessProjects = (
	business: Empresa
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.PROJECTS_GET_ALL_BUSINESS_SUBMITTING,
		});
		try {
			const businessRef = getRef(business);

			const [revisions, revisionsChecklists, checklists] =
				await Promise.all([
					firestore
						.collection("Proyectos")
						.where("EmpresaReference", "==", businessRef)
						.where("Estado", "!=", "Eliminado")
						.get(),

					firestore
						.collection("ProyectosChecklist")
						.where("EmpresaReference", "==", businessRef)
						.where("Estado", "!=", "Eliminado")
						.get(),

					businessRef
						.collection("ProyectosOT")
						.where("Eliminado", "==", false)
						.get(),
				]);

			const snaps = [
				...revisions.docs,
				...revisionsChecklists.docs,
				...checklists.docs,
			];

			const data = snaps.map(
				(x) =>
					({
						...x.data(),
						_ref: x.ref,
						id: x.id,
					} as AnyProject)
			);

			dispatch({
				type: types.PROJECTS_GET_ALL_BUSINESS_SUCCESS,
				payload: data,
			});
		} catch (error: any) {
			console.error(error);
			dispatch({
				type: types.PROJECTS_GET_ALL_BUSINESS_FAILURE,
				payload: error,
			});
		}
	};
};

export const getProjectsByUser = (
	user: any,
	orderBy: OrderBy = "NombreProyecto",
	directionSrt: OrderByDirection = "asc"
): AppThunk => {
	return async (dispatch) => {
		dispatch(isLoading(true));
		try {
			const response = await firestore
				.collection("Proyectos")
				.where("Estado", "==", "Activo" as ProjectStatus)
				.where("Responsable.FirebaseId", "==", user)
				.orderBy(orderBy, directionSrt)
				.get();

			dispatch(
				setProjectsUser(await mapProjects(response))
			);
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		} finally {
			dispatch(isLoading(false));
		}
	};
};
export const getProjectsUserIn = (
	user: any,
	orderBy: OrderBy = "NombreProyecto",
	directionSrt: OrderByDirection = "asc"
): AppThunk => {
	return async (dispatch) => {
		dispatch(isLoading(true));
		try {
			const response = await firestore
				.collection("Proyectos")
				.where("Estado", "==", "Activo" as ProjectStatus)
				.where("Responsables", "array-contains", user)
				.orderBy(orderBy, directionSrt)
				.get();
			const response2 = await firestore
				.collection("ProyectosChecklist")
				.where("Estado", "==", "Activo" as ProjectStatus)
				.where("Responsables", "array-contains", user)
				.orderBy(orderBy, directionSrt)
				.get();

			const res2Array = response2.docs.map((doc: any) => ({
				...doc.data(),
				id: doc.id,
			}));

			const res1Array = await mapProjects(response);

			dispatch(
				setProjectsUser([...res1Array, ...res2Array])
			);
		} catch (error: any) {
			dispatch(setError(error));
			console.error(error);
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const searchProjectBusiness = (
	query: string,
	limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
	return async (dispatch) => {
		dispatch(isProjectsBusinessLoading(true));
		try {
			if (query.trim().length === 0) {
				dispatch(isProjectsBusinessLoading(false));
				return;
			}
			const response = await firestore
				.collection("Empresas")
				.orderBy("Nombre_lower")
				.startAt(query)
				.endAt(query + "\uf8ff")
				.limit(limit)
				.get();

			dispatch(
				setProjectsBusiness(
					response.docs.map((doc: any) => ({
						...doc.data(),
						id: doc.id,
					}))
				)
			);
		} catch (error: any) {
			console.error(error);
		} finally {
			dispatch(isProjectsBusinessLoading(false));
		}
	};
};

export const restoreProject = (
	revision: AnyProject
): AppThunk => {
	return async (dispatch) => {
		try {
			const { EmpresaReference } = revision;

			if (!(await sePuedeAgregarRevision(revision))) {
				throw Error(
					"¡Has sobrepasado el límite de revisiones activas!"
				);
			}

			await firestore
				.collection(
					isChecklistRevision(revision)
						? "ProyectosChecklist"
						: "Proyectos"
				)
				.doc(revision.id)
				.update({
					Estado: "Activo",
					AlertaEliminacion: false,
					DiasEliminacion:
						firebase.firestore.FieldValue.delete(),
				});

			/**
			 * Actualizar el contador de revisiones usadas
			 */
			await EmpresaReference.update({
				[isChecklistRevision(revision)
					? "CheckList.CheckListUsados"
					: "Revisiones.RevisionesUsados"]:
					firebase.firestore.FieldValue.increment(1),
			});

			//
			dispatch({
				type: types.PROJECTS_RESTORE_SUCCESS,
				payload: revision,
			});
			dispatch(
				openSnack("Proyecto restaurado", SnackState.SUCCESS)
			);
			//
		} catch (error: any) {
			dispatch(openSnack(error.message, SnackState.ERROR));
			dispatch({
				type: types.PROJECTS_RESTORE_FAILURE,
				payload: error,
			});
			console.error(error);
		}
	};
};

// PROJECTS CHECKLIST COUNTERS ACTIONS Y FUNCTIONS

export const checkProjectCounters = (
	project: any
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			await updateCounters(project.id);
		} catch (error: any) {
			console.error(error);
		}
	};
};

const updateCounters = async (projectId: string) => {
	let updates: any[] = [];
	const responseRev = await firestore
		.collection("ProyectosChecklist")
		.doc(projectId)
		.collection("Revisiones")
		.get();
	const revisions = responseRev.docs.map((doc: any) => ({
		...doc.data(),
		id: doc.id,
	}));

	let totalResueltasProyecto = 0;

	await Promise.all(
		revisions.map(async (rev: any) => {
			let totalResueltasRev = 0;
			let totalRespuestasRev = 0;
			const responseList = await firestore
				.collection("ProyectosChecklist")
				.doc(projectId)
				.collection("Revisiones")
				.doc(rev.id)
				.collection("Listado")
				.get();
			await Promise.all(
				responseList.docs.map(async (lis: any) => {
					let totalResueltasLis = 0;
					let totalRespuestasLis = 0;
					const responseRec = await firestore
						.collection("ProyectosChecklist")
						.doc(projectId)
						.collection("Revisiones")
						.doc(rev.id)
						.collection("Listado")
						.doc(lis.id)
						.collection("Recintos")
						.get();
					await Promise.all(
						responseRec.docs.map(async (rec: any) => {
							let totalResueltasRec = 0;
							let totalRespuestasRec = 0;
							const responseSub = await firestore
								.collection("ProyectosChecklist")
								.doc(projectId)
								.collection("Revisiones")
								.doc(rev.id)
								.collection("Listado")
								.doc(lis.id)
								.collection("Recintos")
								.doc(rec.id)
								.collection("Subcontratos")
								.get();
							await Promise.all(
								responseSub.docs.map(async (sub: any) => {
									let totalResueltasSub = 0;
									let totalRespuestasSub = 0;
									const responseErr = await firestore
										.collection("ProyectosChecklist")
										.doc(projectId)
										.collection("Revisiones")
										.doc(rev.id)
										.collection("Listado")
										.doc(lis.id)
										.collection("Recintos")
										.doc(rec.id)
										.collection("Subcontratos")
										.doc(sub.id)
										.collection("ErroresTipo")
										.get();
									responseErr.docs.forEach((errT: any) => {
										const resueltasErr = errT.data()
											.Resueltas
											? errT.data().Resueltas
											: 0;
										const respuestasErr = errT.data()
											.TotalRespuestas
											? errT.data().TotalRespuestas
											: 0;
										//SUM
										totalResueltasSub =
											totalResueltasSub + resueltasErr;
										totalRespuestasSub =
											totalRespuestasSub + respuestasErr;
									});

									//
									const originalSubResu = sub.data()
										?.Resueltas
										? sub.data()?.Resueltas
										: 0;
									const originalSubResp = sub.data()
										?.TotalRespuestas
										? sub.data()?.TotalRespuestas
										: 0;
									// ADD UPDATE SUBCONTRATO : totalResueltasSub
									if (
										totalResueltasSub !== originalSubResu ||
										totalRespuestasSub !== originalSubResp
									) {
										updates.push({
											tipo: "Subcontrato",
											ids: [
												`${rev.id}`,
												`${lis.id}`,
												`${rec.id}`,
												`${sub.id}`,
											],
											Resueltas: totalResueltasSub,
											Respuestas: totalRespuestasSub,
										});
									}

									//

									// SUM
									totalResueltasRec =
										totalResueltasRec + totalResueltasSub;
									totalRespuestasRec =
										totalRespuestasRec + totalRespuestasSub;
								})
							);

							//
							const originalRecResu = rec.data()?.Resueltas
								? rec.data()?.Resueltas
								: 0;
							const originalRecResp = rec.data()
								?.TotalRespuestas
								? rec.data()?.TotalRespuestas
								: 0;
							// ADD UPADTE Recintos : totalResueltasRec
							if (
								totalResueltasRec !== originalRecResu ||
								totalRespuestasRec !== originalRecResp
							) {
								updates.push({
									tipo: "Recinto",
									ids: [
										`${rev.id}`,
										`${lis.id}`,
										`${rec.id}`,
									],
									Resueltas: totalResueltasRec,
									Respuestas: totalRespuestasRec,
								});
							}

							//
							// SUM
							totalResueltasLis =
								totalResueltasLis + totalResueltasRec;
							totalRespuestasLis =
								totalRespuestasLis + totalRespuestasRec;
						})
					);

					//
					const originalLisResu = lis.data()?.Resueltas
						? lis.data()?.Resueltas
						: 0;
					const originalLisResp = lis.data()
						?.TotalRespuestas
						? lis.data()?.TotalRespuestas
						: 0;
					// ADD UPDATE Listado : totalResueltasLis
					if (
						totalResueltasLis !== originalLisResu ||
						totalRespuestasLis !== originalLisResp
					) {
						updates.push({
							tipo: "Listado",
							ids: [`${rev.id}`, `${lis.id}`],
							Resueltas: totalResueltasLis,
							Respuestas: totalRespuestasLis,
						});
					}
					//
					// SUM
					totalResueltasRev =
						totalResueltasRev + totalResueltasLis;
					totalRespuestasRev =
						totalRespuestasRev + totalRespuestasLis;
				})
			);

			//
			const originalRevResu = rev?.Resueltas
				? rev?.Resueltas
				: 0;
			const originalRevResp = rev?.TotalRespuestas
				? rev?.TotalRespuestas
				: 0;
			// ADD UPDATE Revisiones : totalResueltasRev
			if (
				totalResueltasRev !== originalRevResu ||
				totalRespuestasRev !== originalRevResp
			) {
				updates.push({
					tipo: "Revision",
					ids: [`${rev.id}`],
					Resueltas: totalResueltasRev,
					Respuestas: totalRespuestasRev,
				});
			}

			//
			totalResueltasProyecto =
				totalResueltasProyecto + totalResueltasRev;
		})
	);

	// PROCEED TO UPDATE DATA
	let batch = firestore.batch();
	let i = 0;

	await Promise.all(
		updates.map(async (data) => {
			// ADD TO COMMIT
			if (data?.tipo === "Revision") {
				const updateRef = firestore
					.collection("ProyectosChecklist")
					.doc(projectId)
					.collection("Revisiones")
					.doc(data?.ids[0]);
				const updateData = {
					Resueltas: data?.Resueltas,
					TotalRespuestas: data?.Respuestas,
				};
				batch.update(updateRef, updateData);
			}
			if (data?.tipo === "Listado") {
				const updateRef = firestore
					.collection("ProyectosChecklist")
					.doc(projectId)
					.collection("Revisiones")
					.doc(data?.ids[0])
					.collection("Listado")
					.doc(data?.ids[1]);
				const updateData = {
					Resueltas: data?.Resueltas,
					TotalRespuestas: data?.Respuestas,
				};
				batch.update(updateRef, updateData);
			}
			if (data?.tipo === "Recinto") {
				const updateRef = firestore
					.collection("ProyectosChecklist")
					.doc(projectId)
					.collection("Revisiones")
					.doc(data?.ids[0])
					.collection("Listado")
					.doc(data?.ids[1])
					.collection("Recintos")
					.doc(data?.ids[2]);
				const updateData = {
					Resueltas: data?.Resueltas,
					TotalRespuestas: data?.Respuestas,
				};
				batch.update(updateRef, updateData);
			}
			if (data?.tipo === "Subcontrato") {
				const updateRef = firestore
					.collection("ProyectosChecklist")
					.doc(projectId)
					.collection("Revisiones")
					.doc(data?.ids[0])
					.collection("Listado")
					.doc(data?.ids[1])
					.collection("Recintos")
					.doc(data?.ids[2])
					.collection("Subcontratos")
					.doc(data?.ids[3]);
				const updateData = {
					Resueltas: data?.Resueltas,
					TotalRespuestas: data?.Respuestas,
				};
				batch.update(updateRef, updateData);
			}

			//
			i += 1;
			//
			if (i >= 499) {
				// LIMIT 499
				// EXECUTE COMMIT MAX 500 UPDATES AT ONCE
				await batch.commit().catch((errBatch: any) => {
					console.error(errBatch);
				});

				// RESET COMMIT AND COUNTER
				i = 0;
				batch = firestore.batch();
			}
		})
	);
	//
	//
};

/////////////////////////////////////////////////

const setProjectsBusiness = (
	businesses: any[]
): Action => ({
	type: types.PROJECTS_GET_BUSINESS,
	payload: businesses,
});

const isProjectsBusinessLoading = (
	isLoading: boolean
): Action => ({
	type: types.PROJECTS_BUSINESS_LOADING,
	payload: isLoading,
});

const isProjectsAddDocLoading = (
	isLoading: boolean
): Action => ({
	type: types.PROJECTS_ADD_DOC_LOADING,
	payload: isLoading,
});

export const setHideDeleted = (change: any): Action => ({
	type: types.PROJECTS_HIDE_CHANGE,
	payload: change,
});
export const setGettingProjectsUserIn = (
	state: "Initial" | "Submitting" | "Success" | "Failure"
): Action => ({
	type: types.PROJECT_USER_IN_SET_STATE,
	payload: state,
});

export const getChecklists =
	(idEmpresa?: string): AppThunk =>
	async (dispatch, getState) => {
		try {
			const { user } = getState().authReducer;
			if (!user) throw Error("User not logged.");
			const { tipoUsuario, empresaReference } = user;

			dispatch({
				type: types.CHECKLISTS_GET_SUBMITTING,
			});

			projectListener.close();
			projectListener.set(
				conseguirChecklists({
					tipoUsuario,
					idEmpresa: idEmpresa ?? empresaReference.id,
				}).onSnapshot((snap) => {
					dispatch({
						type: types.CHECKLISTS_GET_SUCCESS,
						payload: {
							projectsChecklist: snap.docs.map((s) => ({
								...s.data(),
								id: s.id,
							})),
						},
					});
				})
			);
		} catch (error: any) {
			console.error(error);
			dispatch({
				type: types.CHECKLISTS_GET_FAILURE,
				payload: "Ha ocurrido un error...",
			});
		}
	};
