import {
	TableCell,
	TableRow,
	TableBody as MuiTableBody,
	useTheme,
} from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import { useMemo } from "react";
import { useTableContext } from "./Context";
import { TableEmpty } from "./cell/Empty";
import { TableLoading } from "./cell/Loading";

export const TableBody = () => {
	const theme = useTheme();

	const {
		page,
		elementsProps,
		columns,
		loading,
		list,
		rowsPerPage,
		getPaginatedList,
		disablePagination,
		stripped,
		stripeColor = theme.palette.background.default,
		emptyMessage,
	} = useTableContext();

	const { TableRowProps, TableCellProps } = elementsProps;

	const config = useMemo(
		() => ({
			colSpan: columns?.length || 1,
			rows: rowsPerPage,
		}),
		[columns, rowsPerPage]
	);

	const listToRender: any[] = useMemo(
		() =>
			disablePagination ? list : getPaginatedList(list),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[list, page, rowsPerPage, disablePagination]
	);

	if (list?.length === 0)
		return (
			<TableEmpty {...config} message={emptyMessage} />
		);

	if (loading) return <TableLoading {...config} />;

	return (
		<MuiTableBody>
			{listToRender.map((item, nRow) => (
				<TableRow
					{...TableRowProps}
					style={{
						backgroundColor:
							stripped && nRow % 2 === 0
								? darken(stripeColor, 0.05)
								: TableRowProps?.style?.backgroundColor,
						...TableRowProps?.style,
					}}
					key={`cool-row-${nRow}`}
				>
					{columns?.map(({ Cell, ...props }, nCol) => (
						<TableCell
							{...TableCellProps}
							{...props}
							key={`cool-cell-${nRow}-${nCol}`}
						>
							<Cell value={item} row={nRow} column={nCol} />
						</TableCell>
					))}
				</TableRow>
			))}
		</MuiTableBody>
	);
};
