import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel as MuiFormControlLabel,
	Switch,
	TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import * as yup from "yup";

const FormControlLabel = styled(MuiFormControlLabel)(
	spacing
);

interface Props {
	data?: any;
	open: boolean;
	onClose: () => void;
	onAccept: (values: any) => void;
	title?: string;
	type?: string;
}

export const EditActivateDesactivate: React.FC<Props> =
	React.memo(
		({ open, onClose, onAccept, type, data, title }) => {
			const {
				handleSubmit,
				values,
				handleChange,
				touched,
				errors,
				setValues,
				resetForm,
			} = useFormik({
				initialValues: {
					Nombre: "",
					Activado: true,
				},
				onSubmit: (values) => {
					onAccept(values);
				},
				validationSchema: yup.object({
					Nombre: yup.string().required("Nombre requerido"),
				}),
			});

			useEffect(() => {
				if (type === "new") {
					resetForm();
				} else {
					if (data) {
						setValues({
							Nombre: data?.Nombre,
							Activado: data?.Activado,
						});
					}
				}
			}, [data]);
			return (
				<>
					<Dialog
						open={open}
						onClose={(_, reason) => {
							if (reason !== "backdropClick") {
								onClose();
							}
						}}
						aria-labelledby="add-and-update-dialog-title"
					>
						<DialogTitle id="add-and-update-dialog-title">
							{type === "edit"
								? "Editar " + title
								: "Agregar " + title}
						</DialogTitle>

						<form noValidate onSubmit={handleSubmit}>
							<DialogContent>
								<TextField
									autoFocus
									id="Nombre"
									label="Nombre"
									type="text"
									value={values.Nombre}
									name="Nombre"
									onChange={handleChange}
									error={
										touched.Nombre && Boolean(errors.Nombre)
									}
									helperText={
										touched.Nombre && errors.Nombre
									}
									fullWidth
								/>
								<FormControlLabel
									control={
										<Switch
											checked={values.Activado}
											onChange={handleChange}
											name="Activado"
											color="primary"
										/>
									}
									my={4}
									label="Activado"
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={onClose} color="primary">
									Cancelar
								</Button>
								<Button type="submit" color="primary">
									Guardar
								</Button>
							</DialogActions>
						</form>
					</Dialog>
				</>
			);
		}
	);
