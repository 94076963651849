import { cleanString } from "../utils/utils";
import {
	ToggableProyecto,
	Subcontrato,
	Empresa,
} from "../models/Empresa";
import { firestore } from "../firebase/firebase";

export const quitarEntradasRepetidas = (
	entradas: (ToggableProyecto | Subcontrato)[]
): (ToggableProyecto | Subcontrato)[] =>
	entradas.filter(
		(entrada1, index) =>
			entradas.findIndex(
				(entrada2) => entrada2.Nombre === entrada1.Nombre
			) === index
	);

export const entradaExiste = (
	nombreEntrada: string,
	lista: (ToggableProyecto | Subcontrato)[]
): boolean => {
	const _nombre = cleanString(nombreEntrada);

	return !!lista.find(
		(entrada) => cleanString(entrada.Nombre) === _nombre
	);
};

/**
 * Retorna la copia de una entrada de tipo `ToggableProyecto`
 * o `Subcontrato`. Retorna `null` si no existe la entrada.
 * @param nombreEntrada
 * @param lista
 */
export const encontrarEntrada = (
	nombreEntrada: string,
	lista: (ToggableProyecto | Subcontrato)[]
): ToggableProyecto | Subcontrato | null => {
	const _nombre = cleanString(nombreEntrada);

	const entrada = lista.find(
		(entrada) => cleanString(entrada.Nombre) === _nombre
	);

	return entrada ? { ...entrada } : null;
};

export const encontrarIndexEntrada = (
	nombreEntrada: string,
	lista: (ToggableProyecto | Subcontrato)[]
) => {
	const _nombre = cleanString(nombreEntrada);

	return lista.findIndex(
		(entrada) => cleanString(entrada.Nombre) === _nombre
	);
};

export const actualizarRevisionesUsadas = async (
	empresa: Empresa,
	cantidad: number
) => {
	const Revisiones = { ...empresa.Revisiones };
	// 
	
	Revisiones.RevisionesUsados = cantidad;

	await firestore
		.doc(`Empresas/${empresa.id}`)
		.update({ Revisiones });
};

export const actualizarChecklistsUsados = async (
	empresa: Empresa,
	cantidad: number
) => {
	const CheckList = { ...empresa.CheckList };
	CheckList.CheckListUsados = cantidad;

	await firestore
		.doc(`Empresas/${empresa.id}`)
		.update({ CheckList });
};
