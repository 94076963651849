import { Action } from "../../models/action";
import * as types from "../../constants";
import { Observacion } from "src/models/Observacion";

export interface IObservationsState {
  observations: Observacion[];
  allObservations: any[];
  isLoading: boolean;
  error?: string;
  updateLoading: boolean;
  totalDocs: number;
  lastDoc?: any;
  imagesLoading: boolean;
  updateState?: "Submitting" | "Success" | "Failure" | "Initial";
  updateError?: any;
}

const initialState = {
  observations: [],
  allObservations: [],
  isLoading: false,
  updateLoading: false,
  totalDocs: 0,
  imagesLoading: false,
  updateState: "Initial",
} as IObservationsState;

export const observationsReducer = (
  state = initialState,
  action: Action
): IObservationsState => {
  switch (action.type) {
    case types.OBSERVATIONS_GET_DOCS:
      return {
        ...state,
        observations: action.payload.observations,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.OBSERVATIONS_ADD_DOCS:
      return {
        ...state,
        observations: [...state.observations, ...action.payload],
      };
    case types.OBSERVATIONS_UPDATE_DOC:
      return {
        ...state,
        observations: state.observations.map((x) =>
          x.id === action.payload.id ? { ...x, ...action.payload } : x
        ),
      };
    case types.OBSERVATIONS_REMOVE_DOC:
      return {
        ...state,
        observations: state.observations.filter(
          (x) => x.id !== action.payload.id
        ),
      };
    case types.OBSERVATIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.OBSERVATIONS_UPDATE_LOADING:
      return {
        ...state,
        updateLoading: action.payload,
      };
    case types.OBSERVATIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.OBSERVATIONS_SET_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };
    case types.OBSERVATIONS_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.IMAGES_GET_DOC:
      return {
        ...state,
        imagesLoading: action.payload,
      };
    case types.OBSERVATION_UPDATE_IS_SUBMITTING:
      return {
        ...state,
        updateState: "Submitting",
        updateError: undefined,
      };
    case types.OBSERVATION_UPDATE_SUCCESS:
      return {
        ...state,
        updateState: "Success",
      };
    case types.OBSERVATION_UPDATE_FAILURE:
      return {
        ...state,
        updateState: "Failure",
        updateError: action.payload,
      };

    case types.OBSERVATIONS_SET_ALL_DOCUMENTS_DATA:
      return {
        ...state,
        allObservations: action.payload,
      };

    default:
      return state;
  }
};
