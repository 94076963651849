import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	TextField,
	Typography,
} from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

export const NeoProjectDeleteDialog: FC<DialogProps> = ({
	...props
}) => {
	const { removeProject, selected, loading } =
		useProjectsOT();

	const [userAgrees, setUserAgrees] = useState("");

	const handleDelete = async () => {
		if (!selected.project) return;
		await removeProject();
		props.onClose?.({}, "escapeKeyDown");
	};

	useEffect(() => {
		setUserAgrees("");
	}, [selected.project]);

	return (
		<Dialog {...props}>
			<DialogTitle
				bigText="Eliminar proyecto"
				smallText={selected.project?.Nombre}
			/>

			<DialogContent>
				<Typography>
					Un proyecto eliminado no se puede recuperar y será
					de solo lectura. Esto no se puede deshacer.
					<br />
					<br />
					Si estás seguro que quieres continuar, por favor
					escribe el nombre del proyecto:
					<br />
					<br />
				</Typography>

				<TextField
					fullWidth
					variant="outlined"
					value={userAgrees}
					onChange={(ev) => setUserAgrees(ev.target.value)}
				/>
			</DialogContent>

			<DialogActions>
				<Button
					disabled={loading}
					onClick={() =>
						props.onClose?.({}, "escapeKeyDown")
					}
				>
					No
				</Button>
				<Button
					disabled={
						userAgrees !== selected.project?.Nombre ||
						loading
					}
					color="primary"
					variant="contained"
					onClick={handleDelete}
				>
					{loading ? <CircularProgress size={24} /> : "Sí"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
