import { Link } from "@material-ui/core";
import { FC, useMemo } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useCheckproLinks } from "./hooks/useCheckproLinks";
import { UrlParams } from ".";

export const LinkProjectsOT: FC = () => {
	const { auth } = useCheckproLinks();

	const { businessId } = useParams<UrlParams>();

	const url = useMemo(() => {
		const userType = auth.user?.tipoUsuario;

		if (userType === "Administrador")
			return `/administrador/empresas/${businessId}/neo-projects`;
		else return `/administrador/empresas/neo-projects`;
	}, [auth.user, businessId]);

	return (
		<Link component={NavLink} exact to={url}>
			Proyectos (Checklists)
		</Link>
	);
};
