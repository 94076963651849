import {
	Button,
	Card as MuiCard,
	CardContent,
	Fade,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import {
	ChangeEvent,
	FC,
	useEffect,
	useMemo,
	useState,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import {
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../../constants";
import { useTable } from "../../hooks/useTable";
import { addChecklistToList } from "../../redux/actions/listsActions";
import { setHideDeleted } from "../../redux/actions/projectsActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { RootState } from "../../redux/store/reducer";
import { useStyles } from "../../theme/useStyles";
import { SetListadoDialog } from "./SetListadoDialog";
import { DataRow } from "./DataRow";

const Card = styled(MuiCard)(spacing);

interface TableListadoProps {
	tableLimit?: number;
	handleOpenEditDialog: (selected: any) => void;
	handleOpenConfirmDialog: (selected: any) => void;
	handleOpenResponsiblesDialog: (selected: any) => void;
	openNewDialog?: () => void;
	projects: any[];
	isLoading: boolean;
	totalDocs: number;
	onLimitChange: (
		event: ChangeEvent<HTMLInputElement>,
		limit: number
	) => void;
	onPageChange: (
		newPage: number,
		page: number,
		limit: number
	) => void;
	handleChecksButton: (selected: any) => void;
}

export const TableListados: FC<TableListadoProps> = ({
	tableLimit = 10,
	handleOpenEditDialog,
	projects: listados,
	isLoading,
	totalDocs,
	handleOpenConfirmDialog,
	handleOpenResponsiblesDialog,
	onLimitChange,
	onPageChange,
	handleChecksButton,
}) => {
	const { projectId, revisionId } = useParams<any>();
	const dispatch = useDispatch();
	let [selectedListados, setSelectedListados] = useState<
		any[]
	>([]);
	const [checkedBox, setCheckedBox] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);

	const { pathname } = useLocation();

	const {
		handleLimitChange,
		handlePageChange,
		limit,
		page,
	} = useTable({
		limit: TABLE_LIMIT_DEFAULT || 5,
		onLimitChange: (e) => onLimitChange(e, tableLimit),
		onPageChange: onPageChange,
	});

	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);

	useEffect(() => {
		dispatch(setHideDeleted(true));
	}, [dispatch]);

	const cantAssign = useMemo(() => {
		if (selectedListados.length <= 0) return true;

		return !!selectedListados.find(
			(l) => l?.TotalRespuestas > 0
		);
	}, [selectedListados]);

	const addListado = (l: any) => {
		const listados = [...selectedListados];
		listados.push(l);
		setSelectedListados(listados);
	};

	return (
		<>
			<Card mb={6}>
				{auth.user?.tipoUsuario !== "UsuarioEmpresa" ? (
					<>
						<CardContent>
							<Grid
								item
								container
								justify="space-between"
								sm={12}
							>
								<Grid item>
									<Button
										disabled={cantAssign}
										variant="contained"
										color="primary"
										onClick={() => {
											setOpenDialog(true);
										}}
									>
										Asignar Checklist
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</>
				) : (
					""
				)}
				<CardContent>
					<TableContainer>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									{auth.user?.tipoUsuario !==
										"UsuarioEmpresa" && (
										<TableCell width={24}></TableCell>
									)}

									<TableCell>Nombre</TableCell>
									<TableCell>Checklist</TableCell>
									{pathname.includes(
										"checklist-proyectos"
									) && (
										<TableCell>{"Observaciones"}</TableCell>
									)}
									<TableCell>Encargado</TableCell>
									<TableCell>Fecha Propuesta</TableCell>
									<TableCell>Fecha Real</TableCell>
									<TableCell>Acciones</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!isLoading
									? listados
											.sort((a: any, b: any) => {
												if (a.Nombre === b.Nombre) {
													return 0;
												}
												if (a.Nombre < b.Nombre) {
													return -1;
												}
												return 1;
											})
											.slice(
												page * limit,
												page * limit + limit
											)
											.map((project) => (
												<Fade key={project.id} in={true}>
													<DataRow
														selectedListados={
															selectedListados
														}
														project={project}
														checkedBox={checkedBox}
														handleStatsButton={() => {}}
														handleOpenEditDialog={(
															project
														) =>
															handleOpenEditDialog(project)
														}
														handleOpenConfirmDialog={(
															project
														) =>
															handleOpenConfirmDialog(
																project
															)
														}
														handleOpenResponsiblesDialog={(
															project
														) =>
															handleOpenResponsiblesDialog(
																project
															)
														}
														handleChecksButton={(project) =>
															handleChecksButton(project)
														}
														handleCheckedAdd={(
															listado,
															check
														) => {
															if (check === true)
																addListado(listado);
															else if (check === false) {
																setSelectedListados(
																	selectedListados.filter(
																		(x) =>
																			x.id !== listado.id
																	)
																);
															}
														}}
													/>
												</Fade>
											))
									: new Array(limit).fill(0).map((x, i) => (
											<TableRow key={i}>
												<TableCell colSpan={10}>
													<Skeleton />
												</TableCell>
											</TableRow>
									  ))}
							</TableBody>
						</Table>
					</TableContainer>

					<TablePagination
						component="div"
						count={listados.length}
						onChangePage={handlePageChange as any}
						onChangeRowsPerPage={handleLimitChange}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={TABLE_LIMITS}
					/>
				</CardContent>
			</Card>

			<SetListadoDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onAccept={(value) => {
					dispatch(
						addChecklistToList(
							value,
							selectedListados,
							projectId,
							revisionId,
							true
						)
					);

					setCheckedBox(false);
					setOpenDialog(false);
				}}
			/>
		</>
	);
};
