import { Paper } from "@material-ui/core";
import naturalSort from "natural-sort";
import { useMemo, FC } from "react";
import {
	CrudValue,
	SimpleCrud,
} from "src/components/SimpleCrud";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { DialogAction } from "src/models/dialog_state";

type Props = {
	type: keyof ReturnType<
		typeof useBusiness
	>["neoVariables"];
};

export const ParamTab: FC<Props> = ({ type }) => {
	const { neoVariables } = useBusiness();
	const { add, current, remove, update } =
		neoVariables[type];

	const parsedParams = useMemo(() => {
		if (!current) return [];

		return [...current]
			.map((u, i) => ({
				Nombre: u,
				Activado: true,
				index: i,
			}))
			.sort((a, b) => naturalSort()(a.Nombre, b.Nombre));
	}, [current]);

	const [crudName, crudCardTitle] = useMemo(() => {
		switch (type) {
			case "units":
				return ["Unidad", "Unidades"];
			case "corridors":
				return ["Pasillo", "Pasillos"];
			case "sectors":
				return ["Sector", "Sectores"];
			case "precincts":
				return ["Recinto", "Recintos"];
			case "others":
				return ["Otro", "Otros"];
			case "elements":
				return ["Elemento", "Elementos"];
		}
	}, [type]);

	const handleCreateOrUpdate = (
		value: CrudValue,
		action: DialogAction
	) => {
		if (action === "New") add(value.Nombre);
		else if (action === "Edit")
			update(value.Nombre, value.index);
	};

	const handleRemove = (value: CrudValue) => {
		remove(value.Nombre);
	};

	return (
		<>
			<Paper
				elevation={3}
				style={{
					display: "flex",
					flexDirection: "column",
					gap: 10,
				}}
			>
				<SimpleCrud
					canEdit={true}
					canToggle={false}
					name={crudName}
					cardTitle={crudCardTitle}
					array={parsedParams}
					onCreateOrUpdate={handleCreateOrUpdate}
					onDelete={handleRemove}
				/>
			</Paper>
		</>
	);
};
