import { Action } from "../../models/action";
import * as types from "../../constants";
import { sortByName } from "../../utils/utils";

export interface IChargesState {
  charges: any[];
  isLoading: boolean;
  error?: string;
}

const initialState = {
  charges: [],
  isLoading: false,
  totalDocs: 0,
} as IChargesState;

export const chargesReducer = (
  state = initialState,
  action: Action
): IChargesState => {
  switch (action.type) {
    case types.CHARGES_GET_DOCS:
      return {
        ...state,
        charges: action.payload,
      };
    case types.CHARGES_ADD_DOC:
      return {
        ...state,
        charges: [...state.charges, ...action.payload].sort(sortByName),
      };
    case types.CHARGES_UPDATE_DOC:
      return {
        ...state,
        charges: state.charges
          .map((x) => (x.id === action.payload.id ? action.payload : x))
          .sort(sortByName),
      };
    case types.CHARGES_REMOVE_DOC:
      return {
        ...state,
        charges: state.charges.filter((x) => x.id !== action.payload.id),
      };
    case types.CHARGES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.CHARGES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
