import { TableCell, TableRow } from "@material-ui/core";
import { useTableContext } from "./Context";

export const ActionRow = () => {
	const {
		elementsProps,
		columns,
		disableExporting,
		actions,
	} = useTableContext();
	const { ActionRowProps } = elementsProps;

	return (
		<TableRow>
			<TableCell
				{...ActionRowProps}
				colSpan={columns.length || 1}
			>
				{actions}
			</TableCell>
		</TableRow>
	);
};
