import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import {
	firebaseConfig,
	firestore,
} from "../../firebase/firebase";
import firebase from "firebase/app";
import Axios from "axios";
import { NewGoogleUserResponse } from "../../models/new_google_user_response";
import { SnackState } from "../../models/snack-state";
import { openSnack } from "./uiActions";
import {
	ToggableProyecto,
	Subcontrato,
	Empresa,
} from "../../models/Empresa";
import { Usuario } from "src/models/Usuario";
import { v4 } from "uuid";
import { AnyProject } from "src/models/Proyecto";
import {
	addAssigneesToProject,
	decrementBusinessCounters,
	getAssignedProjects,
	incrementBusinessCounters,
	updateBusinessCounters,
	updateProjectAssignees,
} from "../user/utils";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";

const API_KEY = firebaseConfig.apiKey;

const listener = FirebaseListener.addListener("users");

export const getUsers = (
	business?: Pick<Empresa, "id">
): AppThunk => {
	return async (dispatch, state) => {
		dispatch(isLoading(true));

		const businessId =
			business?.id ??
			state().businessReducer.edit.selectedBusiness?.id;

		if (!businessId)
			throw Error("No hay una empresa seleccionada.");

		const businessReference = firestore
			.collection("Empresas")
			.doc(businessId);

		listener.close();
		listener.set(
			firestore
				.collection("Usuarios")
				.orderBy("Nombre")
				.where("Deleted", "==", false)
				.where("EmpresaReference", "==", businessReference)
				.onSnapshot(
					(snap) => {
						const users = snap.docs.map(
							(doc) =>
								({
									...doc.data(),
									_ref: doc.ref,
									id: doc.id,
								} as Usuario)
						);
						dispatch(setUsers(users));
					},
					(error) => {
						console.error(error);
						dispatch(setError(error.message));
					}
				)
		);
		dispatch(isLoading(false));
	};
};

export const getUser = (id: string): AppThunk => {
	return async (dispatch) => {
		dispatch(isLoading(true));
		try {
			//
			const response = await firestore
				.collection("Usuarios")
				.doc(id)
				.get();

			dispatch(setSelectedUser(mapUser(response)));
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const createUser = (
	user: Omit<
		Usuario,
		| "EmpresaReference"
		| "Deleted"
		| "Prioridades"
		| "Entregas"
		| "Recintos"
		| "Sectores"
		| "Subcontratos"
	>,
	projectsToAssign?: AnyProject[]
): AppThunk => {
	return async (dispatch, getState) => {
		const selectedBusiness =
			getState().businessReducer.selectedBusiness;

		dispatch(isLoading(true));
		try {
			if (!selectedBusiness)
				throw Error("No hay empresa seleccionada.");

			user.Email = user.Email.toLowerCase();
			//Crear usuario en Authentication
			const res = await Axios.post<NewGoogleUserResponse>(
				`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${API_KEY}`,
				{
					email: user.Email,
					password: v4(),
					returnSecureToken: true,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const userId = res.data.localId;

			const projectsToUpdate =
				projectsToAssign &&
				addAssigneesToProject(userId, projectsToAssign);

			// Asignar nuevo usuario a los proyectos seleccionados
			while (projectsToUpdate?.length) {
				const batch = firestore.batch();
				const spliced = projectsToUpdate.splice(0, 500);
				spliced.forEach(({ ref, data }) =>
					batch.update(ref, data)
				);
				await batch.commit();
			}

			const counters = incrementBusinessCounters(user);

			await Promise.all([
				// Actualizar contadores de usuarios usados
				firestore
					.doc(`Empresas/${selectedBusiness.id}`)
					.update(counters),

				//Crear registro en tabla Usuarios
				firestore
					.collection("Usuarios")
					.doc(userId)
					.set({
						...user,
						EmpresaReference: firestore
							.collection("Empresas")
							.doc(selectedBusiness.id),
						Prioridades: selectedBusiness.Prioridades,
						Recintos: selectedBusiness.Recintos,
						Sectores: selectedBusiness.Sectores,
						Subcontratos: selectedBusiness.Subcontratos,
						Deleted: false,
					}),

				// Asignar nuevo usuario a los proyectos seleccionados
				async () => {
					while (projectsToUpdate?.length) {
						const batch = firestore.batch();
						const spliced = projectsToUpdate.splice(0, 500);
						spliced.forEach(({ ref, data }) =>
							batch.update(ref, data)
						);
						await batch.commit();
					}
				},

				// Enviar mail para asignar contraseña
				Axios.post(
					`https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${API_KEY}`,
					{
						requestType: "PASSWORD_RESET",
						email: user.Email,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				),
			]);

			dispatch(
				openSnack(
					"Hemos creado el usuario y le enviamos un email " +
						"para que asigne su contraseña.",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack("El usuario ya existe", SnackState.ERROR)
			);
		}

		dispatch(isLoading(false));
	};
};

export const updateUser = (
	user: Omit<
		Usuario,
		| "id"
		| "EmpresaReference"
		| "Deleted"
		| "Prioridades"
		| "Entregas"
		| "Recintos"
		| "Sectores"
		| "Subcontratos"
	>,
	toAssign?: AnyProject[],
	toUnassign?: AnyProject[]
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(editIsLoading(true));

		try {
			const { Activado, ...data } = user;

			const previousUser =
				getState().usersReducer.edit.selectedUser;

			if (!previousUser?.id)
				throw Error("No encontramos al usuario anterior.");

			const { EmpresaReference, id } = previousUser;

			const counters = updateBusinessCounters(
				user,
				previousUser
			);

			const projectsToUpdate = updateProjectAssignees(id, {
				toAssign,
				toUnassign,
			});

			while (projectsToUpdate.length) {
				const batch = firestore.batch();
				const spliced = projectsToUpdate.splice(0, 500);
				spliced.forEach(({ ref, data }) =>
					batch.update(ref, data)
				);
				await batch.commit();
			}

			await Promise.all([
				EmpresaReference.update(counters),

				firestore.doc(`Usuarios/${id}`).update(data),

				async () => {
					while (projectsToUpdate.length) {
						const batch = firestore.batch();
						const spliced = projectsToUpdate.splice(0, 500);
						spliced.forEach(({ ref, data }) =>
							batch.update(ref, data)
						);
						await batch.commit();
					}
				},
			]);

			dispatch(
				openSnack(
					"Hemos actualizado al usuario.",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			console.error(error);
			dispatch(openSnack(error.message, SnackState.ERROR));
		}

		dispatch(editIsLoading(false));
	};
};

export const deleteUser = (user: Usuario): AppThunk => {
	return async (dispatch) => {
		dispatch(editIsLoading(true));

		try {
			const { id, EmpresaReference } = user;

			const userRef = firestore.doc(`Usuarios/${id}`);

			const { revisions, revisionChecklists, checklists } =
				await getAssignedProjects(user);

			const projectsToUpdate = [
				...revisions.docs,
				...revisionChecklists.docs,
				...checklists.docs,
			];

			const counters = decrementBusinessCounters(user);

			await Promise.all([
				// Actualizar contadores
				EmpresaReference.update(counters),
				// Actualizar estado del usuario
				userRef.update({
					Activado: false,
					Deleted: true,
					Email: null,
				}),
				// Quitar al usuario de los proyectos
				(async () => {
					while (projectsToUpdate.length) {
						const batch = firestore.batch();
						const spliced = projectsToUpdate.splice(0, 500);
						spliced.forEach((snap) =>
							batch.update(snap.ref, {
								Responsables:
									firebase.firestore.FieldValue.arrayRemove(
										user.id
									),
							})
						);
						await batch.commit();
					}
				})(),
				// Eliminar usuario de la autenticación
				Axios.post(
					`https://us-central1-checkpro-3a90a.cloudfunctions.net/app/users/delete`,
					{
						email: user.Email,
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				),
			]);

			dispatch(
				openSnack(
					"Hemos eliminado el usuario.",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			console.error(error);
			dispatch(openSnack(error.message, SnackState.ERROR));
		}

		dispatch(editIsLoading(false));
	};
};

export const sendRecoveryMail = (user: any): AppThunk => {
	return async (dispatch) => {
		dispatch(editIsLoading(true));
		try {
			await Axios.post(
				`https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${API_KEY}`,
				{
					requestType: "PASSWORD_RESET",
					email: user.Email,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			dispatch(
				openSnack(
					"Correo de restauración enviado",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack("Hubo un problema.", SnackState.ERROR)
			);
		}

		dispatch(editIsLoading(false));
	};
};

/**
 * Toma ciertos datos de la empresa y se los agrega a sus
 * usuarios.
 *
 * Datos actualizados:
 *  - Prioridades
 *  - Recintos
 *  - Sectores
 *  - Subcontratos
 *  - Errores tipo
 */
export const reloadDefaultTables = (): AppThunk => {
	return async (dispatch, useState) => {
		dispatch({
			type: types.USERS_TABLES_UPDATE_SUBMITTING,
		});
		const {
			edit: { selectedBusiness },
		} = useState().businessReducer;
		try {
			const empresaRef = firestore
				.collection("Empresas")
				.doc(selectedBusiness!.id);

			const usersSnap = await firestore
				.collection("Usuarios")
				.where("EmpresaReference", "==", empresaRef)
				.where("Deleted", "==", false)
				.get();

			// new data
			const {
				Prioridades: newPrioridades,
				Recintos: newRecintos,
				Sectores: newSectores,
				Subcontratos: newSubcontratos,
			} = selectedBusiness!;

			const users = [...usersSnap.docs];
			/**
			 * user's and business's data get merged
			 */
			const mergedUserData = users.map((doc) => {
				const data: Partial<Usuario> = {};

				const allPrioridades = {} as {
					[key: string]: ToggableProyecto;
				};
				const allRecintos = {} as {
					[key: string]: ToggableProyecto;
				};
				const allSectores = {} as {
					[key: string]: ToggableProyecto;
				};
				const allSubcontratos = {} as {
					[key: string]: Subcontrato;
				};

				// get old data
				const {
					Prioridades: oldPrioridades = [],
					Recintos: oldRecintos = [],
					Sectores: oldSectores = [],
					Subcontratos: oldSubcontratos = [],
				} = doc.data() as Usuario;

				[...oldPrioridades, ...newPrioridades].forEach(
					(priority) => {
						const key = priority.Nombre;
						allPrioridades[key] = priority;
					}
				);

				[...oldRecintos, ...newRecintos].forEach(
					(recinto) => {
						const key = recinto.Nombre;
						allRecintos[key] = recinto;
					}
				);

				[...oldSectores, ...newSectores].forEach(
					(sector) => {
						const key = sector.Nombre;
						allSectores[key] = sector;
					}
				);

				/**
				 * Special case: if a subcontrato exists, their errores tipo
				 * get merged
				 */
				[...oldSubcontratos, ...newSubcontratos].forEach(
					(current) => {
						const previous =
							allSubcontratos[current.Nombre];

						if (previous) {
							const allErroresTipo = {} as {
								[key: string]: ToggableProyecto;
							};

							[
								...(previous.ErroresTipo ?? []),
								...(current?.ErroresTipo ?? []),
							].forEach((error) => {
								const key = error.Nombre;
								allErroresTipo[key] = error;
							});

							current.ErroresTipo =
								Object.values(allErroresTipo);

							allSubcontratos[current.Nombre] = current;
						} else
							allSubcontratos[current.Nombre] = current;
					}
				);

				data.Prioridades = Object.values(allPrioridades);
				data.Recintos = Object.values(allRecintos);
				data.Sectores = Object.values(allSectores);
				data.Subcontratos = Object.values(allSubcontratos);

				return data;
			});

			let index = 0;
			while (users.length) {
				const batch = firestore.batch();
				const spliced = users.splice(0, 500);

				for (const doc of spliced) {
					batch.update(doc.ref, mergedUserData[index]);
					index++;
				}

				await batch.commit();
			}

			dispatch({
				type: types.USERS_TABLES_UPDATE_SUCCESS,
			});
			dispatch(
				openSnack(
					"Se han recargado datos",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch({
				type: types.USERS_TABLES_UPDATE_FAILURE,
				error: error,
			});
			dispatch(
				openSnack(
					"Error al recargar datos",
					SnackState.ERROR
				)
			);
			console.error(error);
		}
	};
};

const mapUsers = (
	response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
	let users: any[] = [];
	response.docs.forEach((doc) => {
		let data = mapUser(doc);
		users.push(data);
	});
	return users;
};

const mapUser = (doc: any) => {
	let data = doc.data();
	data.id = doc.id;
	return data;
};

export const addMoreUsers = (
	limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));

		const lastDoc = getState().usersReducer.lastDoc || "";

		try {
			//
			const response = await firestore
				.collection("Usuarios")
				.orderBy("Nombre")
				.startAfter(lastDoc)
				.limit(limit)
				.get();

			dispatch(addUsers(mapUsers(response)));
			dispatch(
				setLastDoc(response.docs[response.docs.length - 1])
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const getTotalDocs = (): AppThunk => {
	return async (dispatch) => {
		try {
			const response = await firestore
				.collection("Usuarios")
				.get();
			dispatch(setTotalDocs(response.size));
		} catch (error: any) {
			dispatch(setError(error));
		}
	};
};

export const setSelectedUser = (
	user: Usuario | null
): Action => ({
	type: types.USERS_SET_SELECTED,
	payload: user,
});

const editIsLoading = (isLoading: boolean): Action => ({
	type: types.USERS_EDIT_LOADING,
	payload: isLoading,
});

export const setUsers = (users: any[]): Action => ({
	type: types.USERS_GET_DOCS,
	payload: users,
});

const setLastDoc = (doc: any): Action => ({
	type: types.USERS_SET_LAST_DOC,
	payload: doc,
});

const addUsers = (users: any[]): Action => ({
	type: types.USERS_ADD_DOCS,
	payload: users,
});

const isLoading = (isLoading: boolean): Action => ({
	type: types.USERS_LOADING,
	payload: isLoading,
});

const setError = (error: string): Action => ({
	type: types.USERS_FAILURE,
	payload: error,
});

const setTotalDocs = (total: number): Action => ({
	type: types.USERS_SET_TOTAL_DOCS,
	payload: total,
});
