import {
	Accordion,
	AccordionDetails,
	AccordionProps,
	AccordionSummary,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Paper,
	Tooltip,
	Typography,
} from "@material-ui/core";
import {
	Description,
	ExpandMore,
	PictureAsPdfTwoTone,
} from "@material-ui/icons";

import { useSelector } from "src/redux/store";
import {
	SheetAnswerMap,
	SheetOT,
} from "src/models/OT/Sheet";
import { FC, useMemo, useState } from "react";
import { SheetCategoryDialogOT } from "../Dialogs/SheetCategory";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import {
	getReviewerStatusColor,
	twoOrMoreAreWrong,
} from "src/models/OT/utils/sheets";
import { DropdownMenu } from "src/components/Generic/DropdownMenu";
import { getRef } from "src/utils/firebase";
import { Trash2 } from "react-feather";
import { RemoveSheetDialogOT } from "../../Dialog/RemoveSheet";
import { Usuario } from "src/models/Usuario";
import { useAsyncMemo } from "src/utils/hooks/useAsyncEffect";

type Props = { sheet?: SheetOT } & Omit<
	AccordionProps,
	"children"
>;

export const SheetAccordionOT: FC<Props> = ({
	sheet,
	...accordion
}) => {
	const users = useSelector((s) => s.usersReducer.users);
	const { selected, get, set } = useProjectsOT();
	const [dialog, setDialog] = useState(0);

	const {
		Revisores = [],
		FechaRevisionFinal,
		Unidades = 0,
		_position = 0,
		FechaCreacion,
		Tipo,
		Elemento = "-",
		Ubicacion = "-",
		PlanoRevisor = "-",
		Sector = "-",
		Recinto = "-",
		Pasillo = "-",
		Otro = "-",
	} = sheet ?? {};

	const reviewers = useMemo(() => {
		if (!sheet) return [];
		console.log(users);
		return sheet.Revisores.map((revisor, i) => {
			// @ts-ignore
			const { Revisor } = revisor;
			// @ts-ignore
			const user = users?.find((u) => u.id === Revisor.id);

			return user
				? `${user.Nombre} ${user.Apellido}`
				: `Revisor ${i}`;
		});
	}, [sheet, users]);

	const groupedByCategory = useMemo(() => {
		const grouped: SheetAnswerMap = {};

		const categories = new Set(
			Revisores[0].Categorias.map((c) => c.Categoria)
		);

		categories.forEach((category) => {
			const questions = Revisores[0].Categorias.find(
				(c) => c.Categoria === category
			)!.Preguntas.map((p) => p.Pregunta);

			grouped[category] = questions.map((question) => ({
				question,
				answersByReviewer: Revisores.map(
					(revisor) =>
						revisor.Categorias.find(
							(c) => c.Categoria === category
						)?.Preguntas.find(
							(p) => p.Pregunta === question
						)!
				),
			}));
		});

		return grouped;
	}, [Revisores]);

	const responsibleName = useAsyncMemo(async () => {
		try {
			const responsibleRef =
				sheet?.Revisores.at(0)?.Revisor;
			const responsibleSnap = await responsibleRef?.get();
			const responsible =
				responsibleSnap?.data() as Usuario;
			return `${responsible.Nombre} ${responsible.Apellido}`;
		} catch (error) {
			console.error(error);
			return "Responsable no encontrado.";
		}
	}, [sheet?.Revisores.at(0)?.Revisor]);

	const statusColor = useMemo(() => {
		if (sheet && FechaRevisionFinal) {
			if (twoOrMoreAreWrong(sheet)) return "#FF0000";
			else return "#00B050";
		} else return "#FFFF00";
	}, [sheet, FechaRevisionFinal]);

	const percentage = useMemo(() => {
		const max = selected.assignedPlace?.Cantidad ?? 0;
		const current = Unidades;

		return Math.round((current / max) * 100);
	}, [selected.assignedPlace?.Cantidad, Unidades]);

	const [loading, setLoading] = useState(0);
	const handleImageSummaryClick = async () => {
		setLoading(1);
		await get.report.sheetPhotoPdf(
			getRef(sheet).path,
			_position
		);
		setLoading(0);
	};

	const handleSignatureSummaryClick = async () => {
		setLoading(2);
		await get.report.sheetSignaturePdf({
			responsePath: getRef(sheet).path,
			responseNumber: _position,
		});
		setLoading(0);
	};

	const handleDelete = () => {
		console.log(sheet);
		if (!sheet) return;
		setDialog(2);
		set.sheet(sheet);
	};

	return (
		<>
			<Accordion {...accordion}>
				<AccordionSummary
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						gap: 10,
					}}
					expandIcon={<ExpandMore />}
				>
					<Paper
						style={{
							width: "100%",
							display: "grid",
							gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
						}}
					>
						<Box
							bgcolor={statusColor}
							color="white"
							width={30}
							height={30}
							borderRadius={15}
							fontSize={15}
							textAlign="center"
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<b>{_position}</b>
						</Box>

						<Tooltip
							title={
								<Typography>
									<ol>
										{reviewers?.map((name, index) => (
											<li
												style={{
													color:
														sheet &&
														getReviewerStatusColor(
															sheet,
															index
														),
												}}
												key={name}
											>
												{name}
											</li>
										))}
									</ol>
								</Typography>
							}
						>
							<Typography
								style={{
									fontSize: 15,
									textAlign: "center",
								}}
							>
								<b>Revisores</b>
								<br />
								{reviewers?.length}
							</Typography>
						</Tooltip>

						<Typography
							style={{
								fontSize: 15,
								textAlign: "center",
							}}
						>
							<b>Inicio</b>
							<br />
							{FechaCreacion?.toDate().toLocaleDateString()}
						</Typography>

						<Typography
							style={{
								fontSize: 15,
								textAlign: "center",
							}}
						>
							<b>Fin</b>
							<br />
							{FechaRevisionFinal?.toDate().toLocaleDateString() ??
								"-"}
						</Typography>

						<Typography
							style={{
								fontSize: 15,
								textAlign: "center",
							}}
						>
							<b>Aporte</b>
							<br />
							{percentage}%
						</Typography>
					</Paper>
				</AccordionSummary>

				<AccordionDetails
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					<Box
						display="grid"
						marginLeft="1.30rem"
						gridTemplateColumns="1fr 1fr"
						paddingRight={20}
						paddingLeft={20}
						gridGap={10}
					>
						<Typography style={{ fontSize: 15 }}>
							<b>Responsable</b>
						</Typography>
						<Typography style={{ fontSize: 15 }}>
							: {responsibleName ?? "Cargando..."}
						</Typography>

						<Typography style={{ fontSize: 15 }}>
							<b>Cantidad</b>
						</Typography>
						<Typography style={{ fontSize: 15 }}>
							: {Unidades}{" "}
							{selected.checklist?.Unidad ?? "Unidades"}
						</Typography>

						{Tipo === "Obra Gruesa" && (
							<>
								<Typography style={{ fontSize: 15 }}>
									<b>Elemento</b>
								</Typography>
								<Typography style={{ fontSize: 15 }}>
									: {Elemento}
								</Typography>

								<Typography style={{ fontSize: 15 }}>
									<b>Ubicación</b>
								</Typography>
								<Typography style={{ fontSize: 15 }}>
									: {Ubicacion}
								</Typography>

								<Typography style={{ fontSize: 15 }}>
									<b>Plano revisor</b>
								</Typography>
								<Typography style={{ fontSize: 15 }}>
									: {PlanoRevisor}
								</Typography>
							</>
						)}

						{Tipo === "Terminaciones" && (
							<>
								<Typography style={{ fontSize: 15 }}>
									<b>Sector</b>
								</Typography>
								<Typography style={{ fontSize: 15 }}>
									: {Sector}
								</Typography>

								<Typography style={{ fontSize: 15 }}>
									<b>Recinto</b>
								</Typography>
								<Typography style={{ fontSize: 15 }}>
									: {Recinto}
								</Typography>

								<Typography style={{ fontSize: 15 }}>
									<b>Pasillo</b>
								</Typography>
								<Typography style={{ fontSize: 15 }}>
									: {Pasillo}
								</Typography>

								<Typography style={{ fontSize: 15 }}>
									<b>Otro</b>
								</Typography>
								<Typography style={{ fontSize: 15 }}>
									: {Otro}
								</Typography>
							</>
						)}
					</Box>

					<Box
						style={{
							display: "flex",
							flexDirection: "row",
							gap: 2,
						}}
					>
						<Button
							style={{ flexGrow: 1 }}
							size="large"
							variant="contained"
							color="primary"
							onClick={() => setDialog(1)}
						>
							Ver respuestas
						</Button>

						<DropdownMenu
							Opener={(props) => (
								<IconButton
									color="primary"
									{...props}
									disabled={loading !== 0}
								>
									{loading !== 0 ? (
										<CircularProgress size={24} />
									) : (
										<Description />
									)}
								</IconButton>
							)}
							options={[
								{
									label: "Eliminar",
									icon: <Trash2 size={24} color="red" />,
									onClick: handleDelete,
								},
								{
									disabled: loading !== 0,
									label: "Resumen fotográfico",
									icon:
										loading === 1 ? (
											<CircularProgress size={24} />
										) : (
											<PictureAsPdfTwoTone
												htmlColor={
													loading !== 0 ? "darkgray" : "red"
												}
											/>
										),
									onClick: handleImageSummaryClick,
								},
								{
									disabled: loading !== 0,
									label: "Respuestas y firmas",
									icon:
										loading === 2 ? (
											<CircularProgress size={24} />
										) : (
											<PictureAsPdfTwoTone
												htmlColor={
													loading !== 0 ? "darkgray" : "red"
												}
											/>
										),
									onClick: () =>
										handleSignatureSummaryClick(),
								},
							]}
						/>
					</Box>
				</AccordionDetails>
			</Accordion>

			<SheetCategoryDialogOT
				position={_position ?? 1}
				fullScreen
				open={dialog === 1}
				onClose={() => setDialog(0)}
				groupedByCategory={groupedByCategory}
				reviewerNames={reviewers}
			/>

			<RemoveSheetDialogOT
				open={dialog === 2}
				onClose={() => setDialog(0)}
			/>
		</>
	);
};
