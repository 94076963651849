// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
	DEFAULT: "DEFAULT",
	DARK: "DARK",
	LIGHT: "LIGHT",
	BLUE: "BLUE",
	GREEN: "GREEN",
	INDIGO: "INDIGO",
};
export const SNACK_DURATION = 3000;
export const VERSION = "v3.9.84";
export const TABLE_LIMITS = [20, 50, 80, 110, 140];
export const TABLE_LIMIT_DEFAULT = TABLE_LIMITS[2];
//Redux

//UI
export const UI_SNACK_STATE_CHANGE =
	"[UI] Snack state change";
export const UI_SNACK_OPEN = "[UI] Snack open change";
export const UI_SNACK_TEXT_CHANGE =
	"[UI] Snack text change";

export const UI_SNACK_STATE_CHANGE_LOGIN =
	"[UI] Snack state change login";
export const UI_SNACK_OPEN_LOGIN =
	"[UI] Snack open change login";
export const UI_SNACK_TEXT_CHANGE_LOGIN =
	"[UI] Snack text change login";

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST =
	"AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS =
	"AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE =
	"AUTH_RESET_PASSWORD_FAILURE";

// Empresa
export const BUSINESS_GET_DOCS = "[Business] Get Docs";
export const BUSINESS_ADD_DOCS = "[Business] Add Docs";
export const BUSINESS_GET_CHARGES =
	"[Business] Get Charges";
export const BUSINESS_SET_LAST_DOC =
	"[Business] Set last Doc";
export const BUSINESS_SET_TOTAL_DOCS =
	"[Business] Set Total Docs";
export const BUSINESS_LOADING = "[Business] Is Loading";
export const BUSINESS_LOADING_CHARGES =
	"[Business] Is Charges Loading";
export const BUSINESS_LOADING_SECTORS =
	"[Business] Is Sectors Loading";
export const BUSINESS_LOADING_PLACES =
	"[Business] Is Places Loading";
export const BUSINESS_LOADING_SUBCONTRACTS =
	"[Business] Is SubContracts Loading";
export const BUSINESS_LOADING_TYPE_ERRORS =
	"[Business] Is Type Errors Loading";
export const BUSINESS_LOADING_PRIORITIES =
	"[Business] Is Priorities Loading";
export const BUSINESS_UPDATE_LOADING =
	"[Business] Is update loading";
export const BUSINESS_UPDATE_SELECTED =
	"[Business] Update selected business";
export const BUSINESS_FAILURE = "[Business] Failure";
export const BUSINESS_SET_SELECTED =
	"[Business] Set Selected business";
export const BUSINESS_EDIT_LOADING =
	"[Business] Edit is loading";
export const BUSINESS_FILTER_ACTIVE =
	"[Business] Filter active change";
export const BUSINESS_RESTORE_SUBMITTING =
	"[Business] Business restore active is submitting";
export const BUSINESS_RESTORE_SUCCESS =
	"[Business] Business restore active success";
export const BUSINESS_RESTORE_FAILURE =
	"[Business] Business restore active failure";
export const BUSINESS_DELETE_ON_REQUEST =
	"[Business] Delete business on request";
export const BUSINESS_DELETE_SUCCESS =
	"[Business] Delete business success";
export const BUSINESS_DELETE_FAILURE =
	"[Business] Delete business failure";

// Usuarios
export const USERS_GET_DOCS = "[Users] Set Docs";
export const USERS_ADD_DOCS = "[Users] Add Docs";
export const USERS_SET_LAST_DOC = "[Users] Set last Doc";
export const USERS_SET_TOTAL_DOCS =
	"[Users] Set Total Docs";
export const USERS_LOADING = "[Users] Is Loading";
export const USERS_FAILURE = "[Users] Failure";
export const USERS_SUCCESS = "[Users] Success";
export const USERS_EDIT_LOADING = "[Users] Edit is loading";
export const USERS_SET_SELECTED = "[Users] Set selected";
export const USERS_UPDATE_DOC = "[Users] Update doc";
export const USERS_UPDATE_SELECTED_USER =
	"[Users] Update selected doc";
export const USERS_REMOVE_DOC = "[Users] Delete doc";
export const USERS_TABLES_UPDATE_SUBMITTING =
	"[Users] Business tables update is submitting";
export const USERS_TABLES_UPDATE_SUCCESS =
	"[Users] Business tables update success";
export const USERS_TABLES_UPDATE_FAILURE =
	"[Users] Business tables update failure";

// Cargos
export const CHARGES_GET_DOCS = "[Charges] Get Docs";
export const CHARGES_ADD_DOC = "[Charges] Add Docs";
export const CHARGES_UPDATE_DOC = "[Priorities] Update Doc";
export const CHARGES_REMOVE_DOC = "[Priorities] Remove Doc";
export const CHARGES_LOADING = "[Charges] Is Loading";
export const CHARGES_FAILURE = "[Charges] Failure";

// Proyectos
export const PROJECTS_GET_DOCS = "[Projects] Get Docs";
export const PROJECTS_USER_GET_DOCS =
	"[Projects User] Get Docs";
export const PROJECTS_UPDATE_DOC = "[Projects] Update Doc";
export const PROJECTS_UPDATE_SELECTED =
	"[Projects] Update selected";
export const PROJECTS_ADD_DOC_LOADING =
	"[Projects] Add Doc is loading";
export const PROJECTS_REMOVE_DOC = "[Projects] Delete Doc";
export const PROJECTS_SELECTED_DOC =
	"[Projects] Set selected project Doc";
export const PROJECTS_ADD_DOCS = "[Projects] Add Docs";
export const PROJECTS_SET_LAST_DOC =
	"[Projects] Set last Doc";
export const PROJECTS_SET_TOTAL_DOCS =
	"[Projects] Set Total Docs";
export const PROJECTS_LOADING = "[Projects] Is Loading";
export const PROJECTS_EDIT_LOADING =
	"[Projects] Edit is Loading";
export const PROJECTS_FAILURE = "[Projects] Failure";
export const PROJECTS_SUCCESS = "[Projects] Success";
export const PROJECTS_GET_USERS = "[Projects] Get Users";
export const PROJECTS_USERS_LOADING =
	"[Projects] Users is Loading";
export const PROJECTS_GET_BUSINESS =
	"[Projects] Get Businesses";
export const PROJECTS_BUSINESS_LOADING =
	"[Projects] Business is Loading";
export const PROJECTS_UPDATE_LOADING =
	"[Projects] Update is Loading";
export const PROJECTS_REVISIONS_FAILURE =
	"[Projects] Failure";
export const PROJECTS_REVISIONS_lOADING =
	"[Projects] Failure";
export const PROJECTS_HIDE_CHANGE =
	"[Projects] Hide deleted projects change";
export const PROJECTS_RESTORE_SUBMITTING =
	"[Projects] Projects restore active is submitting";
export const PROJECTS_RESTORE_SUCCESS =
	"[Projects] Projects restore active success";
export const PROJECTS_RESTORE_FAILURE =
	"[Projects] Projects restore active failure";

export const PROJECT_GET_ONE_SUBMITTING =
	"[Project] getting one is submitting";
export const PROJECT_GET_ONE_SUCCESS =
	"[Project] getting one success";
export const PROJECT_GET_ONE_FAILURE =
	"[Project] getting one failure";
export const PROJECTS_GET_ALL_BUSINESS_SUBMITTING =
	"[Project] Get all projects from one business request";
export const PROJECTS_GET_ALL_BUSINESS_SUCCESS =
	"[Project] Get all projects from one business success";
export const PROJECTS_GET_ALL_BUSINESS_FAILURE =
	"[Project] Get all projects from one business failure";

// PROJECTS TYPES CHECKLIST
export const CHECKLISTS_GET_SUBMITTING =
	"[Checklists/get] Projects checklist get submitting";
export const CHECKLISTS_GET_SUCCESS =
	"[Checklists/get] Projects checklist get success";
export const CHECKLISTS_GET_FAILURE =
	"[Checklist/get] Projects checklist get failure";

export const PROJECT_USER_IN_SET_STATE =
	"[Project] Set state getting projects user is in";

// Sectores
export const SECTORS_GET_DOCS = "[Sectors] Get Docs";
export const SECTORS_UPDATE_DOC = "[Sectors] Update Doc";
export const SECTORS_REMOVE_DOC = "[Sectors] Remove Doc";
export const SECTORS_GET_BUSINESS =
	"[Sectors] Get Docs of business";
export const SECTORS_ADD_DOCS = "[Sectors] Add Docs";
export const SECTORS_SET_LAST_DOC =
	"[Sectors] Set last Doc";
export const SECTORS_SET_TOTAL_DOCS =
	"[Sectors] Set Total Docs";
export const SECTORS_LOADING = "[Sectors] Is Loading";
export const SECTORS_FAILURE = "[Sectors] Failure";
export const SECTORS_SUCCESS = "[Sectors] Success";
export const SECTORS_IS_DIALOG_OPEN =
	"[Sectors] Is Dialog open";
export const SECTORS_IS_DIALOG_LOADING =
	"[Sectors] Is Dialog loading";
export const SECTORS_CHANGE_DIALOG_ACTION =
	"[Sectors] Change Dialog Action";
export const SECTORS_SET_SELECTED_SECTOR =
	"[Sectors] Set selected sector";

// Recintos
export const PLACES_GET_DOCS = "[Places] Get Docs";
export const PLACES_UPDATE_DOC = "[Places] Update Doc";
export const PLACES_REMOVE_DOC = "[Places] Remove Doc";
export const PLACES_GET_BUSINESS =
	"[Places] Get Docs of business";
export const PLACES_ADD_DOCS = "[Places] Add Docs";
export const PLACES_SET_LAST_DOC = "[Places] Set last Doc";
export const PLACES_SET_TOTAL_DOCS =
	"[Places] Set Total Docs";
export const PLACES_LOADING = "[Places] Is Loading";
export const PLACES_FAILURE = "[Places] Failure";
export const PLACES_SUCCESS = "[Places] Success";
export const PLACES_IS_DIALOG_OPEN =
	"[Places] Is Dialog open";
export const PLACES_IS_DIALOG_LOADING =
	"[Places] Is Dialog loading";
export const PLACES_CHANGE_DIALOG_ACTION =
	"[Places] Change Dialog Action";
export const PLACES_SET_SELECTED_SECTOR =
	"[Places] Set selected sector";

// Sub Contratos
export const SUBCONTRACT_GET_DOCS =
	"[SubContract] Get Docs";
export const SUBCONTRACT_UPDATE_DOC =
	"[SubContract] Update Doc";
export const SUBCONTRACT_REMOVE_DOC =
	"[SubContract] Remove Doc";
export const SUBCONTRACT_ADD_DOCS =
	"[SubContract] Add Docs";
export const SUBCONTRACT_SET_LAST_DOC =
	"[SubContract] Set last Doc";
export const SUBCONTRACT_SET_TOTAL_DOCS =
	"[SubContract] Set Total Docs";
export const SUBCONTRACT_LOADING =
	"[SubContract] Is Loading";
export const SUBCONTRACT_FAILURE = "[SubContract] Failure";
export const SUBCONTRACT_IS_DIALOG_OPEN =
	"[SubContract] Is Dialog open";
export const SUBCONTRACT_IS_DIALOG_LOADING =
	"[SubContract] Is Dialog loading";
export const SUBCONTRACT_CHANGE_DIALOG_ACTION =
	"[SubContract] Change Dialog Action";
export const SUBCONTRACT_SET_SELECTED =
	"[SubContract] Set selected";
export const SUBCONTRACT_UPDATE_SELECTED =
	"[SubContract] Update selected";
export const SUBCONTRACT_UPDATE_LOADING =
	"[SubContract] Update isLoading";
export const SUBCONTRACT_TYPE_ERROR_LOADING =
	"[SubContract] Type Error is Loading";

// Priorities
export const PRIORITIES_GET_DOCS = "[Priorities] Get Docs";
export const PRIORITIES_UPDATE_DOC =
	"[Priorities] Update Doc";
export const PRIORITIES_REMOVE_DOC =
	"[Priorities] Remove Doc";
export const PRIORITIES_LOADING = "[Priorities] Is Loading";
export const PRIORITIES_FAILURE = "[Priorities] Failure";

// Revisiones
export const REVISIONS_GET_DOCS = "[Revisions] Get Docs";
export const REVISIONS_ADD_DOCS = "[Revisions] Add Docs";
export const REVISIONS_SET_SELECTED =
	"[Revisions] Set selected";
export const REVISIONS_UPDATE_DOC =
	"[Revisions] Update Doc";
export const REVISIONS_REMOVE_DOC =
	"[Revisions] Remove Doc";
export const REVISIONS_LOADING = "[Revisions] Is Loading";
export const REVISIONS_UPDATE_LOADING =
	"[Revisions] Is Update Loading";
export const REVISIONS_FAILURE = "[Revisions] Failure";
export const REVISIONS_SET_LAST_DOC =
	"[Revisions] Set last Doc";
export const REVISIONS_SET_TOTAL_DOCS =
	"[Revisions] Set Total Docs";
export const GET_ONE_REVISION_SUCCESS =
	"[Revisions] get one success";
export const GET_ONE_REVISION_SUBMITTING =
	"[Revisions] get one submitting";
export const GET_ONE_REVISION_FAILURE =
	"[Revisions] get one failure";

// Listados
export const LISTS_GET_DOCS = "[Lists] Get Docs";
export const LISTS_ADD_DOCS = "[Lists] Add Docs";
export const LISTS_SET_SELECTED = "[List] Set selected";
export const LISTS_UPDATE_DOC = "[Lists] Update Doc";
export const LISTS_REMOVE_DOC = "[Lists] Remove Doc";
export const LISTS_LOADING = "[Lists] Is Loading";
export const LISTS_UPDATE_LOADING =
	"[Lists] Is Update Loading";
export const LISTS_FAILURE = "[Lists] Failure";
export const LISTS_SET_LAST_DOC = "[Lists] Set last Doc";
export const LISTS_SET_TOTAL_DOCS =
	"[Lists] Set Total Docs";
export const LISTS_ORDER_BY_SET =
	"[Lists] Set order by query";
export const GET_ONE_LIST_FAILURE =
	"[Revisions] get one failure";
export const GET_ONE_LIST_SUBMITTING =
	"[Revisions] get one submitting";
export const GET_ONE_LIST_SUCCESS =
	"[Revisions] get one success";

// Observaciones
export const OBSERVATIONS_GET_DOCS =
	"[Observations] Get Docs";
export const OBSERVATIONS_ADD_DOCS =
	"[Observations] Add Docs";
export const OBSERVATIONS_SET_SELECTED =
	"[Observations] Set selected";
export const OBSERVATIONS_UPDATE_DOC =
	"[Observations] Update Doc";
export const OBSERVATIONS_REMOVE_DOC =
	"[Observations] Remove Doc";
export const OBSERVATIONS_LOADING =
	"[Observations] Is Loading";
export const OBSERVATIONS_UPDATE_LOADING =
	"[Observations] Is Update Loading";
export const OBSERVATIONS_FAILURE =
	"[Observations] Failure";
export const OBSERVATIONS_SET_LAST_DOC =
	"[Observations] Set last Doc";
export const OBSERVATIONS_SET_TOTAL_DOCS =
	"[Observations] Set Total Docs";
export const IMAGES_GET_DOC =
	"[Observations] Get Images Docs";
export const OBSERVATION_UPDATE_IS_SUBMITTING =
	"[Observations] Update is submitting";
export const OBSERVATION_UPDATE_SUCCESS =
	"[Observations] Update success";
export const OBSERVATION_UPDATE_FAILURE =
	"[Observations] Update failure";

export const OBSERVATIONS_SET_ALL_DOCUMENTS_DATA =
	"[Observations] Set list of all documents data";

//checklist
export const CHECKLIST_GET_DOCS = "[Checklist] Get Docs";
export const CHECKLIST_UPDATE_DOC =
	"[Checklist] Update Doc";
export const CHECKLIST_REMOVE_DOC =
	"[Checklist] Remove Doc";
export const CHECKLIST_GET_BUSINESS =
	"[Checklist] Get Docs of business";
export const CHECKLIST_ADD_DOCS = "[Checklist] Add Docs";
export const CHECKLIST_SET_LAST_DOC =
	"[Checklist] Set last Doc";
export const CHECKLIST_SET_TOTAL_DOCS =
	"[Checklist] Set Total Docs";
export const CHECKLIST_LOADING = "[Checklist] Is Loading";
export const CHECKLIST_FAILURE = "[Checklist] Failure";
export const CHECKLIST_SUCCESS = "[Checklist] Success";
export const CHECKLIST_IS_DIALOG_OPEN =
	"[Checklist] Is Dialog open";
export const CHECKLIST_IS_DIALOG_LOADING =
	"[Checklist] Is Dialog loading";
export const CHECKLIST_CHANGE_DIALOG_ACTION =
	"[Checklist] Change Dialog Action";
export const CHECKLIST_SET_SELECTED =
	"[Checklist] Set selected";
// nuevos types checklist Ramón
export const CHECKLIST_GET_SUBMITTING =
	"[Checklist] Get submitting";
export const CHECKLIST_GET_SUCCESS =
	"[Checklist] Get success";
export const CHECKLIST_GET_FAILURE =
	"[Checklist] Get failure";
export const CHECKLIST_UPDATE_ONE_SUBMITTING =
	"[Checklist] Update one Submitting";
export const CHECKLIST_UPDATE_ONE_FAILURE =
	"[Checklist] Update one failure";
export const CHECKLIST_UPDATE_ONE_SUCCESS =
	"[Checklist] Update one success";
export const CHECKLIST_REMOVE_SUBMITTING =
	"[Checklist] Remove submitting";
export const CHECKLIST_REMOVE_SUCCESS =
	"[Checklist] Remove success";
export const CHECKLIST_REMOVE_FAILURE =
	"[Checklist] Remove success";
export const CHECKLIST_GET_ONE_SUBMITTING =
	"[Checklist] Get one submitting";
export const CHECKLIST_GET_ONE_SUCCESS =
	"[Checklist] Get one success";
export const CHECKLIST_GET_ONE_FAILURE =
	"[Checklist] Get one failure";
export const CHECKLIST_EDIT_SUBMMITING =
	"[Checklist] edit submmiting";
export const CHECKLIST_EDIT_SUCCES =
	"[Checklist] edit success";
export const CHECKLIST_EDIT_FAILURE =
	"[Checklist] edit failure";
// recintos 2 ramon
export const RECINTOS_SET_LAST_DOC =
	"[Recintos] Set last doc";
export const RECINTOS_FAILURE = "[Recintos]  Failure";
export const RECINTOS_GET_DOCS = "[Recintos] Get docs";
export const RECINTOS_GET_SUBMMITING =
	"[Recintos] Get submiting";
export const RECINTOS_GET_SUCCESS =
	"[Recintos] Get success";
export const RECINTOS_GET_FAILURE =
	"[Recintos] Get failure";
export const SET_SELECTED_RECINTO =
	"[Recintos] Set one selected";
export const RECINTO_ADD_SUCCESS =
	"[Recintos] Recinto add success";
export const RECINTO_ADD_SUBMMITING =
	"[Recintos] Recinto add submmiting";
export const RECINTO_ADD_FAILURE =
	"[Recintos] Recinto add failure";
export const RECINTO_REMOVE_SUCCESS =
	"[Recintos] Recinto remove success";
export const RECINTO_REMOVE_FAILURE =
	"[Recintos] Recinto remove failure";
export const RECINTO_REMOVE_SUBMMITING =
	"[Recintos] Recinto remove sunmmiting";

// subcontratos
export const SUBCONTRATO_REMOVE_SUCCESS =
	"[Recintos] Recinto remove success";
export const SUBCONTRATO_REMOVE_FAILURE =
	"[Recintos] Recinto remove failure";
export const SUBCONTRATO_REMOVE_SUBMMITING =
	"[Recintos] Recinto remove submmiting";
export const SUBCONTRACTO_SET_SELECTED =
	"[Recintos] Set selected";
export const ERROR_TYPES_SET_SELECTED =
	"[Recintos] Error type set selected";
export const SUBCONTRATO_ADD_SUCCESS =
	"[Recintos] Subcontrato add success";
export const SUBCONTRATO_ADD_FAILURE =
	"[Recintos] Subcontrato add failure";
export const SUBCONTRATO_ADD_SUBMMITING =
	"[Recintos] Subcontrato add submmiting";
export const SUBCONTRATO_GET_ONE_FAILURE =
	"[Recintos] Subcontrato get one failure";
export const SUBCONTRATO_GET_ONE_SUCCESS =
	"[Recintos] Subcontrato get one succes";
export const SUBCONTRATO_GET_ONE_SUBMMITING =
	"[Recintos] Subcontrato get one submmiting";
export const SUBCONTRATO_UPDATE_SUBMMITING =
	"[Recintos] Subcontrato update submmiting";
export const SUBCONTRATO_UPDATE_FAILURE =
	"[Recintos] Subcontrato update failure";
export const SUBCONTRATO_UPDATE_SUCCESS =
	"[Recintos] Subcontrato update success";
// Errores tipo
export const ERRORTIPO_ADD_SUCCESS =
	"[Recintos] Error tipo add success";
export const ERRORTIPO_ADD_SUBMMITING =
	"[Recintos] Error tipo add submmiting";
export const ERRORTIPO_ADD_FAILURE =
	"[Recintos] Error tipo add failure";
export const ERRORTIPO_DELETED_FAILURE =
	"[Recintos] Error tipo deleted failure";
export const ERRORTIPO_DELETED_SUCCESS =
	"[Recintos] Error tipo deleted success";
export const ERRORTIPO_DELETED_SUBMITING =
	"[Recintos] Error tipo deleted submmiting";
// add checklist to list (new module)
export const ADD_CHECKLIST_TO_LIST_SUCCESS =
	"[Listados] Add checklist to list success";
export const ADD_CHECKLIST_TO_LIST_SUBMITTING =
	"[Listados] Add checklist to list submmiting";
export const ADD_CHECKLIST_TO_LIST_FAILURE =
	"[Listados] Add checklist to list failure";
export const CHECKLIST_PROJECT_SET_SELECTED =
	"[Listados] Project checklist Set selected ";
// GET recintos project
export const RECINTOS_PROJECT_SUCCESS =
	"[Listados] recintos project success";
export const RECINTOS_PROJECT_FAILURE =
	"[Listados] recintos project failure";
export const RECINTOS_PROJECT_SUBMITTING =
	"[Listados] recintos project submmiting";
export const RECINTO_SET_SELECTED =
	"[Listados] recinto set selected ";

// GET SUBCONTRATOS PROJECT
export const SUBCONTRATOS_PROJECT_SUBMITTING =
	"[Listados] sub contratos project submmiting";
export const SUBCONTRATOS_PROJECT_SUCCESS =
	"[Listados] sub contratos project success";
export const SUBCONTRATOS_PROJECT_FAILURE =
	"[Listados] sub contratos project failure";
export const SUBCONTRATO_PROJECT_SET_SELECTED =
	"[Listados] sub contrato set selected ";
export const GET_ONE_SUBCONTRATO_PROJECT_FAILURE =
	"[Subcontrato]  Get one sub contrato project failure";
export const GET_ONE_SUBCONTRATO_PROJECT_SUCCESS =
	"[Subcontrato]  Get one sub contrato project success";
export const GET_ONE_SUBCONTRATO_PROJECT_SUBMITTING =
	"[Subcontrato]  Get one sub contrato project submmiting";

// get one checklist project(recinto)
export const CHECKLIST_PROJECT_GET_ONE_SUCCESS =
	"[Listados] recintos project get one success";
export const CHECKLIST_PROJECT_GET_ONE_SUBMITTING =
	"[Listados] recintos project get one submmiting";
export const CHECKLIST_PROJECT_GET_ONE_FAILURE =
	"[Listados] recintos project get one failure";
// get one checklist project(sub contrato)
export const SUBCONTRATO_PROJECT_GET_ONE_SUCCESS =
	"[Listados] sub contrato project get one success";
export const SUBCONTRATO_PROJECT_GET_ONE_SUBMMITING =
	"[Listados] sub contrato project get one submmiting";
export const SUBCONTRATO_PROJECT_GET_ONE_FAILURE =
	"[Listados] sub contrato project get one failure";

// get Errores tipo
export const ERROR_TIPO_PROJECT_GET_FAILURE =
	"[Listados] Errors tipo project get failure";
export const ERROR_TIPO_PROJECT_GET_SUBMITTING =
	"[Listados] Errors tipo project get submmiting";
export const ERROR_TIPO_PROJECT_GET_SUCCESS =
	"[Listados] Errors tipo project get success";
export const ERROR_TIPO_PROJECT_SET_SELECTED =
	"[Listados] Checklist Errors tipo project set selected";
export const ERROR_TIPO_ANSWERS_SUCCESS =
	"[ERROR TIPO]  Errors tipo answers success";
export const ERROR_TIPO_ANSWERS_FAILURE =
	"[ERROR TIPO]  Errors tipo answers failure";
export const ERROR_TIPO_ANSWERS_SUBMITTING =
	"[ERROR TIPO]  Errors tipo answers submmiting";
export const ERROR_TIPO_SET_SELECTED =
	"[ERROR TIPO]  Errors tipo set selected";
export const UPDATE_ANSWERS_ERROR_TIPO_SUCCESS =
	"[ANSWERS]  update answers error tipo success";
export const UPDATE_ANSWERS_ERROR_TIPO_FAILURE =
	"[ANSWERS]  update answers error tipo failure";
export const UPDATE_ANSWERS_ERROR_TIPO_SUBMITTING =
	"[ANSWERS]  update answers error tipo submmiting";
export const ANSWERS_SET_SELECTED =
	"[ANSWERS]  Answers Errors tipo set selected";
export const GET_ONE_ANSWERS_SUCCESS =
	"[ANSWERS]  Get one answers success";
export const GET_ONE_ANSWERS_FAILURE =
	"[ANSWERS]  Get one answers failure";
export const GET_ONE_ANSWERS_SUBMMITING =
	"[ANSWERS]  Get one answers submmiting";
export const LISTADO_ERROR_TIPO_PROJECT_SET_SELECTED =
	"[Listados] error tipo set selected ";
export const GET_ONE_ERROR_TIPO_SUCCESS =
	"[Errortipo]  Get one error tipo success";
export const GET_ONE_ERROR_TIPO_SUBMITTING =
	"[Errortipo]  Get one error tipo submmiting";
export const GET_ONE_ERROR_TIPO_FAILURE =
	"[Errortipo]  Get one error tipo failure";

// get subcontratos por empresa
export const SUBCONTRATOS_GET_FAILURE =
	"[Checklist] Subcontratos get failure";
export const SUBCONTRATOS_GET_SUCCESS =
	"[Checklist] Subcontratos get success";
export const SUBCONTRATOS_GET_SUBMMITING =
	"[Checklist] Subcontratos get submmting";
// Add checklist name to colunm list
export const CHECKLIST_NAME_UPDATE_SUCCESS =
	"[Projects] Checklist name Update success";
export const CHECKLIST_NAME_UPDATE_SUBMITTING =
	"[Projects] Checklist name Update submmiting";
export const CHECKLIST_NAME_UPDATE_FAILURE =
	"[Projects] Checklist name Update failure";
export const ERRORSTIPO_CHECKLIST_SET_SELECTED =
	"[Listados] Checklist Errors tipo set selected";
// get one recinto project
export const GET_ONE_RECINTO_PROJECT_SUBMITTING =
	"[Listado] get one recinto project submmiting";
export const GET_ONE_RECINTO_PROJECT_FAILURE =
	"[Listado] get one recinto project failure";
export const GET_ONE_RECINTO_PROJECT_SUCCESS =
	"[Listado] get one recinto project success";

export const GET_ALL_LIST_SUCCESS =
	"[lists] Gt all list to pdfs success";
export const GET_ALL_LIST_SUBMITTING =
	"[lists] Gt all list to pdfs submmiting";
export const GET_ALL_LIST_FAILURE =
	"[lists] Gt all list to pdfs failure";
export const PDF_LOADING = "[PDF] loading";

// Generacion de pdf
export const PDF_GENERATOR_GET_REVISIONS_INITIAL =
	"[PDF Generator] Get Revisions initial";
export const PDF_GENERATOR_GET_REVISIONS_SUBMITTING =
	"[PDF Generator] Get Revisions Submitting";
export const PDF_GENERATOR_GET_REVISIONS_FAILURE =
	"[PDF Generator] Get Revisions Failure";
export const PDF_GENERATOR_GET_REVISIONS_SUCCESS =
	"[PDF Generator] Get Revisions Success";
export const PDF_GENERATOR_GET_LISTS_INITIAL =
	"[PDF Generator] Get Lists Initial";
export const PDF_GENERATOR_GET_LISTS_SUBMITTING =
	"[PDF Generator] Get Lists Submitting";
export const PDF_GENERATOR_GET_LISTS_FAILURE =
	"[PDF Generator] Get Lists Failure";
export const PDF_GENERATOR_GET_LISTS_SUCCESS =
	"[PDF Generator] Get Lists Success";
export const PDF_GENERATOR_INITIAL =
	"[PDF Generator] submitting";
export const PDF_GENERATOR_SUBMITTING =
	"[PDF Generator] submitting";
export const PDF_GENERATOR_FAILURE =
	"[PDF Generator] failure";
export const PDF_GENERATOR_SUCCESS =
	"[PDF Generator] success";

// Observaciones
export const OBSERVATIONS_CHECKLIST_INITIAL =
	"[Observations Checklist] Initial";
export const OBSERVATIONS_CHECKLIST_LOADING =
	"[Observations Checklist] Is Loading";
export const OBSERVATIONS_CHECKLIST_FAILURE =
	"[Observations Checklist] Failure";
export const OBSERVATIONS_CHECKLIST_SUCCESS =
	"[Observations Checklist] Success";
export const OBSERVATIONS_CHECKLIST_GENERATE_DOC_INITIAL =
	"[Observations Checklist] Generate Doc Initial";
export const OBSERVATIONS_CHECKLIST_GENERATE_DOC_LOADING =
	"[Observations Checklist] Generate Doc Is Loading";
export const OBSERVATIONS_CHECKLIST_GENERATE_DOC_FAILURE =
	"[Observations Checklist] Generate Doc Failure";
export const OBSERVATIONS_CHECKLIST_GENERATE_DOC_SUCCESS =
	"[Observations Checklist] Generate Doc Success";
