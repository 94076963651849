import {
	Box,
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Card as MuiCard,
	CardContent,
	Chip,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider as MuiDivider,
	Fade,
	Grid,
	Grow,
	IconButton,
	Link,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	blue,
	green,
	red,
	yellow,
} from "@material-ui/core/colors";
import {
	ExpandLess,
	ExpandMore,
	KeyboardArrowDown,
	KeyboardArrowUp,
} from "@material-ui/icons";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import MuiAlert, {
	AlertProps,
} from "@material-ui/lab/Alert";
import { spacing } from "@material-ui/system";
import { compareAsc } from "date-fns";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
	Edit as EditIcon,
	Minus as MinusIcon,
	MinusSquare as X,
	Search,
	X as CloseIcon,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
	NavLink,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import { TableNoContent } from "../components/TableNoContent";
import { TableSkeleton } from "../components/TableSkeleton";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../constants";
import { useTable } from "../hooks/useTable";
import { getBusiness } from "../redux/actions/businessActions";
import { getList } from "../redux/actions/listsActions";
import {
	getMoreObservations,
	getObservations,
	searchMoreObservations,
	searchObservations,
	updateEstadoObservacion,
	updateObservation,
} from "../redux/actions/observationsActions";
import { getPlacesList } from "../redux/actions/placesActions";
import { getOneProject } from "../redux/actions/projectsActions";
import { IAuthState } from "../redux/reducers/authReducer";
import { IBusinessState } from "../redux/reducers/businessReducer";
import { IListsState } from "../redux/reducers/listsReducer";
import { IObservationsState } from "../redux/reducers/observationsReducer";
import { IPlacesState } from "../redux/reducers/placesReducer";
import { IProjectsState } from "../redux/reducers/projectsReducer";
import { IRevisionsState } from "../redux/reducers/revisionsReducer";
import { RootState } from "../redux/store/reducer";
import { IUsersState } from "../redux/reducers/usersReducer";
import { useStyles } from "../theme/useStyles";
import Axios from "axios";
import { conseguirImagenObservacion } from "../services/observaciones-revision";
import {
	timesStampToDateFormatted,
	timesStampToDateFormattedDate,
} from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Alert = (props: AlertProps) => (
	<MuiAlert elevation={6} variant="filled" {...props} />
);

interface RowsProps {
	data: any;
	itemsOpen: ItemOpen[];
	setItemsOpen: React.Dispatch<
		React.SetStateAction<ItemOpen[]>
	>;
	openDialog: (value: string) => void;
	openEditDialog: (arg: any) => void;
}

const Rows: React.FC<RowsProps> = ({
	data,
	itemsOpen,
	setItemsOpen,
	openDialog,
	openEditDialog,
}) => {
	const classes = useStyles();

	//abrir dialogo al clickear imagen
	const handleClickImage = (img: string) => {
		openDialog(img);
	};
	// logica de open
	const open: boolean =
		itemsOpen.find((x) => x.id === data.id)?.open ?? false;

	const setOpen = (open: boolean) => {
		// const index = itemsOpen.findIndex((x) => x.id === data.id)
		setItemsOpen((itemsOpen) =>
			itemsOpen.map((x) => {
				if (x.id === data.id)
					return {
						...x,
						open,
					};
				return x;
			})
		);
	};

	// Fecha1 - Fecha2
	// 1 : Fecha1 > Fecha2
	// -1 : Fecha1 < Fecha2
	// 0 : Fecha1 === Fecha2
	// logica para render
	const porVencer = compareAsc(
		new Date(),
		data.PorVencer.toDate()
	);
	const vencida = compareAsc(
		new Date(),
		data.Vencida.toDate()
	);
	const resuelta = !!data.Resuelta;

	let status = "";
	let backgroundColor: string = "";
	let color: string = "white";
	if (resuelta) {
		status = "Resuelta";
		backgroundColor = green[500];
	} else if (vencida === 0 || vencida > 0) {
		status = "Vencida";
		backgroundColor = red[500];
	} else if (
		(porVencer === 0 || porVencer > 0) &&
		vencida < 0
	) {
		status = "Por Vencer";
		backgroundColor = yellow[500];
		color = "";
	} else {
		status = "Obervada";
		backgroundColor = blue[500];
	}

	const [imageData, setImageData] = useState<
		string | undefined
	>();
	// get image
	useEffect(() => {
		(async () => {
			const url = await conseguirImagenObservacion(data);
			setImageData(url);
		})();
	}, [data]);

	return (
		<React.Fragment>
			<TableRow hover className={classes.styledRow2}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? (
							<KeyboardArrowUp />
						) : (
							<KeyboardArrowDown />
						)}
					</IconButton>
				</TableCell>
				<TableCell>{data?.Numero}</TableCell>
				<TableCell>
					<Chip
						style={{ backgroundColor, color, width: 100 }}
						label={
							status === "Vencida" ? "Por resolver" : status
						}
					/>
				</TableCell>
				<TableCell>{data.Prioridad}</TableCell>
				<TableCell>
					{timesStampToDateFormatted(data.CreatedAt)}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={10}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Box display="flex" flexWrap="wrap">
								<Box order={1} mt={3.5}>
									<Typography variant="h5" component="div">
										Detalle
									</Typography>
								</Box>
								<Box order={2}>
									<IconButton
										color="secondary"
										onClick={() =>
											openEditDialog({
												comentario: data.Comentario,
												errorTipo: data.ErrorTipo,
												sector: data.Sector,
												subContrato: data.Subcontrato,
												id: data.id,
												Recinto: data.Recinto,
												Prioridad: data.Prioridad,
											})
										}
									>
										<EditIcon />
									</IconButton>
								</Box>
							</Box>

							<Typography
								variant="subtitle1"
								gutterBottom
								component="div"
							>
								{`${data.Recinto} - ${data.Subcontrato}${
									data.ErrorTipo
										? " - " + data.ErrorTipo
										: " - Sin error tipo"
								}`}
							</Typography>

							<Grid container spacing={2}>
								<Grid
									container
									item
									xs={12}
									sm={6}
									justify="center"
									direction="column"
								>
									{[
										{
											title: "Observada",
											value: data.CreatedAt
												? timesStampToDateFormatted(
														data.CreatedAt
												  )
												: " - ",
										},
										{
											title: "Por Vencer",
											value: timesStampToDateFormattedDate(
												data.PorVencer
											),
										},
										{
											title: "Por Resolver",
											value: timesStampToDateFormattedDate(
												data.Vencida
											),
										},
										{
											title: "Resuelta",
											value: data.Resuelta
												? timesStampToDateFormattedDate(
														data.Resuelta
												  )
												: " - ",
										},
										{
											title: "Sector",
											value: data.Sector,
										},
										{
											title: "Comentarios",
											value: data.Comentario,
										},
									].map((obj: any, index: number) => {
										return (
											<Box
												display="flex"
												flexWrap="wrap"
												key={index}
											>
												<Box
													order={1}
													style={{ width: 125 }}
												>
													<Typography
														component="div"
														variant="body1"
														style={{ fontWeight: "bold" }}
													>
														{obj.title}
													</Typography>
												</Box>
												<Box order={2}>
													<Typography
														component="div"
														variant="body1"
														style={{ fontWeight: "bold" }}
													>
														:
													</Typography>
												</Box>
												<Box order={3}>
													<Typography
														component="div"
														variant="body1"
													>
														&nbsp;{obj.value}
													</Typography>
												</Box>
											</Box>
										);
									})}
								</Grid>
								<Grid
									container
									item
									xs={12}
									sm={6}
									justify="center"
								>
									{/*/// sin imagen*/}
									<Grid item xs={6}>
										{imageData && (
											<Box
												display="flex"
												justifyContent="flex-start"
											>
												<Grow in={open} timeout={600}>
													<img
														onKeyDown={() => {}}
														src={imageData}
														alt="Imagen Observacion 1"
														height={120}
														onClick={() =>
															handleClickImage(imageData)
														}
														style={{ cursor: "pointer" }}
													/>
												</Grow>
											</Box>
										)}
									</Grid>
									{data.Image2 && (
										<Grid item xs={6}>
											<Box
												display="flex"
												justifyContent="flex-start"
											>
												<Grow
													in={open}
													{...(open
														? { timeout: 1000 }
														: {})}
												>
													<img
														onKeyDown={() => {}}
														src={`data:image/png;base64, ${data.Image2}`}
														alt="Imagen Observacion 2"
														height={120}
														onClick={() =>
															handleClickImage(data.Image2)
														}
														style={{ cursor: "pointer" }}
													/>
												</Grow>
											</Box>
											<Box
												display="flex"
												justifyContent="flex-start"
											>
												<Typography>Imagen 2</Typography>
											</Box>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};

interface ItemOpen {
	open: boolean;
	id: string;
}

interface Props {
	array: any[];
	allObservations: any[];
	isLoading: boolean;
	totalDocs: number;
	listData: any;
	selectedRevision: any;
}

const ContentCard: React.FC<Props> = ({
	array,
	allObservations,
	isLoading,
	totalDocs,
	selectedRevision,
}) => {
	const [valueWidth, setValueWidth] = React.useState(
		window.innerWidth
	);
	const widthBreakPoint = 1415;
	function updateDimensions() {
		setValueWidth(window.innerWidth);
	}
	window.addEventListener("resize", updateDimensions);
	const classes = useStyles();
	const dispatch = useDispatch();
	const { projectId, revisionId, listId } =
		useParams<any>();

	const {
		limit,
		handleLimitChange,
		handlePageChange,
		page,
		handleResetPage,
	} = useTable({
		limit: TABLE_LIMIT_DEFAULT || 5,
		onLimitChange: (e, newLimit) => {
			if (newLimit > array.length) {
				if (Object.keys(filtersList).length === 0) {
					dispatch(getObservations(listId));
				} else {
					dispatch(
						searchObservations(
							filtersList,
							projectId,
							revisionId,
							listId,
							newLimit
						)
					);
				}
			}
		},
		onPageChange: (newPage, oldPage, limit) => {
			if (array.length < totalDocs && newPage > oldPage) {
				if (Object.keys(filtersList).length === 0) {
					dispatch(
						getMoreObservations(
							projectId,
							revisionId,
							listId,
							limit
						)
					);
				} else {
					dispatch(
						searchMoreObservations(
							filtersList,
							projectId,
							revisionId,
							listId,
							limit
						)
					);
				}
			}
		},
	});

	// For Download Alert
	const [downloadAlert, setDownloadAlert] = useState(false);
	const [downloadMsg, setDownloadMsg] =
		useState("Descargando");

	const [pdfDescargando, setPdfDescargando] =
		useState(false);
	const [pdfDescargando2, setPdfDescargando2] =
		useState(false);

	//Array for excel download
	const newArray = array.map(
		({ Image, Image2, id, ...item }) => item
	);
	///////////////////////////
	const [thisObservations, setThisObservation] =
		useState<any>([]);

	const handleClose = (
		event?: React.SyntheticEvent,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}

		setDownloadAlert(false);
	};

	const [itemsOpen, setItemsOpen] = useState<ItemOpen[]>(
		[]
	);

	useEffect(() => {
		setItemsOpen(
			array.map((x) => ({
				open: false,
				id: x.id,
			}))
		);
	}, [array]);

	const [selectedImage, setSelectedImage] = useState<
		string | null
	>(null);
	const [selectedObs, setSelectedObs] = useState({
		id: "",
		comentario: "",
		errorTipo: "",
		sector: "",
		subContrato: "",
		Recinto: "",
		Prioridad: "",
	});
	const [openDialogImage, setOpenDialogImage] =
		useState(false);
	const [openDialogEdit, setOpenDialogEdit] =
		useState(false);
	const [openFilter, setOpenFilters] = useState(false);
	const [optionsErrorTipo, setOptionsErrorTipo] =
		React.useState<string[]>([]);

	const {
		edit: { selectedUser },
	} = useSelector<RootState, IUsersState>(
		(state) => state.usersReducer
	);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const { selectedList } = useSelector<
		RootState,
		IListsState
	>((state) => state.listsReducer);
	const { places } = useSelector<RootState, IPlacesState>(
		(state) => state.placesReducer
	);

	useEffect(() => {
		if (Object.keys(selectedList).length === 0) {
			dispatch(getList(projectId, revisionId, listId));
		}
	}, [dispatch, projectId, revisionId, listId]);

	// useEffect(() => {
	// 	if (selectedList.Responsable) {
	// 		// dispatch(getUser(selectedList.Responsable.FirebaseId));
	// 	}
	// }, [dispatch, selectedList]);

	useEffect(() => {
		dispatch(getPlacesList());
	}, []);

	useEffect(() => {
		setThisObservation(array);
	}, [array]);
	/*
  // Filter constants
  */
	const [filtersList, setValueFiltersList] =
		React.useState<any>({});

	const optionsEstados = [
		"Resuelta",
		"Por Resolver",
		"Por Vencer",
		"Observada",
	];
	const [valueRecinto, setValueRecinto] = React.useState<
		string | null
	>(null);
	const [valuePrioridad, setValuePrioridad] =
		React.useState<string | null>(null);
	const [valueEstado, setValueEstado] = React.useState<
		string | null
	>(null);
	const [valueSector, setValueSector] = React.useState<
		string | null
	>(null);
	const [valueSubcontrato, setValueSubcontrato] =
		React.useState<string | null>(null);
	const [valueErrorTipo, setValueErrorTipo] =
		React.useState<string | null>(null);
	const [optionsErroresTipo, setOptionsErroresTipo] =
		React.useState<string[]>([]);
	const [valueCreacionStart, setValueCreacionStart] =
		useState("");
	const [valueCreacionEnd, setValueCreacionEnd] =
		useState("");

	const [showObservations, setShowObservations] =
		React.useState<boolean>(true);
	/*
  //End filter constants
  */

	{
		///////////////////////////
		/*DIALOG EDIT FORM UTILS*/
	}
	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		setValues,
	} = useFormik({
		initialValues: {
			id: "",
			subcontrato: "",
			sector: "",
			errorTipo: "",
			comentario: "",
			recinto: "",
			prioridad: "",
		},
		onSubmit: (values) => {
			const index = thisObservations.findIndex(
				(x: any) => x.id === values.id
			);

			thisObservations[index] = {
				...array[index],
				Comentario: values.comentario,
				ErrorTipo: values.errorTipo,
				Prioridad: values.prioridad,
				Recinto: values.recinto,
				Sector: values.sector,
				Subcontrato: values.subcontrato,
			};
			dispatch(
				updateObservation(
					values,
					projectId,
					revisionId,
					listId
				)
			);
			setOpenDialogEdit(false);
		},
		validationSchema: yup.object({
			sector: yup.string().required("Sector es requerido"),
		}),
	});

	useEffect(() => {
		if (selectedObs) {
			setValues({
				id: selectedObs?.id,
				subcontrato: selectedObs?.subContrato,
				sector: selectedObs?.sector,
				errorTipo: selectedObs?.errorTipo,
				comentario: selectedObs?.comentario,
				recinto: selectedObs?.Recinto,
				prioridad: selectedObs?.Prioridad,
			});
		}
	}, [setValues, selectedObs]);

	const handleFilterChange = () => {
		handleResetPage();
		if (Object.keys(filtersList).length !== 0) {
			dispatch(
				searchObservations(
					filtersList,
					projectId,
					revisionId,
					listId
				)
			);
		}
	};

	const handlePdfDownload = async (
		ordenarPorSubcontrato: boolean,
		idResponsable: string,
		toLoad: number
	) => {
		toLoad === 1
			? setPdfDescargando(true)
			: setPdfDescargando2(true);

		try {
			const response = await Axios.post(
				"https://us-central1-checkpro-3a90a.cloudfunctions.net/generateLegacyProjectSummary",
				{
					textoFooter:
						selectedBusiness?.PiePagina || "CheckPro",
					listadoId: listId,
					userId: idResponsable,
					orderBy: ordenarPorSubcontrato
						? "subcontrato"
						: "recinto",
					// ordenarPorSubcontrato,
				}
			);

			if (!response.data) throw Error("No data");

			setTimeout(() => {
				window.open(response.data.url, "_blank");
				toLoad === 1
					? setPdfDescargando(false)
					: setPdfDescargando2(false);
			}, 3000);
		} catch (error: any) {
			console.error(error);
			alert("Ocurrió un error.");
			toLoad === 1
				? setPdfDescargando(false)
				: setPdfDescargando2(false);
		}
	};

	useEffect(() => {
		if (Object.keys(filtersList).length === 0) {
			handleResetPage();
			dispatch(getObservations(listId));
		}
	}, [Object.keys(filtersList).length]);

	return (
		<>
			<Card mb={6}>
				{/*
      // Start Filters
      */}
				<CardContent>
					<Grid container justify="space-between">
						<Box mb={2}>
							<Button
								size="large"
								onClick={() => setOpenFilters(!openFilter)}
							>
								Filtros{" "}
								{openFilter ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)}
							</Button>
						</Box>
					</Grid>
					<Collapse
						in={openFilter}
						timeout="auto"
						unmountOnExit
					>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={2}>
								<Autocomplete
									id="sectores-filtro"
									value={valueSector as any}
									options={selectedBusiness?.Sectores || []}
									getOptionLabel={(option: any) =>
										option.Nombre
									}
									filterOptions={(option: any) =>
										selectedBusiness!.Sectores.filter(
											(x: any) => x.Activado
										)
									}
									getOptionDisabled={(option: any) =>
										!option.Activado
									}
									fullWidth
									onChange={(
										event: any,
										newValue: any | null
									) => {
										setValueSector(newValue);
										if (newValue) {
											filtersList["Sector"] =
												newValue.Nombre;
											handleFilterChange();
										} else {
											delete filtersList["Sector"];
											handleFilterChange();
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Todos sectores"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<Autocomplete
									id="recintos-filtro"
									value={valueRecinto as any}
									options={selectedBusiness?.Recintos || []}
									getOptionLabel={(option: any) =>
										option.Nombre
									}
									filterOptions={(option: any) =>
										selectedBusiness?.Recintos.filter(
											(x: any) => x.Activado
										) as any
									}
									getOptionDisabled={(option: any) =>
										!option.Activado
									}
									fullWidth
									onChange={(
										event: any,
										newValue: any | null
									) => {
										setValueRecinto(newValue);
										if (newValue) {
											filtersList["Recinto"] =
												newValue.Nombre;
											handleFilterChange();
										} else {
											delete filtersList["Recinto"];
											handleFilterChange();
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Todos recintos"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<Autocomplete
									id="subcontratos-filtro"
									value={valueSubcontrato as any}
									options={
										selectedBusiness?.Subcontratos as any
									}
									getOptionLabel={(option: any) =>
										option.Nombre
									}
									filterOptions={(option: any) =>
										selectedBusiness?.Subcontratos.filter(
											(x: any) => x.Activado
										) as any
									}
									getOptionDisabled={(option: any) =>
										!option.Activado
									}
									fullWidth
									onChange={(
										event: any,
										newValue: any | null
									) => {
										setValueSubcontrato(newValue);

										if (newValue) {
											filtersList["Subcontrato"] =
												newValue.Nombre;
											setOptionsErroresTipo(
												newValue.ErroresTipo
											);
											handleFilterChange();
										} else {
											delete filtersList["Subcontrato"];
											setOptionsErroresTipo([]);
											handleFilterChange();
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Todos subcontrato"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<Autocomplete
									id="errotipo-filtro"
									value={valueErrorTipo}
									options={optionsErroresTipo}
									getOptionLabel={(option: any) =>
										option.Nombre
									}
									fullWidth
									onChange={(
										event: any,
										newValue: any | null
									) => {
										setValueErrorTipo(newValue);
										if (newValue) {
											filtersList["ErrorTipo"] =
												newValue.Nombre;
											handleFilterChange();
										} else {
											delete filtersList["ErrorTipo"];
											handleFilterChange();
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Todos error Tipo"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<Autocomplete
									id="prioridad-filtro"
									value={valuePrioridad as any}
									options={
										selectedBusiness?.Prioridades || []
									}
									getOptionLabel={(option: any) =>
										option.Nombre as any
									}
									filterOptions={(option: any) =>
										selectedBusiness?.Prioridades.filter(
											(x: any) => x.Activado
										) as any
									}
									getOptionDisabled={(option: any) =>
										!option.Activado
									}
									fullWidth
									onChange={(
										event: any,
										newValue: any | null
									) => {
										setValuePrioridad(newValue);
										if (newValue) {
											filtersList["Prioridad"] =
												newValue.Nombre;
											handleFilterChange();
										} else {
											delete filtersList["Prioridad"];
											handleFilterChange();
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Todos prioridades"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<Autocomplete
									id="estado-filtro"
									value={valueEstado}
									options={optionsEstados}
									fullWidth
									getOptionLabel={(option) => {
										return option === "Vencida"
											? "Por Resolver"
											: option;
									}}
									onChange={(
										event: any,
										newValue: string | null
									) => {
										setValueEstado(newValue);

										if (newValue) {
											dispatch(
												updateEstadoObservacion(
													projectId,
													revisionId,
													listId,
													newValue
												)
											);
											filtersList["Estado"] = newValue;
											handleFilterChange();
										} else {
											delete filtersList["Estado"];
											handleFilterChange();
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Todos estados"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid
								item
								xs={12}
								sm={
									Number(valueWidth) > widthBreakPoint
										? 2
										: 3
								}
							>
								<Box mt={2}>
									<TextField
										id="creacion-start"
										label="Fecha Creación: Inicio"
										type="datetime-local"
										value={valueCreacionStart}
										defaultValue={valueCreacionStart}
										onChange={(event) => {
											setValueCreacionStart(
												event.target.value
											);
											filtersList["dateStart"] =
												event.target.value;
										}}
										style={{ width: 170 }}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								sm={
									Number(valueWidth) > widthBreakPoint
										? 2
										: 3
								}
							>
								<Box display="flex">
									<Box mt={2} order={1}>
										<TextField
											id="creacion-end"
											label="Fecha Creación: Final"
											type="datetime-local"
											value={valueCreacionEnd}
											defaultValue={valueCreacionEnd}
											onChange={(event) => {
												setValueCreacionEnd(
													event.target.value
												);
												filtersList["dateEnd"] =
													event.target.value;
											}}
											style={{ width: 170 }}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Box>
									<Box mt={5} order={2}>
										<IconButton
											size="small"
											aria-label="Filtrar"
											onClick={() => {
												if (
													valueCreacionStart !== "" &&
													valueCreacionEnd !== ""
												) {
													dispatch(
														searchObservations(
															filtersList,
															projectId,
															revisionId,
															listId
														)
													);
												}
											}}
										>
											<Search />
										</IconButton>
									</Box>
									<Box mt={5} order={3}>
										<IconButton
											size="small"
											aria-label="Borrar filtro"
											onClick={() => {
												setValueFiltersList({});
												dispatch(getObservations(listId));
												setValueCreacionStart("");
												setValueCreacionEnd("");
												setValueRecinto(null);
												setValuePrioridad(null);
												setValueEstado(null);
												setValueSector(null);
												setValueSubcontrato(null);
												setValueErrorTipo(null);
											}}
										>
											<X />
										</IconButton>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={2}></Grid>
						</Grid>
					</Collapse>
				</CardContent>
				{/*
      // End Filters
      */}
				<CardContent>
					<TableContainer
						className={classes.tableContainer}
					>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>
										<IconButton
											size="small"
											onClick={() => {
												setShowObservations(
													(showObservations) =>
														!showObservations
												);
												setItemsOpen((items) =>
													items.map((i) => ({
														...i,
														open: showObservations,
													}))
												);
											}}
										>
											<MinusIcon size={20} />
										</IconButton>
									</TableCell>
									<TableCell>N°</TableCell>
									<TableCell>Estado</TableCell>
									<TableCell>Prioridad</TableCell>
									<TableCell>Fecha de Creación</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!isLoading ? (
									thisObservations
										// .filter((d) => validarFiltroEstado(d))
										.slice(
											page * limit,
											page * limit + limit
										)
										.map((data: any, i: any) => (
											<Fade key={i} in={true}>
												<Rows
													data={data}
													itemsOpen={itemsOpen}
													setItemsOpen={setItemsOpen}
													openDialog={(selected) => {
														setSelectedImage(selected);
														setOpenDialogImage(true);
													}}
													openEditDialog={(selected) => {
														setSelectedObs(selected);
														setOpenDialogEdit(true);

														const obj =
															selectedBusiness?.Subcontratos.find(
																(o: { Nombre: string }) =>
																	o.Nombre ===
																	selected?.subContrato
															);
														setOptionsErrorTipo(
															obj?.ErroresTipo as any
														);
													}}
												/>
											</Fade>
										))
								) : !isLoading && array.length === 0 ? (
									<TableNoContent colSpan={5} />
								) : (
									<TableSkeleton
										colSpan={5}
										limit={limit}
									/>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						component="div"
						count={totalDocs}
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handleLimitChange}
						page={page}
						rowsPerPage={limit}
						rowsPerPageOptions={TABLE_LIMITS}
					/>
				</CardContent>
				<Dialog
					open={openDialogImage}
					onClose={() => setOpenDialogImage(false)}
				>
					{selectedImage && (
						<>
							<IconButton
								size="small"
								color="primary"
								style={{
									right: 0,
									margin: 5,
									position: "absolute",
									background: "white",
								}}
								onClick={() => setOpenDialogImage(false)}
							>
								<CloseIcon />
							</IconButton>
							<img
								src={selectedImage}
								alt="Imagen observada"
							/>
						</>
					)}
				</Dialog>

				{/* Dialog Editar */}

				<Dialog
					open={openDialogEdit}
					onClose={() => setOpenDialogEdit(false)}
					aria-labelledby="form-dialog-title"
				>
					<form onSubmit={handleSubmit}>
						<DialogTitle id="form-dialog-title">
							Editar detalle
						</DialogTitle>

						<DialogContent>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={12}>
									<Autocomplete
										id="sector"
										value={values?.sector as any}
										options={
											selectedBusiness?.Sectores as any
										}
										getOptionLabel={(option: any) =>
											option.Nombre || values?.sector
										}
										onChange={(
											event: any,
											newValue: any | null
										) => {
											if (newValue) {
												values.sector = newValue.Nombre;
											}
										}}
										fullWidth
										renderInput={(params) => (
											<TextField
												{...params}
												error={
													touched.sector &&
													Boolean(errors.sector)
												}
												helperText={
													touched.sector && errors.sector
												}
												name="sector"
												label={"Sectores"}
												variant="outlined"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Autocomplete
										id="prioridad"
										value={values?.prioridad as any}
										options={
											selectedBusiness?.Prioridades as any
										}
										getOptionLabel={(option: any) =>
											option.Nombre || values?.prioridad
										}
										onChange={(
											event: any,
											newValue: any | null
										) => {
											if (newValue) {
												values.prioridad = newValue.Nombre;
											}
										}}
										fullWidth
										renderInput={(params) => (
											<TextField
												{...params}
												error={
													touched.sector &&
													Boolean(errors.sector)
												}
												helperText={
													touched.sector && errors.sector
												}
												name="prioridad"
												label={"Prioridad"}
												variant="outlined"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Autocomplete
										id="recinto"
										value={values?.recinto}
										options={places}
										getOptionLabel={(option: any) =>
											option.Nombre || values?.recinto
										}
										onChange={(
											event: any,
											newValue: any | null
										) => {
											if (newValue) {
												values.recinto = newValue.Nombre;
											}
										}}
										fullWidth
										renderInput={(params) => (
											<TextField
												{...params}
												// error={touched.sector && Boolean(errors.sector)}
												// helperText={touched.sector && errors.sector}
												name="recinto"
												label={"Recinto"}
												variant="outlined"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Autocomplete
										id="subcontrato"
										value={values?.subcontrato as any}
										options={
											selectedBusiness?.Subcontratos as any
										}
										getOptionLabel={(option: any) =>
											option.Nombre || values?.subcontrato
										}
										onChange={(
											event: any,
											newValue: any | null
										) => {
											if (newValue) {
												values.subcontrato =
													newValue.Nombre;

												setOptionsErrorTipo(
													newValue.ErroresTipo
												);
											}
										}}
										fullWidth
										renderInput={(params) => (
											<TextField
												{...params}
												name="subcontrato"
												label={"Subcontrato"}
												variant="outlined"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Autocomplete
										id="errortipo"
										value={values?.errorTipo}
										options={optionsErrorTipo}
										getOptionLabel={(option: any) =>
											option.Nombre || values?.errorTipo
										}
										onChange={(
											event: any,
											newValue: any | null
										) => {
											if (newValue) {
												values.errorTipo = newValue.Nombre;
											}
											handleChange(event);
										}}
										fullWidth
										renderInput={(params) => (
											<TextField
												{...params}
												name="errortipo"
												label={"Error Tipo"}
												variant="outlined"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<TextField
										fullWidth
										multiline
										rows={3}
										id="comentario"
										name="comentario"
										label="Comentario"
										variant="outlined"
										value={values.comentario}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									setOpenDialogEdit(false);
								}}
								color="primary"
							>
								Cancel
							</Button>
							<Button type="submit" color="primary">
								Guardar
							</Button>
						</DialogActions>
					</form>
				</Dialog>
				{/*FIN DIALOG EDITAR*/}
			</Card>
			<div>
				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={downloadAlert}
					autoHideDuration={6000}
					onClose={handleClose}
					message="Descargando"
					key={"top" + "right"}
				>
					<Alert onClose={handleClose} severity="success">
						{downloadMsg}
					</Alert>
				</Snackbar>
			</div>
		</>
	);
};

export const ObservationsBusiness = () => {
	const { projectId, revisionId, listId } =
		useParams<any>();
	const { pathname } = useLocation();
	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const businessId = auth.user?.empresaReference.id;
	const dispatch = useDispatch();
	const isFromBusiness =
		pathname.indexOf("/administrador/empresas/") === 0;

	const {
		observations,
		allObservations,
		isLoading: observationsIsLoading,
		totalDocs,
	} = useSelector<RootState, IObservationsState>(
		(state) => state.observationsReducer
	);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const {
		revisions: { selectedProject },
	} = useSelector<RootState, IProjectsState>(
		(state) => state.projectsReducer
	);

	const { selectedList } = useSelector<
		RootState,
		IListsState
	>((state) => state.listsReducer);

	const { selectedRevision } = useSelector<
		RootState,
		IRevisionsState
	>((state) => state.revisionsReducer);

	useEffect(() => {
		if (!observations) {
			dispatch(getObservations(listId));
		}

		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		}
	}, [
		dispatch,
		businessId,
		projectId,
		revisionId,
		selectedBusiness,
		isFromBusiness,
		listId,
		selectedProject,
	]);

	useEffect(() => {
		if (selectedBusiness === null) {
			dispatch(getBusiness(businessId));
		}
	}, [selectedBusiness]);

	return (
		<React.Fragment>
			<Helmet title="Observaciones" />
			<Grid justify="space-between" container>
				<Grid item>
					{!selectedProject ? (
						<Skeleton variant="text" />
					) : (
						<Box>
							<Typography
								variant="h3"
								gutterBottom
								display="inline"
							>
								{`${selectedBusiness?.Nombre} / ${selectedProject?.NombreProyecto} / ${selectedRevision?.Nombre} / ${selectedList?.Nombre}`}
							</Typography>
						</Box>
					)}

					{isFromBusiness ? (
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							<Link
								component={NavLink}
								exact
								to={
									auth.user?.tipoUsuario ===
									"AdministradorEmpresa"
										? `/administrador/empresa/proyectos`
										: `/administrador/user/proyectos`
								}
							>
								Proyectos
							</Link>
							<Link
								component={NavLink}
								exact
								to={
									auth.user?.tipoUsuario ===
									"AdministradorEmpresa"
										? `/administrador/empresa/proyectos/${projectId}/revisiones`
										: `/administrador/user/proyectos/${projectId}/revisiones`
								}
							>
								Revisiones
							</Link>
							<Link
								component={NavLink}
								exact
								to={
									auth.user?.tipoUsuario ===
									"AdministradorEmpresa"
										? `/administrador/empresa/proyectos/${projectId}/revisiones/${revisionId}/listados`
										: `/administrador/user/proyectos/${projectId}/revisiones/${revisionId}/listados`
								}
							>
								Listados
							</Link>
							<Typography>Observaciones</Typography>
						</Breadcrumbs>
					) : (
						<Breadcrumbs aria-label="Breadcrumb" mt={2}>
							{/*TODO: Modificar en vistas de cliente*/}
							<Link
								component={NavLink}
								exact
								to={`/administrador/empresa/proyectos`}
							>
								Proyectos
							</Link>
							<Link
								component={NavLink}
								exact
								to={`/administrador/empresa/proyectos/${projectId}/revisiones`}
							>
								Revisiones
							</Link>
							<Link
								component={NavLink}
								exact
								to={`/administrador/empresa/proyectos/${projectId}/revisiones/${revisionId}/listados`}
							>
								Listados
							</Link>
							<Typography>Observaciones</Typography>
						</Breadcrumbs>
					)}
				</Grid>
			</Grid>
			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<ContentCard
						selectedRevision={selectedRevision}
						array={observations}
						allObservations={allObservations}
						totalDocs={totalDocs}
						isLoading={observationsIsLoading}
						listData={selectedList}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
