import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	StateGetter,
	getSelectedNeoProjectRef,
} from "src/redux/neo-checklists/utils";
import { cleanString } from "src/utils/utils";
import {
	ASSIGNED_PLACES_COLLECTION_NAME,
	PLACES_COLLECTION_NAME,
} from "../constants";
import {
	FireUpdate,
	WithRef,
	getRef,
} from "src/utils/firebase";
import { openSnack } from "src/redux/actions/uiActions";
import { SnackState } from "src/models/snack-state";
import { otActions } from "../slice";
import { firestore } from "src/firebase/firebase";
import { RootState } from "src/redux/store/reducer";
import Firebase from "firebase";
import { ChecklistProject } from "src/models/OT/Projects";
import {
	AssignedPlaceOT,
	PlaceOT,
} from "src/models/OT/Place";
import { ChecklistOT } from "src/models/OT/Checklist";
import naturalSort from "natural-sort";
import { SheetOT } from "src/models/OT/Sheet";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";

const placesListener = FirebaseListener.addListener(
	"project-places-ot"
);
const checklistListener = FirebaseListener.addListener(
	"checklist-places-ot"
);

type CreateSpace = {
	project?: ChecklistProject;
	name: string;
};

/// Project Places

export const getProjectPlacesOT = createAsyncThunk(
	"checklists-ot/projects/places/get",
	async (
		project: ChecklistProject | undefined,
		{ dispatch, getState }
	) => {
		dispatch(otActions.setLoading(true));

		try {
			const projectRef = getSelectedNeoProjectRef(
				project ?? (getState as StateGetter)
			);

			placesListener.close();
			placesListener.set(
				projectRef.collection("EspaciosOT").onSnapshot(
					(snap) => {
						const data = snap.docs
							.map(
								(doc) =>
									({
										...doc.data(),
										_ref: doc.ref,
									} as PlaceOT)
							)
							.sort((a, b) =>
								naturalSort()(a.Nombre, b.Nombre)
							);

						dispatch(otActions.setProjectPlaces(data));
					},

					(error) => {
						dispatch(
							openSnack(
								error.message,
								SnackState.ERROR
							) as any
						);
					}
				)
			);
		} catch (error: any) {
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}

		dispatch(otActions.setLoading(false));
	}
);

export const addPlaceToProjectOT = createAsyncThunk(
	"checklists-ot/projects/places/create",
	async (params: CreateSpace, { getState, dispatch }) => {
		dispatch(otActions.setLoading(true));

		try {
			const {
				project = (getState() as RootState)
					.neoChecklistReducer.selected.project!,
				name,
			} = params;

			const projectRef = project._ref!;

			// check the name's unique
			const spaces = await projectRef
				.collection(PLACES_COLLECTION_NAME)
				.where("Nombre_lower", "==", cleanString(name))
				.limit(1)
				.get();

			if (!spaces.empty)
				throw Error(
					`Ya existe un espacio con el nombre ${name}.`
				);

			const data: PlaceOT = {
				Nombre: name,
				Nombre_lower: cleanString(name),
			};

			await projectRef.collection("EspaciosOT").add(data);

			dispatch(
				openSnack(
					`Hemos agregado el espacio al proyecto.`,
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}

		dispatch(otActions.setLoading(false));
	}
);

export const updatePlaceFromProjectOT = createAsyncThunk(
	"checklists-ot/projects/places/update",
	async (
		place: WithRef<Partial<PlaceOT>>,
		{ getState, dispatch }
	) => {
		dispatch(otActions.setLoading(true));

		try {
			const { _ref, Nombre } = place;

			const { AsignadosRef = [] } = (
				await _ref.get()
			).data() as PlaceOT;

			const placeData: Partial<PlaceOT> = {};

			if (Nombre) {
				// check the name's unique
				const spaces = await _ref.parent
					.where("Nombre_lower", "==", cleanString(Nombre))
					.limit(1)
					.get();

				if (
					spaces.docs.length > 0 &&
					spaces.docs[0].id !== _ref.id
				)
					throw Error(
						`Ya existe un espacio con el nombre ${Nombre}.`
					);

				placeData.Nombre = Nombre;
				placeData.Nombre_lower = cleanString(Nombre);
			}

			const refsToUpdate = [_ref, ...AsignadosRef];

			while (refsToUpdate.length) {
				const batch = firestore.batch();
				const splicedRefs = refsToUpdate.splice(0, 500);

				splicedRefs.forEach((ref) =>
					batch.update(ref, placeData)
				);

				await batch.commit();
			}

			dispatch(
				openSnack(
					"Hemos actualizado el espacio.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}

		dispatch(otActions.setLoading(false));
	}
);

export const removePlaceFromProjectOT = createAsyncThunk(
	"checklists-ot/projects/places/delete",
	async (
		place: WithRef<PlaceOT>,
		{ getState, dispatch }
	) => {
		dispatch(otActions.setLoading(true));

		try {
			const placeRef = getRef(place);
			const { AsignadosRef } = place;
			const refsToRemove = [];
			const refsToUpdate: Promise<any>[] = [];
			const imagesToDelete: string[] = [];

			// get and remove responses
			const responses = await Promise.all(
				AsignadosRef?.map((ref) =>
					ref.collection("RespuestasOT").get()
				) ?? []
			);

			responses.forEach((snap) => {
				const data = snap.docs.map((doc) => {
					return doc.data() as SheetOT;
				});

				refsToRemove.push(
					...snap.docs.map((doc) => doc.ref)
				);

				data.forEach((sheet) => {
					const { Revisores } = sheet;

					Revisores.flatMap((revisor) => {
						if (revisor.Firma)
							imagesToDelete.push(revisor.Firma.Uri);

						return revisor.Categorias;
					})
						.flatMap((categoria) => categoria.Preguntas)
						.flatMap((pregunta) => pregunta.Respuestas)
						.forEach(
							(pregunta) =>
								pregunta?.Foto &&
								imagesToDelete.push(pregunta.Foto.Uri)
						);
				});
			});

			if (AsignadosRef)
				AsignadosRef.forEach((ref) => {
					const checklistRef = ref.parent.parent;
					refsToRemove.push(ref);
					if (checklistRef)
						refsToUpdate.push(
							checklistRef.update({ Contado_: false })
						);
				});
			refsToRemove.push(placeRef);

			while (refsToRemove.length) {
				const batch = firestore.batch();
				const spliced = refsToRemove.splice(0, 500);
				spliced.forEach((ref) => batch.delete(ref));
				await batch.commit();
			}

			await Promise.all(refsToUpdate);

			await firestore
				.collection("ImagesToDelete")
				.add({ images: imagesToDelete });

			dispatch(
				openSnack(
					"Hemos eliminado el espacio.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}

		dispatch(otActions.setLoading(false));
	}
);

type AddToChecklist = {
	checklist?: ChecklistOT;
	data: {
		basePlace: PlaceOT;
		assignedPlace: Pick<
			AssignedPlaceOT,
			"Asignado" | "Cantidad" | "Revisores"
		>;
	}[];
};

/// NeoChecklist places

export const addPlacesToChecklistOT = createAsyncThunk(
	"checklists-ot/projects/checklists/places/add",
	async (
		params: AddToChecklist,
		{ getState, dispatch }
	) => {
		try {
			dispatch(otActions.setLoading(true));

			const {
				checklist = (getState() as RootState)
					.neoChecklistReducer.selected.checklist,
				data,
			} = params;

			const checklistRef = getRef(checklist);
			const projectRef = checklistRef.parent.parent!;

			const batchData: {
				action: "set" | "update";
				ref: Firebase.firestore.DocumentReference;
				data: any;
			}[] = [];

			data.forEach(({ basePlace, assignedPlace }) => {
				const basePlaceRef = getRef(basePlace);
				const assignedPlaceRef = checklistRef
					.collection(ASSIGNED_PLACES_COLLECTION_NAME)
					.doc(basePlaceRef.id);

				const { Nombre, Nombre_lower } = basePlace;

				const { Asignado, Cantidad, Revisores } =
					assignedPlace;

				const assignedPlaceData: FireUpdate<AssignedPlaceOT> =
					{
						Nombre,
						Nombre_lower,
						Asignado,
						Cantidad,
						Revisores,
						FechaCreacion:
							Firebase.firestore.FieldValue.serverTimestamp(),
						TotalRespuestas: 0,
						TotalHojas: 0,
					};

				const basePlaceData: FireUpdate<Partial<PlaceOT>> =
					{
						AsignadosRef:
							Firebase.firestore.FieldValue.arrayUnion(
								assignedPlaceRef
							),
					};

				const validReviewers = new Map<
					string,
					Firebase.firestore.DocumentReference
				>();

				Revisores.forEach((r) => {
					if (!r) return;
					validReviewers.set(r.id, r);
				});

				/**
				 * Add place to batch...
				 * And add reference to original place
				 * to update it later in case of name change.
				 */
				batchData.push(
					{
						action: "set",
						ref: assignedPlaceRef,
						data: assignedPlaceData,
					},
					{
						action: "update",
						ref: basePlaceRef,
						data: basePlaceData,
					},
					{
						action: "update",
						ref: projectRef,
						data: {
							Responsables:
								Firebase.firestore.FieldValue.arrayUnion(
									...validReviewers.values()
								),
						},
					}
				);
			});

			while (batchData.length) {
				const spliced = batchData.splice(0, 500);
				const batch = firestore.batch();

				spliced.forEach(({ action, ref, data }) => {
					if (action === "set") batch.set(ref, data);
					else batch.update(ref, data);
				});

				await batch.commit();
			}

			await checklistRef.update({
				Contado_: false,
			});

			dispatch(
				openSnack(
					`Agregamos los espacios a la lista de chequeo.`,
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}

		dispatch(otActions.setLoading(false));
	}
);

type UpdateFromChecklist = WithRef<
	Partial<
		Pick<
			AssignedPlaceOT,
			| "Asignado"
			| "FechaInicio"
			| "FechaTermino"
			| "Cantidad"
			| "Revisores"
		>
	>
>;

export const updatePlacesFromChecklistOT = createAsyncThunk(
	"ot/projects/checklists/places/update",
	async (
		places: UpdateFromChecklist[],
		{ dispatch, getState }
	) => {
		dispatch(otActions.setLoading(true));
		try {
			if (!places.length) return;

			const checklistRef = getRef(places[0]).parent.parent!;
			const previousChecklist = (
				getState() as RootState
			).neoChecklistReducer.project.checklists?.find(
				(checklist) =>
					checklist._ref?.id === checklistRef.id
			);
			if (!previousChecklist)
				throw Error("No encontramos la lista de chequeo.");

			const updates = places.map((place) => {
				const previous = (
					getState() as RootState
				).neoChecklistReducer.checklist.places?.find(
					(p) => p._ref?.id === place._ref.id
				);

				if (!previous)
					throw Error("El espacio anterior no existe.");

				const { _ref, ...incoming } = place;

				const data: Partial<AssignedPlaceOT> = {
					...incoming,
				};
				const ref = getRef(place);

				if (
					previous.Contado_ &&
					incoming.Cantidad &&
					previous.Cantidad < incoming.Cantidad
				) {
					data.Contado_ = false;
				}

				return { ref, data };
			});

			while (updates.length) {
				const batch = firestore.batch();
				const spliced = updates.splice(0, 500);
				spliced.forEach(({ ref, data }) =>
					batch.update(ref, data)
				);
				await batch.commit();
			}

			await checklistRef.update({ Contado_: false });

			dispatch(
				openSnack(
					`Hemos actualizado los espacios de la lista de chequeo.`,
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}

		dispatch(otActions.setLoading(false));
	}
);

export const getPlacesFromChecklistOT = createAsyncThunk(
	"checklists-ot/projects/checklists/places/get",
	async (
		checklist: ChecklistOT | undefined,
		{ dispatch, getState }
	) => {
		const checklistRef = getRef(
			checklist ??
				(getState() as RootState).neoChecklistReducer
					.selected.checklist
		);

		checklistListener.close();

		checklistListener.set(
			checklistRef
				.collection(ASSIGNED_PLACES_COLLECTION_NAME)
				.orderBy("Nombre_lower")
				.onSnapshot(
					(snap) => {
						const places = snap.docs
							.map((doc) => {
								const data = {
									...doc.data(),
									_ref: doc.ref,
								} as AssignedPlaceOT;

								return data;
							})
							.sort((a, b) =>
								naturalSort()(a.Nombre, b.Nombre)
							);
						dispatch(
							otActions.assignChecklist({ places: places })
						);
					},

					(error) => {
						console.error(error);
						dispatch(
							openSnack(
								error.message,
								SnackState.ERROR
							) as any
						);
					}
				)
		);
	}
);
