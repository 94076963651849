import { Action } from "../../models/action";
import * as types from "../../constants";
import { sortByName } from "../../utils/utils";

export interface IListsState {
	lists: any[];
	selectedList?: any;
	selectedRecinto?: any;
	selectedErrortipeProject?: any;
	selectedSubcontratoProject?: any;
	selectedProjectChecklist?: any;
	recintosProject: any;
	answersErrorType?: any[];
	addChecklistState:
		| "Initial"
		| "Submiting"
		| "Failure"
		| "Success";
	answersState:
		| "Initial"
		| "Submiting"
		| "Failure"
		| "Success";
	pdfState: "Initial" | "Submiting" | "Failure" | "Success";
	subcontractProject: any;
	errorsTypetProject: any;
	selectedErroTypeProject?: any;
	isLoading: boolean;
	PdfisLoading: boolean;
	error?: string;
	updateLoading: boolean;
	totalDocs: number;
	lastDoc?: any;
	orderBy: "Nombre" | "FechaCreacion";
}

const initialState = {
	lists: [],
	selectedList: {},
	selectedProjectChecklist: {},
	recintosProject: null,
	selectedErroTypeProject: {},
	subcontractProject: null,
	addChecklistState: "Initial",
	answersState: "Initial",
	pdfState: "Initial",
	errorsTypetProject: null,
	isLoading: false,
	PdfisLoading: false,
	updateLoading: false,
	totalDocs: 0,
	totalrecintos: 0,
	orderBy: "Nombre",
} as IListsState;

export const listsReducer = (
	state = initialState,
	action: Action
): IListsState => {
	switch (action.type) {
		case types.LISTS_GET_DOCS:
			return {
				...state,
				lists: action.payload,
			};
		case types.LISTS_ADD_DOCS:
			return {
				...state,
				lists: [...state.lists, ...action.payload].sort(
					sortByName
				),
			};
		case types.LISTS_UPDATE_DOC:
			return {
				...state,
				lists: state.lists
					.map((x) =>
						x.id === action.payload.id
							? { ...x, ...action.payload }
							: x
					)
					.sort(sortByName),
			};
		case types.LISTS_REMOVE_DOC:
			return {
				...state,
				lists: state.lists.filter(
					(x) => x.id !== action.payload.id
				),
			};
		case types.LISTS_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case types.LISTS_UPDATE_LOADING:
			return {
				...state,
				updateLoading: action.payload,
			};
		case types.LISTS_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		case types.LISTS_SET_SELECTED:
			return {
				...state,
				selectedList: action.payload,
			};
		case types.LISTS_SET_TOTAL_DOCS:
			return {
				...state,
				totalDocs: action.payload,
			};
		case types.LISTS_SET_LAST_DOC:
			return {
				...state,
				lastDoc: action.payload,
			};
		case types.LISTS_ORDER_BY_SET:
			return {
				...state,
				orderBy: action.payload,
			};
		// add checklist to list
		case types.ADD_CHECKLIST_TO_LIST_SUBMITTING:
			return {
				...state,
				addChecklistState: "Submiting",
			};
		case types.ADD_CHECKLIST_TO_LIST_FAILURE:
			return {
				...state,
				addChecklistState: "Failure",
			};
		case types.ADD_CHECKLIST_TO_LIST_SUCCESS:
			return {
				...state,
				addChecklistState: "Success",
				selectedList: action.payload,
			};
		// Obtener Recintos
		case types.RECINTOS_PROJECT_SUBMITTING:
			return {
				...state,
			};
		case types.RECINTOS_PROJECT_FAILURE:
			return {
				...state,
			};
		case types.RECINTOS_PROJECT_SUCCESS:
			return {
				...state,
				recintosProject: action.payload,
			};
		// Obtener Subcontratos
		case types.SUBCONTRATOS_PROJECT_SUBMITTING:
			return {
				...state,
			};
		case types.SUBCONTRATOS_PROJECT_FAILURE:
			return {
				...state,
			};
		case types.SUBCONTRATOS_PROJECT_SUCCESS:
			return {
				...state,
				subcontractProject: action.payload,
			};
		// get one recinto
		case types.CHECKLIST_PROJECT_GET_ONE_SUBMITTING:
			return {
				...state,
			};
		case types.CHECKLIST_PROJECT_GET_ONE_FAILURE:
			return {
				...state,
			};
		case types.CHECKLIST_PROJECT_GET_ONE_SUCCESS:
			return {
				...state,
				recintosProject: action.payload,
			};
		// get one Subcontrato
		case types.GET_ONE_SUBCONTRATO_PROJECT_SUBMITTING:
			return {
				...state,
			};
		case types.GET_ONE_SUBCONTRATO_PROJECT_FAILURE:
			return {
				...state,
			};
		case types.GET_ONE_SUBCONTRATO_PROJECT_SUCCESS:
			return {
				...state,
				selectedSubcontratoProject: action.payload,
			};
		// obtener errores tipo
		case types.ERROR_TIPO_PROJECT_GET_SUBMITTING:
			return {
				...state,
			};
		case types.ERROR_TIPO_PROJECT_GET_FAILURE:
			return {
				...state,
			};
		case types.ERROR_TIPO_PROJECT_GET_SUCCESS:
			return {
				...state,
				errorsTypetProject: action.payload,
			};
		case types.CHECKLIST_PROJECT_SET_SELECTED:
			return {
				...state,
				subcontractProject: action.payload,
			};
		case types.ERROR_TIPO_PROJECT_SET_SELECTED:
			return {
				...state,
				selectedErroTypeProject: action.payload,
			};
		// limpiar errores tipo al ir atras
		case types.ERROR_TIPO_SET_SELECTED:
			return {
				...state,
				errorsTypetProject: action.payload,
			};
		// Respuestas error tipos
		case types.ERROR_TIPO_ANSWERS_SUBMITTING:
			return {
				...state,
				answersState: "Submiting",
			};
		case types.ERROR_TIPO_ANSWERS_FAILURE:
			return {
				...state,
				answersState: "Failure",
			};
		case types.ERROR_TIPO_ANSWERS_SUCCESS:
			return {
				...state,
				answersState: "Success",
				answersErrorType: action.payload,
			};
		// Update Comentario error tipo
		case types.UPDATE_ANSWERS_ERROR_TIPO_SUBMITTING:
			return {
				...state,
			};
		case types.UPDATE_ANSWERS_ERROR_TIPO_SUCCESS:
			return {
				...state,
			};
		case types.UPDATE_ANSWERS_ERROR_TIPO_FAILURE:
			return {
				...state,
			};
		// answers set selected
		case types.ANSWERS_SET_SELECTED:
			return {
				...state,
				answersErrorType: action.payload,
			};
		// get one answers
		case types.GET_ONE_ANSWERS_SUBMMITING:
			return {
				...state,
			};
		case types.GET_ONE_ANSWERS_SUCCESS:
			return {
				...state,
				answersErrorType: action.payload,
			};
		case types.GET_ONE_ANSWERS_FAILURE:
			return {
				...state,
			};
		// get one list
		case types.GET_ONE_LIST_SUBMITTING:
			return {
				...state,
			};
		case types.GET_ONE_LIST_SUCCESS:
			return {
				...state,
				selectedList: action.payload,
			};
		case types.GET_ONE_LIST_FAILURE:
			return {
				...state,
			};
		// recinto project set selected
		case types.RECINTO_SET_SELECTED:
			return {
				...state,
				selectedRecinto: action.payload,
			};
		// subcontrato project set selected
		case types.SUBCONTRATO_PROJECT_SET_SELECTED:
			return {
				...state,
				selectedSubcontratoProject: action.payload,
			};
		// selected type error
		case types.ERROR_TIPO_PROJECT_SET_SELECTED:
			return {
				...state,
				selectedErrortipeProject: action.payload,
			};

		// get one recinto
		case types.GET_ONE_RECINTO_PROJECT_SUBMITTING:
			return {
				...state,
			};
		case types.GET_ONE_RECINTO_PROJECT_SUCCESS:
			return {
				...state,
				selectedRecinto: action.payload,
			};

		// get one error tipo
		case types.GET_ONE_ERROR_TIPO_SUBMITTING:
			return {
				...state,
			};
		case types.GET_ONE_ERROR_TIPO_SUCCESS:
			return {
				...state,
				selectedErrortipeProject: action.payload,
			};
		case types.GET_ONE_ERROR_TIPO_FAILURE:
			return {
				...state,
			};
		// state pdf download
		case types.PDF_LOADING:
			return {
				...state,
				PdfisLoading: action.payload,
			};
		default:
			return state;
	}
};
