import { combineReducers } from "redux";

import themeReducer from "../reducers/themeReducer";
import authReducer from "../reducers/authReducer";
import { businessReducer } from "../reducers/businessReducer";
import { usersReducer } from "../reducers/usersReducer";
import { sectorsReducer } from "../reducers/sectorsReducer";
import { placesReducer } from "../reducers/placesReducer";
import { subContractsReducer } from "../reducers/subContractsReducer";
import { projectsReducer } from "../reducers/projectsReducer";
import { revisionsReducer } from "../reducers/revisionsReducer";
import { chargesReducer } from "../reducers/chargesReducer";
import { prioritiesReducer } from "../reducers/prioritiesReducer";
import { listsReducer } from "../reducers/listsReducer";
import { observationsReducer } from "../reducers/observationsReducer";
import { uiReducer } from "../reducers/uiReducer";
import { checklistReducer } from "../reducers/checklistReducer";
import { masivePdfGeneratorReducer } from "../reducers/masivePdfGeneratorReducer";
import { observacionesChecklistReducer } from "../reducers/observacionesChecklistReducer";
import { otReducer } from "../neo-checklists/slice";

export const reducer = combineReducers({
	themeReducer,
	uiReducer,
	authReducer,
	businessReducer,
	usersReducer,
	projectsReducer,
	revisionsReducer,
	chargesReducer,
	sectorsReducer,
	subContractsReducer,
	placesReducer,
	prioritiesReducer,
	observationsReducer,
	listsReducer,
	checklistReducer,
	masivePdfGeneratorReducer,
	observacionesChecklistReducer,
	neoChecklistReducer: otReducer,
});

export type RootState = ReturnType<typeof reducer>;
