import { FC, ReactNode } from "react";
import {
	Typography,
	DialogTitle as MuiDialogTitle,
	DialogTitleProps as MuiDialogTitleProps,
	IconButton,
	TypographyProps,
	Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

export type DialogTitleProps = MuiDialogTitleProps & {
	bigText?: ReactNode;
	bigTextProps?: TypographyProps;
	smallText?: ReactNode;
	smallTextProps?: TypographyProps;
	onClose?: (
		ev?: any,
		reason?: any
	) => void;
};

export const DialogTitle: FC<DialogTitleProps> = ({
	children,
	bigText,
	bigTextProps,
	smallText,
	smallTextProps,
	onClose,
	...props
}) => {
	if (children)
		return (
			<MuiDialogTitle {...props}>{children}</MuiDialogTitle>
		);

	return (
		<Box
			{...props}
			style={{
				display: "flex",
				flexDirection: "row",
				padding: "10px 25px 10px 25px",
				borderBottom: "1px solid #e8e8e8",
				boxShadow: "revert-layer",
				...props.style,
			}}
		>
			<Box flexGrow={1}>
				{bigText && (
					<Typography variant="h3" {...bigTextProps}>
						{bigText}
					</Typography>
				)}
				{smallText && (
					<Typography variant="h6" {...smallTextProps}>
						{smallText}
					</Typography>
				)}
			</Box>

			{onClose && (
				<IconButton
					style={{
						alignSelf: "center",
						marginLeft: 10,
						color: "background.paper",
					}}
					onClick={onClose}
				>
					<Close fontSize="large" color="inherit" />
				</IconButton>
			)}
		</Box>
	);
};
