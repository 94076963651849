import {
	SimpleCrud,
	SimpleCrudProps,
} from "src/components/SimpleCrud";
import { useBusiness } from "src/hooks/redux/useBusiness";
import {
	actualizarCargoEmpresa,
	agregarCargoEmpresa,
	quitarCargoEmpresa,
} from "src/redux/actions/businessActions";
import { useDispatch } from "src/redux/store";

export const BusinessCargosTab = () => {
	const dispatch = useDispatch();
	const { selectedBusiness, loading } = useBusiness();

	const handleSubmit: SimpleCrudProps["onCreateOrUpdate"] =
		(values, action, selected) => {
			if (action === "New") {
				dispatch(agregarCargoEmpresa(values));
			} else if (action === "Edit") {
				dispatch(
					// @ts-ignore
					actualizarCargoEmpresa(selected.Nombre, values)
				);
			}
		};

	const handleDelete: SimpleCrudProps["onDelete"] = (
		selected
	) => {
		dispatch(quitarCargoEmpresa(selected.Nombre));
	};


	return (
		<SimpleCrud
			key={"Cargos"}
			// @ts-ignore
			array={[...new Set(selectedBusiness?.Cargos)]}
			isLoading={loading}
			name={"Cargo"}
			onCreateOrUpdate={handleSubmit}
			onDelete={handleDelete}
		/>
	);
};
