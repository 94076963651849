import { firestore } from "../../firebase/firebase";
import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { ObservacionChecklist } from "../../models/ObservacionChecklist";
import firebase from "firebase/app";
import "firebase/firestore";
import Axios from "axios";
import { format } from "date-fns";
import { Observacion } from "../../models/Observacion";

export const getObservations = (
	projectId: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({
			type: types.OBSERVATIONS_CHECKLIST_LOADING,
		});
		try {
			const snaps = await firestore
				.collection("RespuestasErrorestipos")
				.where("ProyectoId", "==", projectId)
				.orderBy("FechaCreacion", "desc")
				.get();

			let actualErrorTipo: string | null = null;
			let actualErrorTipoCount = 1;
			const observations = snaps.docs
				.map(
					(doc) =>
						({
							...(doc.data() as any),
							id: doc.id,
						} as ObservacionChecklist)
				)
				.sort((a, b) => {
					return (
						// b.FechaCreacion.toMillis() - a.FechaCreacion.toMillis() ||
						a.NombreListado.localeCompare(
							b.NombreListado
						) ||
						a?.NombreRevision?.localeCompare(
							b.NombreRevision
						) ||
						a.NombreRecinto.localeCompare(
							b.NombreRecinto
						) ||
						a.NombreSubcontrato.localeCompare(
							b.NombreSubcontrato
						) ||
						a.NombreErrorTipo.localeCompare(
							b.NombreErrorTipo
						)
					);
				})
				.map((obs, _, arr) => {
					if (obs.ErrorTipoId !== actualErrorTipo) {
						actualErrorTipoCount = arr.filter(
							(o) => o.ErrorTipoId === obs.ErrorTipoId
						).length;
						actualErrorTipo = obs.ErrorTipoId;
					}
					obs.Order = actualErrorTipoCount;
					actualErrorTipoCount -= 1;
					return obs;
				});

			dispatch({
				type: types.OBSERVATIONS_CHECKLIST_SUCCESS,
				payload: observations,
			});
		} catch (error: any) {
			dispatch({
				type: types.OBSERVATIONS_CHECKLIST_FAILURE,
				payload: error.toString(),
			});
		}
	};
};

export const generateExcel = (
	projectName: string,
	data: ObservacionChecklist[]
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const user = getState().authReducer.user;
			const business =
				getState().businessReducer.selectedBusiness;

			if (!business)
				throw Error("No hay empresa seleccionada");
			if (!user)
				throw Error("El usuario no está autenticado.");
			dispatch({
				type: types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_LOADING,
			});
			const empresaSnap: firebase.firestore.DocumentSnapshot =
				await user.empresaReference.get();
			const empresa = empresaSnap.data();
			if (!empresa) {
				throw new Error("Error al cargar la empresa");
			}

			const response = await Axios.post(
				"https://us-central1-checkpro-3a90a.cloudfunctions.net/generateSummaryExcel",
				{
					dataRows: data,
					proyect: {
						Nombre: projectName,
						Empresa: business.Nombre,
					},
				},
				{
					responseType: "arraybuffer",
				}
			);
			downloadURI(
				window.URL.createObjectURL(
					new Blob([response.data])
				),
				`${projectName.replace(" ", "_")}_${format(
					new Date(),
					"dd_MM_yyyy_HH_mm_ss"
				)}.xlsx`
			);

			dispatch({
				type: types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_SUCCESS,
			});
		} catch (error: any) {
			dispatch({
				type: types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_FAILURE,
				payload: error.toString(),
			});
		}
	};
};

export const generateLegacyExcel = (
	observaciones: Observacion[]
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const user = getState().authReducer.user;
			const business =
				getState().businessReducer.selectedBusiness;

			if (!business)
				throw Error("No hay empresa seleccionada");
			if (!user)
				throw Error("El usuario no está autenticado.");

			dispatch({
				type: types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_LOADING,
			});
			const empresaSnap: firebase.firestore.DocumentSnapshot =
				await user.empresaReference.get();
			const empresa = empresaSnap.data();
			if (!empresa) {
				throw new Error("Error al cargar la empresa");
			}

			const response = await Axios.post(
				"https://us-central1-checkpro-3a90a.cloudfunctions.net/projects/observations/legacy-excel-list",
				{
					observaciones,
					nombreEmpresa: business.Nombre,
				},
				{
					responseType: "arraybuffer",
				}
			);
			downloadURI(
				window.URL.createObjectURL(
					new Blob([response.data])
				),
				`${observaciones[0].NombreProyecto.replace(
					" ",
					"_"
				)}_${format(
					new Date(),
					"dd_MM_yyyy_HH_mm_ss"
				)}.xlsx`
			);

			dispatch({
				type: types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_SUCCESS,
			});
		} catch (error: any) {
			dispatch({
				type: types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_FAILURE,
				payload: error.toString(),
			});
		}
	};
};

function downloadURI(uri: string, name: string) {
	let link = document.createElement("a");
	link.download = name;
	link.href = uri;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
