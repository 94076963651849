import { QuestionGroupOT } from "src/models/OT/Checklist";
import { RevisorOT } from "src/models/OT/Sheet";
import { Usuario } from "src/models/Usuario";
import { getRef } from "src/utils/firebase";

export const createInitialReviewerResponse = (
	reviewer: Usuario,
	categories: QuestionGroupOT[]
): RevisorOT => {
	return {
		Revisor: getRef(reviewer),
		Bloqueado: false,
		Firma: null,
		NombreRevisor: `${reviewer.Nombre} ${reviewer.Apellido}`,
		Categorias: categories.map((c) => ({
			Categoria: c.Categoria,
			Preguntas: c.Preguntas.map((p) => ({
				Pregunta: p,
				Respuestas: [null],
			})),
		})),
	};
};
