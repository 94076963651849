import * as types from "../../constants";
import { Action } from "../../models/action";
import { ObservacionChecklist } from "../../models/ObservacionChecklist";

export interface ObservacionChecklistState {
  observations: ObservacionChecklist[];
  state: "IsSubmitting" | "Success" | "Failure" | "Initial";
  error?: string;
  getExcel: {
    state: "IsSubmitting" | "Success" | "Failure" | "Initial";
    error?: string;
  };
}

const initialState = {
  observations: [],
  state: "Initial",
  getExcel: {
    state: "Initial",
  },
} as ObservacionChecklistState;

export const observacionesChecklistReducer = (
  state = initialState,
  action: Action
): ObservacionChecklistState => {
  switch (action.type) {
    case types.OBSERVATIONS_CHECKLIST_INITIAL:
      return initialState;
    case types.OBSERVATIONS_CHECKLIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        state: "Failure",
      };
    case types.OBSERVATIONS_CHECKLIST_SUCCESS:
      return {
        ...state,
        state: "Success",
        observations: action.payload,
      };
    case types.OBSERVATIONS_CHECKLIST_LOADING:
      return {
        ...state,
        state: "IsSubmitting",
        error: undefined,
      };
    // GetExcel
    case types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_INITIAL:
      return {
        ...state,
        getExcel: initialState.getExcel,
      };
    case types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_FAILURE:
      return {
        ...state,
        getExcel: {
          error: action.payload,
          state: "Failure",
        },
      };
    case types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_SUCCESS:
      return {
        ...state,
        getExcel: {
          state: "Success",
        },
      };
    case types.OBSERVATIONS_CHECKLIST_GENERATE_DOC_LOADING:
      return {
        ...state,
        getExcel: {
          state: "IsSubmitting",
          error: undefined,
        },
      };
    default:
      return state;
  }
};
