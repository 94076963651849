import Axios from "axios";
import { format } from "date-fns";
import firebase from "firebase/app";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import {
	capitalizeAllFirstLetters,
	capitalizeFirstLetter,
	cleanString,
} from "../../utils/utils";
import { openSnack } from "./uiActions";
import {
	actualizarListados,
	eliminarDatosListado,
	actualizarNombreListado,
} from "../../services/listados";
import { indexarObservaciones } from "../../services/empresa/revisiones";
import { Observacion } from "src/models/Observacion";
import { ObservacionChecklist } from "src/models/ObservacionChecklist";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";
import Firebase from "firebase";
import { getInnerSnaps } from "src/utils/firebase/snaps";
import { isChecklistRevision } from "src/models/Proyecto";

const listener = FirebaseListener.addListener("lists");

export const getLists = (
	projectId: string,
	revisionId: string,
	limit: number = types.TABLE_LIMIT_DEFAULT,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const { orderBy } = getState().listsReducer;
		try {
			const query = firestore
				.collection(
					`${
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					}/${projectId}/Revisiones/${revisionId}/Listado`
				)
				.orderBy(orderBy, "desc");

			listener.close();
			listener.set(
				query.onSnapshot(
					(snap) => {
						dispatch(setLists(mapLists(snap)));
					},
					(error) => {
						console.error(error);
					}
				)
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const getList = (
	projectId: string,
	revisionId: string,
	listId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.get();

			dispatch(
				setSelectedList({
					...response.data(),
					id: response.id,
				})
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};
// get pdf document
export const getPdfDocument = (
	Selected: any,
	orderRecinto?: boolean
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const { user } = getState().authReducer;
			if (!user) throw Error("No hay usuario autenticado.");

			const { selectedRevision } =
				getState().revisionsReducer;
			const {
				revisions: { selectedProject },
			} = getState().projectsReducer;

			const empresaId = user.empresaReference.id;

			let responseUser;

			if (Selected.Responsable.FirebaseId)
				responseUser = await firestore
					.collection("Usuarios")
					.doc(Selected.Responsable.FirebaseId)
					.get();

			const dataUser = responseUser?.data();
			const resEmpresa = await firestore
				.collection("Empresas")
				.doc(empresaId)
				.get();
			const dataEmpresa = resEmpresa.data();
			const resPdf = await Axios.post(
				"https://us-central1-checkpro-3a90a.cloudfunctions.net/generatePdf",
				{
					Proyecto: selectedProject,
					Revision: selectedRevision,
					Listado: Selected,
					Usuario: {
						name: dataUser
							? `${dataUser?.Nombre} ${dataUser?.Apellido}`
							: "Sin asignar",
					},
					orderRecinto: orderRecinto,
					cargo: dataUser?.Cargo || "N/A",
					telefono: dataUser?.Telefono || "N/A",
					textoPie: dataEmpresa?.PiePagina || "CheckPro",
				}
			);
			const url = resPdf.data.data;
			const link = document.createElement("a");
			link.target = "_blank";
			link.rel = "noopener noreferrer";
			link.setAttribute(
				"download",
				`Respuestas_${Selected.Nombre.replace(
					" ",
					"_"
				)}_${format(new Date(), "dd_MM_yyyy")}.pdf`
			); //or any other extension
			document.body.appendChild(link);
			link.href = url;
			link.click();
			dispatch(PdfLoading(false));
		} catch (error: any) {
			console.error(error);
			dispatch(PdfLoading(false));
		}
	};
};
// agregar fecha propuesta
export const addDateTotList = (
	value: any,
	projectId: string,
	revisionId: string,
	listadoId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const listSelected = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listadoId)
				.get();

			firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listadoId)
				.update({
					...listSelected.data(),
					FechaPropuesta: value,
				});
			await time(50);
			dispatch(
				getLists(
					projectId,
					revisionId,
					types.TABLE_LIMIT_DEFAULT,
					isChecklist
				)
			);
			dispatch(
				openSnack(
					"Fecha asignada correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch(isLoading(false));
		}
	};
};
// agregar fecha real
export const addRealDateTotList = (
	value: any,
	projectId: string,
	revisionId: string,
	listadoId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const listSelected = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listadoId)
				.get();

			firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listadoId)
				.update({
					...listSelected.data(),
					FechaReal: value,
				});
			await time(50);
			dispatch(
				getLists(
					projectId,
					revisionId,
					types.TABLE_LIMIT_DEFAULT,
					isChecklist
				)
			);
			dispatch(
				openSnack(
					"Fecha asignada correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch(isLoading(false));
		}
	};
};
// editar fecha propuesta
export const updateDateTotList = (
	value: any,
	projectId: string,
	revisionId: string,
	listadoId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		try {
			firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listadoId)
				.update({
					FechaPropuesta: value,
				});
			await time(50);
			dispatch(
				getLists(
					projectId,
					revisionId,
					types.TABLE_LIMIT_DEFAULT,
					isChecklist
				)
			);
			dispatch(
				openSnack(
					"Fecha editada correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch(isLoading(false));
		}
	};
};
// update fecha real
export const updateRealDate = (
	value: any,
	projectId: string,
	revisionId: string,
	listadoId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		try {
			firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listadoId)
				.update({
					FechaReal: value,
				});
			await time(50);
			dispatch(
				getLists(
					projectId,
					revisionId,
					types.TABLE_LIMIT_DEFAULT,
					isChecklist
				)
			);
			dispatch(
				openSnack(
					"Fecha editada correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch(isLoading(false));
		}
	};
};

export const addList = (list: any): AppThunk => {
	return async (dispatch, getState) => {
		try {
			dispatch(isLoading(true));

			const project =
				getState().projectsReducer.revisions
					.selectedProject;
			const revision =
				getState().revisionsReducer.selectedRevision;

			if (!project)
				throw Error("No hay proyecto seleccionado");

			if (!revision)
				throw Error("No hay revisión seleccionada");

			const isChecklist =
				project.Tipo === "CheckList" || project.Tipo === "";

			const revisionSnap = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(project.id)
				.collection("Revisiones")
				.doc(revision.id)
				.get();

			const listadoRef = revisionSnap.ref
				.collection("Listado")
				.doc();

			const docExistsSnap = await revisionSnap.ref
				.collection("Listado")
				.where(
					"Nombre_lower",
					"==",
					cleanString(list.Nombre)
				)
				.get();

			if (!docExistsSnap.empty)
				throw Error("Existe listado con ese nombre");

			const newList = {
				FechaCreacion: new Date(),
				Nombre: list.Nombre,
				Nombre_lower: cleanString(list.Nombre),
				Responsable: {
					Fecha: new Date(),
					FirebaseId: list.Responsable
						? list.Responsable.id
						: "",
					NombreCompleto: list.Responsable
						? list.Responsable.Nombre +
						  " " +
						  list.Responsable.Apellido
						: "",
					NombreCompleto_lower: list.Responsable
						? cleanString(
								list.Responsable.Nombre +
									" " +
									list.Responsable.Apellido
						  )
						: "",
					Cargo: list.Responsable
						? list.Responsable.Cargo
						: "",
				},
			};

			const batch = firestore.batch();

			batch.set(listadoRef, newList);

			const { Carpetas } = revisionSnap.data() as {
				Carpetas: string | number;
			};

			if (typeof Carpetas === "string") {
				const listsSnap = await revisionSnap.ref
					.collection("Listado")
					.get();

				batch.update(revisionSnap.ref, {
					Carpetas: listsSnap.size + 1,
				});
			} else
				batch.update(revisionSnap.ref, {
					Carpetas:
						Firebase.firestore.FieldValue.increment(1),
				});

			if (list.Responsable)
				batch.update(
					firestore
						.collection(
							isChecklist
								? "ProyectosChecklist"
								: "Proyectos"
						)
						.doc(project.id),
					{
						Responsables:
							firebase.firestore.FieldValue.arrayUnion(
								list.Responsable.id
							),
					}
				);

			await batch.commit();

			dispatch(
				openSnack(
					"Listado agregado Correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch(setError(error));
			dispatch(
				openSnack(error.toString(), SnackState.ERROR)
			);

			dispatch(isLoading(false));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

// AGREGAR NOMBRE DEL CHECKLIST AL PROYECTO

export const addNameChecklist = (
	projectId: string,
	revisionId: string,
	lists: { id: string }[],
	value: any,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.CHECKLIST_NAME_UPDATE_SUBMITTING,
		});
		try {
			await Promise.all(
				lists.map(({ id }) =>
					actualizarNombreListado({
						isChecklist,
						projectId,
						revisionId,
						listId: id,
						checklist: value,
					})
				)
			);

			dispatch({
				type: types.CHECKLIST_NAME_UPDATE_SUCCESS,
			});
		} catch (error: any) {
			dispatch(setError(error));

			dispatch({
				type: types.CHECKLIST_NAME_UPDATE_FAILURE,
			});
		}
	};
};
// agregar checklist al la lista seleccionada

const time = (ms: number) =>
	new Promise((resolve) => setTimeout(resolve, ms));

export const addChecklistToList = (
	checklist: any,
	list: { id: string }[],
	projectId: string,
	revisionId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.ADD_CHECKLIST_TO_LIST_SUBMITTING,
		});
		try {
			await Promise.all(
				list.map(({ id }) => eliminarDatosListado(id))
			);

			await Promise.all(
				list.map(({ id }) =>
					firestore
						.collection(
							isChecklist
								? "ProyectosChecklist"
								: "Proyectos"
						)
						.doc(projectId)
						.collection("Revisiones")
						.doc(revisionId)
						.collection("Listado")
						.doc(id)
						.update({
							ChecklistId: checklist.id,
						})
				)
			);

			await Promise.all(
				list.map(({ id }) =>
					actualizarListados({
						checklist,
						isChecklist,
						listId: id,
						projectId,
						revisionId,
					})
				)
			);

			await Promise.all(
				list.map(({ id }) =>
					actualizarNombreListado({
						isChecklist,
						projectId,
						revisionId,
						checklist,
						listId: id,
					})
				)
			);

			dispatch({
				type: types.ADD_CHECKLIST_TO_LIST_SUCCESS,
			});
			dispatch(
				openSnack(
					"Checklist Agregado Correctamente",
					SnackState.SUCCESS
				)
			);
			dispatch(
				getLists(
					projectId,
					revisionId,
					types.TABLE_LIMIT_DEFAULT,
					isChecklist
				)
			);
		} catch (error: any) {
			dispatch({
				type: types.ADD_CHECKLIST_TO_LIST_FAILURE,
			});
			dispatch(
				openSnack(error.toString(), SnackState.ERROR)
			);
		}
	};
};
// get one Recinto project

export const getOneRecinto = (
	projectId: string,
	revisionId: string,
	listId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.CHECKLIST_PROJECT_GET_ONE_SUBMITTING,
		});
		try {
			const recintosRef = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.get();

			const recintos = recintosRef.docs.map((x: any) => ({
				...x.data(),
				id: x.id,
			}));
			dispatch({
				type: types.CHECKLIST_PROJECT_GET_ONE_SUCCESS,
				payload: recintos,
			});
		} catch (error: any) {
			dispatch({
				type: types.CHECKLIST_PROJECT_GET_ONE_FAILURE,
				payload:
					"Ha ocurrido un error al obtener el checklists",
			});
		}
	};
};
// get errores tipo
export const getErrorTipoProject = (
	projectId: string,
	revisionId: string,
	listId: string,
	recintoId: string,
	subcontratoId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.ERROR_TIPO_PROJECT_GET_SUBMITTING,
		});
		try {
			const errorsTipoRef = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.doc(recintoId)
				.collection("Subcontratos")
				.doc(subcontratoId)
				.collection("ErroresTipo")
				.get();

			await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.doc(recintoId)
				.collection("Subcontratos")
				.doc(subcontratoId)
				.update({
					TotalErrorsTipo: errorsTipoRef.docs.length,
				});

			const errorsType = errorsTipoRef.docs.map((x) => ({
				...x.data(),
				id: x.id,
				ref: x.ref,
			}));

			dispatch({
				type: types.ERROR_TIPO_PROJECT_GET_SUCCESS,
				payload: errorsType,
			});
		} catch (error: any) {
			dispatch({
				type: types.ERROR_TIPO_PROJECT_GET_FAILURE,
				payload:
					"Ha ocurrido un error al obtener los errores tipo",
			});
		}
	};
};
// Obteniendo las respuestas de los errores tipo.
export const getAnswersErrorTipo = (
	errorTipoId: string
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.ERROR_TIPO_ANSWERS_SUBMITTING,
		});
		try {
			const errorTipoSnap = await firestore
				.collection("RespuestasErrorestipos")
				.where("ErrorTipoId", "==", errorTipoId)
				.orderBy("FechaCreacion", "desc")
				.get();

			let answers: any = [];
			if (errorTipoSnap.docs.length > 0) {
				answers = errorTipoSnap.docs.map((x) => ({
					...x.data(),
					id: x.id,
				}));
			}

			dispatch({
				type: types.ERROR_TIPO_ANSWERS_SUCCESS,
				payload: answers,
			});
		} catch (error: any) {
			dispatch({
				type: types.ERROR_TIPO_ANSWERS_FAILURE,
				payload:
					"Ha ocurrido un error al obtener los errores tipo",
			});
		}
	};
};
// updateComentario Respuestas error tipo
export const updateComentarioErrorTipo = (
	id: string,
	value: string
): AppThunk => {
	return async (dispatch, getState) => {
		try {
			const { user } = getState().authReducer;
			if (!user) throw Error("No hay usuario autenticado.");

			dispatch({
				type: types.UPDATE_ANSWERS_ERROR_TIPO_SUBMITTING,
			});
			const { answersErrorType } = getState().listsReducer;
			let newValue: string = "";
			answersErrorType
				?.filter((e: any) => e.id === id)
				.forEach((x: any) => {
					if (x.id === id) {
						x.Comentario = value;
						newValue = x.Comentario;
					}
				});
			firestore
				.collection("RespuestasErrorestipos")
				.doc(id)
				.update({
					Comentario: newValue,
					EditReponsible: user.id,
				});
			dispatch({
				type: types.UPDATE_ANSWERS_ERROR_TIPO_SUCCESS,
			});
			dispatch(
				openSnack(
					"Comentario Editado Correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch({
				type: types.UPDATE_ANSWERS_ERROR_TIPO_FAILURE,
				payload:
					"Ha ocurrido un error al editar el comentario",
			});
		}
	};
};
// obtener una respuesta
export const getOneAnswers = (
	errorTipoId: string
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.GET_ONE_ANSWERS_SUCCESS,
		});
		try {
			const errorTipoSnap = await firestore
				.collection("RespuestasErrorestipos")
				.where("ErrorTipoId", "==", errorTipoId)
				.orderBy("FechaCreacion", "asc")
				.get();

			let answers: any = [];
			if (errorTipoSnap.docs.length > 0) {
				answers = errorTipoSnap.docs.map((x) => ({
					...x.data(),
					id: x.id,
				}));
			}
			dispatch({
				type: types.GET_ONE_ANSWERS_SUCCESS,
				payload: answers,
			});
		} catch (error: any) {
			dispatch({
				type: types.GET_ONE_ANSWERS_FAILURE,
				payload:
					"Ha ocurrido un error al obtener la respuesta",
			});
		}
	};
};
// get one subcontrato project
export const getOneSubcontratoProject = (
	projectId: string,
	revisionId: string,
	listId: string,
	recintoId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.CHECKLIST_PROJECT_GET_ONE_SUBMITTING,
		});
		try {
			const subcontratoSnap = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.doc(recintoId)
				.collection("Subcontratos")
				.get();

			const subcontract = subcontratoSnap.docs.map(
				(x: any) => ({
					...x.data(),
					id: x.id,
				})
			);
			dispatch({
				type: types.CHECKLIST_PROJECT_GET_ONE_SUCCESS,
				payload: subcontract,
			});
		} catch (error: any) {
			dispatch({
				type: types.CHECKLIST_PROJECT_GET_ONE_FAILURE,
				payload:
					"Ha ocurrido un error al obtener el checklists",
			});
		}
	};
};
// get one listado
export const getOneList = (
	projectId: string,
	revisionId: string,
	listId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.GET_ONE_LIST_SUBMITTING,
		});
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.get();

			dispatch({
				type: types.GET_ONE_LIST_SUCCESS,
				payload: { ...response.data(), id: response.id },
			});
		} catch (error: any) {
			dispatch({
				type: types.GET_ONE_LIST_FAILURE,
				payload: "Ha ocurrido un error al obtener la lista",
			});
		}
	};
};
// get one recinto Project
export const getRecintoFromProject = (
	projectId: string,
	revisionId: string,
	listId: string,
	recintoId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.GET_ONE_RECINTO_PROJECT_SUBMITTING,
		});
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.doc(recintoId)
				.get();

			const recinto = {
				...response.data(),
				id: response.id,
			};

			dispatch({
				type: types.GET_ONE_RECINTO_PROJECT_SUCCESS,
				payload: recinto,
			});
		} catch (error: any) {
			dispatch({
				type: types.GET_ONE_RECINTO_PROJECT_FAILURE,
				payload:
					"Ha ocurrido un error al obtener el recinto",
			});
		}
	};
};
//get one subcontrato project
export const getSubcontratoFromProject = (
	projectId: string,
	revisionId: string,
	listId: string,
	recintoId: string,
	subcontratoId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.GET_ONE_SUBCONTRATO_PROJECT_SUBMITTING,
		});
		try {
			const resSubcontratos = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.doc(recintoId)
				.collection("Subcontratos")
				.doc(subcontratoId)
				.get();

			const subcontrato = {
				...resSubcontratos.data(),
				id: resSubcontratos.id,
			};
			dispatch({
				type: types.GET_ONE_SUBCONTRATO_PROJECT_SUCCESS,
				payload: subcontrato,
			});
		} catch (error: any) {
			dispatch({
				type: types.GET_ONE_SUBCONTRATO_PROJECT_FAILURE,
				payload:
					"Ha ocurrido un error al obtener el subcontrato",
			});
		}
	};
};
// get one error tipo project
export const getErrorTipoFromProject = (
	projectId: string,
	revisionId: string,
	listId: string,
	recintoId: string,
	subcontratoId: string,
	errorTipoId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.GET_ONE_ERROR_TIPO_SUBMITTING,
		});
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.doc(recintoId)
				.collection("Subcontratos")
				.doc(subcontratoId)
				.collection("ErroresTipo")
				.doc(errorTipoId)
				.get();

			const errorTipo = {
				...response.data(),
				id: response.id,
			};

			dispatch({
				type: types.GET_ONE_ERROR_TIPO_SUCCESS,
				payload: errorTipo,
			});
		} catch (error: any) {
			dispatch({
				type: types.GET_ONE_ERROR_TIPO_FAILURE,
				payload:
					"Ha ocurrido un error al obtener el error tipo",
			});
		}
	};
};
// OBTENER RECINTOS DEL LISTADO
export const getRecintosProject = (
	projectId: string,
	revisionId: string,
	listId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.RECINTOS_PROJECT_SUBMITTING,
		});
		try {
			const recintoSnap = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.get();
			const recintos = recintoSnap.docs.map((x: any) => ({
				...x.data(),
				id: x.id,
			}));
			dispatch({
				type: types.RECINTOS_PROJECT_SUCCESS,
				payload: recintos,
			});
		} catch (error: any) {
			dispatch({
				type: types.RECINTOS_PROJECT_FAILURE,
				payload:
					"Ha ocurrido un error al obtener los recintos",
			});
		}
	};
};
// Obtener subcontratos

export const getSubcontratosProject = (
	projectId: string,
	revisionId: string,
	listId: string,
	recintoId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.SUBCONTRATOS_PROJECT_SUBMITTING,
		});
		try {
			const subcontratoSnap = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Recintos")
				.doc(recintoId)
				.collection("Subcontratos")
				.get();

			const subcontratos = subcontratoSnap.docs.map(
				(x: any) => ({
					...x.data(),
					id: x.id,
				})
			);
			dispatch({
				type: types.SUBCONTRATOS_PROJECT_SUCCESS,
				payload: subcontratos,
			});
		} catch (error: any) {
			dispatch({
				type: types.SUBCONTRATOS_PROJECT_FAILURE,
				payload:
					"Ha ocurrido un error al obtener los subcontratos",
			});
		}
	};
};

export const updateList = (
	values: any,
	id: string,
	revisionId: any,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isUpdateLoading(true));
		const selectedProject =
			getState().projectsReducer.revisions.selectedProject;

		if (!selectedProject)
			throw Error("No hay proyecto seleccionado.");

		try {
			if (!isChecklist) {
				indexarObservaciones(
					"NombreListado",
					id,
					values.Nombre
				);
			}

			if (!values.Responsable.FirebaseId) {
				const lists = await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.where("Nombre", "==", values.Nombre)
					.get();
				const listsMinus = await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.where("Nombre", "==", cleanString(values.Nombre))
					.get();

				const listsCaps = await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.where(
						"Nombre",
						"==",
						capitalizeFirstLetter(values.Nombre)
					)
					.get();
				const listsAllCaps = await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.where(
						"Nombre",
						"==",
						capitalizeAllFirstLetters(values.Nombre)
					)
					.get();

				const listIds = lists.docs.map((doc) => ({
					id: doc.id,
				}));
				const listMinusIds = listsMinus.docs.map((doc) => ({
					id: doc.id,
				}));
				const listCapsIds = listsCaps.docs.map((doc) => ({
					id: doc.id,
				}));
				const listAllCapsIds = listsAllCaps.docs.map(
					(doc) => ({ id: doc.id })
				);
				const listsIds = [
					...listIds,
					...listMinusIds,
					...listCapsIds,
					...listAllCapsIds,
				];

				const invalidDocs = listsIds.filter(
					(doc) => doc.id !== id
				);

				if (invalidDocs.length > 0) {
					dispatch(isUpdateLoading(false));
					throw new Error("Existe listado con ese nombre");
				}
				// else {
				//   if (listsIds.length === 1 && listsIds[0].id !== id) {
				//     dispatch(isUpdateLoading(false));
				//     throw new Error("Existe listado con ese nombre");
				//   }
				// }

				await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.doc(id)
					.update({
						Nombre: values.Nombre,
						Nombre_lower: cleanString(values.Nombre),
						Responsable: {
							Fecha:
								firebase.firestore.FieldValue.serverTimestamp(),
							FirebaseId: values.Responsable.id,
							NombreCompleto:
								values.Responsable.Nombre +
								" " +
								values.Responsable.Apellido,
							Cargo: values.Responsable.Cargo,
						},
					});

				dispatch(
					getLists(
						selectedProject.id,
						revisionId,
						types.TABLE_LIMIT_DEFAULT,
						isChecklist
					)
				);
				dispatch(
					getTotalDocs(
						selectedProject.id,
						revisionId,
						isChecklist
					)
				);
				dispatch(
					openSnack(
						"Listado Actualizada Correctamente",
						SnackState.SUCCESS
					)
				);
			} else {
				const lists = await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.where("Nombre", "==", values.Nombre)
					.get();
				const listsMinus = await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.where("Nombre", "==", cleanString(values.Nombre))
					.get();

				const listsCaps = await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.where(
						"Nombre",
						"==",
						capitalizeFirstLetter(values.Nombre)
					)
					.get();
				const listsAllCaps = await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.where(
						"Nombre",
						"==",
						capitalizeAllFirstLetters(values.Nombre)
					)
					.get();

				const listIds = lists.docs.map((doc) => ({
					id: doc.id,
				}));
				const listMinusIds = listsMinus.docs.map((doc) => ({
					id: doc.id,
				}));
				const listCapsIds = listsCaps.docs.map((doc) => ({
					id: doc.id,
				}));
				const listAllCapsIds = listsAllCaps.docs.map(
					(doc) => ({ id: doc.id })
				);
				const listsIds = [
					...listIds,
					...listMinusIds,
					...listCapsIds,
					...listAllCapsIds,
				];

				const invalidDocs = listsIds.filter(
					(doc) => doc.id !== id
				);

				if (invalidDocs.length > 0) {
					dispatch(isUpdateLoading(false));
					throw new Error("Existe listado con ese nombre");
				}
				// else {
				//   if (listsIds.length === 1 && listsIds[0].id !== id) {
				//     dispatch(isUpdateLoading(false));
				//     throw new Error("Existe listado con ese nombre");
				//   }
				// }
				await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(revisionId)
					.collection("Listado")
					.doc(id)
					.update({
						Nombre: values.Nombre,
					});

				dispatch(
					getLists(
						selectedProject.id,
						revisionId,
						types.TABLE_LIMIT_DEFAULT,
						isChecklist
					)
				);
				dispatch(
					getTotalDocs(
						selectedProject.id,
						revisionId,
						isChecklist
					)
				);
				dispatch(
					openSnack(
						"Listado Actualizada Correctamente",
						SnackState.SUCCESS
					)
				);
			}
		} catch (error: any) {
			dispatch(setError(error));
			dispatch(
				openSnack(error.toString(), SnackState.ERROR)
			);
		} finally {
			dispatch(isUpdateLoading(false));
		}
	};
};

export const deleteList = (
	listId: string
	// isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isUpdateLoading(true));
		try {
			const project =
				getState().projectsReducer.revisions
					.selectedProject;
			const revision =
				getState().revisionsReducer.selectedRevision;

			if (!project)
				throw Error("No hay proyecto seleccionado.");

			if (!revision)
				throw Error("No hay revisión seleccionada.");

			const isChecklist = isChecklistRevision(project);

			const listRef = firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(project.id)
				.collection("Revisiones")
				.doc(revision.id)
				.collection("Listado")
				.doc(listId);

			await listRef.delete();

			const revisionSnap =
				await listRef.parent.parent?.get();

			if (!revisionSnap) {
				throw Error("No se encontró la revisión.");
			}

			const { Carpetas } = revisionSnap.data() as {
				Carpetas: string | number;
			};

			if (typeof Carpetas === "string") {
				const listsSnap = await revisionSnap.ref
					.collection("Listado")
					.get();

				await revisionSnap.ref.update({
					Carpetas: listsSnap.size - 1,
				});
			} else
				await revisionSnap.ref.update({
					Carpetas: Carpetas - 1,
				});

			dispatch(
				openSnack(
					"Hemos quitado el listado.",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			console.error(error);
			dispatch(openSnack(error.message, SnackState.ERROR));
			dispatch(setError(error));
		}

		dispatch(isUpdateLoading(false));
	};
};

export const addMoreLists = (
	limit: number,
	projectId: string,
	revisionId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const { lastDoc, orderBy } = getState().listsReducer;
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.startAfter(lastDoc)
				.orderBy(orderBy, "desc")
				.limit(limit)
				.get();

			dispatch(addListsPage(mapLists(response)));
			dispatch(
				setLastDoc(response.docs[response.docs.length - 1])
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const getTotalDocs = (
	projectId: string,
	revisionId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.get();

			firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.update({
					Carpetas: "(" + response.size + ")",
				});

			dispatch(setTotalDocs(response.docs.length));
		} catch (error: any) {
			dispatch(setError(error));
		}
	};
};

export const mapLists = (
	response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
	revisionId?: string
) => {
	let lists: any[] = [];
	response.docs.forEach((doc) => {
		let data = doc.data();
		data.id = doc.id;
		data.revisionId = revisionId ?? "";
		lists.push(data);
	});
	return lists;
};

export const setOrderBy = (order: any): Action => ({
	type: types.LISTS_ORDER_BY_SET,
	payload: order,
});

export const setSelectedList = (list: any): Action => ({
	type: types.LISTS_SET_SELECTED,
	payload: list,
});
export const setSelectedProjectChecklist = (
	checklist: any
): Action => ({
	type: types.CHECKLIST_PROJECT_SET_SELECTED,
	payload: checklist,
});
const setLists = (lists: any[]): Action => ({
	type: types.LISTS_GET_DOCS,
	payload: lists,
});

const isLoading = (isLoading: boolean): Action => ({
	type: types.LISTS_LOADING,
	payload: isLoading,
});
const PdfLoading = (isLoading: boolean): Action => ({
	type: types.PDF_LOADING,
	payload: isLoading,
});
const isUpdateLoading = (isLoading: boolean): Action => ({
	type: types.LISTS_UPDATE_LOADING,
	payload: isLoading,
});

const setError = (error: string): Action => ({
	type: types.LISTS_FAILURE,
	payload: error,
});

const addListsPage = (revision: any[]): Action => ({
	type: types.LISTS_ADD_DOCS,
	payload: revision,
});

const setTotalDocs = (totalDocs: number): Action => ({
	type: types.LISTS_SET_TOTAL_DOCS,
	payload: totalDocs,
});

const setLastDoc = (lastDoc: any): Action => ({
	type: types.LISTS_SET_LAST_DOC,
	payload: lastDoc,
});

export const setSelectedTypeErrorProject = (
	errorType: any
): Action => ({
	type: types.ERROR_TIPO_PROJECT_SET_SELECTED,
	payload: errorType,
});
export const setSelectedErrorType = (
	errorType?: any[]
): Action => ({
	type: types.ERROR_TIPO_SET_SELECTED,
	payload: errorType,
});

export const setSelectedAnswers = (
	answers?: any[]
): Action => ({
	type: types.ANSWERS_SET_SELECTED,
	payload: answers,
});
export const setSelectedRecintoProject = (
	recinto?: any
): Action => ({
	type: types.RECINTO_SET_SELECTED,
	payload: recinto,
});
export const setSelectedSubcontratoProject = (
	subcontrato?: any
): Action => ({
	type: types.SUBCONTRATO_PROJECT_SET_SELECTED,
	payload: subcontrato,
});
export const setSelectedErrorTipoProject = (
	errorTipo?: any
): Action => ({
	type: types.ERROR_TIPO_PROJECT_SET_SELECTED,
	payload: errorTipo,
});
