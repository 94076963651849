import { Usuario } from "src/models/Usuario";
import * as types from "../../constants";
import Firebase from "firebase";

export interface IAuthState {
	user?: {
		og: Usuario;
		id?: string;
		localId: string;
		email?: string;
		name?: string;
		image?: string;
		token?: string;
		tipoUsuario:
			| "Administrador"
			| "AdministradorEmpresa"
			| "UsuarioEmpresa";
		empresaReference: Firebase.firestore.DocumentReference;
		activado?: any;
		modulos: {
			Revisiones: boolean;
			ChecklistRevisiones: boolean;
			Checklists: boolean;
		};
	};
}

const initialState = {} as IAuthState;

export default function authReducer(
	state = initialState,
	actions: any
): IAuthState {
	switch (actions.type) {
		case types.AUTH_SIGN_IN_SUCCESS:
			return {
				...state,
				user: {
					...actions,
				},
			};

		case types.AUTH_SIGN_OUT:
			return {
				...state,
				user: undefined,
			};

		default:
			return state;
	}
}
