import {
	Box,
	BoxProps,
	TextField,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { FC, useMemo } from "react";
import { Empresa } from "src/models/Empresa";

type Props = {
	module: "CheckList" | "Revisiones" | "ChecklistsOT";
	formik: FormikProps<Empresa>;
	disabled?: boolean;
} & BoxProps;

export const ModuleLimitForm: FC<Props> = ({
	module,
	formik,
	disabled,
	...props
}) => {
	const { values, handleChange, touched, errors } = formik;

	const projectLimitValue = useMemo(() => {
		if (module === "CheckList")
			return values.CheckList.LimiteCheckList;
		else if (module === "Revisiones")
			return values.Revisiones.LimiteRevisiones;
		else return values?.[module]?.LimiteProyectos ?? 0;
	}, [values, module]);

	const projectLimitError = useMemo(() => {
		if (module === "CheckList")
			return (
				touched?.CheckList?.LimiteCheckList &&
				!!errors?.CheckList?.LimiteCheckList
			);
		else if (module === "Revisiones")
			return (
				touched?.Revisiones?.LimiteRevisiones &&
				!!errors?.Revisiones?.LimiteRevisiones
			);
		else
			return (
				// @ts-ignore
				touched[module]?.LimiteProyectos &&
				// @ts-ignore
				!!errors[module]?.LimiteProyectos
			);
	}, [touched, errors, module]);

	const projectLimitName = useMemo(() => {
		if (module === "CheckList")
			return "CheckList.LimiteCheckList";
		else if (module === "Revisiones")
			return "Revisiones.LimiteRevisiones";
		else return `${module}.LimiteProyectos`;
	}, [module]);

	const helperText = useMemo(() => {
		if (module === "CheckList")
			return (
				touched?.CheckList?.LimiteCheckList &&
				errors?.CheckList?.LimiteCheckList
			);
		else if (module === "Revisiones")
			return (
				touched?.Revisiones?.LimiteRevisiones &&
				errors?.Revisiones?.LimiteRevisiones
			);
		else
			return (
				// @ts-ignore
				touched?.[module]?.LimiteProyectos &&
				// @ts-ignore
				errors?.[module]?.LimiteProyectos
			);
	}, [touched, errors, module]);

	return (
		<Box display="flex" gridGap={10} {...props}>
			<TextField
				// autoFocus
				label="Limite de proyectos"
				type="number"
				disabled={disabled}
				autoComplete="off"
				value={projectLimitValue}
				name={projectLimitName}
				onChange={handleChange}
				error={projectLimitError}
				helperText={helperText}
				fullWidth
			/>
			<TextField
				label="Límite de usuarios"
				type="number"
				autoComplete="off"
				value={values?.[module]?.LimiteUsuarios ?? 10}
				name={`${module}.LimiteUsuarios`}
				onChange={handleChange}
				disabled={disabled}
				error={
					// @ts-ignore
					touched[module]?.LimiteUsuarios &&
					// @ts-ignore
					!!errors[module]?.LimiteUsuarios
				}
				helperText={
					// @ts-ignore
					touched[module]?.LimiteUsuarios &&
					// @ts-ignore
					errors[module]?.LimiteUsuarios
				}
				fullWidth
			/>
		</Box>
	);
};
