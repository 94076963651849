import {
	Box,
	Breadcrumbs,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	MenuItem,
	Slider,
	TextField,
	Typography,
} from "@material-ui/core";
import naturalSort from "natural-sort";
import { FC, useMemo, useState, useEffect } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useSheetValidator } from "src/hooks/validators/useSheetValidator";
import { useSelector } from "src/redux/store";

export const CreateSheetDialog: FC<DialogProps> = (
	props
) => {
	const { selectedBusiness } = useBusiness();

	const { assignedPlace, checklist, project } = useSelector(
		(s) => s.neoChecklistReducer.selected
	);

	const [mode, setMode] = useState<
		"Sector y recinto" | "Pasillos" | "Otro"
	>("Sector y recinto");

	const {
		values,
		handleChange,
		setFieldValue,
		isSubmitting,
		touched,
		errors,
		submitForm,
		maxUnits,
	} = useSheetValidator(mode, (_, { resetForm }) => {
		props.onClose?.({}, "backdropClick");
		resetForm();
	});

	const percentage = useMemo(() => {
		const max = assignedPlace?.Cantidad ?? 0;
		const current = values.Unidades;

		return Math.round((current / max) * 100);
	}, [assignedPlace?.Cantidad, values.Unidades]);

	useEffect(() => {
		setFieldValue("Sector", "");
		setFieldValue("Recinto", "");
		setFieldValue("Pasillo", "");
		setFieldValue("Otro", "");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode]);

	const sorted = useMemo(() => {
		if (!selectedBusiness) return;

		return {
			elements: [
				...(selectedBusiness.ElementosOT ?? []),
			].sort((a, b) => naturalSort()(a, b)),

			sectors: [
				...(selectedBusiness.SectoresOT ?? []),
			].sort((a, b) => naturalSort()(a, b)),

			precincts: [
				...(selectedBusiness.RecintosOT ?? []),
			].sort((a, b) => naturalSort()(a, b)),

			corridors: [
				...(selectedBusiness.PasillosOT ?? []),
			].sort((a, b) => naturalSort()(a, b)),

			others: [...(selectedBusiness.OtrosOT ?? [])].sort(
				(a, b) => naturalSort()(a, b)
			),
		};
	}, [selectedBusiness]);

	return (
		<Dialog {...props}>
			<DialogTitle
				bigText="Agregar hoja de respuestas"
				smallText={
					<Breadcrumbs>
						<Typography>{project?.Nombre}</Typography>
						<Typography>{checklist?.Nombre}</Typography>
						<Typography>{assignedPlace?.Nombre}</Typography>
					</Breadcrumbs>
				}
			/>

			<DialogContent
				style={{
					display: "flex",
					flexDirection: "column",
					gap: 10,
				}}
			>
				{checklist?.Tipo === "Obra Gruesa" && (
					<>
						<TextField
							id="PlanoRevisor"
							value={values.PlanoRevisor}
							onChange={handleChange}
							error={
								touched.PlanoRevisor &&
								!!errors.PlanoRevisor
							}
							helperText={
								touched.PlanoRevisor && errors.PlanoRevisor
							}
							label="Plano revisor"
							variant="outlined"
							size="small"
						/>

						<TextField
							value={values.Elemento}
							onChange={(ev) =>
								setFieldValue("Elemento", ev.target.value)
							}
							error={touched.Elemento && !!errors.Elemento}
							helperText={
								touched.Elemento && errors.Elemento
							}
							label="Elemento"
							variant="outlined"
							size="small"
							select
						>
							{sorted?.elements.map((element) => (
								<MenuItem key={element} value={element}>
									{element}
								</MenuItem>
							))}
						</TextField>

						<TextField
							id="Ubicacion"
							value={values.Ubicacion}
							onChange={handleChange}
							error={
								touched.Ubicacion && !!errors.Ubicacion
							}
							helperText={
								touched.Ubicacion && errors.Ubicacion
							}
							label="Ubicación"
							variant="outlined"
							size="small"
						/>
					</>
				)}

				{checklist?.Tipo === "Terminaciones" && (
					<>
						<TextField
							label="Modo"
							select
							size="small"
							variant="outlined"
							value={mode}
							onChange={(ev) =>
								setMode(ev.target.value as typeof mode)
							}
						>
							<MenuItem value="Sector y recinto">
								Sector y recinto
							</MenuItem>
							<MenuItem value="Pasillos">Pasillos</MenuItem>
							<MenuItem value="Otro">Otro</MenuItem>
						</TextField>

						{mode === "Sector y recinto" && (
							<>
								<TextField
									value={values.Sector}
									onChange={(ev) =>
										setFieldValue("Sector", ev.target.value)
									}
									label="Sector"
									error={touched.Sector && !!errors.Sector}
									helperText={
										touched.Sector && errors.Sector
									}
									variant="outlined"
									size="small"
									select
								>
									<MenuItem value={""}>Sin sector</MenuItem>

									{sorted?.sectors.map((sector) => (
										<MenuItem key={sector} value={sector}>
											{sector}
										</MenuItem>
									))}
								</TextField>

								<TextField
									value={values.Recinto}
									onChange={(ev) =>
										setFieldValue(
											"Recinto",
											ev.target.value
										)
									}
									label="Recinto"
									error={
										touched.Recinto && !!errors.Recinto
									}
									helperText={
										touched.Recinto && errors.Recinto
									}
									variant="outlined"
									size="small"
									select
								>
									{sorted?.precincts.map((precinct) => (
										<MenuItem
											key={precinct}
											value={precinct}
										>
											{precinct}
										</MenuItem>
									))}
								</TextField>
							</>
						)}

						{mode === "Pasillos" && (
							<TextField
								value={values.Pasillo}
								onChange={(ev) =>
									setFieldValue("Pasillo", ev.target.value)
								}
								label="Pasillo"
								error={touched.Pasillo && !!errors.Pasillo}
								helperText={
									touched.Pasillo && errors.Pasillo
								}
								variant="outlined"
								size="small"
								select
							>
								<MenuItem value={""}>Sin pasillo</MenuItem>

								{sorted?.corridors.map((corridor) => (
									<MenuItem key={corridor} value={corridor}>
										{corridor}
									</MenuItem>
								))}
							</TextField>
						)}

						{mode === "Otro" && (
							<TextField
								value={values.Otro}
								onChange={(ev) =>
									setFieldValue("Otro", ev.target.value)
								}
								label="Otro"
								error={touched.Otro && !!errors.Otro}
								helperText={touched.Otro && errors.Otro}
								variant="outlined"
								size="small"
								select
							>
								<MenuItem value={""}>Sin otro</MenuItem>
								{sorted?.others.map((other) => (
									<MenuItem key={other} value={other}>
										{other}
									</MenuItem>
								))}
							</TextField>
						)}
					</>
				)}

				<Box
					display="flex"
					flexDirection="row"
					gridGap={10}
					alignItems="center"
				>
					<TextField
						style={{ width: 200 }}
						label="Cantidad"
						id="Unidades"
						value={values.Unidades}
						onChange={handleChange}
						error={touched.Unidades && !!errors.Unidades}
						type="number"
						variant="outlined"
						size="small"
						FormHelperTextProps={{
							style: { textAlign: "right" },
						}}
						helperText={checklist?.Unidad}
						inputProps={{ min: 0, max: maxUnits }}
					/>
					<Slider
						value={values.Unidades}
						onChange={(e, v) =>
							setFieldValue("Unidades", v)
						}
						min={0}
						max={maxUnits}
						marks={[
							{ value: 0, label: `0` },
							{
								value: maxUnits / 2,
								label: `${Math.round(maxUnits / 2)} ${
									checklist?.Unidad
								}`,
							},
							{
								value: maxUnits,
								label: `${maxUnits}`,
							},
						]}
						valueLabelDisplay="auto"
						valueLabelFormat={(v) => `${percentage}%`}
						style={{ zIndex: 10 }}
					/>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					disabled={isSubmitting}
					onClick={() =>
						props.onClose?.({}, "backdropClick")
					}
				>
					Cancelar
				</Button>

				<Button
					onClick={submitForm}
					color="primary"
					variant="contained"
					disabled={isSubmitting}
				>
					{isSubmitting ? (
						<CircularProgress size={24} />
					) : (
						"Crear"
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
