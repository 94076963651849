import {
	Box,
	Breadcrumbs,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	Tab,
	Tabs,
	Toolbar,
	Tooltip,
	Typography,
	darken,
} from "@material-ui/core";
import { FC, useEffect, useState, useMemo } from "react";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { Add, Person } from "@material-ui/icons";
import { Edit } from "react-feather";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { NeoPlaceAssignDialog } from "../../PlacesOT/Dialog/NeoPlaceAssign";
import { PlaceOT } from "src/models/OT/Place";

const ICON_BUTTON_STYLE = {
	width: 40,
	height: 40,
	alignSelf: "center",
};

export const NeoChecklistPlacesDialog: FC<DialogProps> = ({
	...props
}) => {
	const [modal, setModal] = useState(0);
	const [mode, setMode] = useState<"assign" | "edit">(
		"assign"
	);

	const {
		loading,
		selected,
		checklist,
		project,
		toggle,
		isSelected,
		get,
	} = useProjectsOT();

	const { Unidad } = selected.checklist ?? {};

	const handleClose = () => {
		props.onClose?.({}, "backdropClick");
		toggle.assignedPlace([]);
		toggle.place([]);
	};

	useEffect(() => {
		if (checklist.availablePlaces.length === 0)
			setMode("edit");
	}, [checklist.availablePlaces.length]);

	const allAreSelected = useMemo(() => {
		if (mode === "assign")
			return !!checklist.availablePlaces?.every(
				isSelected.place
			);
		if (mode === "edit")
			return !!checklist.places?.every(
				isSelected.assignedPlace
			);

		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		mode,
		checklist.availablePlaces,
		checklist.places,
		selected.places,
		selected.assignedPlaces,
	]);

	const handleToggleAll = async () => {
		if (mode === "assign") {
			if (allAreSelected) toggle.place([]);
			else toggle.place(checklist.availablePlaces);
			toggle.assignedPlace([]);
		} else {
			if (allAreSelected) toggle.assignedPlace([]);
			else toggle.assignedPlace(checklist.places ?? []);
			toggle.place([]);
		}
	};

	useEffect(() => {
		if (!selected.checklist) return;
		if (!props.open) return;
		get.checklist.places();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected.checklist, props.open]);

	useEffect(() => {
		if (!selected.project) return;
		if (!props.open) return;
		get.project.places();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected.project, props.open]);

	const availablePlaces = useMemo(() => {
		const available = project.places;
		const assigned = checklist.places;

		if (!available || !assigned) return [];

		const availableMap = new Map<string, PlaceOT>();

		available.forEach((place) => {
			availableMap.set(place.Nombre, place);
		});

		assigned.forEach((place) => {
			availableMap.delete(place.Nombre);
		});

		return Array.from(availableMap.values());
	}, [project.places, checklist.places]);

	return (
		<>
			{/* main dialog */}
			<Dialog {...props}>
				<DialogTitle
					onClose={handleClose}
					bigText="Asignar espacios"
					smallText={
						<Breadcrumbs>
							<Typography>
								{selected.project?.Nombre}
							</Typography>
							<Typography>
								{selected.checklist?.Nombre}
							</Typography>
						</Breadcrumbs>
					}
				/>

				<DialogContent
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
						height: "100%",
					}}
				>
					<Paper elevation={3}>
						<Toolbar style={{ gap: 10 }}>
							<Tooltip title="Seleccionar todo">
								{allAreSelected === null ? (
									<CircularProgress size={24} />
								) : (
									<Checkbox
										checked={allAreSelected}
										onClick={handleToggleAll}
									/>
								)}
							</Tooltip>

							<Tabs
								variant="fullWidth"
								style={{ flexGrow: 1 }}
								value={mode}
								onChange={(_, value) => setMode(value)}
							>
								{checklist.availablePlaces.length > 0 && (
									<Tab
										label="Espacios disponibles"
										value="assign"
									/>
								)}
								<Tab
									label="Espacios asignados"
									value="edit"
								/>
							</Tabs>

							{mode === "assign" && (
								<Tooltip
									title={
										selected.places.length === 0
											? "Primero, selecciona algunos espacios."
											: "Asigna los espacios seleccionados."
									}
								>
									<span>
										<Button
											style={{
												width: 100,
												display: "flex",
												padding: 5,
												flexDirection: "row",
												justifyContent: "space-around",
											}}
											variant="contained"
											size="small"
											onClick={() => {
												setModal(1);
											}}
											color="primary"
											disabled={
												selected.places.length === 0
											}
										>
											<Typography
												style={{ marginRight: 5 }}
											>
												Asignar
											</Typography>
											<Add fontSize="small" />
										</Button>
									</span>
								</Tooltip>
							)}

							{mode === "edit" && (
								<Tooltip
									title={
										selected.places.length === 0
											? "Primero, selecciona algunos espacios."
											: "Modifica los espacios seleccionados."
									}
								>
									<span>
										<Button
											style={{
												width: 100,
												display: "flex",
												padding: 5,
												flexDirection: "row",
												justifyContent: "space-around",
											}}
											variant="contained"
											size="small"
											onClick={() => {
												setModal(1);
											}}
											color="primary"
											disabled={
												selected.assignedPlaces.length === 0
											}
										>
											<Typography
												style={{ marginRight: 5 }}
											>
												Modificar
											</Typography>
											<Edit
												color={
													selected.assignedPlaces.length ===
													0
														? "gray"
														: "white"
												}
												size={15}
												style={{ marginTop: -2 }}
											/>
										</Button>
									</span>
								</Tooltip>
							)}
						</Toolbar>
					</Paper>

					<Paper
						elevation={3}
						style={{ overflow: "auto", height: "100%" }}
					>
						{/* list of available places */}
						{mode === "assign" &&
							availablePlaces.map((place, i) => (
								<Box
									bgcolor={
										i % 2 !== 0
											? "white"
											: darken("#fff", 0.05)
									}
									display="flex"
									flexDirection="row"
									key={place._ref!.id}
								>
									<MenuItem
										style={{ flexGrow: 1 }}
										onClick={() => toggle.place(place)}
									>
										<ListItemIcon>
											<Checkbox
												checked={isSelected.place(place)}
											/>
										</ListItemIcon>
										<ListItemText>
											{place.Nombre}
										</ListItemText>
									</MenuItem>

									<IconButton
										style={ICON_BUTTON_STYLE}
										color="primary"
										size="small"
										onClick={() => {
											toggle.place([place]);
											setModal(1);
										}}
									>
										<Add fontSize="small" />
									</IconButton>
								</Box>
							))}

						{/* list of assigned places */}
						{mode === "edit" &&
							checklist.places?.map((place, i) => (
								<Box
									bgcolor={
										i % 2 !== 0
											? "white"
											: darken("#fff", 0.05)
									}
									key={place._ref!.id}
									display="flex"
									flexDirection="row"
								>
									<MenuItem
										style={{ flexGrow: 1 }}
										onClick={() =>
											toggle.assignedPlace(place)
										}
									>
										<ListItemIcon>
											<Checkbox
												checked={isSelected.assignedPlace(
													place
												)}
											/>
										</ListItemIcon>

										<ListItemText
											primary={place.Nombre}
											secondary={`${place.Cantidad} ${Unidad}`}
										/>

										<ListItemIcon
											style={{
												marginRight: 10,
												alignItems: "center",
											}}
										>
											<Person />
											{place.Revisores.length} / 6 revisores
										</ListItemIcon>

										<ListItemIcon>
											<Chip
												style={{
													width: 100,
													backgroundColor: place.Asignado
														? "limegreen"
														: "red",
													color: "white",
												}}
												label={
													place.Asignado
														? "Asignado"
														: "Sin asignar"
												}
											/>
										</ListItemIcon>
									</MenuItem>

									<Tooltip title="Modifica este espacio">
										<IconButton
											onClick={() => {
												toggle.assignedPlace([place]);
												setModal(1);
											}}
											style={ICON_BUTTON_STYLE}
											color="primary"
										>
											<Edit />
										</IconButton>
									</Tooltip>
								</Box>
							))}
					</Paper>
				</DialogContent>

				<DialogActions>
					<Button
						color="primary"
						variant="contained"
						disabled={loading}
						onClick={handleClose}
					>
						Listo
					</Button>
				</DialogActions>
			</Dialog>

			<NeoPlaceAssignDialog
				mode={mode}
				open={modal === 1}
				onClose={() => setModal(0)}
			/>
		</>
	);
};
