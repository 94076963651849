import {
	Button,
	Checkbox,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	Autocomplete,
	Alert,
	AlertTitle,
} from "@material-ui/lab";
import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUsers } from "../../redux/actions/usersActions";
import { IAuthState } from "../../redux/reducers/authReducer";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { IChecklistState } from "../../redux/reducers/checklistReducer";
import { RootState } from "../../redux/store/reducer";
import { IUsersState } from "../../redux/reducers/usersReducer";
import { useStyles } from "../../theme/useStyles";
import { getChecklist } from "src/redux/actions/checklistActions";

export interface SimpleDialogProps {
	open: boolean;
	onClose: () => void;
	onAccept: (value: any) => void;
}

export const SetListadoDialog = (
	props: SimpleDialogProps
) => {
	const { onClose, onAccept, open } = props;
	const dispatch = useDispatch();

	const { user } = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const { checklist } = useSelector<
		RootState,
		IChecklistState
	>((state) => state.checklistReducer as any);
	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const [selectedResponsible, setSelectedResponsible] =
		React.useState<any | null>(null);
	const { businessId } = useParams<any>();

	React.useEffect(() => {
		if (!open) return;

		if (selectedBusiness?.id) {
			if (user?.tipoUsuario === "AdministradorEmpresa") {
				dispatch(getUsers({ id: selectedBusiness.id }));
			} else {
				dispatch(
					getUsers({
						id: selectedBusiness.id,
					})
				);
			}
		} else {
			if (user?.tipoUsuario === "AdministradorEmpresa") {
				dispatch(getUsers({ id: businessId }));
			} else {
				dispatch(getUsers({ id: businessId }));
			}
		}
	}, [open]);

	React.useEffect(() => {
		dispatch(getChecklist(selectedBusiness?.id));
	}, [selectedBusiness?.id]);

	// Lógica alerta de aceptamiento:
	const [alertIsOpen, setAlertIsOpen] =
		React.useState(false);
	const [userAccepts, setUserAccepts] =
		React.useState(false);

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="simple-dialog-title"
			open={open}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle id="simple-dialog-title">
				Seleccionar listado
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Autocomplete
							id="combo-box-demo"
							options={checklist ?? []}
							getOptionLabel={(option) => option.Nombre}
							onChange={(_, value) => {
								!alertIsOpen && setAlertIsOpen(true);
								setSelectedResponsible(value);
							}}
							fullWidth
							renderInput={(params) => (
								<TextField
									{...params}
									label="Checklist"
									variant="outlined"
								/>
							)}
						/>
					</Grid>
				</Grid>
				{/* Alerta de aceptamiento: */}
				<Grid item xs={12}>
					<Collapse in={alertIsOpen}>
						<Alert
							severity="error"
							style={{ marginTop: "1rem" }}
						>
							<AlertTitle>ATENCIÓN:</AlertTitle>
							<Typography>
								Reemplazar un Listado quitará TODAS las
								Observaciones. Esta acción NO se puede
								revertir. Por favor, proceder con cuidado.
							</Typography>

							<FormControlLabel
								control={
									<Checkbox
										checked={userAccepts}
										onChange={(ev) =>
											setUserAccepts(ev.target.checked)
										}
									/>
								}
								label="Comprendo los riesgos."
							/>
						</Alert>
					</Collapse>
				</Grid>
				<Grid item xs={12}></Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancelar
				</Button>
				<Button
					disabled={!userAccepts}
					onClick={() => {
						onAccept(selectedResponsible);
					}}
					color="primary"
					autoFocus
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
