import { ProjectsPageOT } from "src/pages/NeoProjects";
import { ChecklistsPageOT } from "src/pages/NeoProjects/[neoId]";
import { PlacesPageOT } from "src/pages/NeoProjects/[neoId]/[neoChecklistId]";
import { PlaceAnswersPageOT } from "src/pages/NeoProjects/[neoId]/[neoChecklistId]/[neoPlaceId]";
import { DeviationByQuestionGraphPageOT } from "src/pages/NeoProjects/[neoId]/desviacion-por-pregunta";
import { CheckproRoute } from "./CheckproRoute";
import { RevisionGoalGraphPageOT } from "src/pages/NeoProjects/[neoId]/metas-revision";
import { DeviationByChecklistGraphPageOT } from "src/pages/NeoProjects/[neoId]/desviacion-listas-chequeo";
import { PERMISOS } from "src/constants/permisos";
import { BusinessAdminGuard } from "src/guards";
import { BusinessCenter } from "@material-ui/icons";
import { DeviationByParameterPageOT } from "src/pages/NeoProjects/[neoId]/desviacion-por-espacio";

export const adminRoutesOT: CheckproRoute[] = [
	{
		path: "/administrador/empresas/:businessId/neo-projects",
		name: "Proyectos NEO",
		component: ProjectsPageOT,
	},
	{
		path: "/administrador/empresas/:businessId/neo-projects/:neoProjectId",
		name: "Checklists OT",
		component: ChecklistsPageOT,
	},
	{
		path: "/administrador/empresas/:businessId/neo-projects/:neoProjectId/metas-revision",
		name: "Metas de Revisión OT",
		component: RevisionGoalGraphPageOT,
	},
	{
		path: "/administrador/empresas/:businessId/neo-projects/:neoProjectId/rechazadas-listas-chequeo",
		name: "Desviación por Checklist",
		component: DeviationByChecklistGraphPageOT,
	},
	{
		path: "/administrador/empresas/:businessId/neo-projects/:neoProjectId/rechazadas-parametro",
		name: "Desviación por espacio",
		component: DeviationByParameterPageOT,
	},
	{
		path: "/administrador/empresas/:businessId/neo-projects/:neoProjectId/rechazadas-pregunta",
		name: "Desviación por preguntas",
		component: DeviationByQuestionGraphPageOT,
	},
	{
		path: "/administrador/empresas/:businessId/neo-projects/:neoProjectId/:neoChecklistId",
		name: "Espacios OT",
		component: PlacesPageOT,
	},
	{
		path: "/administrador/empresas/:businessId/neo-projects/:neoProjectId/:neoChecklistId/:neoPlaceId",
		name: "Respuestas OT",
		component: PlaceAnswersPageOT,
	},
];

export const businessAdminRoutesOT: CheckproRoute[] = [
	{
		path: "/administrador/empresas/neo-projects",
		name: "Proyectos NEO",
		component: ProjectsPageOT,
	},
	{
		path: "/administrador/empresas/neo-projects/:neoProjectId",
		name: "Checklists OT",
		component: ChecklistsPageOT,
	},
	{
		path: "/administrador/empresas/neo-projects/:neoProjectId/metas-revision",
		name: "Metas de Revisión",
		component: RevisionGoalGraphPageOT,
	},
	{
		path: "/administrador/empresas/neo-projects/:neoProjectId/rechazadas-listas-chequeo",
		name: "Desviación por Checklist",
		component: DeviationByChecklistGraphPageOT,
	},
	{
		path: "/administrador/empresas/neo-projects/:neoProjectId/rechazadas-parametro",
		name: "Desviación por espacio",
		component: DeviationByParameterPageOT,
	},
	{
		path: "/administrador/empresas/neo-projects/:neoProjectId/rechazadas-pregunta",
		name: "Gráfico Respuestas OT",
		component: DeviationByQuestionGraphPageOT,
	},
	{
		path: "/administrador/empresas/neo-projects/:neoProjectId/:neoChecklistId",
		name: "Espacios OT",
		component: PlacesPageOT,
	},
	{
		path: "/administrador/empresas/neo-projects/:neoProjectId/:neoChecklistId/rechazadas-pregunta",
		name: "Gráfico Respuestas OT",
		component: DeviationByQuestionGraphPageOT,
	},
	{
		path: "/administrador/empresas/neo-projects/:neoProjectId/:neoChecklistId/:neoPlaceId",
		name: "Respuestas OT",
		component: PlaceAnswersPageOT,
	},
];

export const businessAdminSidebarOT: CheckproRoute = {
	path: "/administrador",
	id: "Checklists",
	name: "Checklists",
	guard: BusinessAdminGuard,
	icon: (
		<svg
			fill={"white"}
			height={24}
			width={24}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
		>
			<path d="M17,21L14.25,18L15.41,16.84L17,18.43L20.59,14.84L21.75,16.25M12.8,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19A2,2 0 0,1 21,5V12.8C20.12,12.29 19.09,12 18,12L17,12.08V11H7V13H14.69C13.07,14.07 12,15.91 12,18C12,19.09 12.29,20.12 12.8,21M12,15H7V17H12M17,7H7V9H17" />
		</svg>
	),
	tipoUser: PERMISOS.ADMINISTRADOR_EMPRESA,
	children: [
		{
			name: "Proyectos",
			path: "/administrador/empresas/neo-projects",
			component: ProjectsPageOT,
		},
	],
};

export const businessUserSidebarOT: CheckproRoute = {
	path: "/administrador",
	id: "Checklists",
	name: "Checklists",
	guard: BusinessAdminGuard,
	icon: <BusinessCenter />,
	tipoUser: PERMISOS.USUARIO_EMPRESA,
	children: [
		{
			name: "Proyectos",
			path: "/administrador/empresas/neo-projects",
			component: ProjectsPageOT,
		},
	],
};
