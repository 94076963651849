import {
	Breadcrumbs,
	Divider,
	Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { LinkNeoChecklists } from "src/components/CheckPro/Links/NeoChecklists";
import { LinkProjectsOT } from "src/components/CheckPro/Links/NeoProjects";
import { NeoPlacesTable } from "src/components/ProjectsOT/PlacesOT/Table";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

export type UrlParamsOT = Partial<{
	neoId: string;
	neoChecklistId: string;
	neoPlaceId: string;
	responseId: string;
}>;

export const PlacesPageOT = () => {
	const { selectedBusiness } = useBusiness();
	const { selected, get } = useProjectsOT();

	useEffect(() => {
		if (!selected.checklist) return;
		get.checklist.places();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected.checklist]);

	return (
		<>
			<Helmet title="Checklist" />

			<Breadcrumbs style={{ fontSize: 20 }}>
				<Typography variant="h3" style={{ color: "black" }}>
					{selectedBusiness?.Nombre ?? "Empresa"}
				</Typography>
				<Typography variant="h3" style={{ color: "black" }}>
					{selected.project?.Nombre ?? "Proyecto"}
				</Typography>
				<Typography variant="h3" style={{ color: "black" }}>
					{selected.checklist?.Nombre ?? "Lista de chequeo"}
				</Typography>
			</Breadcrumbs>

			<Breadcrumbs>
				<LinkEmpresas />
				<LinkProjectsOT />
				<LinkNeoChecklists />
				<Typography>Espacios</Typography>
			</Breadcrumbs>

			<Divider
				style={{ marginTop: 10, marginBottom: 10 }}
			/>

			<NeoPlacesTable />
		</>
	);
};
