import * as React from "react";
import { Icon } from "@material-ui/core";
import { ReactComponent as RevisionesSvg } from "./revisiones.svg";
import { IconProps } from "./IconProps";
import { grey } from "@material-ui/core/colors";

export const RevisionesIcon: React.FC<IconProps> = (props) => {
  const fill = !props?.disabled ? props?.style?.fill : grey[400];
  return (
    <Icon>
      <RevisionesSvg style={{ ...props.style, fill }} />
    </Icon>
  );
};
