import {
	Breadcrumbs,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	Typography,
} from "@material-ui/core";
import { FC } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";

export const RemoveSheetDialogOT: FC<DialogProps> = (
	props
) => {
	const { selected, remove, loading, set } =
		useProjectsOT();

	const handleRemove = async () => {
		if (!selected.sheet) return;

		await remove.place.sheet(selected.sheet);
		set.sheet(null);
		props.onClose?.({}, "escapeKeyDown");
	};

	return (
		<Dialog {...props}>
			<DialogTitle
				onClose={props.onClose}
				bigText="Eliminar Checklist"
				smallText={
					<Breadcrumbs>
						<Typography>
							{selected.project?.Nombre}
						</Typography>
						<Typography>
							{selected.checklist?.Nombre}
						</Typography>
						<Typography>
							{selected.assignedPlace?.Nombre}
						</Typography>
					</Breadcrumbs>
				}
			/>

			<DialogContent>
				<Typography>
					¿Estás seguro de que deseas eliminar este
					Checklist? Esto no se puede deshacer.
				</Typography>
			</DialogContent>

			<DialogActions>
				<Button
					disabled={loading}
					onClick={() =>
						props.onClose?.({}, "escapeKeyDown")
					}
				>
					Cancelar
				</Button>

				<Button
					color="primary"
					variant="contained"
					disabled={loading}
					onClick={handleRemove}
				>
					{loading ? (
						<CircularProgress size={24} />
					) : (
						"Eliminar"
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
