import {
	Box,
	Button,
	CircularProgress,
	TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { FC } from "react";
import * as Yup from "yup";
import Axios from "axios";
import { useDispatch, useSelector } from "src/redux/store";

type MailChangerProps = {
	email?: string;
	setEmail?: (email: string) => void;
	userId?: string;
	enableReinitialize?: boolean;
};

export const MailChanger: FC<MailChangerProps> = ({
	userId,
	setEmail,
	email = "",
	enableReinitialize = true,
}) => {
	const {
		values,
		dirty,
		errors,
		setFieldValue,
		submitForm,
		isSubmitting,
	} = useFormik({
		validateOnChange: false,
		enableReinitialize,
		initialValues: {
			email: "",
		},
		onSubmit: async (
			{ email: newEmail },
			{ setSubmitting }
		) => {
			if (!userId) return;

			try {
				const res = await Axios.post(
					`https://us-central1-checkpro-3a90a.cloudfunctions.net/app/users/update-mail/${userId}`,
					{ email: newEmail, previousMail: email }
				);
				setEmail?.(newEmail);
				alert(res.data);
			} catch (error: any) {
				alert(
					error?.response?.data ||
						"No pudimos actualizar el email."
				);
			}
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Email inválido")
				.required("Email requerido"),
		}),
	});

	const { email: _email } = values;

	return (
		<Box
			display="flex"
			flexDirection="row"
			style={{ gap: 5 }}
		>
			<TextField
				variant="outlined"
				label="Mail para el inicio de sesión"
				placeholder={email}
				fullWidth
				value={_email}
				error={!!errors.email}
				helperText={errors.email}
				onChange={(e) => {
					setFieldValue("email", e.target.value);
				}}
			/>
			<Button
				variant="contained"
				color="primary"
				disabled={isSubmitting || !dirty}
				onClick={submitForm}
			>
				{isSubmitting ? <CircularProgress /> : "Actualizar"}
			</Button>
		</Box>
	);
};
