import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
} from "@material-ui/core";
import { FC, useState } from "react";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { CircularProgress } from "@material-ui/core";
import { ChecklistOT } from "src/models/OT/Checklist";

type Props = {
	selected?: ChecklistOT | null;
} & DialogProps;

export const DeleteBusinessNeoChecklistDialog: FC<Props> =
	({ selected, ...props }) => {
		const { checklists } = useBusiness();

		const [loading, setLoading] = useState(false);

		const handleDelete = async () => {
			if (!selected?.Id) return;
			setLoading(true);
			await checklists.remove({
				Id: selected.Id,
			});
			setLoading(false);
			props.onClose?.({}, "escapeKeyDown");
		};

		return (
			<Dialog {...props}>
				<DialogTitle>
					Eliminar {selected?.Nombre}
				</DialogTitle>

				<DialogContent>
					¿Estás seguro que deseas eliminar esta lista de
					chequeo? Esta acción no afectará los proyectos que
					ya la tengan asignada.
				</DialogContent>

				<DialogActions>
					<Button
						disabled={loading}
						onClick={() =>
							props.onClose?.({}, "escapeKeyDown")
						}
					>
						Cancelar
					</Button>

					<Button
						onClick={handleDelete}
						disabled={loading}
						color="primary"
						variant="contained"
					>
						{loading ? (
							<CircularProgress size={24} />
						) : (
							"Eliminar"
						)}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
