import Firebase from "firebase";
import { AnyProject, BaseProjectLegacy } from "../Proyecto";

// NOTA: OT significa diablo.

export type BaseProjectOT = {
	_ref?: Firebase.firestore.DocumentReference;
	id?: string;
	Nombre: string;
	EmpresaReference: Firebase.firestore.DocumentReference;
	FechaCreacion: Firebase.firestore.Timestamp;
	Responsable: Firebase.firestore.DocumentReference;
	Responsables: Firebase.firestore.DocumentReference[];
	/**
	 * Este campo cuenta la cantidad de revisiones de un proyecto.
	 * ¿Por qué se llama Carpetas? Solo Dios lo sabrá.
	 * ¿Por qué podría ser string? Porque el sistema es un desastre.
	 */
	Carpetas?: number | string;
	Tipo: "Edificios" | "Casas" | "Otro";
	Espacios: { [key: string]: number };
	Nombre_lower: string;
	ProyectoIniciado: boolean;
	ProyectoFinalizado?: boolean;
	TotalChecklists: number;
	ChecklistsCompletados?: number;
	Eliminado: boolean;
	/**
	 * Triggers a cloud function that updates the percentage counters when true.
	 */
	Contado_?: boolean;
	Cantidad?: number;
	CantidadCompletada?: number;
};

export type ChecklistProject = BaseProjectOT & {
	Tipo: "Edificios" | "Casas" | "Otro";
};

export const isChecklist = (
	tbd: AnyProject
): tbd is ChecklistProject => {
	switch (tbd.Tipo) {
		case "Edificios":
		case "Casas":
		case "Otro":
			return true;
		default:
			return false;
	}
};

export const isProject = (
	tbd: BaseProjectLegacy | BaseProjectOT
): tbd is BaseProjectLegacy => !isChecklist(tbd);

/**
 * Valor por defecto de los espacios de un proyecto
 * de tipo Edificios
 */
export const PROJECT_OT_EDIFICIOS = {
	Fachada: 0,
	"Sala de máquinas": 0,
	Piso: 0,
	Subterráneo: 0,
};

/**
 * Valor por defecto de los espacios de un proyecto
 * de tipo Casas
 */
export const PROJECT_OT_CASAS = {
	Casa: 0,
};
