import {
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	MenuItemProps,
	MenuProps,
} from "@material-ui/core";
import { FC, MouseEvent, ReactNode, useState } from "react";

type ClickEvent = MouseEvent<any, any>;

type RequiredProps = {
	onClick?: (ev: ClickEvent) => void;
};

type ContextMenuProps = {
	/**
	 * Este componente funcional abrirá el menú al ser cliqueado.
	 * Es obligatorio que este componente tenga el parámetro `onClick`.
	 */
	Opener: FC<RequiredProps>;
	/** Elementos a renderizar. */
	options: DropdownOption[];
	/**  Por defecto `true`. */
	closeAfterClick?: boolean;
	/**  Por defecto `true`. */
	closeOnBlur?: boolean;
	/**  Por defecto `true`. */
	closeOnEscape?: boolean;
	/**  Por defecto `false`. */
	closeOnTab?: boolean;

	onMenuOpen?: (ev: ClickEvent) => void;
	/** Por defecto `"No hay opciones."` */
	emptyMessage?: ReactNode;
} & Partial<MenuProps>;

type CloseReason =
	| "escapeKeyDown"
	| "backdropClick"
	| "tabKeyDown";

export type DropdownOption = {
	label: ReactNode;
	icon?: ReactNode;
	helperText?: ReactNode;
	disabled?: boolean;
} & MenuItemProps;

export const DropdownMenu: FC<ContextMenuProps> = ({
	Opener,
	options,
	onMenuOpen,
	emptyMessage = "No hay opciones.",
	closeAfterClick = true,
	closeOnBlur = true,
	closeOnEscape = true,
	closeOnTab = false,
	...menuProps
}) => {
	const [anchorEl, setAnchorEl] =
		useState<HTMLElement | null>(null);

	const handleClick = (
		ev: ClickEvent,
		o: DropdownOption
	) => {
		o.onClick?.(ev);
		closeAfterClick && setAnchorEl(null);
	};

	const handleOpen = (ev: ClickEvent) => {
		setAnchorEl(ev.currentTarget);
		onMenuOpen?.(ev);
	};

	const handleClose = (_: any, reason: CloseReason) =>
		((reason === "escapeKeyDown" && closeOnEscape) ||
			(reason === "backdropClick" && closeOnBlur) ||
			(reason === "tabKeyDown" && closeOnTab)) &&
		setAnchorEl(null);

	return (
		<>
			<Opener onClick={(ev) => handleOpen(ev)} />
			<Menu
				{...menuProps}
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				{options.length === 0 && (
					<MenuItem disabled>{emptyMessage}</MenuItem>
				)}
				{options.map((o, i) => {
					const {
						label,
						icon,
						helperText,
						button,
						...props
					} = o;
					return (
						<MenuItem
							{...props}
							key={`menu-item-${i}`}
							onClick={(ev) => handleClick(ev, o)}
						>
							{icon && <ListItemIcon>{icon}</ListItemIcon>}
							<ListItemText
								primary={label}
								secondary={helperText}
							/>
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};
