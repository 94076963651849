import { FC } from "react";
import { NotApplicableIcon } from "./NotApplicableIcon";
import { PendingIcon } from "./PendingIcon";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { AnswerOT } from "src/models/OT/Sheet";
import { BlockedIcon } from "./BlockedIcon";

export const ResponseIcon: FC<AnswerOT> = ({ Estado }) => {
	switch (Estado) {
		case "Aprobado":
			return <CheckCircle htmlColor="limegreen" />;
		case "Rechazado":
			return <Cancel htmlColor="firebrick" />;
		case "No Aplica":
			return <NotApplicableIcon />;
		case "Bloqueado":
			return <BlockedIcon />;
		default:
			return <PendingIcon />;
	}
};
