import {
	Breadcrumbs,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	Typography,
} from "@material-ui/core";
import { FC, Fragment } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { SheetAnswerMap } from "src/models/OT/Sheet";
import { ResponseMatrixOT } from "../Matrix";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { useBusiness } from "src/hooks/redux/useBusiness";

type Props = {
	position: number;
	cursor?: number;
	groupedByCategory: SheetAnswerMap;
	reviewerNames?: string[];
} & DialogProps;

export const SheetCategoryDialogOT: FC<Props> = ({
	cursor = 0,
	groupedByCategory,
	position,
	reviewerNames,
	...dialog
}) => {
	const { selected } = useProjectsOT();
	const { selectedBusiness } = useBusiness();

	return (
		<Dialog {...dialog}>
			<DialogTitle
				bigText={
					<Breadcrumbs>
						<Typography
							variant="h4"
							style={{ color: "black" }}
						>
							{selectedBusiness?.Nombre ?? "Empresa"}
						</Typography>

						<Typography
							variant="h4"
							style={{ color: "black" }}
						>
							{selected.project?.Nombre ?? "Proyecto"}
						</Typography>

						<Typography
							variant="h4"
							style={{ color: "black" }}
						>
							{selected.checklist?.Nombre ??
								"Lista de chequeo"}
						</Typography>

						<Typography
							variant="h4"
							style={{ color: "black" }}
						>
							{selected.assignedPlace?.Nombre ?? "Espacio"}
						</Typography>

						<Typography
							variant="h4"
							style={{ color: "black" }}
						>
							Checklist {position}
						</Typography>
					</Breadcrumbs>
				}
				onClose={dialog.onClose}
			/>

			<DialogContent
				style={{
					display: "flex",
					flexDirection: "column",
					gap: 10,
				}}
			>
				{Object.entries(groupedByCategory).map(
					([categoryName, categoryData]) => (
						<Fragment key={categoryName}>
							{categoryName !== "Preguntas sin subtítulo" &&
								categoryName !== "Sin subtítulo" && (
									<Typography variant="h4">
										{categoryName}
									</Typography>
								)}

							<ResponseMatrixOT
								questions={categoryData}
								reviewerNames={reviewerNames ?? []}
							/>
						</Fragment>
					)
				)}
			</DialogContent>

			<DialogActions>
				<Button
					color="primary"
					variant="contained"
					onClick={() =>
						dialog.onClose?.({}, "escapeKeyDown")
					}
				>
					Listo
				</Button>
			</DialogActions>
		</Dialog>
	);
};
