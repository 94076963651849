/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Breadcrumbs,
	Grid,
	Link,
	Paper,
	Typography,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Bar, Pie } from "react-chartjs-2";
import { NavLink, useParams } from "react-router-dom";
import { firestore } from "../../firebase/firebase";
import "chartjs-plugin-labels";
import { Business } from "@material-ui/icons";
import { calcularPorcentaje } from "../../utils/utils";

type UrlParams = {
	projectId: string;
	revisionId?: string;
	listadoId?: string;
};

/**
 * Este componente puede recibe la ID de un proyecto, una revision y/o un listado
 * mediante parámetros URL... contabiliza y renderiza las observaciones en gráficos de
 * barra y torta. Solo para proyectos CheckList.
 *
 * # Ruta:
 * /administrador/checklist-proyectos/estadisticas/`:idProyecto`/`:?idRevision`/`:?idListado`
 *
 * # Niveles:
 * - Nivel 1: Proyecto
 * - Nivel 2: Revision
 * - Nivel 3: Listado
 *
 */
export const ChecklistChart = () => {
	const { projectId, revisionId, listadoId } =
		useParams<UrlParams>();

	const [currentProject, setCurrentProject] = useState<
		null | any
	>(null);
	const [currentRevision, setCurrentRevision] = useState<
		null | any
	>(null);
	const [currentListado, setCurrentListado] = useState<
		null | any
	>(null);
	const [observations, setObservations] = useState<
		null | any[]
	>(null);

	const [
		observacionesPorListado,
		setObservacionesPorListado,
	] = useState<null | {
		[key: string]: number;
	}>(null);
	const [pageState, setPageState] = useState<
		"loading" | "ok" | "invalid"
	>("loading");
	const [observacionesPorNivel, setObservacionesPorNivel] =
		useState<null | {
			[key: string]: number;
		}>(null);
	const [
		observacionesPorNivelVariant,
		setObservacionesPorNivelVariant,
	] = useState<null | {
		[key: string]: number;
	}>(null);
	const [update, setUpdate] = useState(0);
	const [errorMsg, setErrorMsg] = useState<null | string>(
		null
	);

	const counts = useRef({ total: 0, resueltas: 0 });

	const chartData = useMemo(() => {
		return {
			labels: Object.keys(observacionesPorNivel || {}),
			datasets: [
				{
					label: "Observaciones",
					data: Object.values(observacionesPorNivel || {}),
					backgroundColor: observacionesPorNivel
						? new Array(
								Object.keys(observacionesPorNivel!).length
						  )
								.fill(null)
								.map(
									() =>
										`#${Math.floor(
											Math.random() * 16777215
										).toString(16)}`
								)
						: [],
				},
			],
		};
	}, [observacionesPorNivel]);
	const chartDataVariant = useMemo(() => {
		return {
			labels: Object.keys(
				observacionesPorNivelVariant || {}
			),
			datasets: [
				{
					label: "Observaciones",
					data: Object.values(observacionesPorNivel || {}),
					backgroundColor: observacionesPorNivel
						? new Array(
								Object.keys(observacionesPorNivel!).length
						  )
								.fill(null)
								.map(
									() =>
										`#${Math.floor(
											Math.random() * 16777215
										).toString(16)}`
								)
						: [],
				},
			],
		};
	}, [observacionesPorNivel]);

	const chartData2 = useMemo(() => {
		return {
			labels: Object.keys(observacionesPorListado || {}),
			datasets: [
				{
					label: "Observaciones",
					data: Object.values(
						observacionesPorListado || {}
					),
					backgroundColor: "blue",
				},
			],
		};
	}, [observacionesPorListado]);

	const getCurrentProject = async () => {
		const _ref = firestore.collection("ProyectosChecklist");
		const _doc = await _ref.doc(projectId).get();
		_doc.exists && setCurrentProject(_doc.data());
	};

	const getObservations = async () => {
		const _ref = firestore.collection(
			"RespuestasErrorestipos"
		);
		const _docs = await _ref
			.where(
				listadoId
					? "ListadoId"
					: revisionId
					? "RevisionId"
					: "ProyectoId",
				"==",
				listadoId || revisionId || projectId
			)
			.get();

		if (!_docs.empty) {
			const _dArray = _docs.docs
				.map((doc) => doc.data())
				.sort((a, b) =>
					a.NombreSubcontrato.localeCompare(
						b.NombreSubcontrato
					)
				);
			setObservations(_dArray);
			counts.current = {
				total: _dArray.length,
				resueltas: _dArray.filter(
					(doc) => doc.Estado !== "Por Resolver"
				).length,
			};
		}
	};

	const getCurrentRevision = async () => {
		const _ref = firestore.collection(
			`ProyectosChecklist/${projectId}/Revisiones`
		);
		const _doc = await _ref.doc(revisionId).get();
		_doc.exists && setCurrentRevision(_doc.data());
	};

	const getCurrentListado = async () => {
		const _ref = firestore.collection(
			`ProyectosChecklist/${projectId}/Revisiones/${revisionId}/Listado`
		);
		const _doc = await _ref.doc(listadoId).get();
		_doc.exists && setCurrentListado(_doc.data());
		console.debug("listado:", currentListado);
	};

	const defineMetrics = () => {
		const _metrics: { [key: string]: number } = {};

		observations?.forEach((o) => {
			const _mName = o.NombreSubcontrato;
			_metrics[_mName] = _metrics[_mName]
				? _metrics[_mName] + 1
				: 1;
		});

		// Agregar porcentajes a las métricas
		const metricsWithPercentajes: {
			[key: string]: number;
		} = {};
		const metricsWithPercentajes2: {
			[key: string]: number;
		} = {};
		Object.entries(_metrics).forEach(
			([key, value]) =>
				(metricsWithPercentajes[
					`(${calcularPorcentaje(
						value,
						observations?.length || 1
					)}%) ${key}`
					//   `${key}`
				] = value)
		);
		Object.entries(_metrics).forEach(
			([key, value]) =>
				(metricsWithPercentajes2[`${key}`] = value)
		);
		setObservacionesPorNivelVariant(
			metricsWithPercentajes2
		);
		setObservacionesPorNivel(metricsWithPercentajes);
	};

	const manageErrors = () => {
		if (!currentProject || !observations) {
			setErrorMsg(
				currentProject && !observations
					? "Este proyecto no tiene observaciones."
					: "Este proyecto no existe."
			);

			setPageState("invalid");
		}
	};

	const getListadoRevisiones = async () => {
		const _metrics2: { [key: string]: number } = {};

		const _ref = firestore.collection(
			`ProyectosChecklist/${projectId}/Revisiones/${revisionId}/Listado`
		);
		const querySnap = await _ref.get();

		if (querySnap.empty) {
			setErrorMsg("Este proyecto no registra listados.");
			setPageState("invalid");
			return;
		}

		querySnap.docs
			.sort((a, b) => {
				const data1 = a.data() as any;
				const data2 = b.data() as any;

				return data1.Nombre.localeCompare(data2.Nombre);
			})
			.forEach((doc) => {
				_metrics2[doc.data().Nombre] =
					doc.data()?.Resueltas ||
					doc.data()?.TotalRespuestas ||
					0;
			});

		setObservacionesPorListado(_metrics2);
	};

	const setup = async () => {
		setPageState("loading");
		await getCurrentProject();
		await getObservations();
		revisionId && (await getCurrentRevision());
		listadoId && (await getCurrentListado());
		projectId &&
			revisionId &&
			!listadoId &&
			(await getListadoRevisiones());

		if (!currentProject || !observations) {
			update <= 3 ? setUpdate(update + 1) : manageErrors();
			return;
		}

		defineMetrics();
		setPageState("ok");
	};

	useEffect(() => {
		setup();
	}, [update]);

	return (
		<Paper>
			<Breadcrumbs>
				<Business />
				<Link
					component={NavLink}
					exact
					to="/administrador/checklist-proyectos"
				>
					Proyectos CheckList
				</Link>
				{revisionId && (
					<Link
						component={NavLink}
						exact
						to={`/administrador/checklist-proyectos/${projectId}/revisiones`}
					>
						Revisiones
					</Link>
				)}
				{listadoId && (
					<Link
						component={NavLink}
						exact
						to={`/administrador/checklist-proyectos/${projectId}/revisiones/${revisionId}/listados`}
					>
						Listados
					</Link>
				)}
				<Typography>Estadísticas</Typography>
			</Breadcrumbs>
			{pageState !== "invalid" && (
				<>
					<Typography
						variant="h3"
						align="center"
						style={{ marginTop: "1rem" }}
						component="h3"
					>
						{pageState === "loading"
							? "Cargando proyecto..."
							: "Proyecto " + currentProject.NombreProyecto}
						{currentRevision &&
							pageState !== "loading" &&
							` - ${currentRevision.Nombre}`}
						{currentListado &&
							pageState !== "loading" &&
							` - ${currentListado.Nombre}`}
					</Typography>
					<Typography variant="h5" align="center">
						{pageState === "loading"
							? "Cargando observaciones..."
							: `Observaciones de${
									listadoId
										? "l listado"
										: revisionId
										? " la revisión"
										: "l proyecto"
							  }`}
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Typography
								variant="subtitle1"
								align="center"
							>
								{pageState === "loading" ? (
									<Skeleton variant="text" />
								) : (
									`Observaciones: ${counts.current.total}`
								)}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography
								variant="subtitle1"
								align="center"
							>
								{pageState === "loading" ? (
									<Skeleton variant="text" />
								) : (
									`Observaciones resueltas: ${counts.current.resueltas}`
								)}
							</Typography>
						</Grid>
						{pageState === "loading" && (
							<>
								<Grid item xs={12}>
									<Skeleton
										height={80}
										style={{
											marginLeft: "2rem",
											marginRight: "2rem",
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Skeleton
										variant="circle"
										height="25vw"
										width="25vw"
									/>
								</Grid>
								<Grid item xs={12}>
									<Skeleton
										height="25vw"
										style={{
											marginLeft: "1rem",
											marginRight: "1rem",
										}}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</>
			)}
			{pageState === "ok" && (
				<>
					<Box maxHeight="75vh">
						<Pie
							height={100}
							data={chartData}
							options={{
								legend: {
									position: "right",
								},
								plugins: {
									// @ts-ignore
									labels: {
										render: (col: any) => {
											const _percentage =
												(100 * col.value) /
												counts.current.total;
											//   return `${_percentage.toFixed(2)}%`;
											return ``;
										},
										position: "outside",
										precision: 2,
									},
								},
							}}
						/>
					</Box>

					<Box
						marginTop="2rem"
						bgcolor="transparent"
						zIndex={10}
					>
						<Bar
							height={100}
							data={chartDataVariant}
							// @ts-ignore
							options={{
								legend: {
									display: false,
								},
								plugins: {
									labels: [
										{
											render: (col: any) => col.value,
											position: "outside",
											precision: 2,
										},
									],
								},
								scales: {
									yAxes: [
										{
											ticks: {
												beginAtZero: true,
												stacked: true,
												max: Math.ceil(
													Math.max(
														...Object.values(
															observacionesPorNivel!
														)
													) * 1.15
												),
											},
										},
									],
								},
							}}
						/>
					</Box>

					{projectId && revisionId && !listadoId && (
						<Box paddingTop="2rem">
							{observacionesPorListado ? (
								<Bar
									height={100}
									data={chartData2}
									options={{
										legend: {
											display: false,
										},
										plugins: {
											labels: [
												{
													render: (col: any) => col.value,
													position: "outside",
													precision: 2,
												},
											],
										},
										scales: {
											yAxes: [
												{
													ticks: {
														beginAtZero: true,
														stacked: true,
														suggestedMax: 200,
													},
												},
											],
										},
									}}
								/>
							) : (
								<Alert severity="error">
									No existen datos del listado.
								</Alert>
							)}
						</Box>
					)}
				</>
			)}
			{pageState === "invalid" && (
				<Alert severity="error">{errorMsg}</Alert>
			)}
		</Paper>
	);
};
