import * as React from "react";
import { useEffect, useState } from "react";
import {
	CircularProgress,
	TextField,
	TextFieldProps,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/reducer";
import { getUsers } from "../redux/actions/usersActions";
import { IUsersState } from "../redux/reducers/usersReducer";
import { IBusinessState } from "../redux/reducers/businessReducer";
import { Usuario } from "src/models/Usuario";

type Props = {
	// project?: any;
	onChange?: (user: Usuario | null) => void;
	value?: Usuario | null;
	fetchUsers?: boolean;
	// inProjects?: boolean;
} & Omit<TextFieldProps, "value" | "onChange">;

export const ResponsibleAutocomplete: React.FC<Props> =
	React.memo(
		({
			onChange,
			error,
			value,
			fetchUsers,
			...inputProps
		}) => {
			const { isLoading, users } = useSelector<
				RootState,
				IUsersState
			>((state) => state.usersReducer);

			const {
				edit: { selectedBusiness },
			} = useSelector<RootState, IBusinessState>(
				(state) => state.businessReducer
			);

			const dispatch = useDispatch();

			useEffect(() => {
				if (!selectedBusiness) return;
				if (!fetchUsers) return;
				dispatch(getUsers(selectedBusiness));
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [selectedBusiness]);
			const [openAutocomplete, setOpenAutocomplete] =
				useState(false);

			return (
				<Autocomplete
					id="Responsable"
					fullWidth
					value={value}
					size={inputProps.size}
					open={openAutocomplete}
					onOpen={() => {
						setOpenAutocomplete(true);
					}}
					onClose={() => {
						setOpenAutocomplete(false);
					}}
					getOptionSelected={(option, value) =>
						option.id === value.id
					}
					getOptionLabel={(option) =>
						`${option?.Nombre} ${option?.Apellido} [${option?.Cargo}]`
					}
					options={users ?? []}
					loading={!users}
					loadingText="Cargando..."
					onChange={(_, value) => onChange?.(value)}
					renderInput={(params) => (
						<TextField
							{...inputProps}
							{...params}
							fullWidth
							InputProps={{
								...inputProps.InputProps,
								...params.InputProps,
								endAdornment: isLoading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : (
									params.InputProps.endAdornment
								),
							}}
						/>
					)}
				/>
			);
		}
	);
