import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { IAuthState } from "../../../../redux/reducers/authReducer";
import { RootState } from "../../../../redux/store/reducer";
import { IBusinessState } from "../../../../redux/reducers/businessReducer";
import { UrlParamsOT } from "src/pages/NeoProjects/[neoId]/[neoChecklistId]";

type UrlParamsCheckPro = Partial<{
	projectId: string;
	idProyecto: string;
	revisionId: string;
	idRevision: string;
	listId: string;
	listadoId: string;
	idListado: string;
	recintoId: string;
	subcontratoId: string;
	errtipoId: string;
}>;

export const useCheckproLinks = () => {
	const {
		projectId,
		idProyecto,
		revisionId,
		idRevision,
		listId,
		listadoId,
		idListado,
		recintoId,
		subcontratoId,
		errtipoId,
		...rest
	} = useParams<UrlParamsCheckPro & UrlParamsOT>();

	const { edit } = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);
	const { selectedBusiness } = edit;

	const { pathname } = useLocation();

	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);

	const isFromBusiness =
		pathname.indexOf("/administrador/empresas/") === 0;

	return {
		idEmpresa: selectedBusiness?.id,
		idProyecto: idProyecto || projectId,
		idRevision: revisionId || idRevision,
		idListado: listId || listadoId || idListado,
		idRecinto: recintoId,
		idSubcontrato: subcontratoId,
		idErrorTipo: errtipoId,
		isFromBusiness,
		isCheckList: pathname.includes("checklist"),
		auth,
		...rest,
	};
};
