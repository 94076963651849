
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import React from "react";

interface Props {
  open: boolean;
  onClose?: () => void;
}

export const LoaderModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent
        style={{
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          backgroundColor: "transparent",
        }}
      >
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};