import { FC, useMemo } from "react";
import { Link } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useCheckproLinks } from "./hooks/useCheckproLinks";

export const LinkRevisiones: FC = () => {
	const { auth, idProyecto, isCheckList, idEmpresa } =
		useCheckproLinks();

	const tipoUsuario = auth.user?.tipoUsuario;

	const nextLink = useMemo(() => {
		if (isCheckList) {
			if (tipoUsuario === "Administrador")
				return `/administrador/checklist-proyectos/${idProyecto}/revisiones`;
			else
				return `/administrador/checklist-proyectos/${idProyecto}/revisiones`;
		} else {
			if (tipoUsuario === "Administrador")
				return `/administrador/empresas/${idEmpresa}/proyectos/${idProyecto}/revisiones`;
			else if (tipoUsuario === "AdministradorEmpresa")
				return `/administrador/empresa/proyectos/${idProyecto}/revisiones`;
			else
				return `/administrador/user/proyectos/${idProyecto}/revisiones`;
		}
	}, [tipoUsuario, idEmpresa, idProyecto, isCheckList]);

	return (
		<Link component={NavLink} exact to={nextLink}>
			Revisiones
		</Link>
	);
};
