import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Fade,
  FormControlLabel as MuiFormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { Add as AddIcon } from "@material-ui/icons";
import * as yup from "yup";
import { es } from "yup-locales";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/reducer";
import { ISectorsState } from "../redux/reducers/sectorsReducer";
import {
  addMoreSectors,
  addSector,
  getSectors,
  getTotalDocs,
  openEditSectorDialog,
  openNewSectorDialog,
  removeSector,
  setSectorDialogOpen,
  updateSector,
} from "../redux/actions/sectorsActions";
import { Skeleton } from "@material-ui/lab";
import { green, red } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { Edit as EditIcon, Trash2 as RemoveIcon } from "react-feather";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { DialogAction } from "../models/dialog-actions";
import { useStyles } from "../theme/useStyles";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";

yup.setLocale(es);
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);

const Chip: any = styled(MuiChip)`
  ${spacing};

  background: ${(props: any) => props.activated && green[500]};
  background: ${(props: any) => props.desactivated && red[500]};
  color: ${(props) => props.theme.palette.common.white};
  min-width: 90px;
`;

function ContentCard() {
  const classes = useStyles();
  const { sectors, isLoading, totalDocs } = useSelector<
    RootState,
    ISectorsState
  >((state) => state.sectorsReducer);

  const dispatch = useDispatch();

  const [limit, setLimit] = useState(TABLE_LIMIT_DEFAULT);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));

    if (parseInt(event.target.value, 10) > sectors.length) {
      dispatch(getSectors(parseInt(event.target.value, 10)));
    }
    setPage(0);
  };

  const handlePageChange = (_: any, newPage: number) => {
    if (sectors.length < totalDocs && newPage > page) {
      dispatch(addMoreSectors(limit));
    }
    setPage(newPage);
  };

  // Confirm Dialog
  const [dialogState, setDialogState] = useState({
    open: false,
    title: "",
    sector: {},
  });

  const handleRemoveButton = (sector: any) => {
    setDialogState((dialogState) => ({
      ...dialogState,
      title: `¿Desea eliminar a al sector ${sector.Nombre}?`,
      sector: sector,
      open: true,
    }));
  };

  const handleEditButton = (sector: any) => {
    dispatch(openEditSectorDialog(sector));
  };

  return (
    <Card mb={6}>
      <CardContent>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading
                ? sectors
                    .slice(page * limit, page * limit + limit)
                    .map((sector) => (
                      <Fade key={sector.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell>{sector.Nombre}</TableCell>
                          <TableCell>
                            {sector.Activado ? (
                              <Chip
                                size="small"
                                mr={1}
                                mb={1}
                                label="Activado"
                                activated="true"
                              />
                            ) : (
                              <Chip
                                size="small"
                                mr={1}
                                mb={1}
                                label="Desactivado"
                                desactivated="true"
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Editar">
                              <IconButton
                                onClick={() => handleEditButton(sector)}
                                color="primary"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                              <IconButton
                                onClick={() => handleRemoveButton(sector)}
                              >
                                <RemoveIcon color="red" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))
                : new Array(limit).fill(0).map((x, i) => (
                    <TableRow key={i}>
                      <TableCell colSpan={5}>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalDocs}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={TABLE_LIMITS}
        />
      </CardContent>
      <ConfirmDialog
        open={dialogState.open}
        onClose={() =>
          setDialogState((dialogState) => ({ ...dialogState, open: false }))
        }
        onConfirm={() => {
          dispatch(removeSector(dialogState.sector));
        }}
        title={dialogState.title}
      />
    </Card>
  );
}

const DialogAddSector = () => {
  const {
    dialog: { isDialogLoading, isDialogOpen, dialogAction, selectedSector },
  } = useSelector<RootState, ISectorsState>((state) => state.sectorsReducer);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      Nombre: "",
      Activado: true,
    },
    onSubmit: (values) => {
      if (dialogAction === DialogAction.Edit) {
        dispatch(updateSector({ ...values, id: selectedSector.id }));
      }
      if (dialogAction === DialogAction.New) {
        dispatch(addSector(values));
      }
    },
    validationSchema: yup.object({
      Nombre: yup.string().required("Nombre requerido"),
    }),
  });

  useEffect(() => {
    setValues({
      Nombre: dialogAction === DialogAction.Edit ? selectedSector?.Nombre : "",
      Activado:
        dialogAction === DialogAction.Edit ? selectedSector?.Activado : false,
    });
    if (dialogAction === DialogAction.New) resetForm();
  }, [dialogAction, selectedSector, resetForm, setValues]);

  return (
    <React.Fragment>
      <Dialog
        open={isDialogOpen}
        onClose={() => dispatch(setSectorDialogOpen(false))}
        aria-labelledby="add-and-update-dialog-title"
      >
        <DialogTitle id="add-and-update-dialog-title">
          {dialogAction === DialogAction.Edit
            ? "Editar Sector"
            : "Agregar Sector"}
        </DialogTitle>

        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              id="Nombre"
              label="Nombre Sector"
              type="text"
              value={values.Nombre}
              name="Nombre"
              onChange={handleChange}
              error={touched.Nombre && Boolean(errors.Nombre)}
              helperText={touched.Nombre && errors.Nombre}
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  checked={values.Activado}
                  onChange={handleChange}
                  name="Activado"
                  color="primary"
                />
              }
              mt={4}
              label="Activado"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => dispatch(setSectorDialogOpen(false))}
              color="primary"
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={isDialogLoading}>
              {dialogAction === DialogAction.Edit ? "Editar" : "Agregar"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export const Sectors = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSectors());
    dispatch(getTotalDocs());
  }, [dispatch]);
  return (
    <React.Fragment>
      <Helmet title="Sectores" />
      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sectores
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Sectores</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(openNewSectorDialog());
              }}
            >
              <AddIcon />
              Nuevo Sector
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
      <DialogAddSector />
    </React.Fragment>
  );
};
