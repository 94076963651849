import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { indexarObservaciones } from "../../services/empresa/revisiones";
import {
	capitalizeAllFirstLetters,
	capitalizeFirstLetter,
	cleanString,
} from "../../utils/utils";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";
import {
	LegacyProject,
	isChecklistRevision,
} from "src/models/Proyecto";

const listener = FirebaseListener.addListener("revisions");

export const getRevisions = (
	project: LegacyProject
): AppThunk => {
	const checklistRevision = isChecklistRevision(project);

	return async (dispatch) => {
		dispatch(isLoading(true));
		const query = firestore
			.collection(
				checklistRevision
					? "ProyectosChecklist"
					: "Proyectos"
			)
			.doc(project.id)
			.collection("Revisiones")
			.orderBy("Nombre");

		listener.close();
		listener.set(
			query.onSnapshot(
				(snap) => {
					dispatch(setRevisions(mapRevisions(snap)));
					dispatch(isLoading(false));
				},
				(error) => {
					console.error(error);
					dispatch(
						openSnack(
							error.message,
							SnackState.ERROR
						) as any
					);
				}
			)
		);
	};
};
// get one Revision
export const getOneRevision = (
	projectId: string,
	revisionId: string,
	isChecklist = true
): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: types.GET_ONE_REVISION_SUBMITTING,
		});
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.get();
			dispatch({
				type: types.GET_ONE_REVISION_SUCCESS,
				payload: { ...response.data(), id: response.id },
			});
		} catch (error: any) {
			dispatch({
				type: types.GET_ONE_REVISION_FAILURE,
				payload:
					"Ha ocurrido un error al obtener la revision",
			});
		}
	};
};
export const getRevision = (
	projectId: any,
	revisionId: any,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		dispatch(isLoading(true));
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.get();

			dispatch(
				setSelectedRevision({
					...response.data(),
					id: response.id,
				})
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const addRevision = (
	revision: any
	// isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const selectedProject =
			getState().projectsReducer.revisions.selectedProject;
		try {
			if (!selectedProject) return;

			const batch = firestore.batch();

			const isChecklist =
				isChecklistRevision(selectedProject);

			const projectRef = firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(selectedProject.id);

			const unique = await projectRef
				.collection("Revisiones")
				.where(
					"Nombre_lower",
					"==",
					cleanString(revision.Nombre)
				)
				.limit(1)
				.get();

			if (!unique.empty) {
				throw Error("Existe revisión con ese nombre");
			}

			const newRevision = {
				FechaCreacion:
					firebase.firestore.FieldValue.serverTimestamp(),
				Nombre: revision.Nombre,
				Nombre_lower: cleanString(revision.Nombre),
				Responsable: {
					Fecha:
						firebase.firestore.FieldValue.serverTimestamp(),
					FirebaseId: revision?.Responsable?.id || "",
					NombreCompleto: revision.Responsable
						? revision?.Responsable?.Nombre +
						  " " +
						  revision?.Responsable?.Apellido
						: "",
					NombreCompleto_lower:
						cleanString(
							revision?.Responsable?.Nombre +
								" " +
								revision?.Responsable?.Apellido
						) || "",
					Cargo: revision?.Responsable?.Cargo || "",
				},
			};

			const projectIncludesResponsible =
				selectedProject.Responsables.find(
					(r) => r === revision.Responsable?.id
				);

			if (
				revision.Responsable &&
				!projectIncludesResponsible
			) {
				batch.update(projectRef, {
					Responsables:
						firebase.firestore.FieldValue.arrayUnion(
							revision.Responsable.id
						),
				});
			}

			batch.set(
				projectRef.collection("Revisiones").doc(),
				newRevision
			);

			// update project revision count
			const { Carpetas } = selectedProject;
			if (typeof Carpetas === "string") {
				// si es string, contar revisiones y actualizar valor a un número
				const countSnap = await projectRef
					.collection("Revisiones")
					.get();
				batch.update(projectRef, {
					Carpetas: countSnap.size + 1,
				});
			} else {
				// si es número, sumar 1
				batch.update(projectRef, {
					Carpetas:
						firebase.firestore.FieldValue.increment(1),
				});
			}

			await batch.commit();

			dispatch(
				openSnack(
					"Revision Agregada Correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch(setError(error));
			dispatch(openSnack(error.message, SnackState.ERROR));

			dispatch(isLoading(false));
		}
	};
};

export const updateRevision = (
	revision: any,
	oldRevision: any,
	id: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isUpdateLoading(true));
		const {
			revisions: { selectedProject },
		} = getState().projectsReducer;

		if (!selectedProject) return;

		const isChecklist =
			isChecklistRevision(selectedProject);

		try {
			if (
				!isChecklist &&
				revision.Nombre !== oldRevision.Nombre
			) {
				await indexarObservaciones(
					"NombreRevision",
					oldRevision.id!,
					revision.Nombre
				);
			}

			const revisions = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(selectedProject.id)
				.collection("Revisiones")
				.where("Nombre", "==", revision.Nombre)
				.get();
			const revisionsMinus = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(selectedProject.id)
				.collection("Revisiones")
				.where("Nombre", "==", cleanString(revision.Nombre))
				.get();

			const revisionsCapitalized = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(selectedProject.id)
				.collection("Revisiones")
				.where(
					"Nombre",
					"==",
					capitalizeFirstLetter(revision.Nombre)
				)
				.get();
			const revisionsAllCapitalized = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(selectedProject.id)
				.collection("Revisiones")
				.where(
					"Nombre",
					"==",
					capitalizeAllFirstLetters(revision.Nombre)
				)
				.get();

			const revDocsData = revisions.docs.map((doc) => ({
				id: doc.id,
			}));
			const revMinusDocsData = revisionsMinus.docs.map(
				(doc) => ({
					id: doc.id,
				})
			);
			const revCapsDocsData = revisionsCapitalized.docs.map(
				(doc) => ({
					id: doc.id,
				})
			);
			const revAllCapsDocsData =
				revisionsAllCapitalized.docs.map((doc) => ({
					id: doc.id,
				}));

			const revsDocs = [
				...revDocsData,
				...revMinusDocsData,
				...revCapsDocsData,
				...revAllCapsDocsData,
			];

			////////////////////////
			// Probablemente aplicar filtro buscando aquellos con id de edit y quitarlos de la cuenta

			const invalidDocs = revsDocs.filter(
				(doc) => doc.id !== id
			);
			// Este array debe ser de longitud cero para poder editar

			if (invalidDocs.length > 0) {
				dispatch(isUpdateLoading(false));
				throw new Error("Existe revisión con ese nombre");
			}

			let dataToUpdate: any = {};

			if (revision.Nombre) {
				dataToUpdate.Nombre = revision.Nombre;
				dataToUpdate.Nombre_lower = cleanString(
					revision.Nombre
				);
			}

			if (revision.Responsable.id) {
				dataToUpdate.Responsable = {
					Fecha:
						firebase.firestore.FieldValue.serverTimestamp(),
					FirebaseId: revision.Responsable.id,
					NombreCompleto:
						revision.Responsable.Nombre +
						" " +
						revision.Responsable.Apellido,
					NombreCompleto_lower: cleanString(
						revision.Responsable.Nombre +
							" " +
							revision.Responsable.Apellido
					),
					Cargo: revision.Responsable.Cargo,
				};
			}

			await Promise.all([
				await firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(selectedProject.id)
					.collection("Revisiones")
					.doc(id)
					.update(dataToUpdate),
			]);

			dispatch(
				openSnack(
					"Revision Actualizada Correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			dispatch(setError(error));
			dispatch(
				openSnack(error.toString(), SnackState.ERROR)
			);
		} finally {
			dispatch(isUpdateLoading(false));
		}
	};
};

export const deleteRevision = (
	revisionId: string,
	projectId: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		try {
			const selectedProject =
				getState().projectsReducer.revisions
					.selectedProject;

			if (!selectedProject)
				throw Error("No hay un proyecto seleccionado.");

			const batch = firestore.batch();

			const isChecklist =
				isChecklistRevision(selectedProject);

			const projectRef = firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(selectedProject.id);

			const revisionRef = projectRef
				.collection("Revisiones")
				.doc(revisionId);

			const list = await revisionRef
				.collection("Listado")
				.limit(1)
				.get();

			if (!list.empty)
				throw Error(
					"Para eliminar esta revisión, primero elimina sus listados."
				);

			// update project revision count
			const { Carpetas } = selectedProject;
			if (typeof Carpetas === "string") {
				// si es string, contar revisiones y actualizar valor a un número
				const countSnap = await projectRef
					.collection("Revisiones")
					.get();
				batch.update(projectRef, {
					Carpetas: countSnap.size - 1,
				});
			} else {
				// si es número, restar 1
				batch.update(projectRef, {
					Carpetas:
						firebase.firestore.FieldValue.increment(-1),
				});
			}

			batch.delete(revisionRef);

			await batch.commit();

			dispatch(
				openSnack(
					"Revisión Eliminada Correctamente",
					SnackState.SUCCESS
				)
			);
		} catch (error: any) {
			console.error(error);
			dispatch(openSnack(error.message, SnackState.ERROR));
			dispatch(setError(error));
		}

		dispatch(isLoading(false));
	};
};

export const addMoreRevisions = (
	limit: number = types.TABLE_LIMIT_DEFAULT,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const { lastDoc } = getState().revisionsReducer;
		const {
			revisions: { selectedProject },
		} = getState().projectsReducer;
		try {
			if (!selectedProject) return;
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(selectedProject.id)
				.collection("Revisiones")
				.startAfter(lastDoc)
				.orderBy("Nombre")
				.limit(limit)
				.get();

			dispatch(addRevisionsPage(mapRevisions(response)));
			dispatch(
				setLastDoc(response.docs[response.docs.length - 1])
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const getTotalDocs = (
	project: any,
	isChecklist = false
): AppThunk => {
	return async (dispatch) => {
		try {
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(project.id)
				.collection("Revisiones")
				.get();
			await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(project.id)
				.update({ Carpetas: "(" + response.size + ")" });
			dispatch(setTotalDocs(response.docs.length));
		} catch (error: any) {
			dispatch(setError(error));
		}
	};
};

export const mapRevisions = (
	response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
	let revisions: any[] = [];
	response.docs.forEach((doc) => {
		let data = doc.data();
		data.id = doc.id;
		revisions.push(data);
	});
	return revisions;
};

export const setSelectedRevision = (
	revision: any
): Action => ({
	type: types.REVISIONS_SET_SELECTED,
	payload: revision,
});

const setRevisions = (revisions: any[]): Action => ({
	type: types.REVISIONS_GET_DOCS,
	payload: revisions,
});

const isLoading = (isLoading: boolean): Action => ({
	type: types.REVISIONS_LOADING,
	payload: isLoading,
});

const isUpdateLoading = (isLoading: boolean): Action => ({
	type: types.REVISIONS_UPDATE_LOADING,
	payload: isLoading,
});

const setError = (error: string): Action => ({
	type: types.REVISIONS_FAILURE,
	payload: error,
});

const addRevisionsPage = (revision: any[]): Action => ({
	type: types.REVISIONS_ADD_DOCS,
	payload: revision,
});

const setTotalDocs = (totalDocs: number): Action => ({
	type: types.REVISIONS_SET_TOTAL_DOCS,
	payload: totalDocs,
});

const setLastDoc = (lastDoc: any): Action => ({
	type: types.REVISIONS_SET_LAST_DOC,
	payload: lastDoc,
});

const removeRevisionStore = (revision: any): Action => ({
	type: types.REVISIONS_REMOVE_DOC,
	payload: revision,
});
