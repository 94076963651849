import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { updateBusiness } from "src/redux/actions/businessActions";
import * as yup from "yup";
import * as rutUtils from "rut.js";
import {
	Button,
	Card as MuiCard,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Collapse,
	Divider as MuiDivider,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
	Zoom,
} from "@material-ui/core";
import { HeaderCardActions } from "../HeaderCardAction";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { grey } from "@material-ui/core/colors";
import { IAuthState } from "src/redux/reducers/authReducer";
import { RootState } from "src/redux/store/reducer";
import { Autocomplete } from "@material-ui/lab";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { ModuleLimitForm } from "src/components/Business/ModuleLimitForm";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const cantPhotos = ["1", "2"];

export const BusinessInfoTab = () => {
	const dispatch = useDispatch();
	const [canEdit, setCanEdit] = useState(false);

	const {
		selectedBusiness: business,
		loading: updateLoading,
	} = useBusiness();

	const auth = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);

	const formik = useFormik({
		initialValues: {
			Nombre: business!.Nombre,
			Rut: business!.Rut,
			LimiteFotos: business?.LimiteFotos ?? 1,
			Revisiones: {
				Activado: business?.Revisiones?.Activado ?? false,
				LimiteRevisiones:
					business?.Revisiones?.LimiteRevisiones ?? 10,
				RevisionesUsados:
					business?.Revisiones?.RevisionesUsados ?? 0,
				LimiteUsuarios:
					business?.Revisiones?.LimiteUsuarios ?? 20,
				UsuariosUsados:
					business?.Revisiones?.UsuariosUsados ?? 0,
			},

			CheckList: {
				Activado: business?.CheckList?.Activado ?? false,
				LimiteCheckList:
					business?.CheckList?.LimiteCheckList ?? 10,
				CheckListUsados:
					business?.CheckList?.CheckListUsados ?? 0,
				LimiteUsuarios:
					business?.CheckList?.LimiteUsuarios ?? 20,
				UsuariosUsados:
					business?.CheckList?.UsuariosUsados ?? 0,
			},

			ChecklistsOT: {
				Activado: business?.ChecklistsOT?.Activado ?? false,
				LimiteProyectos:
					business?.ChecklistsOT?.LimiteProyectos ?? 10,
				LimiteUsuarios:
					business?.ChecklistsOT?.LimiteUsuarios ?? 20,
				ProyectosUsados:
					business?.ChecklistsOT?.ProyectosUsados ?? 0,
				UsuariosUsados:
					business?.ChecklistsOT?.UsuariosUsados ?? 0,
			},
			PiePagina: business?.PiePagina ?? "",
		},
		onSubmit: (values) => {
			dispatch(
				updateBusiness({ ...values, id: business!.id })
			);
			setStatus("Submitted");
		},
		validationSchema: yup.object().shape({
			Nombre: yup.string().required("Nombre requerido"),
			Rut: yup
				.string()
				.min(11)
				.required("Rut es requerido")
				.test({
					name: "Rut",
					message: "Rut no válido",
					test: (value) => {
						if (!value) return false;
						return rutUtils.validate(value);
					},
				}),
		}),
	});

	const {
		handleSubmit,
		values,
		handleChange,
		touched,
		errors,
		status,
		setStatus,
		setFieldValue,
	} = formik;

	useEffect(() => {
		if (!updateLoading && status === "Submitted") {
			setCanEdit(false);
			setStatus("Initial");
		}
	}, [status, updateLoading, setStatus]);

	return (
		<Card mb={6}>
			<CardHeader
				title="Información Empresa"
				action={
					auth.user?.tipoUsuario === "Administrador" && (
						<HeaderCardActions
							onClick={() => setCanEdit(!canEdit)}
							value={canEdit}
						/>
					)
				}
			/>
			<Divider />
			<form onSubmit={handleSubmit}>
				<CardContent>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<TextField
								disabled={!canEdit}
								id="Nombre"
								label="Nombre"
								type="text"
								value={values.Nombre}
								name="Nombre"
								onChange={handleChange}
								error={
									touched.Nombre && Boolean(errors.Nombre)
								}
								helperText={touched.Nombre && errors.Nombre}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								disabled={!canEdit}
								id="Rut"
								label="Rut Empresa"
								type="text"
								value={values.Rut}
								name="Rut"
								onChange={(e) => {
									e.target.value = rutUtils.format(
										e.target.value
									);
									handleChange(e);
								}}
								error={touched.Rut && Boolean(errors.Rut)}
								helperText={touched.Rut && errors.Rut}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								disabled={!canEdit}
								id="PiePagina"
								label="Pié de Página"
								type="text"
								value={values.PiePagina}
								name="PiePagina"
								onChange={handleChange}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							style={{ marginTop: 10 }}
						>
							<Typography
								color={
									!canEdit ? "textSecondary" : "inherit"
								}
								variant="subtitle2"
							>
								Limite de Fotografías:
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								disabled={!canEdit}
								value={values.LimiteFotos}
								id="LimiteFotos"
								type="number"
								name="LimiteFotos"
								onChange={handleChange}
								fullWidth
								inputProps={{
									min: 1,
									max: 2,
								}}
							/>
						</Grid>
						<Grid container spacing={1} item xs={12}>
							<Grid item xs={12} style={{ marginTop: 15 }}>
								<Typography
									align="center"
									style={{
										color: !canEdit ? grey[400] : "",
									}}
								>
									Módulos
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											disabled={!canEdit}
											checked={values.Revisiones.Activado}
											onChange={handleChange}
											name="Revisiones.Activado"
											color="primary"
										/>
									}
									label="Revisiones"
								/>
							</Grid>

							<Grid item xs={12}>
								<Collapse in={values?.Revisiones?.Activado}>
									<ModuleLimitForm
										disabled={!canEdit}
										width="100%"
										module="Revisiones"
										formik={formik as any}
									/>
								</Collapse>
							</Grid>

							{/* checkbox neo checklists */}
							<Grid item xs={12} md={4}>
								<FormControlLabel
									control={
										<Checkbox
											disabled={!canEdit}
											checked={values.ChecklistsOT.Activado}
											onChange={handleChange}
											name="ChecklistsOT.Activado"
											color="primary"
										/>
									}
									label="Checklists"
								/>
							</Grid>

							<Grid item xs={12}>
								<Collapse
									in={values?.ChecklistsOT?.Activado}
								>
									<ModuleLimitForm
										disabled={!canEdit}
										width="100%"
										module="ChecklistsOT"
										formik={formik as any}
									/>
								</Collapse>
							</Grid>

							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											disabled={!canEdit}
											checked={values.CheckList.Activado}
											onChange={handleChange}
											name="CheckList.Activado"
											color="primary"
										/>
									}
									label="Checklists Revisiones"
								/>
							</Grid>

							<Grid item xs={12}>
								<Collapse in={values?.CheckList?.Activado}>
									<ModuleLimitForm
										disabled={!canEdit}
										width="100%"
										module="CheckList"
										formik={formik as any}
									/>
								</Collapse>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
				<Collapse in={canEdit}>
					<div>
						<Divider />
						<CardActions>
							<Zoom
								in={canEdit}
								style={{
									marginLeft: "auto",
									transitionDelay: canEdit
										? "150ms"
										: "0ms",
								}}
							>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={updateLoading}
								>
									Guardar
								</Button>
							</Zoom>
						</CardActions>
					</div>
				</Collapse>
			</form>
		</Card>
	);
};
