import { compareAsc } from "date-fns";
import firebase from "firebase/app";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { openSnack } from "./uiActions";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";
import { Observacion } from "src/models/Observacion";
import { getStatusRevision } from "src/utils/checkpro";

const listener = FirebaseListener.addListener(
	"observaciones-revision"
);

export const getObservations = (
	listId: string
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const query = firestore
			.collectionGroup("Observaciones")
			// Typo is intentional... pls don't change
			.where("ListadonId", "==", listId)
			.orderBy("CreatedAt", "desc");

		listener.close();
		listener.set(
			query.onSnapshot(
				(snap) => {
					const observaciones = snap.docs.map(
						(doc, index, arr) =>
							({
								...doc.data(),
								id: doc.id,
								Numero: arr.length - index,
								Estado: getStatusRevision(doc.data() as any)
									.status,
							} as Observacion)
					);

					dispatch(isLoading(false));

					dispatch(
						setObservations(
							observaciones,
							observaciones.length,
							null
						)
					);
				},
				(error) => {
					dispatch(setError(error.message));
					dispatch(isLoading(false));
				}
			)
		);
	};
};
export const getMoreObservations = (
	projectId: string,
	revisionId: string,
	listId: string,
	limit: number = types.TABLE_LIMIT_DEFAULT,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const { lastDoc, observations, totalDocs } =
			getState().observationsReducer;
		try {
			const ids = {
				project: projectId,
				revision: revisionId,
				list: listId,
			};
			const response = await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectId)
				.collection("Revisiones")
				.doc(revisionId)
				.collection("Listado")
				.doc(listId)
				.collection("Observaciones")
				.orderBy("CreatedAt", "desc")
				.startAfter(lastDoc)
				.limit(limit)
				.get();

			dispatch(
				setObservations(
					observations.concat(
						mapObservations(response, ids)
					),
					totalDocs,
					response.docs[response.docs.length - 1]
				)
			);
			// dispatch(setLastDoc(response.docs[response.docs.length - 1]));
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const updateObservation = (
	values: any,
	projectID: string,
	revisionsID: string,
	listID: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({
			type: types.OBSERVATION_UPDATE_IS_SUBMITTING,
		});
		const { observations } = getState().observationsReducer;
		try {
			const updateData = {
				Subcontrato: values.subcontrato,
				Sector: values.sector,
				ErrorTipo: values.errorTipo,
				Comentario: values.comentario,
				Recinto: values.recinto,
				Prioridad: values.prioridad,
			};
			await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectID)
				.collection("Revisiones")
				.doc(revisionsID)
				.collection("Listado")
				.doc(listID)
				.collection("Observaciones")
				.doc(values.id)
				.update(updateData)
				.then(() =>
					dispatch({
						type: types.OBSERVATION_UPDATE_SUCCESS,
					})
				)
				.then(() =>
					dispatch(
						openSnack(
							"Observación actualizada",
							SnackState.SUCCESS
						)
					)
				);
		} catch (error: any) {
			dispatch(
				openSnack(
					"!Ha ocurrido un problema¡",
					SnackState.ERROR
				)
			);
			dispatch({
				type: types.OBSERVATION_UPDATE_FAILURE,
				payload: error,
			});
		}
	};
};

export const deleteList = (
	listId: string,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isUpdateLoading(true));
		const project =
			getState().projectsReducer.revisions.selectedProject;
		const revision =
			getState().revisionsReducer.selectedRevision;

		if (!project)
			throw Error("No se ha seleccionado un proyecto");
		if (!revision)
			throw Error("No se ha seleccionado una revisión");

		try {
			await firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(project.id)
				.collection("Revisiones")
				.doc(revision.id)
				.collection("Listado")
				.doc(listId)
				.delete();

			dispatch(
				openSnack("Listado eliminado", SnackState.SUCCESS)
			);
		} catch (error: any) {
			dispatch(setError(error));
		} finally {
			dispatch(isUpdateLoading(false));
		}
	};
};

// export const addMoreObservations = (
//   limit: number = 10,
//   projectID: string,
//   revisionsID: string,
//   listID: string,
//   myquery: any = {}
// ): AppThunk => {
//   return async (dispatch, getState) => {
//     dispatch(isLoading(true));
//     const { lastDoc } = getState().observationsReducer;
//     try {
//       const ids = {
//         project: projectID,
//         revision: revisionsID,
//         list: listID,
//       };
//       let response: any;
//       if (myquery) {
//         response = firestore
//           .collection("Proyectos")
//           .doc(projectID)
//           .collection("Revisiones")
//           .doc(revisionsID)
//           .collection("Listado")
//           .doc(listID)
//           .collection("Observaciones")
//           .limit(limit)
//           .orderBy("CreatedAt", "desc")
//           .startAfter(lastDoc);

//         Object.keys(myquery).forEach((key) => {
//           let value = myquery[key];

//           if (key == "dateStart") {
//             const miliDate = Date.parse(value);
//             response = response.endAt(new Date(miliDate));
//           } else if (key == "dateEnd") {
//             const miliDate = Date.parse(value);
//             response = response.startAt(new Date(miliDate));
//           } else {
//             response = response.where(key, "==", value);
//           }
//         });

//         response = await response.get();
//       } else {
//

//         response = await firestore
//           .collection("Proyectos")
//           .doc(projectID)
//           .collection("Revisiones")
//           .doc(revisionsID)
//           .collection("Listado")
//           .doc(listID)
//           .collection("Observaciones")
//           .orderBy("CreatedAt", "desc")
//           .startAfter(lastDoc)
//           .limit(limit)
//           .get();
//       }
//       dispatch(
//         setObservations(
//           mapObservations(response, ids),
//           response.size,
//           response.docs[response.docs.length - 1]
//         )
//       );
//       // dispatch(addObservationPage(mapObservations(response, ids)));
//       // dispatch(setLastDoc(response.docs[response.docs.length - 1]));
//     } catch (error: any) {
//       dispatch(setError(error));
//
//     } finally {
//       dispatch(isLoading(false));
//     }
//   };
// };

// export const getTotalDocs = (
//   projectId: string,
//   revisionId: string,
//   listId: string
// ): AppThunk => {
//   return async (dispatch) => {
//     try {
//       const response = await firestore
//         .collection("Proyectos")
//         .doc(projectId)
//         .collection("Revisiones")
//         .doc(revisionId)
//         .collection("Listado")
//         .doc(listId)
//         .collection("Observaciones")
//         .get();

//       dispatch(setTotalDocs(response.size));
//     } catch (error: any) {
//       dispatch(setError(error));
//
//     }
//   };
// };

export const searchObservations = (
	myquery: any = {},
	projectID: string,
	revisionsID: string,
	listID: string,
	limit: number = types.TABLE_LIMIT_DEFAULT,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));

		try {
			const ids = {
				project: projectID,
				revision: revisionsID,
				list: listID,
			};

			let response2: any;
			response2 = firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectID)
				.collection("Revisiones")
				.doc(revisionsID)
				.collection("Listado")
				.doc(listID)
				.collection("Observaciones")
				.orderBy("CreatedAt", "desc");
			let response: any;
			response = firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectID)
				.collection("Revisiones")
				.doc(revisionsID)
				.collection("Listado")
				.doc(listID)
				.collection("Observaciones")
				.limit(limit)
				.orderBy("CreatedAt", "desc");

			Object.keys(myquery).forEach((key) => {
				let value = myquery[key];

				if (key == "dateStart") {
					const miliDate = Date.parse(value);
					response = response.endAt(new Date(miliDate));
					response2 = response2.endAt(new Date(miliDate));
				} else if (key == "dateEnd") {
					const miliDate = Date.parse(value);
					response = response.startAt(new Date(miliDate));
					response2 = response2.startAt(new Date(miliDate));
				} else {
					response = response.where(key, "==", value);
					response2 = response2.where(key, "==", value);
				}
			});

			response = await response.get();
			response2 = await response2.get();

			dispatch(
				setObservations(
					mapObservations(response, ids),
					response2.size,
					response.docs[response.docs.length - 1]
				)
			);
			// dispatch(setLastDoc(response.docs[response.docs.length - 1]));
		} catch (error) {
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const searchMoreObservations = (
	myquery: any = {},
	projectID: string,
	revisionsID: string,
	listID: string,
	limit: number = types.TABLE_LIMIT_DEFAULT,
	isChecklist = false
): AppThunk => {
	return async (dispatch, getState) => {
		dispatch(isLoading(true));
		const { lastDoc, totalDocs, observations } =
			getState().observationsReducer;
		try {
			const ids = {
				project: projectID,
				revision: revisionsID,
				list: listID,
			};

			let response: any;
			response = firestore
				.collection(
					isChecklist ? "ProyectosChecklist" : "Proyectos"
				)
				.doc(projectID)
				.collection("Revisiones")
				.doc(revisionsID)
				.collection("Listado")
				.doc(listID)
				.collection("Observaciones")
				.startAfter(lastDoc)
				.limit(limit)
				.orderBy("CreatedAt", "desc");

			Object.keys(myquery).forEach((key) => {
				let value = myquery[key];

				if (key == "dateStart") {
					const miliDate = Date.parse(value);
					response = response.endAt(new Date(miliDate));
				} else if (key == "dateEnd") {
					const miliDate = Date.parse(value);
					response = response.startAt(new Date(miliDate));
				} else {
					response = response.where(key, "==", value);
				}
			});

			response = await response.get();

			dispatch(
				setObservations(
					observations.concat(
						mapObservations(response, ids)
					),
					totalDocs,
					response.docs[response.docs.length - 1]
				)
			);
			// dispatch(setLastDoc(response.docs[response.docs.length - 1]));
		} catch (error) {
		} finally {
			dispatch(isLoading(false));
		}
	};
};

export const updateEstadoObservacion = (
	projectId: string,
	revisionId: string,
	listId: string,
	tipo?: string,
	isChecklist = false
): AppThunk => {
	return async (_, getState) => {
		const { observations } = getState().observationsReducer;
		try {
			//

			const validarFiltroEstado = (data: any) => {
				// Fecha1 - Fecha2
				// 1 : Fecha1 > Fecha2
				// -1 : Fecha1 < Fecha2
				// 0 : Fecha1 === Fecha2

				const porVencer = compareAsc(
					new Date(),
					data.PorVencer.toDate()
				);
				const vencida = compareAsc(
					new Date(),
					data.Vencida.toDate()
				);

				let color: string = "white";
				if (vencida === 0 || vencida > 0) {
					if (data.Estado !== "Vencida") {
						firestore
							.collection(
								isChecklist
									? "ProyectosChecklist"
									: "Proyectos"
							)
							.doc(projectId)
							.collection("Revisiones")
							.doc(revisionId)
							.collection("Listado")
							.doc(listId)
							.collection("Observaciones")
							.doc(data.id)
							.update({ Estado: "Vencida" });
					}
				} else if (
					(porVencer === 0 || porVencer > 0) &&
					vencida < 0
				) {
					if (data.Estado !== "Por Vencer") {
						firestore
							.collection(
								isChecklist
									? "ProyectosChecklist"
									: "Proyectos"
							)
							.doc(projectId)
							.collection("Revisiones")
							.doc(revisionId)
							.collection("Listado")
							.doc(listId)
							.collection("Observaciones")
							.doc(data.id)
							.update({ Estado: "Por Vencer" });
					}
				} else {
					if (data.Estado !== "Observada") {
						firestore
							.collection(
								isChecklist
									? "ProyectosChecklist"
									: "Proyectos"
							)
							.doc(projectId)
							.collection("Revisiones")
							.doc(revisionId)
							.collection("Listado")
							.doc(listId)
							.collection("Observaciones")
							.doc(data.id)
							.update({ Estado: "Observada" });
					}
				}
			};
			observations.forEach((x) => {
				if (x.Estado !== "Resuelta") {
					validarFiltroEstado(x);
				}
			});
		} catch (error: any) {}
	};
};

export const mapObservations = (
	response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
	ids?: any,
	isChecklist = false
) => {
	try {
		let lists: any[] = [];
		response.docs.forEach((doc) => {
			//
			let data = doc.data();

			try {
				//

				firestore
					.collection(
						isChecklist ? "ProyectosChecklist" : "Proyectos"
					)
					.doc(ids.project)
					.collection("Revisiones")
					.doc(ids.revision)
					.collection("Listado")
					.doc(ids.list)
					.collection("Observaciones")
					.doc(doc.id)
					.collection("Imagenes")
					.get()
					.then((document) => {
						if (document) {
							//

							if (document.docs[1]) {
								data.Image = document.docs[1].data().Image;
							}
							if (document.docs[3]) {
								data.Image2 = document.docs[3].data().Image;
							}
						}
					});
			} catch (error: any) {
			} finally {
				// setGettingImages(true);
				//
			}
			//

			data.id = doc.id;
			lists.push(data);
		});
		return lists;
	} catch (error) {
		throw error;
	}
};

export const setSelectedObservation = (
	observation: any
): Action => ({
	type: types.OBSERVATIONS_SET_SELECTED,
	payload: observation,
});

const setObservations = (
	observations: any[],
	totalDocs: any,
	lastDoc: any
): Action => ({
	type: types.OBSERVATIONS_GET_DOCS,
	payload: {
		observations: observations,
		totalDocs: totalDocs,
		lastDoc: lastDoc,
	},
});

const setGettingImages = (getting: any): Action => ({
	type: types.IMAGES_GET_DOC,
	payload: getting,
});

const isLoading = (isLoading: boolean): Action => ({
	type: types.OBSERVATIONS_LOADING,
	payload: isLoading,
});

const isUpdateLoading = (isLoading: boolean): Action => ({
	type: types.OBSERVATIONS_UPDATE_LOADING,
	payload: isLoading,
});

const setError = (error: string): Action => ({
	type: types.OBSERVATIONS_FAILURE,
	payload: error,
});

const addObservationPage = (
	observations: any[]
): Action => ({
	type: types.OBSERVATIONS_ADD_DOCS,
	payload: observations,
});

const setTotalDocs = (totalDocs: number): Action => ({
	type: types.OBSERVATIONS_SET_TOTAL_DOCS,
	payload: totalDocs,
});

const setLastDoc = (lastDoc: any): Action => ({
	type: types.OBSERVATIONS_SET_LAST_DOC,
	payload: lastDoc,
});

const updateRevisionStore = (observation: any): Action => ({
	type: types.OBSERVATIONS_UPDATE_DOC,
	payload: observation,
});

const removeRevisionStore = (observation: any): Action => ({
	type: types.OBSERVATIONS_REMOVE_DOC,
	payload: observation,
});
