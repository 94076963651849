import {
	Context,
	createContext,
	FC,
	ReactNode,
	useContext,
} from "react";

/**
 * Es un Hook que inicializa un contexto.
 */
export type StateInitializer<T> = (
	partialContext?: Partial<T>
) => T;

export type ProviderProps<Data> = {
	children?: ReactNode;
} & Partial<Data>;

/**
 * Una clase que administra un `Context` de React.
 */
export class State<Data = object> {
	public readonly Context: Context<Data>;
	public readonly factory: () => Data;

	/**
	 * Construye un Estado y lo inicializa.
	 *
	 * Además, esta clase retorna tres cosas:
	 * - Un `Provider` para el `CustomContext`.
	 * - La función `generateInitializer,` que genera un Hook
	 * para inicializar el `CustomContext` de forma manual.
	 * - La función `useCustomContext,` que permite obtener el
	 * estado del `CustomContext`.
	 *
	 * @param factory Una función que construye el estado del Context.
	 */
	constructor(factory: () => Data) {
		this.Context = createContext<Data>({} as Data);
		this.factory = factory;
	}

	/**
	 * @returns Un Componente Funcional que puede ser usado para
	 * proveer de CustomContext a un árbol de Componentes.
	 */
	public readonly Provider: FC<ProviderProps<Data>> = ({
		children,
		...partialContext
	}) => {
		const { Provider } = this.Context;

		return (
			<Provider
				value={{
					...this.factory(),
					...partialContext,
				}}
			>
				{children}
			</Provider>
		);
	};

	/**
	 * Consigue el valor actual del estado.
	 */
	public readonly useContext = () =>
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useContext<Data>(this.Context);

	/**
	 * Crea un Estado.
	 */
	public readonly useFactory = (
		props?: Partial<Data>
	): Data => ({
		...this.factory(),
		...props,
	});
}
