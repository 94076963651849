import { Action } from "../../models/action";
import * as types from "../../constants";
import { DialogAction } from "../../models/dialog-actions";

export interface ISubContractsState {
  subContracts: any[];
  isLoading: boolean;
  totalDocs: number;
  lastDoc?: any;
  error?: string;
  selectedSubContract?: any;
  dialog: {
    isDialogOpen: boolean;
    isDialogLoading: boolean;
    dialogAction: DialogAction;
  };
  typeErrors: {
    isLoading: boolean;
    updateLoading: boolean;
  };
}

const initialState = {
  subContracts: [],
  isLoading: false,
  totalDocs: 0,
  dialog: {
    isDialogOpen: false,
    isDialogLoading: false,
    dialogAction: DialogAction.Initial,
  },
  typeErrors: {
    isLoading: false,
    updateLoading: false,
  },
} as ISubContractsState;

export const subContractsReducer = (
  state = initialState,
  action: Action
): ISubContractsState => {
  switch (action.type) {
    case types.SUBCONTRACT_GET_DOCS:
      return {
        ...state,
        subContracts: action.payload,
      };
    case types.SUBCONTRACT_UPDATE_DOC:
      return {
        ...state,
        subContracts: state.subContracts.map((x) =>
          x.id === action.payload.id ? { ...x, ...action.payload } : x
        ),
      };
    case types.SUBCONTRACT_REMOVE_DOC:
      return {
        ...state,
        subContracts: state.subContracts.filter(
          (x) => x.id !== action.payload.id
        ),
      };
    case types.SUBCONTRACT_ADD_DOCS:
      return {
        ...state,
        subContracts: [...state.subContracts, ...action.payload],
      };
    case types.SUBCONTRACT_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.SUBCONTRACT_SET_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };
    case types.SUBCONTRACT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.SUBCONTRACT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.SUBCONTRACT_IS_DIALOG_OPEN:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          isDialogOpen: action.payload,
        },
      };
    case types.SUBCONTRACT_IS_DIALOG_LOADING:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          isDialogLoading: action.payload,
        },
      };
    case types.SUBCONTRACT_CHANGE_DIALOG_ACTION:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          dialogAction: action.payload,
        },
      };
    // TypeErrors
    case types.SUBCONTRACT_SET_SELECTED:
      return {
        ...state,
        selectedSubContract: action.payload,
      };
    case types.SUBCONTRACT_UPDATE_LOADING:
      return {
        ...state,
        typeErrors: {
          ...state.typeErrors,
          updateLoading: action.payload,
        },
      };
    case types.SUBCONTRACT_TYPE_ERROR_LOADING:
      return {
        ...state,
        typeErrors: {
          ...state.typeErrors,
          isLoading: action.payload,
        },
      };
    case types.SUBCONTRACT_UPDATE_SELECTED:
      return {
        ...state,
        selectedSubContract: {
          ...state.selectedSubContract,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
