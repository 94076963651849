import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";
import { SnackState } from "../../models/snack-state";
import { openSnack } from "./uiActions";
import { cleanString } from "../../utils/utils";

export const getPriorities = (): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Prioridades")
        .orderBy("Nombre")
        .get();

      dispatch(setPriority(mapPriority(response)));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getPrioritiesByBusiness = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Prioridades")
        .where(
          "EmpresaReference",
          "==",
          firestore.collection("Empresas").doc(id)
        )
        .orderBy("Nombre")
        .get();

      dispatch(setPriority(mapPriority(response)));
    } catch (error: any) {
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const addPriority = (priority: any): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore.collection("Prioridades").get();

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (cleanString(post.data().Nombre) === cleanString(priority.Nombre)) {
          return true;
        }
      });

      if (__FOUND) {
        dispatch(openSnack("Prioridad ya existe", SnackState.ERROR));
      } else {
        await firestore.collection("Prioridades").add(priority);

        dispatch(getPriorities());
      }
    } catch (error: any) {
      dispatch(setError(error));
      
      dispatch(isLoading(false));
    }
  };
};

export const updatePriority = (priority: any, id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore.collection("Prioridades").get();

      let priorityId = "0";

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (cleanString(post.data().Nombre) === cleanString(priority.Nombre)) {
          priorityId = post.id;
          return true;
        }
      });

      if (__FOUND && priorityId !== id) {
        dispatch(openSnack("Prioridad ya existe", SnackState.ERROR));
      } else {
        await firestore.collection("Prioridades").doc(id).update(priority);

        dispatch(updatePriorityStore({ id, ...priority }));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const deletePriority = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      await firestore.collection("Prioridades").doc(id).delete();

      dispatch(removePriorityStore({ id }));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const mapPriority = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  try {
    let priorities: any[] = [];
    response.docs.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      priorities.push(data);
    });

    return priorities;
  } catch (error) {
    throw error;
  }
};

const setPriority = (priorities: any[]): Action => ({
  type: types.PRIORITIES_GET_DOCS,
  payload: priorities,
});

const updatePriorityStore = (priority: any): Action => ({
  type: types.PRIORITIES_UPDATE_DOC,
  payload: priority,
});

const removePriorityStore = (priority: any): Action => ({
  type: types.PRIORITIES_REMOVE_DOC,
  payload: priority,
});

const isLoading = (isLoading: boolean): Action => ({
  type: types.PRIORITIES_LOADING,
  payload: isLoading,
});

const setError = (error: string): Action => ({
  type: types.CHARGES_FAILURE,
  payload: error,
});
