import { Dialog, DialogContent } from "@material-ui/core";
import { FC } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { TabGroup } from "src/components/CheckPro/TabGroup";
import { useUserValidator } from "./validator";
import { UserModules } from "./Modules";
import { UserData } from "./Data";
import { UserAuthPanel } from "./Auth";

interface Props {
	open: boolean;
	onClose: () => void;
}

export const DialogCreateEditUser: FC<Props> = ({
	open,
	onClose,
}) => {
	const validator = useUserValidator(onClose);

	const { selectedUser } = validator;

	const {
		Nombre = "Crear",
		Apellido = "usuario",
		Email = "",
	} = selectedUser ?? {};

	return (
		<Dialog
			open={open}
			onClose={(_, reason) => {
				if (reason === "backdropClick") return;
				onClose();
			}}
		>
			<DialogTitle
				onClose={onClose}
				bigText={`${Nombre} ${Apellido}`}
				smallText={
					selectedUser ? Email : "Completa el formulario"
				}
			/>
			<DialogContent style={{ minHeight: 300 }}>
				<form onSubmit={validator.formik.handleSubmit}>
					<TabGroup
						tabs={["Autenticación", "Datos", "Módulos"]}
						disabled={[!selectedUser]}
						hidden={[!selectedUser]}
						defaultSelected={selectedUser ? 0 : 1}
					>
						<UserAuthPanel
							{...validator}
							onClose={onClose}
						/>

						<UserData {...validator} />

						<UserModules {...validator} />
					</TabGroup>
				</form>
			</DialogContent>
		</Dialog>
	);
};
