import {
	Box,
	Chip,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useMemo, FC } from "react";

type Props = {
	used: number;
	limit: number;
};

export const ProjectCounter: FC<Props> = ({
	used,
	limit,
}) => {
	const usedPercentage = useMemo(
		() => (used / limit) * 100,
		[used, limit]
	);

	const percentageColor = useMemo(() => {
		const x = usedPercentage;
		if (x >= 100) return "firebrick";
		else if (x >= 50) return "orange";
		else return "steelblue";
	}, [usedPercentage]);

	return (
		<>
			<Box
				height={10}
				bgcolor="lightgray"
				borderRadius={10}
				margin={0.5}
			>
				<Box
					height={10}
					borderRadius={5}
					width={`${usedPercentage}%`}
					bgcolor={percentageColor}
				/>
			</Box>

			<Box
				margin={2}
				display="flex"
				justifyContent="center"
			>
				{/* popup info */}
				<Tooltip
					title={
						<Typography align="center">
							Proyectos utilizados
							<br />( {Math.round(usedPercentage)}% )
						</Typography>
					}
					arrow
				>
					<Chip
						icon={<Info htmlColor={percentageColor} />}
						color="primary"
						style={{
							borderRadius: 24,
						}}
						variant="outlined"
						size="small"
						label={`${used} / ${limit || "?"}`}
					/>
				</Tooltip>
			</Box>
		</>
	);
};
