import {
	Box,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Collapse,
	Divider,
	FormControlLabel,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FC } from "react";
import { UserValidator } from "./validator";
import { getProjectName } from "src/models/Proyecto";

export const UserModules: FC<UserValidator> = ({
	formik,
	available,
	selectedBusiness,
	allocateProject,
	picked,
	deallocateProject,
	getAutocompleteLabel,
	handleReset,
}) => {
	const { handleChange, values, isSubmitting } = formik;

	return (
		<>
			{/* picker revisiones */}
			<Box>
				<Tooltip
					title={
						selectedBusiness?.Revisiones.Activado
							? ""
							: "Este módulo está desactivado."
					}
				>
					<FormControlLabel
						label="Revisiones"
						control={
							<Checkbox
								disabled={
									!selectedBusiness?.Revisiones.Activado
								}
								id="Revisiones"
								onChange={handleChange}
								checked={values.Revisiones}
								color="primary"
							/>
						}
					/>
				</Tooltip>

				<Collapse in={values.Revisiones}>
					<Autocomplete
						disableCloseOnSelect
						size="small"
						options={available.revisions}
						getOptionLabel={(option) =>
							option.NombreProyecto
						}
						renderOption={(option: any) => (
							<Box
								width="100%"
								height="100%"
								onClick={() => allocateProject(option)}
							>
								<Typography>
									{getAutocompleteLabel(option)}
								</Typography>
							</Box>
						)}
						renderInput={(params: any) => (
							<TextField
								{...params}
								label="Asignar revisiones"
								variant="outlined"
							/>
						)}
					/>

					<Box
						padding={2}
						display="flex"
						flexWrap="wrap"
						style={{ gap: 2 }}
					>
						{picked.revisions.map((r) => (
							<Chip
								key={`revision-${r.id}`}
								color="primary"
								style={{ flexGrow: 1 }}
								size="small"
								label={getProjectName(r)}
								onDelete={() => deallocateProject(r)}
							/>
						))}
					</Box>

					<Divider
						style={{ margin: "10px 0px 10px 0px" }}
					/>
				</Collapse>
			</Box>

			{/* picker checklist revisiones */}
			<Box>
				<Tooltip
					title={
						selectedBusiness?.Revisiones.Activado
							? ""
							: "Este módulo está desactivado."
					}
				>
					<FormControlLabel
						label="Checklist Revisiones"
						control={
							<Checkbox
								disabled={
									!selectedBusiness?.CheckList.Activado
								}
								id="Checklist"
								onChange={handleChange}
								checked={values.Checklist}
								color="primary"
							/>
						}
					/>
				</Tooltip>

				<Collapse in={values.Checklist}>
					<Autocomplete
						disableCloseOnSelect
						size="small"
						options={available.checklistRevisions}
						getOptionLabel={(option) =>
							option.NombreProyecto
						}
						renderOption={(option: any) => (
							<Box
								width="100%"
								height="100%"
								onClick={() => allocateProject(option)}
							>
								<Typography>
									{getAutocompleteLabel(option)}
								</Typography>
							</Box>
						)}
						renderInput={(params: any) => (
							<TextField
								{...params}
								label="Asignar Checklist Revisiones"
								variant="outlined"
							/>
						)}
					/>

					<Box
						padding={2}
						display="flex"
						flexWrap="wrap"
						style={{ gap: 2 }}
					>
						{picked.checklistRevisions.map((r) => (
							<Chip
								key={`revision-${r.id}`}
								color="primary"
								style={{ flexGrow: 1 }}
								size="small"
								label={r.NombreProyecto}
								onDelete={() => deallocateProject(r)}
							/>
						))}
					</Box>

					<Divider
						style={{ margin: "10px 0px 10px 0px" }}
					/>
				</Collapse>
			</Box>

			{/* picker checklists OT */}
			<Box>
				<Tooltip
					title={
						selectedBusiness?.ChecklistsOT?.Activado
							? ""
							: "Este módulo está desactivado."
					}
				>
					<FormControlLabel
						label="Checklists"
						control={
							<Checkbox
								disabled={
									!selectedBusiness?.ChecklistsOT?.Activado
								}
								id="ChecklistsOT"
								onChange={handleChange}
								checked={values.ChecklistsOT}
								color="primary"
							/>
						}
					/>
				</Tooltip>

				<Collapse in={values.ChecklistsOT}>
					<Autocomplete
						disableCloseOnSelect
						size="small"
						options={available.checklists}
						getOptionLabel={getProjectName}
						renderOption={(option: any) => (
							<Box
								width="100%"
								height="100%"
								onClick={() => allocateProject(option)}
							>
								<Typography>
									{getAutocompleteLabel(option)}
								</Typography>
							</Box>
						)}
						renderInput={(params: any) => (
							<TextField
								{...params}
								label="Asignar Checklists"
								variant="outlined"
							/>
						)}
					/>

					<Box
						padding={2}
						display="flex"
						flexWrap="wrap"
						style={{ gap: 2 }}
					>
						{picked.checklists.map((r) => (
							<Chip
								key={`revision-${r.id}`}
								color="primary"
								style={{ flexGrow: 1 }}
								size="small"
								label={r.Nombre}
								onDelete={() => deallocateProject(r)}
							/>
						))}
					</Box>
				</Collapse>
			</Box>

			{/* submit */}
			<Box
				display="flex"
				justifyContent="flex-end"
				gridGap={10}
			>
				<Button onClick={handleReset} color="primary">
					Cancelar
				</Button>
				<Button
					type="submit"
					color="primary"
					variant="contained"
					disabled={isSubmitting}
					startIcon={
						isSubmitting && <CircularProgress size={16} />
					}
				>
					Guardar
				</Button>
			</Box>
		</>
	);
};
