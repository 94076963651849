import { createAsyncThunk } from "@reduxjs/toolkit";
import Firebase from "firebase";
import { firestore } from "src/firebase/firebase";
import { Empresa } from "src/models/Empresa";
import { ChecklistOT } from "src/models/OT/Checklist";
import { SnackState } from "src/models/snack-state";
import { openSnack } from "src/redux/actions/uiActions";
import {
	StateGetter,
	getSelectedBusinessRef,
} from "src/redux/neo-checklists/utils";
import { cleanString } from "src/utils/utils";

type CreateParams = {
	business?: Empresa;
	checklist: Pick<
		ChecklistOT,
		"Nombre" | "Tipo" | "Preguntas"
	>;
};

export const createBusinessNeoChecklist = createAsyncThunk(
	"businesses/create/neo-checklists",
	async (params: CreateParams, { getState, dispatch }) => {
		try {
			const { business, checklist } = params;

			const businessSnap = await getSelectedBusinessRef(
				business ?? (getState as StateGetter)
			).get();

			const businessData =
				(await businessSnap.data()) as Empresa;

			const checklistData: ChecklistOT = {
				...checklist,
				TotalRespuestas: 0,
				Nombre_lower: cleanString(checklist.Nombre),
				FechaCreacion: Firebase.firestore.Timestamp.now(),
				Id: firestore.collection("Aguante/El/Wanders").doc()
					.id,
				Cantidad: 0,
				TotalEspacios: 0,
				EspaciosCompletados: 0,
			};

			if (
				businessData.ListasDeChequeo?.some(
					(c) =>
						c.Nombre_lower === checklistData.Nombre_lower
				)
			)
				throw Error(
					`Ya existe una lista con el nombre ${checklistData.Nombre}.`
				);

			const ListasDeChequeo = [
				...(businessData.ListasDeChequeo ?? []),
				checklistData,
			].sort((a, b) =>
				a.Nombre_lower.localeCompare(b.Nombre_lower)
			);

			await businessSnap.ref.update({
				ListasDeChequeo,
			});

			dispatch(
				openSnack(
					"Creamos la lista de chequeo.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
	}
);

type UpdateParams = {
	business?: Empresa;
	checklist: Partial<
		Pick<
			ChecklistOT,
			| "Nombre"
			| "Preguntas"
			| "Id"
			| "Tipo"
			| "Unidad"
			| "MetaRevision"
		>
	>;
};

export const updateBusinessNeoChecklist = createAsyncThunk(
	"businesses/update/neo-checklists",
	async (params: UpdateParams, { getState, dispatch }) => {
		try {
			const { business, checklist } = params;

			if (!checklist.Id)
				throw Error(
					"No encontramos el id de la lista de chequeo."
				);

			const businessSnap = await getSelectedBusinessRef(
				business ?? (getState as StateGetter)
			).get();
			const businessData = businessSnap.data() as Empresa;
			const checklistData: Partial<ChecklistOT> = {
				...checklist,
			};

			if (checklistData.Nombre) {
				checklistData.Nombre_lower = cleanString(
					checklistData.Nombre
				);

				if (
					businessData.ListasDeChequeo?.filter(
						(c) => c.Id !== checklist.Id
					).some(
						(c) =>
							c.Nombre_lower === checklistData.Nombre_lower
					)
				)
					throw Error(
						`Ya existe una lista con el nombre ${checklist.Nombre}.`
					);
			}

			const ListasDeChequeo = [
				...(businessData.ListasDeChequeo ?? []),
			];
			const indexToUpdate = ListasDeChequeo.findIndex(
				(c) => c.Id === checklist.Id
			);

			if (indexToUpdate === -1)
				throw Error("No encontramos la lista de chequeo.");

			ListasDeChequeo[indexToUpdate] = {
				...ListasDeChequeo[indexToUpdate],
				...checklistData,
			};

			await businessSnap.ref.update({
				ListasDeChequeo,
			});

			dispatch(
				openSnack(
					"Actualizamos la lista de chequeo.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
	}
);

type RemoveParams = {
	business?: Empresa;
	checklist: Pick<ChecklistOT, "Id">;
};

export const removeBusinessNeoChecklist = createAsyncThunk(
	"businesses/remove/neo-checklists",
	async (params: RemoveParams, { getState, dispatch }) => {
		try {
			const { business, checklist } = params;

			if (!checklist.Id)
				throw Error("La lista de chequeo no contiene ID.");

			const businessSnap = await getSelectedBusinessRef(
				business ?? (getState as StateGetter)
			).get();
			const businessData = businessSnap.data() as Empresa;

			const ListasDeChequeo = businessData
				.ListasDeChequeo!.filter(
					(c) => c.Id !== checklist.Id
				)
				.sort((a, b) =>
					a.Nombre_lower.localeCompare(b.Nombre_lower)
				);

			await businessSnap.ref.update({
				ListasDeChequeo,
			});

			dispatch(
				openSnack(
					"Eliminamos la lista de chequeo.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
	}
);
