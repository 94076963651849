import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
	Breadcrumbs as MuiBreadcrumbs,
	Button,
	Divider as MuiDivider,
	Grid,
	Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { Add as AddIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
	addCharge,
	deleteCharge,
	getCharges,
	updateCharge,
} from "../redux/actions/chargesActions";
import { SimpleCrud } from "../components/SimpleCrud";
import { RootState } from "../redux/store/reducer";
import { IChargesState } from "../redux/reducers/chargesReducer";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Charges = () => {
	const dispatch = useDispatch();

	const { isLoading, charges } = useSelector<
		RootState,
		IChargesState
	>((state) => state.chargesReducer);

	useEffect(() => {
		dispatch(getCharges());
	}, [dispatch]);

	const [open, setOpen] = useState(false);

	return (
		<React.Fragment>
			<Helmet title="Cargos" />
			<Grid container spacing={4} justify="space-between">
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						Cargos
					</Typography>
					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<Typography>Cargos</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setOpen(true)}
					>
						<AddIcon />
						Agregar Cargo
					</Button>
				</Grid>
			</Grid>

			<Divider my={6} />

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<SimpleCrud
						key="Cargos"
						isLoading={isLoading}
						name="Cargo"
						onCreateOrUpdate={(
							values,
							action,
							selected
						) => {
							if (action === "New") {
								dispatch(addCharge(values));
							} else if (action === "Edit") {
								//@ts-ignore
								dispatch(updateCharge(values, selected.id));
							}
						}}
						onDelete={(selected) => {
							//@ts-ignore
							dispatch(deleteCharge(selected.id));
						}}
						array={charges}
						openNewDialog={open}
						onClose={() => setOpen(false)}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
