import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Typography,
} from "@material-ui/core";
import { FC } from "react";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { getRef } from "src/utils/firebase";

export const NeoPlaceDisableDialog: FC<DialogProps> = (
	props
) => {
	const { selected, update, loading, toggle } =
		useProjectsOT();

	const handleDisable = async () => {
		await update.checklist.places({
			_ref: getRef(selected.assignedPlaces[0]),
			Asignado: false,
		});

		toggle.assignedPlace([]);
		props.onClose?.({}, "escapeKeyDown");
	};

	return (
		<Dialog {...props}>
			<DialogTitle>Desactivar espacio</DialogTitle>
			<DialogContent>
				<Typography>
					¿Estás seguro de que deseas desactivar este
					espacio? Podrás activarlo nuevamente.
				</Typography>
			</DialogContent>

			<DialogActions>
				<Button
					disabled={loading}
					onClick={() =>
						props.onClose?.({}, "escapeKeyDown")
					}
				>
					Cancelar
				</Button>

				<Button
					color="primary"
					variant="contained"
					disabled={loading}
					onClick={handleDisable}
				>
					{loading ? (
						<CircularProgress size={24} />
					) : (
						"Desactivar"
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
