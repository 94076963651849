type Listener = {
	close: VoidFunction;
	set: (listener: VoidFunction) => void;
};

type ListenerMap = {
	[key: string]: VoidFunction | null;
};

export class FirebaseListener {
	constructor(name: string) {
		return FirebaseListener.addListener(name);
	}

	private static listeners: ListenerMap = {};

	/**
	 * @throws Error if listener with key does not exist.
	 */
	public static getListener = (
		name: string
	): Listener | null => {
		if (!Object.hasOwn(this.listeners, name)) {
			console.warn(`Listener ${name} does not exist`);
			return null;
		}

		return {
			close: () => {
				this.listeners[name]?.();
				this.listeners[name] = null;
			},

			set: (listener, force = false) => {
				if (this.listeners[name] !== null && !force)
					throw Error(
						`Listener ${name} is active. Close it first.`
					);

				if (force) this.listeners[name]?.();
				this.listeners[name] = listener;
			},
		};
	};

	/**
	 * @throws Error if listener with same key exists.
	 */
	public static addListener = (
		name: string,
		listener?: VoidFunction
	): Listener => {
		if (Object.hasOwn(this.listeners, name))
			throw Error(`Listener ${name} already exists`);

		FirebaseListener.listeners[name] = listener ?? null;

		return this.getListener(name)!;
	};

	public static closeAll = () => {
		Object.entries(this.listeners).forEach(
			([name, listener]) => {
				listener?.();
				this.listeners[name] = null;
			}
		);
	};
}
