import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

/**
 * Un simple plahceholder para cuando cargan
 * los datos de un gráfico.
 */
export const ChartLoading = () => {
	return (
		<Box
			padding="1rem"
			display="flex"
			flexDirection="column"
		>
			<Typography
				variant="h3"
				align="center"
				style={{
					paddingTop: "1rem",
					paddingBottom: "2rem",
				}}
			>
				Cargando las observaciones...
			</Typography>
			<Box display="flex" flexDirection="row">
				<Box
					width="50vw"
					height="50vw"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Skeleton
						width="50vw"
						height="50vw"
						variant="circle"
					/>
				</Box>
				<Box
					width="100%"
					alignSelf="center"
					paddingLeft="2rem"
				>
					<Skeleton variant="text" />
					<Skeleton variant="text" />
					<Skeleton variant="text" />
					<Skeleton variant="text" />
					<Skeleton variant="text" />
				</Box>
			</Box>
			<Box
				marginTop="2rem"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
			>
				<Skeleton
					variant="rect"
					height="50vw"
					width="calc(50vw / 5)"
				/>
				<Skeleton
					variant="rect"
					height="50vw"
					width="calc(50vw / 5)"
				/>
				<Skeleton
					variant="rect"
					height="50vw"
					width="calc(50vw / 5)"
				/>
				<Skeleton
					variant="rect"
					height="50vw"
					width="calc(50vw / 5)"
				/>
				<Skeleton
					variant="rect"
					height="50vw"
					width="calc(50vw / 5)"
				/>
			</Box>
		</Box>
	);
};
