import {
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	TextField,
	Box,
	DialogActions,
	Button,
	CircularProgress,
	Typography,
} from "@material-ui/core";
import { FC, useEffect } from "react";
import {
	LegacyProject,
	isChecklistRevision,
} from "../../models/Proyecto";
import { ResponsibleAutocomplete } from "../ResponsibleAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducer";
import { IUsersState } from "src/redux/reducers/usersReducer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateProject } from "../../redux/actions/projectsActions";
import { getUsers } from "src/redux/actions/usersActions";

type Props = DialogProps & { project: LegacyProject };

export const EditRevisionDialog: FC<Props> = ({
	project,
	...props
}) => {
	const dispatch = useDispatch();
	const { users } = useSelector<RootState, IUsersState>(
		(s) => s.usersReducer
	);

	const {
		values,
		setFieldValue: setValue,
		errors,
		handleChange,
		handleSubmit,
		isSubmitting,
		dirty,
	} = useFormik({
		validateOnChange: false,
		onSubmit: async ({ Responsable, ...values }) => {
			await dispatch(
				updateProject(
					{ ...project, ...values },
					Responsable || undefined
				)
			);
			props.onClose?.({}, "escapeKeyDown");
		},
		initialValues: {
			NombreProyecto: project.NombreProyecto,
			Responsable:
				users?.find(
					(u) => u.id === project.Responsable.FirebaseId
				) || null,
			DiasPorVencer: project.DiasPorVencer,
			DiasVencidos: project.DiasVencidos,
		},
		validationSchema: Yup.object({
			NombreProyecto: Yup.string().required("Requerido"),
			DiasPorVencer: Yup.number().min(
				0,
				"Debe ser mayor a 0"
			),
			DiasVencidos: Yup.number().min(
				0,
				"Debe ser mayor a 0"
			),
		}),
	});

	useEffect(() => {
		if (!props.open) return;
		dispatch(getUsers());
	}, [props.open]);

	return (
		<Dialog
			fullWidth
			{...props}
			PaperProps={{
				component: "form",
				onSubmit: handleSubmit as any,
			}}
		>
			<DialogTitle>
				<Typography variant="h4">
					{project.NombreProyecto}
				</Typography>
				<Typography variant="body1">
					Editar propiedades
				</Typography>
			</DialogTitle>

			<DialogContent
				style={{
					display: "flex",
					gap: 20,
					padding: 20,
					flexDirection: "column",
				}}
			>
				<TextField
					fullWidth
					label="Nombre del proyecto"
					value={values.NombreProyecto}
					id="NombreProyecto"
					onChange={handleChange}
					error={!!errors.NombreProyecto}
					helperText={errors.NombreProyecto}
				/>

				<ResponsibleAutocomplete
					label="Responsable"
					value={values.Responsable}
					onChange={(user) => {
						setValue("Responsable", user);
					}}
					error={!!errors.Responsable}
					helperText={errors.Responsable}
				/>

				{!isChecklistRevision(project) && (
					<Box
						display="flex"
						flexDirection="row"
						style={{ gap: 20 }}
					>
						<TextField
							type="number"
							label="Días por vencer"
							value={values.DiasPorVencer}
							id="DiasPorVencer"
							onChange={handleChange}
							error={!!errors.DiasPorVencer}
							helperText={errors.DiasPorVencer}
						/>
						<TextField
							type="number"
							label="Días vencidos"
							value={values.DiasVencidos}
							id="DiasVencidos"
							onChange={handleChange}
							error={!!errors.DiasVencidos}
							helperText={errors.DiasVencidos}
						/>
					</Box>
				)}
			</DialogContent>

			<DialogActions>
				<Button
					disabled={isSubmitting}
					onClick={(ev) =>
						props.onClose?.(ev, "escapeKeyDown")
					}
				>
					Cancelar
				</Button>

				<Button
					disabled={isSubmitting}
					type="submit"
					variant="contained"
					color="primary"
				>
					{isSubmitting ? (
						<CircularProgress size={24} />
					) : (
						"Guardar"
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
