import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	Cancel,
	Done,
	ExpandMore,
} from "@material-ui/icons";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Edit, Trash2 } from "react-feather";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { cleanString } from "src/utils/utils";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { ChecklistOT } from "src/models/OT/Checklist";

type Props = {
	selected?: ChecklistOT | null;
	type: ChecklistOT["Tipo"];
} & DialogProps;

export const BusinessNeoChecklistDialog: FC<Props> = ({
	selected,
	type,
	...props
}) => {
	const [expanded, setExpanded] = useState(0);

	const { checklists } = useBusiness();

	const [selectedCategory, setSelectedCategory] = useState<
		number | null
	>(null);

	const [selectedQuestion, setSelectedQuestion] = useState<
		null | [number, number]
	>(null);

	const {
		values,
		setFieldValue: setField,
		setFieldError: setError,
		handleChange,
		handleSubmit,
		touched,
		errors,
		isSubmitting,
		resetForm,
	} = useFormik({
		// enableReinitialize: true,

		initialValues: {
			name: selected?.Nombre ?? "",
			questions: selected?.Preguntas
				? cloneDeep(selected.Preguntas)
				: [
						{
							Categoria: "Preguntas sin subtítulo",
							Preguntas: [],
						},
				  ],

			currentCategory: "",
			currentQuestion: "",
		},

		validationSchema: Yup.object({
			name: Yup.string().required(
				"Este campo es requerido"
			),
			questions: Yup.array().required(
				"Este campo es requerido"
			),
		}),

		onSubmit: async (
			{ name, questions: categories },
			{ resetForm }
		) => {
			if (selected) {
				await checklists.update({
					Id: selected.Id!,
					Nombre: name,
					Preguntas: categories,
					Tipo: type,
				});
			} else {
				await checklists.create({
					Nombre: name,
					Preguntas: categories,
					Tipo: type,
				});
			}

			props.onClose?.({}, "escapeKeyDown");
			handleReset();
		},
	});

	const handleReset = () => {
		resetForm();
		setSelectedCategory(null);
		setExpanded(0);
		setSelectedQuestion(null);
		setField("questions", [
			{
				Categoria: "Preguntas sin subtítulo",
				Preguntas: [],
			},
		]);
	};

	useEffect(() => {
		if (selected) {
			setField("name", selected.Nombre);
			setField("questions", selected.Preguntas);
		} else {
			handleReset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	const addCategory = () => {
		const questions = [...values.questions];

		if (
			questions
				.map((c) => c.Categoria)
				.map(cleanString)
				.includes(cleanString(values.currentCategory))
		)
			return setError(
				"currentCategory",
				"Ya existe una categoría con este nombre."
			);

		if (!values.currentCategory)
			return setError(
				"currentCategory",
				"Este campo es requerido"
			);

		questions.push({
			Categoria: values.currentCategory,
			Preguntas: [],
		});

		setField("questions", questions);
		setField("currentCategory", "");
	};

	const updateCategory = (index: number) => {
		const questions = [...values.questions];

		const otherQuestions = [...values.questions];
		otherQuestions.splice(index, 1);
		if (
			otherQuestions
				.map((q) => q.Categoria)
				.map(cleanString)
				.includes(cleanString(values.currentCategory))
		)
			return setError(
				"currentCategory",
				"Esta categoría ya existe."
			);

		questions[index].Categoria = values.currentCategory;

		setField("questions", questions);
		setField("currentCategory", "");
		setSelectedCategory(null);
	};

	const deleteCategory = (index: number) => {
		const questions = [...values.questions];
		questions.splice(index, 1);

		setField("questions", questions);
	};

	const addQuestion = async (categoryIndex: number) => {
		if (!values.currentQuestion)
			return setError(
				"currentQuestion",
				"Este campo es requerido"
			);

		const questions = [...values.questions];

		if (
			questions[categoryIndex].Preguntas.map(
				cleanString
			).includes(cleanString(values.currentQuestion))
		)
			return setError(
				"currentQuestion",
				"Ya existe esta pregunta."
			);

		questions[categoryIndex].Preguntas.push(
			values.currentQuestion
		);

		await setField("questions", questions);
		await setField("currentQuestion", "");
	};

	const deleteQuestion = (
		categoryIndex: number,
		questionIndex: number
	) => {
		const questions = [...values.questions];
		questions[categoryIndex].Preguntas.splice(
			questionIndex,
			1
		);

		setField("questions", questions);
	};

	const updateQuestion = (
		categoryIndex: number,
		questionIndex: number
	) => {
		const questions = [...values.questions];
		questions[categoryIndex].Preguntas[questionIndex] =
			values.currentQuestion;
		setField("questions", questions);
		setSelectedQuestion(null);
		setField("currentQuestion", "");
	};
	return (
		<>
			{/* main dialog */}
			<Dialog {...props}>
				<DialogTitle
					bigText={
						selected
							? "Editar lista de chequeo (Empresa)"
							: "Nueva lista de chequeo (Empresa)"
					}
					smallText={type}
					onClose={() =>
						props.onClose?.({}, "backdropClick")
					}
				/>

				<DialogContent
					style={{
						paddingTop: 20,
						display: "flex",
						gap: 20,
						flexDirection: "column",
					}}
				>
					<TextField
						variant="outlined"
						fullWidth
						value={values.name}
						id="name"
						onChange={handleChange}
						label="Título de la lista de chequeo"
						error={touched.name && !!errors.name}
						helperText={touched.name && errors.name}
					/>

					<Paper
						elevation={3}
						style={{
							width: "100%",
							padding: 5,
						}}
					>
						{values.questions.map(
							({ Categoria, Preguntas }, categoryIndex) => (
								<Accordion
									key={`category-${categoryIndex}`}
									expanded={expanded === categoryIndex}
								>
									<AccordionSummary
										onClick={() =>
											setExpanded(
												expanded === categoryIndex
													? -1
													: categoryIndex
											)
										}
										expandIcon={<ExpandMore />}
									>
										{selectedCategory === categoryIndex ? (
											<Box width="100%">
												<TextField
													value={values.currentCategory}
													id="currentCategory"
													onChange={handleChange}
													label="Nombre"
													error={
														touched.currentCategory &&
														!!errors.currentCategory
													}
													helperText={
														touched.currentCategory &&
														errors.currentCategory
													}
													fullWidth
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															e.preventDefault();
															updateCategory(categoryIndex);
														}
													}}
													InputProps={{
														endAdornment: (
															<>
																<IconButton
																	size="small"
																	onClick={() =>
																		updateCategory(
																			categoryIndex
																		)
																	}
																>
																	<Done />
																</IconButton>
																<IconButton
																	size="small"
																	onClick={() =>
																		setSelectedCategory(
																			null
																		)
																	}
																>
																	<Cancel />
																</IconButton>
															</>
														),
													}}
												/>
											</Box>
										) : (
											<Box
												display="flex"
												flexDirection="row"
												style={{
													gap: 10,
													width: "100%",
													alignItems: "center",
												}}
												padding={1}
											>
												<Typography variant="h6">
													{Categoria} ({Preguntas.length})
												</Typography>

												<Box flexGrow={1} />

												{Categoria !==
													"Preguntas sin subtítulo" && (
													<>
														<IconButton
															color="primary"
															size="small"
															onClick={() => {
																setSelectedCategory(
																	categoryIndex
																);
																setField(
																	"currentCategory",
																	Categoria
																);
															}}
														>
															<Edit />
														</IconButton>

														<IconButton
															size="small"
															onClick={() =>
																deleteCategory(
																	categoryIndex
																)
															}
														>
															<Trash2
																color="red"
																size={24}
															/>
														</IconButton>
													</>
												)}
											</Box>
										)}
									</AccordionSummary>

									<AccordionDetails>
										<List style={{ width: "100%" }}>
											{Preguntas.map(
												(question, questionIndex) => (
													<ListItem
														divider
														key={`question-${questionIndex}`}
													>
														{selectedQuestion?.[0] ===
															categoryIndex &&
														selectedQuestion?.[1] ===
															questionIndex ? (
															<TextField
																value={
																	values.currentQuestion
																}
																id="currentQuestion"
																onChange={handleChange}
																label="Actualizar pregunta"
																error={
																	touched.currentQuestion &&
																	!!errors.currentQuestion
																}
																helperText={
																	touched.currentQuestion &&
																	errors.currentQuestion
																}
																fullWidth
																onKeyDown={(e) => {
																	if (e.key === "Enter") {
																		e.preventDefault();
																		updateQuestion(
																			categoryIndex,
																			questionIndex
																		);
																	}
																}}
																InputProps={{
																	endAdornment: (
																		<>
																			<IconButton
																				size="small"
																				onClick={() =>
																					updateQuestion(
																						categoryIndex,
																						questionIndex
																					)
																				}
																			>
																				<Done />
																			</IconButton>
																			<IconButton
																				size="small"
																				onClick={() => {
																					setSelectedQuestion(
																						null
																					);
																					setField(
																						"currentQuestion",
																						""
																					);
																				}}
																			>
																				<Cancel />
																			</IconButton>
																		</>
																	),
																}}
															/>
														) : (
															<ListItemText
																primary={`${
																	questionIndex + 1
																}. ${question}`}
															/>
														)}

														<ListItemIcon
															color="error"
															onClick={() => {
																setSelectedQuestion([
																	categoryIndex,
																	questionIndex,
																]);
																setField(
																	"currentQuestion",
																	question
																);
															}}
														>
															<IconButton
																size="small"
																color="primary"
															>
																<Edit />
															</IconButton>
														</ListItemIcon>

														<ListItemIcon
															color="error"
															onClick={() =>
																deleteQuestion(
																	categoryIndex,
																	questionIndex
																)
															}
														>
															<IconButton size="small">
																<Trash2 color="red" />
															</IconButton>
														</ListItemIcon>
													</ListItem>
												)
											)}

											{selectedQuestion === null && (
												<ListItem>
													<TextField
														value={values.currentQuestion}
														id="currentQuestion"
														onChange={handleChange}
														label="Agregar pregunta..."
														variant="outlined"
														error={!!errors.currentQuestion}
														helperText={
															errors.currentQuestion
														}
														fullWidth
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																e.preventDefault();
																addQuestion(categoryIndex);
															}
														}}
														InputProps={{
															endAdornment: (
																<Button
																	variant="contained"
																	size="small"
																	onClick={() =>
																		addQuestion(
																			categoryIndex
																		)
																	}
																>
																	Agregar
																</Button>
															),
														}}
													/>
												</ListItem>
											)}
										</List>
									</AccordionDetails>
								</Accordion>
							)
						)}

						{selectedCategory === null && (
							<TextField
								style={{ marginTop: 10 }}
								fullWidth
								variant="outlined"
								value={values.currentCategory}
								id="currentCategory"
								onChange={handleChange}
								label="Agregar subtítulo"
								error={!!errors.currentCategory}
								helperText={errors.currentCategory}
								onKeyDown={(e) => {
									const key = e.key;
									if (key === "Enter") {
										e.preventDefault();
										addCategory();
									}
								}}
								InputProps={{
									endAdornment: (
										<Button
											variant="contained"
											onClick={addCategory}
										>
											Agregar
										</Button>
									),
								}}
							/>
						)}
					</Paper>
				</DialogContent>

				<DialogActions>
					<Button
						disabled={isSubmitting}
						onClick={() => {
							props.onClose?.({}, "backdropClick");
							resetForm();
						}}
						color="primary"
					>
						Cancelar
					</Button>

					<Button
						disabled={isSubmitting}
						onClick={() => handleSubmit()}
						color="primary"
						variant="contained"
					>
						{isSubmitting ? (
							<CircularProgress size={24} />
						) : (
							"Guardar"
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
