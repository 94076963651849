import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";
import { DialogAction } from "../../models/dialog-actions";
import { SnackState } from "../../models/snack-state";
import { openSnack } from "./uiActions";
import { cleanString } from "../../utils/utils";

export const getSectors = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Sectores")
        .orderBy("Nombre")
        .limit(limit)
        .get();

      dispatch(setSectors(mapSectors(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};
// export const getSectors = (limit: number = 10): AppThunk => {
//   return async (dispatch) => {
//     dispatch(isLoading(true));
//     try {
//       const response = await firestore
//         .collection("Sectores")
//         .orderBy("Nombre")
//         .limit(limit)
//         .get();

//       dispatch(setSectors(mapSectors(response)));
//       dispatch(setLastDoc(response.docs[response.docs.length - 1]));
//     } catch (error) {
//       dispatch(setError(error));
//       
//     } finally {
//       dispatch(isLoading(false));
//     }
//   };
// };

export const addSector = (sector: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setSectorDialogLoading(true));
    try {
      const response = await firestore.collection("Sectores").get();

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (cleanString(post.data().Nombre) === cleanString(sector.Nombre))
          return true;
      });

      if (__FOUND) {
        dispatch(openSnack("Sector ya existe", SnackState.ERROR));
      } else {
        await firestore.collection("Sectores").doc().set(sector);

        dispatch(getSectors());
        dispatch(setSelectedSector());
        dispatch(setDialogAction(DialogAction.Initial));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setSectorDialogLoading(false));
      dispatch(setSectorDialogOpen(false));
    }
  };
};

export const updateSector = (sector: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setSectorDialogLoading(true));
    try {
      const response = await firestore.collection("Sectores").get();

      let sectorId = "0";

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (cleanString(post.data().Nombre) === cleanString(sector.Nombre)) {
          sectorId = post.id;
          return true;
        }
      });

      if (__FOUND && sectorId !== sector.id) {
        dispatch(openSnack("Sector ya existe", SnackState.ERROR));
      } else {
        const data = { ...sector };
        delete data.id;
        await firestore.collection("Sectores").doc(sector.id).update(data);

        dispatch(updateSectorLocal(sector));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setSectorDialogLoading(false));
      dispatch(setSectorDialogOpen(false));
    }
  };
};

export const removeSector = (sector: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setSectorDialogLoading(true));
    try {
      await firestore.collection("Sectores").doc(sector.id).delete();

      dispatch(removeSectorLocal(sector));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setSectorDialogLoading(false));
      dispatch(setSectorDialogOpen(false));
    }
  };
};

export const mapSectors = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  let sectors: any[] = [];
  response.docs.forEach((doc) => {
    let data = doc.data();
    data.id = doc.id;
    sectors.push(data);
  });
  return sectors;
};

export const addMoreSectors = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    const lastDoc = getState().sectorsReducer.lastDoc || "";

    try {
      const response = await firestore
        .collection("Sectores")
        .orderBy("Nombre")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      dispatch(addSectors(mapSectors(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getTotalDocs = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await firestore.collection("Sectores").get();
      dispatch(setTotalDocs(response.size));
    } catch (error: any) {
      dispatch(setError(error));
      
    }
  };
};

export const openEditSectorDialog = (sector: any): AppThunk => {
  return (dispatch) => {
    dispatch(setDialogAction(DialogAction.Edit));
    dispatch(setSelectedSector(sector));
    dispatch(setSectorDialogOpen(true));
  };
};

export const openNewSectorDialog = (): AppThunk => {
  return (dispatch) => {
    dispatch(setDialogAction(DialogAction.New));
    dispatch(setSectorDialogOpen(true));
  };
};

export const setSectorDialogOpen = (isOpen: boolean): Action => ({
  type: types.SECTORS_IS_DIALOG_OPEN,
  payload: isOpen,
});

const setSectorDialogLoading = (isLoading: boolean): Action => ({
  type: types.SECTORS_IS_DIALOG_LOADING,
  payload: isLoading,
});

export const setDialogAction = (dialogAction: DialogAction): Action => ({
  type: types.SECTORS_CHANGE_DIALOG_ACTION,
  payload: dialogAction,
});

export const setSelectedSector = (setSelected?: any): Action => ({
  type: types.SECTORS_SET_SELECTED_SECTOR,
  payload: setSelected,
});

const setSectors = (sectors: any[]): Action => ({
  type: types.SECTORS_GET_DOCS,
  payload: sectors,
});

const setLastDoc = (doc: any): Action => ({
  type: types.SECTORS_SET_LAST_DOC,
  payload: doc,
});

const addSectors = (sectors: any[]): Action => ({
  type: types.SECTORS_ADD_DOCS,
  payload: sectors,
});

const updateSectorLocal = (sector: any): Action => ({
  type: types.SECTORS_UPDATE_DOC,
  payload: sector,
});

const removeSectorLocal = (sector: any): Action => ({
  type: types.SECTORS_REMOVE_DOC,
  payload: sector,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.SECTORS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.SECTORS_FAILURE,
  payload: error,
});

const setTotalDocs = (total: number): Action => ({
  type: types.SECTORS_SET_TOTAL_DOCS,
  payload: total,
});
