import { useEffect, useRef } from "react";
export const useOutsideAlerter = (
	onClickOutside: (ev: any) => void
) => {
	const ref = useRef<HTMLElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (!event.target) return;
		if (
			ref.current &&
			!ref.current.contains(event.target as any)
		)
			onClickOutside(event);
	};

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */

		// Bind the event listener
		document.addEventListener(
			"mousedown",
			handleClickOutside
		);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener(
				"mousedown",
				handleClickOutside
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);

	return ref;
};
