import { FC, useState } from "react";

import { SheetEntryDialogOT } from "../Dialogs/SheetEntry";
import {
	SheetAnswerMap,
	SheetAnswerTuple,
} from "src/models/OT/Sheet";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	darken,
} from "@material-ui/core";
import { MatrixResponseCell } from "./Cell";
import { useResponsiveQuery } from "src/utils/hooks/useResponsiveQuery";

type Props = {
	questions: SheetAnswerMap[string];
	reviewerNames: string[];
};

export const ResponseMatrixOT: FC<Props> = ({
	questions,
	reviewerNames,
}) => {
	const [dialog, setDialog] = useState(0);
	const [selected, setSelected] =
		useState<SheetAnswerTuple | null>(null);

	const isMobile = useResponsiveQuery("down", "xs");

	console.log(questions)

	const handleItemClick = (
		answerEntry: typeof questions[number]["answersByReviewer"][number]
	) => {
		setSelected(answerEntry);
		setDialog(1);
	};

	const getReviewer = (index: number) => {
		return (
			questions?.[0]?.answersByReviewer?.[0]?.Respuestas?.[
				index
			]?.NombreRevisor ??
			reviewerNames?.[index] ??
			`Revisor ${index + 1}`
		);
	};

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Preguntas</TableCell>

						{Array(
							questions?.[0]?.answersByReviewer?.length ?? 0
						)
							.fill(null)
							.map((_, i) => (
								<TableCell
									align="center"
									key={`response-${i}`}
								>
									{getReviewer(i)}
								</TableCell>
							))}
					</TableRow>
				</TableHead>

				<TableBody>
					{questions?.map(
						(
							{ question, answersByReviewer },
							questionIndex
						) => (
							<TableRow
								style={{
									backgroundColor:
										questionIndex % 2 !== 0
											? "white"
											: darken("#FFF", 0.05),
								}}
								key={`${question}-${questionIndex}`}
							>
								<TableCell>
									{questionIndex + 1}. {question}
								</TableCell>

								{answersByReviewer?.map((answer, i) => (
									<MatrixResponseCell
										key={`${question}-${questionIndex}-${i}`}
										answer={answer}
										reviewerName={reviewerNames[i]}
										onClick={handleItemClick}
									/>
								))}
							</TableRow>
						)
					)}
				</TableBody>
			</Table>

			{selected && (
				<SheetEntryDialogOT
					fullScreen={isMobile}
					open={dialog === 1}
					selected={selected}
					onClose={() => setDialog(0)}
				/>
			)}
		</>
	);
};
