import { FC, Children } from "react";
import { useMemo } from "react";
import {
	Breadcrumbs as BREADCRUMBS,
	BreadcrumbsProps,
} from "@material-ui/core";

export const Breadcrumbs: FC<BreadcrumbsProps> = (
	props
) => {
	const { children, style, ...breadcrumbsProps } = props;

	const filteredChildren = useMemo(
		() => Children.toArray(children).filter(Boolean),
		[children]
	);

	return (
		<BREADCRUMBS
			{...breadcrumbsProps}
			style={{
				marginBottom: 10,
				padding: 5,
				...style,
			}}
		>
			{filteredChildren}
		</BREADCRUMBS>
	);
};
