import {
	Button,
	Paper,
	Tabs,
	Tab,
	IconButton,
	Tooltip,
	Toolbar,
} from "@material-ui/core";
import { useMemo, useState } from "react";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { Table } from "src/utils/components/Table";
import { BusinessNeoChecklistDialog } from "../../Dialogs/NeoChecklist";
import { Edit, Trash2 } from "react-feather";
import { colorsCheckPro } from "src/theme/variants";
import { DeleteBusinessNeoChecklistDialog } from "../../Dialogs/DeleteBusinessNeoChecklist";
import { ChecklistOT } from "src/models/OT/Checklist";

export const BusinessNeoChecklistTab = () => {
	const { selectedBusiness } = useBusiness();

	const [dialog, setDialog] = useState(0);

	const [selected, setSelected] =
		useState<ChecklistOT | null>(null);

	const [selectedType, setSelectedType] =
		useState<ChecklistOT["Tipo"]>("Obra Gruesa");

	const dataToShow = useMemo(
		() =>
			selectedBusiness?.ListasDeChequeo?.filter(
				(c) => c.Tipo === selectedType
			) ?? [],
		[selectedBusiness?.ListasDeChequeo, selectedType]
	);

	return (
		<>
			<Paper elevation={3}>
				<Toolbar style={{ gap: 10 }}>
					<Tabs
						style={{ flexGrow: 1 }}
						variant="fullWidth"
						value={selectedType}
						onChange={(_, v) => setSelectedType(v as any)}
					>
						<Tab label="Obra Gruesa" value="Obra Gruesa" />
						<Tab
							label="Terminaciones"
							value="Terminaciones"
						/>
					</Tabs>

					<Button
						color="primary"
						variant="contained"
						onClick={() => setDialog(1)}
					>
						Crear
					</Button>
				</Toolbar>

				<Table
					stripped
					elementsProps={{
						TableCellProps: {
							style: { padding: 5 },
						},
					}}
					list={dataToShow}
					columns={[
						{
							header: "Lista de chequeo",
							Cell: ({ value }) => <>{value.Nombre}</>,
						},
						{
							align: "right",
							Cell: ({ value }) => (
								<>
									<Tooltip title="Editar">
										<IconButton
											size="small"
											color="primary"
											onClick={() => {
												setSelected(value);
												setDialog(1);
											}}
										>
											<Edit
												size={20}
												color={colorsCheckPro.primary}
											/>
										</IconButton>
									</Tooltip>

									<Tooltip title="Eliminar">
										<IconButton
											size="small"
											color="primary"
											onClick={() => {
												setSelected(value);
												setDialog(2);
											}}
										>
											<Trash2 size={20} color="red" />
										</IconButton>
									</Tooltip>
								</>
							),
						},
					]}
				/>
			</Paper>

			<BusinessNeoChecklistDialog
				type={selectedType}
				open={dialog === 1}
				selected={selected}
				onClose={() => {
					setDialog(0);
					setSelected(null);
				}}
				fullScreen
			/>

			<DeleteBusinessNeoChecklistDialog
				open={dialog === 2}
				selected={selected}
				onClose={() => {
					setDialog(0);
					setSelected(null);
				}}
			/>
		</>
	);
};
