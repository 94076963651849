import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { signIn } from "../../redux/actions/authActions";
import { Alert as Alert2 } from "@material-ui/lab";
import { SnackState } from "../../models/snack-state";
import { closeSnack } from "../../redux/actions/uiActions";

import {
	Button,
	Checkbox,
	FormControlLabel,
	Paper,
	TextField as MuiTextField,
	Typography,
	Snackbar,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
	padding: ${(props) => props.theme.spacing(6)}px;
	${(props) => props.theme.breakpoints.up("md")} {
		padding: ${(props) => props.theme.spacing(10)}px;
	}
`;

// const BigAvatar = styled(Avatar)`
//   width: 92px;
//   height: 92px;
//   text-align: center;
//   margin: 0 auto ${(props) => props.theme.spacing(5)}px;
// `;

function SignIn() {
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector(
		// @ts-ignore
		(state) => state.authReducer
	);
	const {
		// @ts-ignore
		snack: { snackState, open, text },
		// @ts-ignore
	} = useSelector((state) => state.uiReducer);
	useEffect(() => {
		if (user) {
			if (user?.tipoUsuario === "Administrador") {
				history.replace("/administrador/empresas");
			} else {
				history.replace("/administrador/empresa/detalles");
			}
			// const lastPath = localStorage.getItem("lastPath") || "";
			// history.replace("/");
		}
		// @ts-ignore
	}, [user]);
	// @ts-ignore
	const saveEmail = (email) => {
		localStorage.setItem("userEmail", email);
	};

	const removeEmail = () => {
		localStorage.removeItem("userEmail");
	};

	const formikRef = React.useRef(null);
	const [checked, setChecked] = React.useState(false);
	// @ts-ignore
	useEffect(() => {
		const storageEmail = localStorage.getItem("userEmail");
		// @ts-ignore
		formikRef?.current?.setFieldValue(
			"email",
			storageEmail
		);

		setChecked(Boolean(storageEmail));
	}, []);

	return (
		<Wrapper>
			<Helmet title="Incio de Sesión" />

			<Typography
				component="h1"
				variant="h4"
				align="center"
				gutterBottom
			>
				Check Pro Administrador
			</Typography>
			<Formik
				// @ts-ignore
				innerRef={(p) => (formikRef.current = p)}
				initialValues={{
					email: "", //test123@gmail.com
					password: "", //Test-1234
					submit: false,
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.email("Debe ser un email válido")
						.max(255)
						.required("Email es requerido"),
					password: Yup.string()
						.max(255)
						.required("Contraseña es requerida"),
				})}
				onSubmit={async (
					values,
					{ setErrors, setStatus, setSubmitting }
				) => {
					if (checked) {
						saveEmail(values.email);
					}
					try {
						await dispatch(
							signIn({
								email: values.email,
								password: values.password,
							})
						);
						// history.push("/administrador/empresas");
					} catch (error) {
						const message =
							// @ts-ignore
							error.message || "Something went wrong";

						setStatus({ success: false });
						setErrors({ submit: message });
						setSubmitting(false);
					}
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					touched,
					values,
				}) => (
					<form noValidate onSubmit={handleSubmit}>
						{errors.submit && (
							<Alert mt={2} mb={1} severity="warning">
								{errors.submit}
							</Alert>
						)}
						<TextField
							type="email"
							name="email"
							label="Dirección de Email"
							value={values.email}
							error={Boolean(touched.email && errors.email)}
							fullWidth
							helperText={touched.email && errors.email}
							onBlur={handleBlur}
							// @ts-ignore
							onChange={handleChange}
							// @ts-ignore
							my={2}
						/>
						<TextField
							type="password"
							name="password"
							label="Contraseña"
							value={values.password}
							error={Boolean(
								touched.password && errors.password
							)}
							fullWidth
							helperText={
								touched.password && errors.password
							}
							onBlur={handleBlur}
							onChange={handleChange}
							// @ts-ignore
							my={2}
						/>
						{/* TODO: dejar funcionando el recordarme */}
						<FormControlLabel
							control={
								<Checkbox
									value="remember"
									checked={checked}
									onChange={(event) => {
										!checked
											? saveEmail(values.email)
											: removeEmail();
										setChecked(!checked);
									}}
									color="primary"
								/>
							}
							label="Recordarme"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={isSubmitting}
						>
							Iniciar Sesión
						</Button>
						<Button
							component={Link}
							to="/auth/reset-password"
							fullWidth
							color="primary"
						>
							Recuperar contraseña
						</Button>
					</form>
				)}
			</Formik>
			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={(event, reason) => {
					if (reason === "clickaway") {
						return;
					}
					dispatch(closeSnack());
				}}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Alert2
					elevation={6}
					variant="filled"
					severity={"error"}
				>
					{text}
				</Alert2>
			</Snackbar>
		</Wrapper>
	);
}

export default SignIn;
