import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";
import { DialogAction } from "../../models/dialog-actions";
import { openSnack } from "./uiActions";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";

export const getSubContracts = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Subcontratos")
        .orderBy("Nombre")
        .limit(limit)
        .get();

      dispatch(setSubContracts(mapSubContracts(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getSubContractsByBusiness = (
  business: any,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Subcontratos")
        .orderBy("Nombre")
        .limit(limit)
        .get();

      dispatch(setSubContracts(mapSubContracts(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getSubContract = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch(isSubContractTypeErrorsLoading(true));
    try {
      const response = await firestore.collection("Subcontratos").doc(id).get();

      const subContract = {
        ...response.data(),
        id: response.id,
        ErroresTipo: response
          ?.data()
          ?.ErroresTipo?.sort((a: any, b: any) =>
            a.Nombre < b.Nombre ? -1 : a.Nombre > b.Nombre ? 1 : 0
          ),
      };

      dispatch(setSubContractSelected(subContract));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isSubContractTypeErrorsLoading(false));
    }
  };
};

export const addSubContract = (subContract: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setSubContractDialogLoading(true));
    try {
      const response = await firestore.collection("Subcontratos").get();

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (
          cleanString(post.data().Nombre) === cleanString(subContract.Nombre)
        ) {
          return true;
        }
      });

      if (__FOUND) {
        dispatch(openSnack("Subcontrato ya existe", SnackState.ERROR));
      } else {
        await firestore.collection("Subcontratos").doc().set(subContract);

        dispatch(getSubContracts());
        dispatch(setSelectedSubContract());
        dispatch(setDialogAction(DialogAction.Initial));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setSubContractDialogLoading(false));
      dispatch(setSubContractDialogOpen(false));
    }
  };
};

export const updateSubContract = (subContract: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setSubContractDialogLoading(true));
    try {
      const response = await firestore.collection("Subcontratos").get();

      let subcontractId = "0";

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (
          cleanString(post.data().Nombre) === cleanString(subContract.Nombre)
        ) {
          subcontractId = post.id;
          return true;
        }
      });

      if (__FOUND && subcontractId !== subContract.id) {
        dispatch(openSnack("Subcontrato ya existe", SnackState.ERROR));
      } else {
        const data = { ...subContract };
        delete data.id;
        await firestore
          .collection("Subcontratos")
          .doc(subContract.id)
          .update(data);

        dispatch(updateSubContractLocal(subContract));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setSubContractDialogLoading(false));
      dispatch(setSubContractDialogOpen(false));
    }
  };
};

export const removeSubContract = (subContract: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setSubContractDialogLoading(true));
    try {
      await firestore.collection("Subcontratos").doc(subContract.id).delete();

      dispatch(removeSubContractLocal(subContract));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setSubContractDialogOpen(false));
      dispatch(setSubContractDialogLoading(false));
    }
  };
};

export const mapSubContracts = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  let subContracts: any[] = [];
  response.docs.forEach((doc) => {
    let data = doc.data();
    data.id = doc.id;
    if (data.ErroresTipo) {
      data.ErroresTipo = data.ErroresTipo.sort((a: any, b: any) =>
        a.Nombre < b.Nombre ? -1 : a.Nombre > b.Nombre ? 1 : 0
      );
    }
    subContracts.push(data);
  });
  return subContracts;
};

export const addMoreSubContracts = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    const lastDoc = getState().subContractsReducer.lastDoc || "";

    try {
      const response = await firestore
        .collection("Subcontratos")
        .orderBy("Nombre")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      dispatch(addSubContracts(mapSubContracts(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getTotalDocs = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await firestore.collection("Subcontratos").get();
      dispatch(setTotalDocs(response.size));
    } catch (error: any) {
      dispatch(setError(error));
      
    }
  };
};

export const openEditSubContractDialog = (subContract: any): AppThunk => {
  return (dispatch) => {
    dispatch(setDialogAction(DialogAction.Edit));
    dispatch(setSelectedSubContract(subContract));
    dispatch(setSubContractDialogOpen(true));
  };
};

export const openNewSubContractDialog = (): AppThunk => {
  return (dispatch) => {
    dispatch(setDialogAction(DialogAction.New));
    dispatch(setSubContractDialogOpen(true));
  };
};

export const setSubContractDialogOpen = (isOpen: boolean): Action => ({
  type: types.SUBCONTRACT_IS_DIALOG_OPEN,
  payload: isOpen,
});

const setSubContractDialogLoading = (isLoading: boolean): Action => ({
  type: types.SUBCONTRACT_IS_DIALOG_LOADING,
  payload: isLoading,
});

export const setDialogAction = (dialogAction: DialogAction): Action => ({
  type: types.SUBCONTRACT_CHANGE_DIALOG_ACTION,
  payload: dialogAction,
});

const updateSubContractLocal = (subContract: any): Action => ({
  type: types.SUBCONTRACT_UPDATE_DOC,
  payload: subContract,
});

const removeSubContractLocal = (subContract: any): Action => ({
  type: types.SUBCONTRACT_REMOVE_DOC,
  payload: subContract,
});

export const setSelectedSubContract = (setSelected?: any): Action => ({
  type: types.SUBCONTRACT_SET_SELECTED,
  payload: setSelected,
});

const setSubContracts = (subContracts: any[]): Action => ({
  type: types.SUBCONTRACT_GET_DOCS,
  payload: subContracts,
});

const setLastDoc = (doc: any): Action => ({
  type: types.SUBCONTRACT_SET_LAST_DOC,
  payload: doc,
});

const addSubContracts = (subContracts: any[]): Action => ({
  type: types.SUBCONTRACT_ADD_DOCS,
  payload: subContracts,
});

const isLoading = (isLoading: boolean): Action => ({
  type: types.SUBCONTRACT_LOADING,
  payload: isLoading,
});

const setError = (error: string): Action => ({
  type: types.SUBCONTRACT_FAILURE,
  payload: error,
});

const setTotalDocs = (total: number): Action => ({
  type: types.SUBCONTRACT_SET_TOTAL_DOCS,
  payload: total,
});

//TypeErrors actions

export const addTypeError = (typeError: any): AppThunk => {
  return async (dispatch, getState) => {
    // dispatch(updateIsLoadingSubContract(true));
    const { selectedSubContract, subContracts } =
      getState().subContractsReducer;

    let ErroresTipo = selectedSubContract?.ErroresTipo
      ? [...selectedSubContract?.ErroresTipo]
      : [];
    try {
      const __FOUND = ErroresTipo.find(function (post, index) {
        if (cleanString(post.Nombre) === cleanString(typeError.Nombre))
          return true;
      });
      if (__FOUND === undefined) {
        await firestore
          .collection("Subcontratos")
          .doc(selectedSubContract?.id)
          .update({
            ErroresTipo: firebase.firestore.FieldValue.arrayUnion(typeError),
          });
        ErroresTipo = [...ErroresTipo, typeError].sort((a: any, b: any) =>
          a.Nombre < b.Nombre ? -1 : a.Nombre > b.Nombre ? 1 : 0
        );

        const index = subContracts.findIndex(
          (x: any) => x.id === selectedSubContract.id
        );

        selectedSubContract.ErroresTipo = ErroresTipo;
        subContracts[index] = selectedSubContract;

        // dispatch(
        //   updateSelectedSubContract({
        //     ErroresTipo,
        //   })
        // );
        dispatch(openSnack("Error Tipo Agregado", SnackState.SUCCESS));
      } else {
        dispatch(openSnack("Error Tipo Existe", SnackState.ERROR));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(updateIsLoadingSubContract(false));
    }
  };
};

export const updateTypeError = (
  updateTypeError: any,
  oldTypeError: any
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(updateIsLoadingSubContract(true));
    const { selectedSubContract, subContracts } =
      getState().subContractsReducer;
    try {
      const __FOUND = selectedSubContract?.ErroresTipo.find(function (
        post: any,
        index: number
      ) {
        if (cleanString(post.Nombre) === cleanString(updateTypeError.Nombre))
          return true;
      });
      if (cleanString(oldTypeError.Nombre) !== updateTypeError.Nombre) {
        if (__FOUND !== undefined) {
          if (
            cleanString(__FOUND.Nombre) !== cleanString(oldTypeError.Nombre)
          ) {
            dispatch(openSnack("Error Tipo Existe", SnackState.ERROR));
          }
        } else {
          await firestore
            .collection("Subcontratos")
            .doc(selectedSubContract.id)
            .update({
              ErroresTipo:
                firebase.firestore.FieldValue.arrayRemove(oldTypeError),
            });

          await firestore
            .collection("Subcontratos")
            .doc(selectedSubContract.id)
            .update({
              ErroresTipo:
                firebase.firestore.FieldValue.arrayUnion(updateTypeError),
            });
          const index = subContracts.findIndex(
            (x: any) => x.id === selectedSubContract.id
          );
          const ErroresTipo = selectedSubContract.ErroresTipo.map((x: any) =>
            cleanString(x.Nombre) === cleanString(oldTypeError.Nombre)
              ? updateTypeError
              : x
          );
          selectedSubContract.ErroresTipo = ErroresTipo;
          subContracts[index] = selectedSubContract;
          dispatch(openSnack("Error Tipo Actualizado", SnackState.SUCCESS));
        }
      } else {
        await firestore
          .collection("Subcontratos")
          .doc(selectedSubContract.id)
          .update({
            ErroresTipo:
              firebase.firestore.FieldValue.arrayRemove(oldTypeError),
          });

        await firestore
          .collection("Subcontratos")
          .doc(selectedSubContract.id)
          .update({
            ErroresTipo:
              firebase.firestore.FieldValue.arrayUnion(updateTypeError),
          });
        const index = subContracts.findIndex(
          (x: any) => x.id === selectedSubContract.id
        );
        const ErroresTipo = selectedSubContract.ErroresTipo.map((x: any) =>
          x.Nombre === oldTypeError.Nombre ? updateTypeError : x
        );
        selectedSubContract.ErroresTipo = ErroresTipo;
        subContracts[index] = selectedSubContract;
        dispatch(openSnack("Error Tipo Actualizado", SnackState.SUCCESS));
      }

      // dispatch(
      //   updateSelectedSubContract({

      //   })
      // );
      // dispatch(openSnack("Tipo Error Actualizado", SnackState.SUCCESS));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(updateIsLoadingSubContract(false));
    }
  };
};

export const removeTypeError = (typeError: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(updateIsLoadingSubContract(true));
    const { selectedSubContract, subContracts } =
      getState().subContractsReducer;
    try {
      await firestore
        .collection("Subcontratos")
        .doc(selectedSubContract.id)
        .update({
          ErroresTipo: firebase.firestore.FieldValue.arrayRemove(typeError),
        });

      const index = subContracts.findIndex(
        (x: any) => x.id === selectedSubContract.id
      );
      const ErroresTipo = selectedSubContract.ErroresTipo.filter(
        (x: any) => x.Nombre !== typeError.Nombre
      );
      selectedSubContract.ErroresTipo = ErroresTipo;
      subContracts[index] = selectedSubContract;
      // dispatch(
      //   updateSelectedSubContract({
      //     ErroresTipo: selectedSubContract.ErroresTipo.filter(
      //       (x: any) => x.Nombre !== typeError.Nombre
      //     ),
      //   })
      // );
      dispatch(openSnack("Error Tipo Eliminado", SnackState.SUCCESS));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(updateIsLoadingSubContract(false));
    }
  };
};

export const mapTypeErrors = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  let typeErrors: any[] = [];
  response.docs.forEach((doc) => {
    let data = doc.data();
    typeErrors.push(data);
  });
  return typeErrors;
};

const isSubContractTypeErrorsLoading = (isLoading: boolean): Action => ({
  type: types.SUBCONTRACT_TYPE_ERROR_LOADING,
  payload: isLoading,
});

const setSubContractSelected = (subContract: any): Action => ({
  type: types.SUBCONTRACT_SET_SELECTED,
  payload: subContract,
});

const updateSelectedSubContract = (subContract: any): Action => ({
  type: types.SUBCONTRACT_UPDATE_SELECTED,
  payload: subContract,
});

const updateIsLoadingSubContract = (isLoading: boolean): Action => ({
  type: types.SUBCONTRACT_UPDATE_LOADING,
  payload: isLoading,
});
