import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	Grid,
	IconButton,
	Link,
	StyledProps,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import { red } from "@material-ui/core/colors";
import {
	createStyles,
	makeStyles,
	Theme,
} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import { Alert, AlertProps, Color } from "@material-ui/lab";
import clsx from "clsx";
import { default as React, useState } from "react";
import { useSelector } from "react-redux";
import { IListsState } from "../redux/reducers/listsReducer";
import { RootState } from "../redux/store/reducer";
import BorderColorIcon from "@material-ui/icons/BorderColor";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			maxWidth: 345,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
		},
	})
);

interface ICardsProps {
	image: string;
	comentario: string;
	Recinto: string;
	subcontrato: string;
	errortipo: string;
	imagenNumero: number;
	estado: string;
	fechacreacion?: any;
	fechaResuelta?: any;
	onAccept: (value: any) => void;
}

export const AnswersCard: React.FC<ICardsProps> = ({
	image,
	comentario,
	estado = "",
	Recinto = "",
	imagenNumero = 0,
	subcontrato = "",
	errortipo = "",
	fechacreacion,
	fechaResuelta,
	onAccept,
}) => {
	const [showimage, setShowimage] = useState(false);
	const [canEdit, setCanEdit] = useState(false);
	const [inputvalue, setInputvalue] = useState(comentario);
	const classes = useStyles();

	const handleClick = () => {
		setShowimage(!showimage);
	};
	const handleClickEdit = () => {
		setCanEdit(true);
	};

	const onSubmit = () => {
		onAccept(inputvalue);
		setCanEdit(false);
		setInputvalue("");
	};

	const returnColors = () => {
		let alertColors: React.CSSProperties;
		if (!estado) {
			alertColors = {
				backgroundColor: "red",
				color: "white",
			};
		} else if (estado === "Por Resolver") {
			alertColors = {
				backgroundColor: "red",
				color: "white",
			};
		} else if (estado === "Resuelto") {
			alertColors = {
				backgroundColor: "#00B050",
				color: "white",
			};
		} else {
			alertColors = {
				backgroundColor: "#92D050",
				color: "black",
			};
		}
		return alertColors;
	};

	return (
		<Card className={classes.root}>
			<Grid container>
				<Grid item xs={12} sm={11}>
					<Alert
						icon={false}
						style={{
							marginLeft: 14,
							marginTop: 6,
							width: "600",
							...returnColors(),
						}}
					>
						<b>
							{estado
								? estado === "Criterios Propietario"
									? estado.replace("s", "")
									: estado
								: "Sin selección."}{" "}
						</b>
					</Alert>
					<CardHeader
						title={`${Recinto}-${subcontrato}-${errortipo}`}
						subheader={`Imagen # ${imagenNumero}`}
					/>
					<p
						style={{
							marginLeft: 8,
							paddingLeft: 8,
							fontSize: 12,
							marginTop: 0,
						}}
					>
						Encontrada: {fechacreacion}
					</p>
					<p
						style={{
							marginLeft: 8,
							paddingLeft: 8,
							fontSize: 12,
							marginTop: 4,
						}}
					>
						Resuelta: {fechaResuelta ? fechaResuelta : ""}
					</p>
				</Grid>
				<Grid item xs={12}>
					<Link onClick={handleClick}>
						<img
							style={{ maxHeight: 200, width: "100%" }}
							src={image}
							alt="Captura de la observación."
						/>
					</Link>
				</Grid>
				<CardContent>
					{canEdit ? (
						<Grid container>
							<Grid item xs={12}>
								<TextField
									value={inputvalue}
									onChange={(e) =>
										setInputvalue(e.target.value)
									}
									fullWidth
									id="outlined-multiline-static"
									multiline
									rows={3}
									variant="outlined"
								/>
							</Grid>
							<Grid
								item
								xs={12}
								direction="row-reverse"
								container
							>
								<Box style={{ marginTop: 4 }}>
									<Button
										style={{ marginRight: 4 }}
										variant="contained"
										size="small"
										onClick={() => setCanEdit(false)}
									>
										Cancelar
									</Button>
									<Button
										variant="contained"
										color="primary"
										size="small"
										onClick={onSubmit}
									>
										Guardar
									</Button>
								</Box>
							</Grid>
						</Grid>
					) : (
						<Grid item>
							<Typography
								style={{
									marginLeft: 15,
									fontWeight: 600,
									color: "#767474",
									display: "flex",
									justifyContent: "left",
								}}
								component="p"
							>
								{comentario}
								<Tooltip title="Editar Comentario">
									<IconButton
										onClick={handleClickEdit}
										color="inherit"
										size="small"
									>
										<BorderColorIcon
											style={{ fontSize: 16 }}
											color="primary"
										/>
									</IconButton>
								</Tooltip>
							</Typography>
						</Grid>
					)}
				</CardContent>

				<Dialog open={showimage} onClose={handleClick}>
					<Link onClick={handleClick}>
						<img
							style={{
								maxHeight: 600,
								maxWidth: 600,
								padding: 0,
								margin: 0,
								borderRadius: 4,
							}}
							src={image}
							alt="image"
						/>
					</Link>
				</Dialog>
			</Grid>
		</Card>
	);
};
