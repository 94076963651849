/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
	Button,
	Card,
	CardContent,
	Divider,
	Fade,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
	useHistory,
	useLocation,
	useParams,
} from "react-router-dom";
import { ChevronRight, Edit, Trash2 } from "react-feather";
import {
	Add as AddIcon,
	PieChart,
} from "@material-ui/icons";
import { useStyles } from "../../theme/useStyles";
import { useTable } from "../../hooks/useTable";
import { timesStampToDateFormatted } from "../../utils/utils";
import { getOneProject } from "../../redux/actions/projectsActions";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { DialogNewEdit } from "./components/DialogNewEdit";
import {
	addRevision,
	deleteRevision,
	getRevisions,
	setSelectedRevision,
	updateRevision,
} from "../../redux/actions/revisionsActions";
import { TableSkeleton } from "../../components/TableSkeleton";
import { NewConfirmDialog } from "../../components/NewConfirmDialog";
import {
	TABLE_LIMITS,
	TABLE_LIMIT_DEFAULT,
} from "../../constants";
import { getBusiness } from "../../redux/actions/businessActions";
import { DropdownMenu } from "../../components/Generic/DropdownMenu";
import { Opener } from "../../components/CheckPro/Opener";
import { Breadcrumbs } from "../../components/CheckPro/Breadcrumbs";
import { LinkEmpresas } from "../../components/CheckPro/Links/Empresas";
import { LinkProyectos } from "../../components/CheckPro/Links/Proyectos";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useDispatch, useSelector } from "src/redux/store";

interface Props {
	array: any[];
	isLoading: boolean;
	openEditDialog: (selected: any) => void;
	openConfirmDialog: (selected: any) => any;
	currentProject: any;
}

const ContentCard: React.FC<Props> = ({
	array,
	isLoading,
	currentProject,
	openEditDialog,
	openConfirmDialog,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const theme = useTheme();

	const {
		limit,
		handleLimitChange,
		handlePageChange,
		page,
	} = useTable({
		limit: TABLE_LIMIT_DEFAULT || 5,
	});

	const redirectToCharts = (data: any) =>
		history.push(
			window.location.pathname.includes(
				"checklist-proyectos"
			)
				? `/administrador/checklist-proyectos/estadisticas/${currentProject.id}/${data.id}`
				: `/administrador/estadisticas/${currentProject.id}/${data.id}`
		);

	return (
		<Card style={{ marginBottom: 6 }}>
			<CardContent>
				<TableContainer className={classes.tableContainer}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Nombre</TableCell>
								<TableCell>Listados</TableCell>
								{pathname.includes(
									"checklist-proyectos"
								) && (
									<TableCell>{"Observaciones"}</TableCell>
								)}
								<TableCell>Encargado</TableCell>
								<TableCell>Cargo</TableCell>
								<TableCell>Fecha de Creación</TableCell>
								<TableCell align="center">
									Acciones
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading ? (
								array
									.slice(page * limit, page * limit + limit)
									.map((data: any, i: any) => (
										<Fade key={i} in={true}>
											<TableRow
												hover
												className={classes.styledRow}
											>
												<TableCell>{data.Nombre}</TableCell>
												<TableCell>
													{typeof data.Carpetas === "string"
														? data?.Carpetas?.replace(
																"(",
																""
														  )?.replace(")", "")
														: data.Carpetas}
												</TableCell>
												{pathname.includes(
													"checklist-proyectos"
												) && (
													<TableCell>
														{(data?.Resueltas || "0") +
															" de " +
															(data?.TotalRespuestas ||
																"0")}
													</TableCell>
												)}
												<TableCell>
													{data.Responsable.NombreCompleto}
												</TableCell>
												<TableCell>
													{data.Responsable.Cargo}
												</TableCell>
												<TableCell>
													{timesStampToDateFormatted(
														data.FechaCreacion
													)}
												</TableCell>
												{/* Columna de opciones */}
												<TableCell
													style={{ whiteSpace: "nowrap" }}
													align="center"
												>
													<DropdownMenu
														Opener={Opener}
														options={[
															{
																label: "Editar",
																icon: (
																	<Edit
																		size={22}
																		color={
																			theme.palette.primary
																				.main
																		}
																	/>
																),
																onClick: () =>
																	openEditDialog(data),
															},
															{
																label: "Eliminar",
																icon: (
																	<Trash2
																		color="red"
																		size={22}
																	/>
																),
																onClick: () =>
																	openConfirmDialog(data),
															},
															{
																label: "Estadísticas",
																icon: (
																	<PieChart color="primary" />
																),
																onClick: () =>
																	redirectToCharts(data),
															},
														]}
													/>

													<Tooltip title="Listados">
														<IconButton
															size="small"
															color="primary"
															onClick={() => {
																dispatch(
																	setSelectedRevision(data)
																);
																history.push(
																	`revisiones/${data.id}/listados`
																);
															}}
														>
															<ChevronRight />
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										</Fade>
									))
							) : (
								<TableSkeleton colSpan={5} limit={limit} />
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={array.length}
					onChangePage={handlePageChange as any}
					onChangeRowsPerPage={handleLimitChange}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={TABLE_LIMITS}
				/>
			</CardContent>
		</Card>
	);
};

export const RevisionsBusiness = () => {
	const { pathname } = useLocation();
	const { projectId } = useParams<any>();
	const dispatch = useDispatch();

	const { selectedBusiness } = useBusiness();

	const empresaReference = useSelector(
		(state) => state.authReducer.user?.empresaReference
	);

	const {
		revisions: {
			isLoading: projectIsLoading,
			selectedProject,
			error,
		},
	} = useSelector((state) => state.projectsReducer);

	const {
		revisions,
		isLoading: revisionIsLoading,
		updateLoading,
	} = useSelector((state) => state.revisionsReducer);

	useEffect(() => {
		if (selectedProject === null) {
			dispatch(getOneProject(projectId));
		} else if (selectedProject) {
			dispatch(getRevisions(selectedProject));
		}
	}, [selectedProject, dispatch, projectId]);

	useEffect(() => {
		if (!selectedBusiness) {
			dispatch(getBusiness(empresaReference?.id));
		}
	}, [selectedBusiness]);

	const {
		open,
		openEditDialog,
		openNewDialog,
		closeDialog,
		onAccept,
		action,
		selected,
		setIsLoading: setIsLoadingNewEditDialog,
	} = useNewEditDialog<any>({
		onAccept: (values, action) => {
			if (action === "New") {
				dispatch(
					addRevision(
						values,
						pathname.includes("/checklist-proyectos/")
					)
				);
			} else if (action === "Edit") {
				dispatch(
					updateRevision(
						values,
						selected,
						selected.id,
						pathname.includes("/checklist-proyectos/")
					)
				);
			}
		},
	});

	const {
		open: openConfirmDialog,
		setIsLoading: setIsLoadingConfirmDialog,
		dialogConfirmHandleClose,
		dialogConfirmHandleConfirm,
		openDialog,
		selected: confirmDialogSelected,
	} = useConfirmDialog({
		onConfirm: (selected) => {
			dispatch(
				deleteRevision(
					selected.id,
					selectedProject!.id,
					pathname.includes("/checklist-proyectos/")
				)
			);
		},
	});

	useEffect(() => {
		setIsLoadingNewEditDialog(updateLoading);
		setIsLoadingConfirmDialog(updateLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLoading]);

	return (
		<React.Fragment>
			<Helmet title="Revisiones" />

			<Grid justify="space-between" container>
				<Grid item>
					{projectIsLoading && !selectedProject ? (
						<Skeleton variant="text" />
					) : (
						<Typography
							variant="h3"
							gutterBottom
							display="inline"
						>
							{`${
								selectedBusiness?.Nombre ?? "Empresa"
							} / ${
								selectedProject?.NombreProyecto ??
								"Proyecto"
							} `}
						</Typography>
					)}

					<Breadcrumbs>
						<LinkEmpresas />
						<LinkProyectos />
						<Typography>Revisiones</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={openNewDialog}
						>
							<AddIcon />
							Nueva Revisión
						</Button>
					</div>
				</Grid>
			</Grid>

			<Divider />

			<Grid container spacing={6} style={{ marginTop: 6 }}>
				<Grid item xs={12}>
					<ContentCard
						currentProject={selectedProject}
						array={revisions}
						isLoading={revisionIsLoading}
						openEditDialog={openEditDialog}
						openConfirmDialog={openDialog}
					/>
				</Grid>
			</Grid>

			{!projectIsLoading && (
				<React.Fragment>
					<DialogNewEdit
						open={open}
						onClose={closeDialog}
						onAccept={onAccept}
						isLoading={updateLoading}
						action={action}
						title="Revisión"
						selected={selected}
						project={selectedProject}
						error={error ?? undefined}
					/>
					<NewConfirmDialog
						open={openConfirmDialog}
						title={`¿Desea borrar la revisión ${confirmDialogSelected?.Nombre}?`}
						onClose={dialogConfirmHandleClose}
						isLoading={updateLoading}
						onConfirm={dialogConfirmHandleConfirm}
						onConfirmText="Borrar"
					/>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
