import React, { useEffect } from "react";

import { Helmet } from "react-helmet-async";

import {
	Breadcrumbs,
	Button,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import {
	addProject,
	deleteProject,
	getRevisions,
	getProjectUsers,
	setSelectedProject,
} from "../../redux/actions/projectsActions";
import { RootState } from "../../redux/store/reducer";
import { IProjectsState } from "../../redux/reducers/projectsReducer";
import { useHistory } from "react-router-dom";
import { useNewEditDialog } from "../../hooks/useNewEditDialog";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { RevisionesTable } from "../../components/Revisiones/Table";
import { DialogNewEdit } from "./components/DialogNewEdit";
import { NewConfirmDialog } from "../../components/NewConfirmDialog";
import { IBusinessState } from "../../redux/reducers/businessReducer";
import { IAuthState } from "../../redux/reducers/authReducer";
import { AssigneesDialog } from "../../components/Revisiones/AssigneesDialog";
import { getBusiness } from "src/redux/actions/businessActions";

export const ProjectsBusinessScreen = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector<RootState, IAuthState>(
		(state) => state.authReducer
	);
	const currentBusinessId = user?.empresaReference?.id;

	const { projects, isLoading } = useSelector<
		RootState,
		IProjectsState
	>((state) => state.projectsReducer);

	const {
		edit: { selectedBusiness },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	useEffect(() => {
		if (user?.tipoUsuario === "UsuarioEmpresa") {
			dispatch(getBusiness(user.empresaReference.id));
			dispatch(getRevisions());
		} else dispatch(getRevisions(currentBusinessId));
	}, [currentBusinessId]);

	useEffect(() => {
		if (
			user?.tipoUsuario !== "Administrador" &&
			selectedBusiness !== null
		) {
			dispatch(
				getProjectUsers({ Empresa: selectedBusiness })
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBusiness]);

	const {
		open: openConfirmDialog,
		dialogConfirmHandleClose,
		dialogConfirmHandleConfirm,
		openDialog,
		selected: confirmDialogSelected,
	} = useConfirmDialog({
		onConfirm: (selected) => {
			dispatch(deleteProject(selected, selectedBusiness));
		},
	});

	const {
		open: openResponsibles,
		dialogConfirmHandleClose: dialogResponsiblesHandleClose,
		openDialog: openDialogResponsibles,
		selected: responsiblesDialogSelected,
	} = useConfirmDialog({
		onConfirm: () => {},
	});

	const {
		open,
		openEditDialog,
		openNewDialog,
		closeDialog,
		onAccept,
		action,
		selected,
	} = useNewEditDialog<any>({
		onAccept: (values, action) => {
			if (action === "New") {
				dispatch(addProject(values, selectedBusiness));
			} else if (action === "Edit") {
				console.debug("todo");
				// dispatch(
				// 	updateProject(selected, values, selectedBusiness)
				// );
			}
		},
		onOpenNewDialog: () => {
			// TOOD: solo funciona en vista administrador
			dispatch(
				getProjectUsers({ Empresa: selectedBusiness })
			);
		},
	});

	return (
		<React.Fragment>
			<Helmet title="Proyectos" />
			<Grid justify="space-between" container>
				<Grid item>
					<Typography
						variant="h3"
						gutterBottom
						display="inline"
					>
						{selectedBusiness?.Nombre}
					</Typography>

					<Breadcrumbs>
						<Typography>Proyectos</Typography>
					</Breadcrumbs>
				</Grid>
				<Grid item>
					<div>
						{user?.tipoUsuario !== "UsuarioEmpresa" && (
							<Button
								variant="contained"
								color="primary"
								disabled={
									projects.filter(
										// @ts-ignore
										(p) => p.Estado === "Activo"
									).length >=
									selectedBusiness?.Revisiones
										.LimiteRevisiones!
								}
								onClick={() => openNewDialog()}
							>
								<AddIcon />
								Nuevo Proyecto
							</Button>
						)}
					</div>
				</Grid>
			</Grid>

			<Divider
				style={{ marginBottom: 10, marginTop: 10 }}
			/>

			<Grid container spacing={6}>
				<Grid item xs={12}>
					<RevisionesTable
						canRestore={
							user?.tipoUsuario === "Administrador"
						}
						hidePercentage={
							user?.tipoUsuario === "UsuarioEmpresa"
						}
						canEdit={user?.tipoUsuario !== "UsuarioEmpresa"}
						type="revisiones"
						handleOpenEditDialog={openEditDialog}
						handleOpenConfirmDialog={openDialog}
						isLoading={isLoading}
						revisiones={projects}
						onArrowClick={(selected: any) => {
							dispatch(setSelectedProject(selected));
							// if (auth.user?.tipoUsuario==="")
							history.push(
								`proyectos/${selected.id}/revisiones`
							);
						}}
						handleOpenResponsiblesDialog={
							openDialogResponsibles
						}
					/>
				</Grid>
			</Grid>
			<DialogNewEdit
				open={open}
				onClose={closeDialog}
				onAccept={onAccept}
				action={action}
				title="Proyecto"
				selected={selected}
				business={{}}
				isRevision={true}
			/>
			<NewConfirmDialog
				open={openConfirmDialog}
				title={`¿Desea eliminar el Proyecto ${confirmDialogSelected?.NombreProyecto}?`}
				onClose={dialogConfirmHandleClose}
				onConfirm={dialogConfirmHandleConfirm}
				onConfirmText={"Eliminar"}
			/>
			<AssigneesDialog
				open={openResponsibles}
				onClose={dialogResponsiblesHandleClose}
				project={responsiblesDialogSelected}
			/>
		</React.Fragment>
	);
};
