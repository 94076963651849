import { Action } from "../../models/action";
import * as types from "../../constants";

export interface IPrioritiesState {
  priorities: any[];
  isLoading: boolean;
  error?: string;
}

const initialState = {
  priorities: [],
  isLoading: false,
} as IPrioritiesState;

export const prioritiesReducer = (
  state = initialState,
  action: Action
): IPrioritiesState => {
  switch (action.type) {
    case types.PRIORITIES_GET_DOCS:
      return {
        ...state,
        priorities: action.payload,
      };
    case types.PRIORITIES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.CHARGES_UPDATE_DOC:
      return {
        ...state,
        priorities: state.priorities.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    case types.CHARGES_REMOVE_DOC:
      return {
        ...state,
        priorities: state.priorities.filter((x) => x.id !== action.payload.id),
      };
    case types.PRIORITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
